/**
 *  app.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Punto de Entrada de la aplicación
 * 	@process: 2
 */

import React from 'react';
import Views from './core/views';

const App = () => ( 
    <div className = "App">
    <Views> </Views>  
    </div >
);

export default App;