import React, { Component } from 'react';
import { Consumer } from "../../context";
import { Link } from "react-router-dom";

//import Request from "../../core/httpClient";
//const request = new Request();

class Sidebar extends Component {
    state = {
        active: 0,
        user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0, idAdministracion: 0 },
		menus: [],
		menusLocal: [],
        activeSub: 0
    }

	componentDidUpdate(prevProps, prevState) {
		/*if(prevProps.context.menusCatalogos !== this.props.context.menusCatalogos) {
			this.menusLocal();
		}*/
	}

    render() {
        let menus = [];
        if (Array.isArray(this.state.menus)) {
            menus = this.state.menus;
        }

        return (
            <div className="sidebar row">
                <div className="column content-side">
                    {
						menus.map((item, index) => {
                        	return (
								item.recordset ?
								<React.Fragment key={index}>
									<button className={`accordion color-white ${this.state.active.toString() === item.id_menu.toString() ? 'active' : ''}`}
										onClick={this.parentClick.bind(this, item)}>
										{item.parent_name}
										<i className="fas fa-angle-down font-regular color-white"></i>
									</button>
									{item.recordset.map((list, ind) =>
										<div className={`panel ${item.open ? 'maxHeight' : ''}`} key={ind}>
											<div className={`row nav-item auto ${this.state.activeSub.toString() === list.id_menu.toString() ? 'active-item' : ''}`} onClick={this.childrenClick.bind(this, list)}>
												<Link
													to={list.ruta}
													className="font-regular color-white">
													<span className="color-white">{list.nombre}</span>
												</Link>
											</div>
										</div>)}
								</React.Fragment> : null
							)
						})
					}
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.load();
        this.menusLocal();

        let submenu = window.localStorage.getItem('submenu');
        if (submenu) {
            submenu = JSON.parse(submenu);
            this.setState({ activeSub: submenu });
		}
    }

    menusLocal() {
		this.setState({loading: true});
        const menusLocal = window.localStorage.getItem('catalogos_menus');;
        let parentsOpenLocal = window.localStorage.getItem('parents_open');
        parentsOpenLocal = JSON.parse(parentsOpenLocal);
        if (menusLocal) {
            let menusObject = JSON.parse(menusLocal);
            menusObject = menusObject.map(menuList => {
                if(parentsOpenLocal) {
                    if(parentsOpenLocal.find(p => p.menu.toString() === menuList.id_menu.toString())) {
                        menuList.open = true;
                    }
                    return menuList;
                }
                return menuList;
            })
            this.setState({ menus: menusObject, loading: false });
        } else {
			this.setState({ menus: [], loading: false });
		}
    }

    parentClick(item) {
        this.setState({ active: item.id_menu });
        Object.assign(item, { open: !item.open });
        window.localStorage.setItem('catalogos_menus', JSON.stringify(this.state.menus));
        let parentsOpen = [];
        const parentsOpenLocal = window.localStorage.getItem('parents_open');
        if(parentsOpenLocal) {
            parentsOpen = JSON.parse(parentsOpenLocal);
            if(parentsOpen.find(parent => parent.menu.toString() === item.id_menu.toString())) {
                parentsOpen = parentsOpen.filter(parent => parent.menu.toString() !== item.id_menu.toString());
            } else {
                parentsOpen.push({menu: item.id_menu});
            }
            window.localStorage.setItem('parents_open', JSON.stringify(parentsOpen));
        } else {
            window.localStorage.setItem('parents_open', JSON.stringify([{menu: item.id_menu}]));
        }
    }

    childrenClick(list) {
        this.setState({ activeSub: list.id_menu });
        window.localStorage.setItem('submenu', list.id_menu);
    }

    async load() {
        //console.log(this.props);
        const user = await this.props.context.loadUser();
        //console.log(user);
        if (user) {
            this.setState({
                user: {
                    idUsuario: user.id.id_usuario,
                    idPerfil: user.id.id_perfil,
                    idFraccionamiento: user.id.id_fraccionamiento,
                    idAdministracion: user.id.id_administracion
                }
            });
        }
        this.getPermissions();
    }

    async getPermissions() {
        // let data = {

        //     idPerfil: this.state.user.idPerfil
        // }

        // const response = await request.post("/profiles/get/permissions", data)

    }
}

export default Consumer(Sidebar);