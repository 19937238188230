import React, { Component } from 'react';
import moment from "moment";

class NotificationHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        // let notificaciones = []
        // if (this.props.notificaciones) {
        //     notificaciones = this.props.notificaciones
        // }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="column">

                    <table>
                        <thead>
                            <tr className="text-left">
                                <th>Tipo</th>
                                <th className="">Mensaje</th>
                                <th className="">Usuario</th>
                                <th className="text-left">Fecha de envío</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.notificaciones.slice(((this.props.page - 1) * 7), (this.props.page * 7)).map((notificacion, key) => {
                                return (

                                    <tr key={key}>

                                        <td>{notificacion.tipo}</td>
                                        <td>
                                            <p className="">{notificacion.notificacion_texto}</p>
                                        </td>
                                        <td>{notificacion.usuario}</td>
                                        <td>{moment(notificacion.fecha_envio).tz('GMT').format("DD-MM-YYYY")}</td>

                                    </tr>

                                )
                            }
                            )
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default NotificationHistoryTable;