import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import moment from "moment";
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete';
import { isValidPhoneNumber } from 'react-phone-number-input';
import FormNewInquilino from "../inquilinos/formNewInquilino";
import FormEditInquilino from "../inquilinos/formEditInquilino";
const request = new Request();

class InquilinoModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			startDate: new Date(),
			inquilinos: [],
			inquilino: {},
			idViv: 0,
			add: false,
			edditProp: false,
			search: false,
			pNombre: '',
			pDireccion: '',
			pMunicipio: '',
			pEstado: '',
			pCp: '',
			pTelefono: '',
			pPais: '',
			pGenero: 0,
			pId: 0,
			pNumId: '',
			pVigencia: '',
			pFechaCompra: '',
			pIdPersona: 0,
			isChecked: false,
			isPrincipal: false,
			viewOwners: true,
			email:'',
			id: 0
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.id !== prevProps.id) {
			this.getInquilinosVivienda();
		}
	}

	openModal(idModal, inq) {
		this.setState({message: null, inquilino: inq});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	delete = (idState, id, close) => {
		this.handleDelete(id, close)
	}

	propietario = data => {
		this.setState({
			pNombre: data.nombre,
			pDireccion: data.direccion,
			pMunicipio: data.municipio,
			pEstado: data.estado,
			pCp: data.cp,
			pTelefono: data.telefono,
			pPais: data.pais,
			pGenero: data.genero,
			pId: parseInt(data.tipo_identificacion),
			pNumId: data.num_identificacion,
			pVigencia: data.vigencia_identificacion,
			pFechaCompra: moment(data.fecha_compra).format("YYYY-MM-DD"),
			pIdPersona: data.id_persona,
			email: data.email
		});
	}

	componentDidMount() {
		this.getInquilinosVivienda();
	}

	setOwnersInfo(state) {
		this.setState({
			IdViv: this.props.id,
			pNombre: state.INombre,
			pDireccion: state.IDireccion,
			pMunicipio: state.IMunicipio,
			pEstado: state.IEstado,
			pCp: state.ICp,
			pTelefono: state.iTelefono,
			pPais: state.IPais,
			pGenero: state.IGenero,
			pId: state.IId,
			pNumId: state.iNumId,
			pVigencia: state.iVigencia || '',
			pFechaIngreso: state.IFechaIngreso,
			pFechaSalida: state.IFechaSalida,
			idPersona: state.IIdPersona,
			principal: state.isPrincipal,
			email: state.email
		});
	}

  	render() {
		let id = 0
		if (this.props.id) {
			id = this.props.id;
		}
    	return (
			<div className="full row align-center">
				<div className="column full">
					<div className="">
						<div className="justify-end">
							<button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.openModal.bind(this,"addOwner",id)} >
								<i className="fas fa-plus"/> Añadir
							</button>
						</div>
					</div> 
					<div className="column card-table">
						<div className="table-responsiv column">
							<table>
								<thead>
									<tr className="text-left">
										<th className="">Nombre - Teléfono</th>
										<th className="text-center th-options-small">Editar</th>
										<th className="text-center th-options-small">Borrar</th>
									</tr>
								</thead>
								<tbody>
								{	this.state.loadingInquilinos? 
									<tr>
										<td colSpan="4">
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										</td>
									</tr>
									:
									this.state.inquilinos.map((inquilino, key) => (
									<tr key={key}>
										<td className="text-left">
												{inquilino.nombre} - {" "}{inquilino.telefono}
										</td>
										
										<td className="text-center ">
											<div className="aling-center justify-center">
												<button type="button" className="btn-full justify-center btn-mini btn-secondary color-white" onClick={this.openModal.bind(this, 'editOwner', inquilino)}>
													<i className="font-small fas fa-pen" />
												</button>
											</div>
										</td>
										<td className="text-center">
											<div className="aling-center justify-center">
												<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, inquilino)} style={{ padding: '5px' }} >
													<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
												</button>
											</div>
										</td>    
									</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				{/* botones para enviar el formulario */}
				</div>

				<ModalBorrar 
					icono={true}
					id={this.state.id}
					delete={this.delete.bind(this, this.state.id)} />

				<DinamicModal 
					idModal={'addOwner'}
					sizeModalContainer={'big'}
					title={'AÑADIR INQUILINOS ASOCIADOS A LA VIVIENDA'} 
					success={this.handleSubmit.bind(this)} 
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}>
					<FormNewInquilino setInfo={this.setOwnersInfo.bind(this)} editVivienda={this.props.editVivienda} idVivienda={this.props.id}/>
				</DinamicModal>

				<DinamicModal
					idModal={'editOwner'}
					sizeModalContainer={'big'}
					title={'EDITAR INQUILINOS ASOCIADOS A LA VIVIENDA'} 
					success={this.handleUpdate.bind(this)} 
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}>
					<FormEditInquilino telefonoError={this.state.telefonoError} setInfo={this.setOwnersInfo.bind(this)} inquilino={this.state.inquilino}/>	
				</DinamicModal>
			</div>
		);
	}

	openModalDelete(prop) {
		this.setState({id: prop.id_inquilino});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	handleChecked(id) {
		this.makePrincipal(id);
	}

	showSearch() {
		this.setState({ search: !this.state.search });
	}

	makePrincipal(id) {
		let check = document.getElementById("check");
	}

	handleOpen(id) {
		this.setState({ showModal: !this.state.showModal, viewOwners: true });
		this.getInquilinosVivienda(id);
	}

	handleAddProp() {
		this.setState({ add: !this.state.add, edditProp: false, viewOwners: false });
		this.limpiarState();
	}

	handleCancel() {
		this.setState({ add: false, viewOwners: true });
		this.limpiarState();
	}

	limpiarState() {
		this.setState({
		pNombre: "",
		pDireccion: "",
		pMunicipio: "",
		pEstado: "",
		pCp: "",
		pTelefono: "",
		pPais: "",
		pGenero: 0,
		pId: 0,
		pNumId: "",
		pVigencia: 0,
		pFechaCompra: "",
		pIdPersona: 0,
		edditProp: false,
		search: false,
		isPrincipal: false,
		edditProp:false,
		email:''
		});
	}

	handleDateChange(date, event) {
		this.setState({ startDate: date });
	}

	handleChangeEddit(date) {
		this.setState({ pFechaCompra: document.getElementById("fecha").value });
	}

	handleClose() {
		this.setState({
			showModal: !this.state.showModal,
			pNombre: "",
			pDireccion: "",
			pMunicipio: "",
			pEstado: "",
			pCp: "",
			pTelefono: "",
			pPais: "",
			pGenero: 0,
			pId: 0,
			pNumId: "",
			pVigencia: 0,
			pFechaCompra: "",
			pIdPersona: 0,
			edditProp: false,
			search: false,
			viewOwners: true,
			add: false,
			email:''
		});
	}

	async handleDelete(id, close) {
		let data = {
			id_inquilino: id
		};
		const response = await request.post("/viviendas/inquilino/delete", data);
		if(response.deleted) {
			close();
		}
		this.getInquilinosVivienda();
		this.props.reload();
	}

	showSearch() {
		this.setState({ search: !this.state.search })
	}

	edditProp(idPersona) {
		this.setState({ edditProp: true, add: false, viewOwners: false });
		console.log(this.state);
		this.handlePropietarioDetalle(idPersona);
	}

	// TRAE TODOS LOS CONTACTOS DE LA VIVIENDA

	async getInquilinosVivienda() {
		this.setState({loadingInquilinos: true});
    	let data = { 
			idVivienda: this.props.id,
			idFraccionamiento: localStorage.getItem('frac')
		};

		const response = await request.post("/viviendas/vivienda/inquilinos", data);
    	if (response && !response.error) {
			if (response.inquilinoVivienda && !response.empty) {
				console.log(response.inquilinoVivienda);
				this.setState({
					inquilinos: response.inquilinoVivienda,
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					inquilinos: []
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.setState({loadingInquilinos: false});
	}

	async handleSubmit(event) {
    	event.preventDefault();
		this.setState({loadingSave: true});
    	if (this.state.pIdPersona === 0) {
      		let data = {
				idVivienda: this.props.id,
				Nombre: this.state.pNombre,
				Direccion: this.state.pDireccion,
				Municipio: this.state.pMunicipio,
				Estado: this.state.pEstado,
				cp: this.state.pCp,
				Telefono: this.state.pTelefono,
				pais: this.state.pPais,
				Genero: this.state.pGenero,
				Id: this.state.pId,
				NumId: this.state.pNumId,
				Vigencia: this.state.pVigencia,
				Fechaingreso: this.state.pFechaIngreso,
				FechaSalida: this.state.pFechaSalida,
				IdPersona: this.state.idPersona,
				principal: this.state.isPrincipal,
				email:this.state.email,
				idFraccionamiento: localStorage.getItem('frac')
      		};
		  const response = await request.post("/viviendas/inquilino/crear", data);
		//   addOwner
		console.log(response);
		  if(response.created){
			this.setState({message:"NUEVO INQUILINO AÑADIDO"});
			  this.props.reload();
			  this.getInquilinosVivienda();
		  }else{
			this.setState({message:"NO SE PUDO AÑADIR NUEVO INQUILINO"});
		  }
		
		this.setState({loadingSave: false});
    }
  }

  async handleUpdate(event) {
	this.setState({loadingSave: true});
	event.preventDefault();
	/**Validar Telefono */
	if(!isValidPhoneNumber(this.state.pTelefono)) {
		this.setState({telefonoError: 'Número inválido', loadingSave: false});
		return;
	} else {
		this.setState({telefonoError: undefined});
	}
	let data = {
		idVivienda: this.props.id,
		Nombre: this.state.pNombre,
		Direccion: this.state.pDireccion,
		Municipio: this.state.pMunicipio,
		Estado: this.state.pEstado,
		cp: this.state.pCp,
		Telefono: this.state.pTelefono,
		pais: this.state.pPais,
		Genero: this.state.pGenero,
		Id: this.state.pId,
		NumId: this.state.pNumId,
		Vigencia: this.state.pVigencia,
		Fechaingreso: moment(this.state.pFechaIngreso).format("YYYY-MM-DD"),
		FechaSalida: moment(this.state.pFechaSalida).format("YYYY-MM-DD"),
		Code: this.state.code,
		IdPersona: this.state.idPersona,
		email: this.state.email,
		idFraccionamiento: localStorage.getItem('frac') || 0
	}
	console.log(data);
	const response = await request.post("/viviendas/inquilino/update", data);
	if(response.updated){
		this.props.reload();
		this.getInquilinosVivienda();
	}else{
		this.setState({message: "NO SE PUDO EDITAR INQUILINO"});
	}
	this.setState({loadingSave: false});
}

  // MUESTRA LOS DATOS DE UN ÚNICO propietario EN FORMULARIO
  async handlePropietarioDetalle(idPersona) {

    let data = { idPersona: idPersona };

    const response = await request.post("/viviendas/vivienda/get/propietario", data);

    if (response && !response.error) {
      if (response.propietarioVivienda && !response.empty) {
        console.log(response);
        this.setState({
          pNombre: response.propietarioVivienda[0].nombre,
          pDireccion: response.propietarioVivienda[0].direccion,
          pMunicipio: response.propietarioVivienda[0].municipio,
          pEstado: response.propietarioVivienda[0].estado,
          pCp: response.propietarioVivienda[0].cp,
          pTelefono: response.propietarioVivienda[0].telefono,
          pPais: response.propietarioVivienda[0].pais,
          pGenero: response.propietarioVivienda[0].genero,
          pId: response.propietarioVivienda[0].tipo_identificacion,
          pNumId: response.propietarioVivienda[0].num_identificacion,
          pVigencia: response.propietarioVivienda[0].vigencia_identificacion,
          pFechaCompra: moment(response.propietarioVivienda[0].fecha_compra).format('MM-DD-YYYY'),
          pIdPersona: response.propietarioVivienda[0].id_persona,
          email: response.propietarioVivienda[0].email
        });

      } else {
        this.setState({ empty: true, message: response.message });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
      });
    }
  }
}
export default InquilinoModal;