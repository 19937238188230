import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import ReactModal from "react-modal";
import Request from "../../core/httpClient";
const request = new Request();


class EdditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            id: this.props.id,
            tipo: '',
            texto: '',
            notificacionestipe: []


        }
	}

	componentDidUpdate(prevProps) {
		if(this.props.id !== prevProps.id) {
			this.getNotificacion();
		}
	}

	componentDidMount() {
		this.getNotificacion();
	}

    render() {
        return (
            <div>
				<DinamicModal 
					idModal             =   {'editNotificationsDef'}
					sizeModalContainer  =   {'small'}
					title               =   {'EDITAR NOTIFICACIÓN'} 
					success             =   {this.handleSubmit.bind(this)} 
					showBtnSuccess      =   {true}
					btnTextSuccess      =   {"ACTUALIZAR"}
					showBtnCancel       =   {true}
					btnTextCancel       =   {"CANCELAR"}
					loadingSave         =   {this.state.loading}
					message             =   {this.state.message}
				>
				{this.renderEditNotificationsDef()}
				</DinamicModal>
            </div>

        );
	}
	
	renderEditNotificationsDef() {
		return(
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-big align-start">
						<p><b>  Tipo de notificación: </b></p>
					</div>
					<select name="tipo" id="" className="input input-select-type" value={this.state.tipo} onChange={event => this.setState({ tipo: event.target.value })}>
						{
							this.state.notificacionestipe.map((value, key) => (
							<option key={key} value={value.Descripcion}>{value.Descripcion}</option>
						))}
					</select>
				</div>
				<div className="white-space-16"></div>
				<div className="row full justify-start">
				<p>
					<b>Mensaje:</b>
				</p>
				</div>
				<div className="white-space-4" />
				<textarea className="textarea" value={this.state.texto} onChange={event => this.setState({ texto: event.target.value })} name="descriptionMessage" placeholder="Mensaje a enviar (600 caracteres máximo)" cols="50" rows="8" maxLength="600" minLength="20" required></textarea>
			</div>
		</div>
		)
	}

    handleOpen() {
        this.setState({ showModal: !this.state.showModal })
        this.getNotificacion()
	}

    async getTipeNotification() {
		this.setState({ notificacionestipe: [], loadingType: true });
        let data = {
            id: parseInt(localStorage.getItem('frac'))
        }
        const response = await request.post("/notificaciones/get/tipe", data);

        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({
                    notificacionestipe: response.notifications
                });
            } else {
                this.setState({
                    empty: true,
                    loading: false,
                    showModal: false
                });
            }
        } else {
            this.setState({
                error: true,
                loading: false,
                showModal: false
            });
		}
		this.setState({loadingType: false});
	}

    async getNotificacion() {

        let data = {

            idNotificacion: this.props.id
        }
        const response = await request.post("/notificaciones/get/notification", data)

        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({

                    tipo: response.notifications[0].tipo,
                    texto: response.notifications[0].texto

                });
            } else {
                this.setState({ empty: true });
            }
        } else {
            this.setState({ error: true });
        }
        this.getTipeNotification()

    }
    async handleSubmit(event) {
		this.setState({loading: true});
        event.preventDefault();
        let data = {
            texto: this.state.texto,
            tipo: this.state.tipo,
            idNotificacion: this.props.id
        }
        console.log(data);
        const response = await request.post("/notificaciones/update/notification", data)

        
        if (response.updated) {
            this.setState({
                tipo: '',
                texto: '',
                showModal: false
            });

            this.props.reload()
            
        } else {
            this.setState({
                empty: true,
                message: "NO SE PUDO EDITAR NOTIFICACIÓN "
            });
        }
        
		this.setState({loading: false});
    }
}

export default EdditModal;