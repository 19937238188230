import React, { Component } from 'react';
import ConceptModalEddit from './conceptModalEddit';
import ModalBorrar from '../modals/modalDelete'
import Request from "../../core/httpClient";

const request = new Request();

class BudgetConceptTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			id_concepto:    '',
			concepto:       ''
		}
    }

    recargar() {
        this.props.reload()
    }
    delete = id => {

        this.handleDelete(id)
	}
	
	openModalDelete() {
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}
	
    render() {
        let conceptos = [];
        if (this.props.conceptos) {
            conceptos = this.props.conceptos
        }
        return (
            <div className="card-table card-table-budget card-owners column" id="card-owners">
                <div className="table-responsiv column">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="">Concepto</th>
                                <th className="date text-center th-options-small">Editar</th>
                                <th className="date text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                        {conceptos.slice(((this.props.page - 1) * 7),(this.props.page * 7)).map((concepto, key) => { 
                         return (  
                           
                                <tr key={key}>
                                    <td>{concepto.descripcion}</td>
                                    <td className="justify-arround text-center"> 
										<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this,"editBudget",concepto)}>
											<i className="font-small fas fa-pen" />
										</button>
                                    </td>
                                    <td className="text-center">
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, concepto.id_concepto)} style={{ padding: '5px' }} >
											<i className="font-text fas fa-trash-alt " />
										</button>
                                    </td>
                                </tr>
                        )})}
                        </tbody>
                    </table>
					<ConceptModalEddit datos={this.state.concepto} reload={this.recargar.bind(this)} />
					<ModalBorrar id={this.state.id_concepto} delete={this.delete.bind(this)} />
                </div>
            </div>

        );
	}
	
	openModalDelete(id) {
		this.setState({id_concepto: id});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	openModal(idModal,concept) {
		this.setState({mesagge: null,concepto: concept});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

    async handleDelete(id) {
        let data = {

            idConcepto: id
        }
        const response = await request.post("/presupuestos/presupuesto/delete/concept", data);


        if (response && !response.error) {
            if (response.updated && !response.empty) {
                this.props.reload()

                this.setState({
                    showModal: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }
    }
}

export default BudgetConceptTable;