/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Conexión con el Context API
import { GlobalContext } from "../context";

// Páginas Web
import Login from '../pages/login';
import DashBoard from '../pages/dashboard';
import Owners from '../pages/owners';
import Incidents from '../pages/incidents';
import Guests from '../pages/guests';
import Houses from '../pages/houses';
import Invitation from '../pages/invitation';
import Catalogos from '../pages/catalogos';
import SubFraccPage from '../pages/subfraccionamiento'
import Complete from '../pages/complete';
import UserPage from '../pages/users'
import AdministracionPage from '../pages/administracionPage'
import Emergency from '../pages/emergengency'
import NotificacionDefinition from '../pages/notificacionDefinition'
import NotificacionHistory from '../pages/notificationHistory'
import CommonAreas from '../pages/commonAreasPage'
import CommonAreasReservation from '../pages/commonAreasReservation'
import CommonAreasHistorry from '../pages/commonPageHistory'
import incidents from "../pages/incidents";
import IncidentsHistory from "../pages/incidentsHistoryPage"
import SecurityBitacora from "../pages/securitybitacora"
import Reports from "../pages/reports"
import BudgerConcepts from '../pages/budgetConcept';
import BudgetTeoric from '../pages/budgetTeoric';
import budgetExpenses from "../pages/budgetExpenses";
import Profiles from '../pages/profiles';
import Environment from '../pages/environment';
import accountStatement from '../pages/accountStatement'
import InquilinoPage from '../pages/inquilinoPage'
import NotificationTipe from '../pages/configurationType'
import ConfigurationTypeReports from '../pages/configurationTypeReports'
import FamiliaresHistory from '../pages/familiaresHistory'
import personalDomesticoHistory from '../pages/personalDomesticoHistory';
import vacacionalHistory from '../pages/vacacionalHistoty';
import Contactos from '../pages/contactos';
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
/**Socket */
import Socket from './socket';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => {
                const usuario = window.localStorage.getItem("iGateAdmin");
                if (usuario) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    );
                }
            }
        }
    />
);

class Routes extends Component {
	componentDidMount() {
		Socket.connect();
		Socket.emit('action', { type: 'listAdmin', data:{idfrac: localStorage.getItem('frac')} });
		Socket.on('newEmergency', (data) => {
			toaster.notify(`El inquilino ${data.name || ''}, tiene una emergencia`, {
				duration: 10000
			});
		})
	}

    render() {
        return (
            <GlobalContext>
                <BrowserRouter>
                    <div className="flex main">
                        <div className="column full">
                            <Switch>
                                <Route path="/" exact component={Login} />
                                <Route exact path="/login" component={Login} />
                                <PrivateRoute exact path="/tablero" component={DashBoard} />
                                <PrivateRoute exact path="/catalogos" component={Catalogos} />
                                <PrivateRoute exact path="/catalogos/viviendas" component={Houses} />
                                <PrivateRoute exact path="/catalogos/propietarios" component={Owners} />
                                <PrivateRoute exact path="/catalogos/inquilinos" component={InquilinoPage} />
								<PrivateRoute exact path="/catalogos/contactos" component={Contactos} />
                                <PrivateRoute exact path="/catalogos/incidencias" component={Incidents} />
                                <PrivateRoute exact path="/catalogos/subfraccionamientos" component={SubFraccPage} />
                                <PrivateRoute exact path="/catalogos/presupuestos/concepto" component={BudgerConcepts} />
                                <PrivateRoute exact path="/catalogos/presupuestos/teorico" component={BudgetTeoric} />
                                <PrivateRoute exact path="/catalogos/presupuestos/gastos" component={budgetExpenses} />
                                <PrivateRoute exact path="/catalogos/estados/cuentas" component={accountStatement} />
                                <PrivateRoute exact path="/users" component={UserPage} />
                                <PrivateRoute exact path="/administracion" component={Emergency} />
                                <PrivateRoute exact path="/administracion/emergencias" component={Emergency} />
                                <PrivateRoute exact path="/administracion/notificaciones/definicion" component={NotificacionDefinition} />
                                <PrivateRoute exact path="/administracion/notificaciones/historial" component={NotificacionHistory} />
                                <PrivateRoute exact path="/administracion/areas/instalaciones" component={CommonAreas} />
                                <PrivateRoute exact path="/administracion/areas/reservacion" component={CommonAreasReservation} />
                                <PrivateRoute exact path="/administracion/areas/historial" component={CommonAreasHistorry} />
                                <PrivateRoute exact path="/administracion/quejas/pendientes" component={incidents} />
                                <PrivateRoute exact path="/administracion/quejas/historial" component={IncidentsHistory} />
                                <PrivateRoute exact path="/administracion/quejas/tipos" component={ConfigurationTypeReports} />
                                <PrivateRoute exact path="/administracion/bitacora/historial" component={SecurityBitacora} />
                                <PrivateRoute exact path="/administracion/invitados/familiar" component={FamiliaresHistory} />
                                <PrivateRoute exact path="/administracion/invitados/personalDom" component={personalDomesticoHistory} />
                                <PrivateRoute exact path="/administracion/invitados/vacacional" component={vacacionalHistory} />
                                <PrivateRoute exact path="/reportes" component={Reports} />
                                <PrivateRoute exact path="/perfiles" component={Profiles} />
                                <PrivateRoute exact path="/entornos" component={Environment} />
                                <PrivateRoute exact path="/invitados" component={Guests} />
                                <PrivateRoute exact path="/notificaciones/tipos" component={NotificationTipe} />
                                <Route path="/complete/:id" exact component={Complete} />
                                <Route path="/invitation/:id" exact component={Invitation} />
                            </Switch>
                        </div>
                    </div>
                </BrowserRouter>
            </GlobalContext>
        );
    }
}

export default Routes;