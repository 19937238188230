import React, { Component } from 'react';
import ReactModal from "react-modal";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
const request = new Request();


class ReportTypeEddit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal:      false,
            Descripcion:    this.props.tipo.descripcion,
            id:             this.props.tipo.id_tipoqueja,
            loadingSave:    false
        }
    }
    
    componentDidUpdate(prevProps) {
		if (this.props.tipo !== prevProps.tipo) {
			this.setState({
				Descripcion: this.props.tipo.descripcion,
				id: this.props.tipo.id_tipoqueja
			});
		}
	}

    render() {
        return (
            <div>
               	

				<DinamicModal 
					idModal             =   {'editNewTypeReport'}
					sizeModalContainer  =   {'small'}
					title               =   {'EDITAR TIPO DE REPORTE'} 
					success             =   { this.handleSubmit.bind(this)} 
					showBtnSuccess      =   { true}
					btnTextSuccess      =   {"ACTUALIZAR"}
					showBtnCancel       =   { true}
					btnTextCancel       =   {"CANCELAR"}
					loadingSave         =   { this.state.loadingSave}
					message             =   { this.state.message}
				>
				{this.renderEditTypeReport()}
				</DinamicModal>
				</div>

        );
	}

	renderEditTypeReport() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium align-start">
							<p><b>Tipo de reporte:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="conceptName" value={this.state.Descripcion}
								onChange={event => this.setState({ Descripcion: event.target.value })} className="input input-modals" placeholder="Nuevo tipo de reporte" required maxLength="64" />
						</div>
					</div>
				</div>
			</div>
		)
	}

    handleClose() { this.setState({ showModal: false }) }

    async handleSubmit(event) {
        event.preventDefault()

        let data = {

            Descripcion: this.state.Descripcion,
            id: this.state.id
        }
        const response = await request.post("/incidencias/update/type", data)

        
        if (response.edited) {
            this.setState({
                showModal: false
            });
            this.props.reload();
        } else {
            this.setState({
                empty:      true,
                message:    "NO SE PUDO EDITAR TIPO DE REPORTE"
            });
        }

        

    }
}

export default ReportTypeEddit;