import React, { Component, Fragment } from 'react';
import NumberFormat from "react-number-format";
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
const request = new Request();

class BudgetTableTeoric extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
			conceptos: [],
			concepto: {},
			idconcepto: 0,
			id_detalle: '',
			years: []
        }
    }

    componentDidMount() {
		this.getYears();
		this.getConcepts();
    }

	async getYears() {
		const res = await request.get('/presupuestos/get/years');
		if(res.anios) {
			this.setState({years: res.anios});
		} else {
			this.setState({years: []});
		}
	}

    editado() {
        this.props.getPresupuestos()
    }

    delete = (id, close) => {
        this.handleDelete(id, close)
	}

	openModal(idModal, concepto) {
		this.setState({concepto, importe: concepto.importe, formMessage: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

    render() {
        let abilitar = false
        if (this.props.disabled) {
            abilitar = this.props.disabled
        }

        let conceptosprop = []
		let total = 0;
		let totalReal = 0
        if (Array.isArray(this.props.presupuestos)) {
			conceptosprop = this.props.presupuestos;
			total = conceptosprop.reduce((accumulator, concepto) => (accumulator + concepto.importe), 0);
			totalReal = conceptosprop.reduce((accumulator, concepto) => (accumulator + concepto.total), 0);
		}

        return (
            <div className="card-table card-table-teoric card-owners column" id="card-owners">
                <div className="row align-center">
					<p className="font-regular weight-medium color-dark">
						Presupuesto:
					</p>
					<div className="column">
						<select className="input input-select" id="year" value={this.props.year} onChange={this.props.setYear.bind(this)}>
							{this.state.years.map(year => <option key={year.anio} value={year.anio}>{year.anio}</option>)}
						</select>
					</div>
                </div>
                <div className="white-space-16"></div>
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="">Concepto</th>
                                <th><p className="justify-end">Presupuesto</p></th>
								<th><p className="justify-end">Gasto acumulado</p></th>
                                <th className="text-center th-options-small">{this.props.subFrac > 0 && 'Editar'}</th>
                                <th className="text-center th-options-small">{this.props.subFrac > 0 && 'Eliminar'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {conceptosprop.length > 0 ? (
                                conceptosprop.slice(((this.props.page - 1) * 7), (this.props.page * 6)).map((concepto, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                {concepto.concepto}
                                            </td>
                                            <td>
												<p className="justify-end">
													<NumberFormat
														value={concepto.importe || 0}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
												</p>
                                            </td>
                                            <td> 
												<p className="justify-end">
													<NumberFormat
														value={concepto.total || 0}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
												</p>
											</td>
											{this.props.subFrac > 0 &&
                                            <td className="justify-center">
												<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this, 'editBudget', concepto)}>
													<i className="font-small fas fa-pen" />
												</button>
											</td>
											}
                                            {this.props.subFrac > 0 &&
											<td>
												<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, concepto.id_detalle)} style={{ padding: '5px' }} >
													<i className="font-text fas fa-trash-alt " />
												</button>
											</td>
											}
                                        </tr>

                                    )
                                })
                            ) :
                                null

                            }
                            <tr>
                                <td className="total-budget  align-end weight-bold"> Totales</td>
                                <td className="budget weight-bold">
									<p className="justify-end">
										<NumberFormat
											value={total}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"$"}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</p>
                                </td>
								<td className="weight-bold">
									<p className="justify-end">
										<NumberFormat
											value={totalReal}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"$"}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</p>
                                </td>
                                <td className="total-real align-rigth"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
				<ModalBorrar id={this.state.id_detalle} delete={this.delete.bind(this)} />
				<DinamicModal 
					idModal={'editBudget'}
					sizeModalContainer={'normal'}
					title={'EDITAR'} 
					success={this.handleSubmit.bind(this)} 
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.formMessage}
					>
					<div className="row full align-center">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Concepto:</b>
									</p>
								</div>
								<div className="column full">
									<select name="concepto" value={this.state.concepto.id_concepto} required disabled>
										{this.state.conceptos.map((concepto, key) => (
											<option value={concepto.id_concepto} key={key}>{concepto.descripcion}</option>
										))}
									</select>
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Importe: </b>
									</p>
								</div>
								<div className="column full">
									<input type="number" className='input' placeholder='$0.00' name='importe' className='input' onChange={event => this.setState({ importe: event.target.value })} value={this.state.importe} required />
								</div>
							</div>
						</div>
					</div>
				</DinamicModal>
            </div>
        );
    }

	handleClose() {
		this.props.handleClose();
	}

	openModalDelete(id) {
		this.setState({id_detalle: id});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleSubmit(event) {
		this.setState({loadingSave: true, formMessage: ''});
        event.preventDefault();
        const form = event.target;
        let data = {
			id_detalle: this.state.concepto.id_detalle,
            importe: parseFloat(form.importe.value)
        }
        const response = await request.post("/presupuestos/eddit/amount/concept", data)
		if (response.edited) {
			this.setState({formMessage: 'PRESUPUESTO ACTUALIZADO'});
			this.props.getPresupuestos();
		}
        this.handleClose();
		this.setState({loadingSave: false, formMessage: response.message});
	}

    // traer todos los conceptos por fraccionamiento
    async getConcepts() {

        this.setState({ conceptos: [], idconcepto: 0 })

        let data = {
            IdFraccionamiento: localStorage.getItem('frac')
        }
        let response = await request.post("/presupuestos/get/all/real/concepts", data)

        if (response && !response.error) {
            if (response.concepts && !response.empty) {
                this.setState({
                    conceptos: response.concepts
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
    }

    async handleDelete(id, close) {
        let data = {
            IdDetalle: id
        }
        const response = await request.post("/presupuestos/delete/budget/concept", data)
        if (response.deleted) {
			if(close) {
				close();
			}
		}
        this.props.getPresupuestos()
        this.handleClose()
    }
}

export default BudgetTableTeoric;