/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de todos los propietarios
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import OwnersTable from "../components/propietarios/Owners-Table";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import Autocomplete from '../components/controls/autocomplete';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

const request = new Request();

class Owners extends Component {
  	constructor(props) {
		super(props);
		this.state = {
			showModalOwners: false,
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
			houses: [],
			error: false,
			reload: false,
			fraccionamientoNombre: '',
			propietarios: [],
			idSubfrac: 0,
			searchHouse: false,
			nombre: '',
			pNombre: '',
			pDireccion: '',
			pMunicipio: '',
			pEstado: '',
			pCp: '',
			pTelefono: '',
			pPais: '',
			pGenero: 0,
			pId: 0,
			pNumId: '',
			pVigencia: '',
			pFechaCompra: '',
			pFechaVenta: '',
			pIdPersona: 0,
			pviv: '',
			pidViv: 0,
			page: 1,
			filterData: [],
			filter: '',
			keys: [],
			email: '',
			subfraccionamiento: localStorage.getItem('subfrac') || 0,
			subfraccionamientos: []
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getPropietarioFraccionamiento();
		}
		if((this.state.pIdPersona !== prevState.pIdPersona) || (this.state.pidViv !== prevState.pidViv)) {
			this.getFechas();
		}
	}

	reload() {
		this.getSubFraccionamiento()
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	reloadOwners() {
		this.getPropietarioFraccionamiento()

	}
	change() {
		this.getPropietarioFraccionamiento()
	}

	propietario = data => {
		this.setState({
			pNombre: data.nombre,
			pDireccion: data.direccion,
			pMunicipio: data.municipio,
			pEstado: data.estado,
			pCp: data.cp,
			pTelefono: data.telefono,
			pPais: data.pais,
			pGenero: data.genero,
			pId: parseInt(data.tipo_identificacion),
			pNumId: data.num_identificacion,
			pVigencia: data.vigencia_identificacion,
			pIdPersona: data.id_persona,
			search: false
		});
	}

	vivienda = data => {
		this.setState({
			pviv: data.numero_registro,
			pidViv: data.id_vivienda,
			searchHouse: false
		});
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}
	
	nuevoPropietario() {
		this.setState({showModalOwners: true})
	}

	setFilter(filter) {
		console.log(filter)
		this.setState({filter: filter.toLowerCase()});
	}

	filter(propietarios) {
		propietarios = propietarios.filter(pro => {
			if(pro.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if(pro.telefono.toLowerCase().indexOf(this.state.filter) !== -1){
				return true;
			} else if(pro.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1){
				return true;
			} else {
				return false;
			}
		})
		return propietarios;
	}

	async completeForm(item) {
		this.setState({ pNombre: item.label });
		if(item.value > 0) {
			const res = await request.get('/inquilinos/getbypersona', {id_persona: item.value, id_fraccionamiento: localStorage.getItem('frac')});
			if(res.inquilino) {
				let phone = '';
				if(res.inquilino.telefono) {
					if(isValidPhoneNumber(`+${res.inquilino.telefono}`)) {
						phone = parsePhoneNumber(`+${res.inquilino.telefono}`);
						console.log(phone);
						phone = phone.number;
					}
				}

				this.setState({
					pIdPersona: item.value,
					pTelefono: phone,
					email: res.inquilino.email || '',
					pId: res.inquilino.tipo_identificacion,
					pNumId: res.inquilino.num_identificacion,
					pGenero: res.inquilino.genero,
					pDireccion: res.inquilino.direccion,
					pMunicipio: res.inquilino.municipio,
					pCp: res.inquilino.cp,
					pEstado: res.inquilino.estado,
					pPais: res.inquilino.pais
				});
			}
		}
	}

  	render() {
		let propietarios = [];
		let dataSet;

		if (this.state.propietarios) {
			propietarios = this.state.propietarios;
			dataSet = propietarios;
			propietarios = this.filter(propietarios);
		}

		return (
		<div className="owners column">
			<Helmet>
				<title>iGate - Propietarios</title>
			</Helmet>
			<Header reload={this.reload.bind(this)} change={this.change.bind(this)} sidebar={true} active={'propietarios'} parent={'admin'} panel={'panel1'} nav={"catalogos"} />

			<div className="row">
			<div className="column" id="sidebar">
				<Sidebar />
			</div>
			<div className="column" id="content">
				<div className="justify-center">
					<div className="container column">
						<div className="white-space-32" />
						<Controls title="Propietarios"
							nuevo={this.openModal.bind(this,"newOwner")}
							dataSet={dataSet}
							setFilter={this.setFilter.bind(this)}
							subChange={this.subChange.bind(this)}/>
						<div className="white-space-16" />
						{this.state.loadingPropietarios?
							<div className="row justify-center">
								<i className="fas fa-spinner fa-spin"></i>
							</div>
							:
							<OwnersTable
								reload={this.getPropietarioFraccionamiento.bind(this)}
								data={propietarios}
								page={this.state.page}
								reloadOwners={this.reloadOwners.bind(this)}
							/>
						}
						<div className="white-space-16" />
						<Paginador pages={Math.ceil(propietarios.length / 7)} setPage={this.setPage.bind(this)} />
						<div className="white-space-32" />
					</div>
				</div>

				<DinamicModal 
					idModal={'newOwner'}
					sizeModalContainer={'big'}
					title={'AÑADIR PROPIETARIO'} 
					success={this.handledSubmit.bind(this)} 
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					closeModal={this.resetValue.bind(this)}
					loadingSave={this.state.loading}
					message={this.state.message}
				>
				{this.renderNewOwner()}
				</DinamicModal>
			</div>
			</div>
		</div>
		);
	}

	componentDidMount() {
		this.getPropietarioFraccionamiento();
		this.getPersonas();
		this.getViviendas();
	}

	openModal(idModal) {
		this.setState({message: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	async resetValue() {
		this.setState({
			pidViv: '',
			pNombre: '', 
			pTelefono: '', 
			pGenero:'', 
			email: '',
			pId: '', 
			pNumId: '', 
			pDireccion: '', 
			pMunicipio:'',
			pCp:'',
			pEstado:'',
			pPais:'',
			pFechaCompra:'',
			pFechaVenta: ''
		})
	}

	renderNewOwner() {
		let list_personas = [];
		let viviendas = [];
		if(Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map(persona => { return {label: persona.nombre, value: persona.id_persona}});
		}
		if(Array.isArray(this.state.viviendas)) {
			viviendas = this.state.viviendas;
		}
		return(
		<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b> Unidad:	</b></p>
					</div>
					<div className="column full">
						<select className="input" name="vivienda" required value={this.state.pidViv} onChange={(event) => this.setState({pidViv: event.target.value})}>
							<option value="0">SELECCIONAR VIVIENDA</option>
							{viviendas.map(viv => 
								<option key={viv.id_vivienda} value={viv.id_vivienda}>
									{viv.numero_registro}
								</option>
							)}
						</select>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>  Nombre completo:	</b></p>
					</div>
					<div className="column full">
						<Autocomplete
							assignValue={this.completeForm.bind(this)}
							list={list_personas}>
							<input type="text" name="fullName" value={this.state.pNombre}
								onChange={event => this.setState({ pNombre: event.target.value })}
								className="input input-modals" placeholder="Nombre completo del inquilino"
								required
								maxLength="64" />
						</Autocomplete>
					</div>
				</div>
				<div className="white-space-8"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-duo-big">
						<p><b>  Telefono:	</b></p>
					</div>
					<div className="column full">
						<PhoneInput
							displayInitialValueAsLocalNumber
							name="phoneNumber"
							country="MX"
							required
							placeholder="Número de teléfono"
							value={this.state.pTelefono}
							onChange={ value => this.setState({ pTelefono: value, telefonoError: undefined }) }
							error={ this.state.telefonoError }
							className={'input-modals'}/>
					</div>
					<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
							<b>  Género:	</b>
						</p>
					</div>
					<div className="column full">
						<select name="sex" id="" value={this.state.pGenero} required
							onChange={event => this.setState({ pGenero: event.target.value })} className="input input-modals">
							<option value="0">Hombre</option>
							<option value="1">Mujer</option>
							<option value="2">No especificado</option>
						</select>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="input-form-content row full align-center"> 
					<div className="column label-medium">
						<p>
							<b>Correo:</b>
						</p>
					</div>
					<div className="column full">
						<input type="email" name="EmailEdit" value={this.state.email}
						onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder='Email' required maxLength="24" />
					</div>
				</div>
				<div className="white-space-8" />
				<div className="input-form-content row full align-center "> 
					<div className="column label-duo-big">
						<p><b>  Identificación:	</b></p>
					</div>
					<div className="column full">
						<select name="id" id="idEddit" className="input input-modals" value={this.state.pId} onChange={event => this.setState({ pId: event.target.value })}>
						<option value="0">INE</option>
						<option value="1">Pasaporte/Visa</option>
						<option value="2">Curp</option>
						<option value="3">Cartilla Militar</option>
						</select>
					</div>
					<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
							<b>ID:</b>
						</p>
					</div>
					<div className="column full">
						<input type="text" name="numIdEddit" value={this.state.pNumId}
						onChange={event => this.setState({ pNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" />
					</div>
				</div>
				<div className="white-space-8" />
				<div className="input-form-content row full align-center ">
					<div className="column label-medium">
					<p>
						<b>Dirección:</b>
					</p>
					</div>
					<div className="column full">
					<input type="text" name="address" value={this.state.pDireccion}
						onChange={event => this.setState({ pDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" required maxLength="150" />
					</div>
				</div>
				<div className="white-space-8" />
				<div className="input-form-content row full align-center ">
					<div className="column label-duo-big">
					<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
						<b>Municipio:</b>
					</p>
					</div>
					<div className="column full">
					<input type="text" name="municipality" value={this.state.pMunicipio}
						onChange={event => this.setState({ pMunicipio: event.target.value })} className="input input-modals" placeholder='Municipio' required maxLength="80" />
					</div>
					<div className="column label-duo-normal space">
					<p  className="justify-start" style={{  paddingLeft: '20px' }}>
						<b>C.P.:</b>
					</p>
					</div>
					<div className="column full">
					<input type="text" value={this.state.pCp}
						onChange={event => this.setState({ pCp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' required maxLength="10" />
					</div>
				</div>
				<div className="white-space-8" />
				<div className="input-form-content row full align-center ">
					<div className="column label-duo-big">
					<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
						<b>Estado:</b>
					</p>
					</div>
					<div className="column full">
					<input type="text" name="state" value={this.state.pEstado}
						onChange={event => this.setState({ pEstado: event.target.value })} className="input input-modals" placeholder='Estado' required maxLength="24" />
					</div>
					<div className="column label-duo-normal space">
					<p  className="justify-start" style={{  paddingLeft: '20px' }}>
						<b>País:</b>
					</p>
					</div>
					<div className="column full">
					<input type="text" value={this.state.pPais}
						onChange={event => this.setState({ pPais: event.target.value })} name="country" className="input input-modals" placeholder='País' required maxLength="10" />
					</div>
				</div>
				<div className="white-space-8" />
				<div className="input-form-content row full align-center ">
					<div className="column label-duo-big">
						<p>
							<b>Fecha de compra:</b>
						</p>
					</div>
					<div className="column full">
						<input type="date" value={this.state.pFechaCompra}
							onChange={event => this.setState({ pFechaCompra: event.target.value })} required className='input input-modals' id='fcompra' />
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>Fecha de venta:</b>
						</p>
					</div>
					<div className="column full">
						<input type="date" value={this.state.pFechaVenta}
							onChange={event => this.setState({ pFechaVenta: event.target.value })} className='input input-modals' id='fventa' />
					</div>
				</div>
			</div>
		</div>
		)
	}

  	//traer Propietarios del fraccionaiento
  	async getPropietarioFraccionamiento() {
		this.setState({ loadingPropietarios: true });
		let IdSub = this.state.subfraccionamiento;
		const idFrac = localStorage.getItem('frac');

		let data = { IdSub, idFrac };

    	const response = await request.post("/fraccionamientos/get/propietarios", data);

		if (response && !response.error) {
			if (response.propietario && !response.empty) {
				const propietarios = response.propietario;
				this.setState({
					propietarios,
					filterData: propietarios
				});
			//console.log(response.propietario);
			} else {
				this.setState({
				propietarios: [],
				empty: true,
				message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.setState({ loadingPropietarios: false });
  	}

  	async handledSubmit(event) {
		event.preventDefault();
		this.setState({loading: true, message: ''});
		/**Validar Vivienda */
		if (!(this.state.pidViv > 0)) {
			this.setState({loading: false});
            alert("Para continuar debes seleccionar una vivienda")
            return;
		}
		/**Validar Telefono */
		if(!isValidPhoneNumber(this.state.pTelefono)) {
			this.setState({telefonoError: 'Número inválido', loading: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}

		let data = {
			IdViv: 				this.state.pidViv,
			Nombre: 			this.state.pNombre,
			Direccion: 			this.state.pDireccion,
			Municipio: 			this.state.pMunicipio,
			Estado: 			this.state.pEstado,
			Cp: 				this.state.pCp,
			Telefono: 			this.state.pTelefono,
			Pais: 				this.state.pPais,
			Genero: 			parseInt(this.state.pGenero),
			TipoId: 			parseInt(this.state.pId),
			NumId: 				this.state.pNumId,
			Vigencia: 			this.state.pVigencia,
			FechaCompra: 		this.state.pFechaCompra,
			FechaVenta: 		this.state.pFechaVenta,
			idPersona: 			this.state.pIdPersona,
			email: 				this.state.email,
			idFraccionamiento: 	localStorage.getItem('frac') || '0'
		};

    	const response = await request.post("/propietarios/propietario/create", data);

		this.setState({ loading: false, message: response.message });
		if(response.created) {
			this.setState({message: 'PROPIETARIO AÑADIDO'});
			this.getPropietarioFraccionamiento();
			// this.getViviendas();
			const modal = document.getElementById("newOwner");
			if( modal){
				modal.classList.add('hide-dinamic-modal');
				modal.classList.remove('show-dinamic-modal');
			}
		}else{
			this.setState({message: "NO SE PUDO AÑADIR PROPIETARIO"});
		}
		this.setState({ loadingSave: false });
  	}

	handleOpen() {
		this.setState({ showModalOwners: true });
	}

	handleClose() {
		this.setState({
		showModalOwners: false,
		pNombre: '',
		pDireccion: '',
		pMunicipio: '',
		pEstado: '',
		pCp: '',
		pTelefono: '',
		pPais: '',
		pGenero: '',
		pId: 0,
		pNumId: '',
		pVigencia: '',
		pFechaCompra: '',
		pIdPersona: 0,
		edditProp: false,
		pviv: '',
		email : ''
		});
	}

	async getPersonas() {
		const response = await request.post("/personas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.personas)) {
			this.setState({personas: response.personas});
		} else {
			this.setState({personas: []});
		}
	}

	async getViviendas() {
		const response = await request.post("/viviendas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.viviendas)) {
			this.setState({viviendas: response.viviendas});
		} else {
			this.setState({viviendas: []});
		}
	}

	async getFechas() {
		const res = await request.post('/propietarios/get/fechas', {IdPersona: this.state.pIdPersona, IdViv: this.state.pidViv});
		if(res.dates) {
			console.log(res.dates)
			this.setState({
				pFechaCompra: res.dates.fecha_compra || '',
				pFechaVenta: res.dates.fecha_venta || ''
			})
		}
	}
}

export default Consumer(Owners);
