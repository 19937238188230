/**
 *  invitation.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de visualización del QR
*/

import React, { Component } from "react";
import HeaderSimple from "../components/header/header-simple";
import Request from "../core/httpClient";
import moment from "moment";
import { Helmet } from "react-helmet";
const request = new Request();

class Complete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            states: [],
            image: '',
            data: '',
            telephone: '',
            id: 0
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true, showQR: false});
        const form = event.target;
        const data = {
            id: this.state.id,
            number: this.state.telephone,
            name: form.name.value,
            vivienda: this.state.vivienda,
            inquilino: this.state.inquilino
        }
        const res = await request.post('/invitaciones/set/guestdata', data);
        if(res.qr) {
            this.setState({qr: `data:image/jpeg;base64,${res.qr}`, nombre_invitado: res.nombre_invitado, expiracion: res.expiracion, showQR: true});
        }
        this.setState({message: res.message, loading: false});
    }

    render() {
        return(
            <div className="invitation column">
                <Helmet>
                    <meta property="og:title" content="iGate - Completa Información" />
                    <meta property="og:description" content="Tienes una nueva invitación desde iGate - Completa tu información" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="/" />
                </Helmet>
                <HeaderSimple />
                <div className="justify-center">
                    {this.state.loading?
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <div className="justify-center">
                            <i className="fas fa-spinner fa-2x fa-spin"></i>
                        </div>
                    </div>
                    :   this.state.showQR? 
                        <div className="container column">
                            <div className="white-space-32"></div>
                            <div className="justify-center">
                                <img src={this.state.qr} alt="qr" width="250px" height="250px"></img>
                            </div>
                            <div className="column">
                                <div className="white-space-32"></div>
                                <p className="text-center">
                                    Invitado: <b>{ this.state.nombre_invitado }</b>
                                </p>
                                <div className="white-space-16"></div>
                                <p className="text-center">
                                    Fecha de expiración: <b>{ moment(this.state.expiracion).format("DD-MM-YYYY") }</b>
                                </p>
                            </div>
                        </div>
                        :
                        <form className="container column" onSubmit={this.handleSubmit.bind(this)}>
                            <div className="white-space-24"></div>
                            <h1>
                                Completa tu información
                            </h1>
                            <div className="white-space-4"></div>
                            <p>
                                Esta información es necesaria obligatoria, no olvides presentar algún tipo de indentificación.
                            </p>
                            <div className="white-space-16"></div>
                            <p>Teléfono:</p>
                            <div className="white-space-4"></div>
                            <label className="input" required>{this.state.telephone}</label>
                            <div className="white-space-8"></div>
                            <p>Nombre completo:</p>
                            <div className="white-space-4"></div>
                            <input id="name" name="name" className="input" required></input>
                            <div className="white-space-8"></div>
                            <p>Pais:</p>
                            <div className="white-space-4"></div>
                            <select id="country" name="country" className="input" required onChange={()=>this.loadStates()}>
                                {this.state.countries.map(country => 
                                    <option key={country.id_pais} value={country.id_pais}>{country.pais}</option>
                                )}
                            </select>
                            <div className="white-space-8"></div>
                            <p>Estado:</p>
                            <div className="white-space-4"></div>
                            <select id="state" name="state" className="input" required>
                                {this.state.states.map(state => 
                                    <option key={state.id_estado} value={state.id_estado}>{state.estado}</option>
                                )}
                            </select>
                            <div className="white-space-16" />
                            <button className="btn btn-primary btn-small color-white" type="submit">REGISTRAR</button>
                            <div className="white-space-16" />
                            <div className="column align-center justify-center">
                                {this.state.message}
                            </div>
                        </form>
                    }
                </div>
            </div>
        );
    }

    componentDidMount() {
        if(this.props.match.params) {
            this.load(this.props.match.params);
        }

        this.loadCountries();
    }

    async load(params) {
        this.setState({loading: true});
        const res = await request.post('/invitaciones/get/databytoken', {token: params.id});
        if(res.number) {
            this.setState({telephone: res.number, id: res.id, vivienda: res.vivienda, inquilino: res.inquilino});
        }
        this.setState({error: res.error, message: res.message, loading: false});
    }

    async loadCountries() {
        const countries = await request.get('/fraccionamientos/get/countries');
        if(Array.isArray(countries)) {
            this.setState({countries});
        }
        this.loadStates();
    }

    async loadStates() {
        let states = [];
        const country = document.getElementById('country');
        if(country) {
            states = await request.get(`/fraccionamientos/get/states/${country.value}`);
        } else {
            states = await request.get(`/fraccionamientos/get/states/${1}`);
        }
        if(Array.isArray(states)) {
            this.setState({states});
        }
    }

}

export default Complete;
