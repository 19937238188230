/**
 *  incidents.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Inicio de Incidencias
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import IncidentsHistoryTable from '../components/incidents/incidentsHistoryTable';
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import moment from "moment";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";

const request = new Request();

class IncidentsHistory extends Component {

	constructor(props) {
		super(props);
		this.state = {
			incidentes: [],
			/* Estados para el filtro generico : filterData y keys */
			filterData: [],
			keys: [],
			page: 1,
			filter: '',
			subfraccionamiento: localStorage.getItem('subfrac')
		}
	}

	componentDidUpdate(prevProps, prevState) {
        if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
            this.getIncidencias();
        }
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    /* funcion para el filtro generico */

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        if (param1 === "todo") {
            this.setState({ incidentes: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ incidentes: filterData });
        }
    }

    reload() {
        this.getIncidencias()
        // this.getNombreSubFraccionamiento()
    }
    changeFrac() {
        this.getIncidencias()
        // this.getNombreSubFraccionamiento()
        // this.getHouses();
    }

    filtrar = idR => {
        this.handleChange(idR)
    }

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevaReporte() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(incidentes) {
		incidentes = incidentes.filter(inc => {
			if(inc.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (inc.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (inc.quejaDesripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return incidentes;
	}

    render() {
        let incidentes = [];
        let dataSet;

        if (Array.isArray(this.state.incidentes)) {
			incidentes = this.state.incidentes;
			dataSet = incidentes;
			incidentes = this.filter(incidentes);
        }

        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Incidentes</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'historialp'} nav="administracion" />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="white-space-32" />
                        <div className="navigation justify-center">
                            <div className="container column">
								<Controls title="Historial de incidencias"
									nuevo={this.nuevaReporte.bind(this)}
									dataSet={dataSet}
									newButton={false}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}/>
								<div className="white-space-16"></div>
								{this.state.loadingIncidencias?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
                                	<IncidentsHistoryTable incidentes={incidentes} page={this.state.page} filtrar={this.filtrar.bind(this)} />
								}
								<div className="white-space-16"></div>
                                <Paginador pages={Math.ceil(incidentes.length / 7)} setPage={this.setPage.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.getIncidencias()
    }

    async getIncidencias() {
		this.setState({ incidentes: [], loadingIncidencias: true  });
		let idSub = this.state.subfraccionamiento || 0;
        let data = {
            Fecha: moment(new Date()).format("YYYY-MM-DD"),
			IdFraccionamiento: localStorage.getItem('frac'),
			idSub
        };

        const response = await request.post("/incidencias/get/total", data);

        if (response && !response.error) {
            if (response.incidenciasTotales && !response.empty) {
                this.setState({
                    incidentes: response.incidenciasTotales,
                    filterData: response.incidenciasTotales,
                    keys: Object.keys(response.incidenciasTotales[0]).filter(key => key.indexOf('id') === -1),
                    loadingIncidencias: false
				});
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loadingIncidencias: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loadingIncidencias: false
            });
		}
		this.setState({ loadingIncidencias: false });
    }

    handleOpen(idQueja) {
        this.setState({ showModal: true, Rdescription: '' });
        this.getIncidenciaDetalle(idQueja);
	}

    // filtro por resolucion
    async handleChange() {

        let idR = parseInt(document.getElementById('resolucion').value);
        this.setState({ incidentes: [] })
        let observable = JSON.parse(localStorage.getItem('object'))
        if (!observable) {
            return
        }

        let data = {
            resolution: idR,
            idFraccionamiento: observable.if
        }
        const response = await request.post("/incidencias/filtro/resolucion", data)
        if (response && !response.error) {
            if (response.incidencias && !response.empty) {
                this.setState({
                    incidentes: response.incidencias,
                    filterData: response.incidencias,
                    keys: Object.keys(response.incidencias[0]).filter(key => key.indexOf('id') === -1)
                })

            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
	}
}

export default Consumer(IncidentsHistory);