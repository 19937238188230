import React, { Component } from 'react';
import PersonalDomesticoTableEdit from './personalDomesticoTableEdit';
import ModalBorrar from '../modals/modalDelete';
import Request from "../../core/httpClient";
const request = new Request();

class PersonalDomesticoHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invitado: {}
		}
	}

	openModal(idModal, invitado) {
		this.setState({invitado});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	openModalDelete(inv) {
		this.setState({id_frecuente: inv.id_frecuente});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleDelete(id, close) {
        let data = {
            id_frecuente: id,
		}
		const response = await request.post('/invitadosFrecuentes/invitados/borrar', data);
		this.setState({message: response});
		this.props.reload();
		if(close) {
			close();
		}
    }

    render() {
        let invitados = []

        if (this.props.invitados) {
            invitados = this.props.invitados
        }

        return (
            <div>
                <div className="table-responsiv column">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="">Nombre</th>
								<th className="">Telefono</th>
                                <th className="">Unidad</th>
                                <th className="text-left">Acceso</th>
                                <th className="text-left">Tipo</th>
                                <th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
							{invitados.slice((this.props.page - 1) * 7, this.props.page * 7).map((invitado, key) => (
                                <tr key={key}>
                                    <td>{invitado.invitado}</td>
									<td>{invitado.telefono}</td>
                                    <td>{invitado.numero_registro}</td>
                                    <td>{invitado.acceso}</td>
                                    <td>Personal Domestico</td>
									<td className="text-center">
										<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this,"editDomestico", invitado)}>
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td>
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, invitado)} style={{ padding: '5px' }} >
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
				{<PersonalDomesticoTableEdit 
					reload		=	{this.props.reload.bind(this)} 
					invitado	=	{this.state.invitado} 
				/>}
				<ModalBorrar
					id		=	{this.state.id_frecuente}
					delete	=	{this.handleDelete.bind(this)}
				/>
            </div>
        );
	}
}

export default PersonalDomesticoHistoryTable;