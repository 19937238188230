import React, { Component } from 'react';
import Request from "../../core/httpClient";
const request = new Request();

class HousesSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viviendas: []
        }
    }
    render() {
        return (
            <div className="column left full">
                <div className="align-center">
                    <div className="align-center justify-between">
                        <p>Número de registro: </p>
                        <input type="search" name="" className="input input-modals-small" id='reg' placeholder="Número de identificación"
                            required />
                        <button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-inqulino-add" onClick={this.handleSearch.bind(this)}>
                            <i className="font-text fas fa-search" /> Buscar
                        </button>
                    </div>
                </div>
                <div className="white-space-16"></div>
                {this.state.viviendas.length ? ('') : <h3 className="text-center">Sin resultados.</h3>}
                {this.state.viviendas.map((vivienda, key) => (
                    <div className='text-center' key={key}>
                        <button type='button' onClick={this.enviar.bind(this, vivienda)}>
                            <h4 className="text-center"> {vivienda.numero_registro}</h4>
                        </button>
                    </div>
                ))}
            </div>
        );
    }
    async handleSearch() {
        let data = {
            NumReg: document.getElementById('reg').value,
            IdSub: localStorage.getItem('subfrac')
        }

        const response = await request.post("/viviendas/vivienda/search", data);

        if (response && !response.error) {
            if (response.viviendas && !response.empty) {
                this.setState({
                    viviendas: response.viviendas,
                });
            } else {
                this.setState({

                    propietarios: [],
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }



    }
    enviar(vivienda) {
        this.props.vivienda(vivienda);
    }


}

export default HousesSearch;