import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import ReactModal from 'react-modal'
import CommonAreaTable from '../components/commonAreas/commonTable'
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import { runInThisContext } from 'vm';
import { read } from 'fs';
const request = new Request();



class CommonAreas extends Component {
	state = {
		showModal: false,
		commonAreas: [],
		categories: [],
		categoria: 0,
		/* Estados para el filtro generico : filterData y keys */
		filterData: [],
		keys: [],
		page: 1,
		filter: '',
		subfraccionamiento: localStorage.getItem('subfrac'),
		subfraccionamientos: [],
		imageArea: '',
		idSubfrac: 0
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getCommonAreas();
		}
	}

	openModal(idModal) {
		this.setState({ mesagge: null, imageArea: '' });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	reload() {
		this.getCommonAreas()
	}

	changeFrac() {
		this.getCommonAreas()
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	}

	nuevaArea() {
		this.getCategories();
		this.setState({ showModal: true });
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	filter(CommonAreas) {
		CommonAreas = CommonAreas.filter(com => {
			if (com.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (com.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return CommonAreas;
	}

	render() {
		let CommonAreas = [];
		let dataSet;

		if (Array.isArray(this.state.commonAreas)) {
			CommonAreas = this.state.commonAreas;
			dataSet = CommonAreas;
			CommonAreas = this.filter(CommonAreas);
		}
		return (
			<div className="admin column">
				<Helmet>
					<title>iGate - Administración</title>
				</Helmet>
				<Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'activas'} parent={'areas'} panel={'panel3'} nav={'administracion'} />
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarAdministracion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								<Controls title="Áreas comunes"
									nuevo={this.openModal.bind(this, "newCommonArea")}
									dataSet={dataSet}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)} />
								<div className="white-space-16" />
								{this.state.loading ?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<CommonAreaTable 
										commonAreas			=	{CommonAreas} 
										subfraccionamientos	=	{this.state.subfraccionamientos} 
										page				=	{this.state.page} 
										reload				=	{this.reload.bind(this)} 
									/>
								}
								<div className="white-space-16"></div>
								<Paginador pages={Math.ceil(CommonAreas.length / 7)} setPage={this.setPage.bind(this)} />
							</div>
						</div>
					</div>
				</div>

				<DinamicModal
					idModal				=	{'newCommonArea'}
					sizeModalContainer	=	{'big'}
					title				=	{'NUEVA ÁREA COMÚN'}
					success				=	{this.handleSubmit.bind(this)}
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
				>
					{this.renderNewCommonArea()}
				</DinamicModal>


			</div>
		);
	}

	componentDidMount() {
		this.getCommonAreas();
		this.getCategories();
		this.getSubFraccionamientos();
	}

	showPreviewImageCommonArea() {
		let file = document.getElementById("fileImage").files[0];
		let reader = new FileReader();
		if (file) {
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				//document.getElementById("previewArea").src = reader.result;
				this.setState({imageArea: reader.result});
				
			}
			
		}
	}

	renderNewCommonArea() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-big">
							<p>
								<b>Nombre:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="commonArea" className="input input-modals" placeholder="Nombre de  área común" required
								maxLength="150"
							/>
						</div>
						<div className="column labe-normal space">
							<h5> <b>Activo: </b> </h5>
						</div>
						<div className="column space">
							<input type="checkbox" name="activo" id="activar" checked={this.state.activo ? (true) : (false)} onChange={event => this.setState({ activo: event.target.checked })} />
						</div>
					</div>
					<div className="white-space-24"></div>

					<div className="row full">
						<div className="column full">

							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Subfraccionamiento:</b>
									</p>
								</div>
								<div className="column full">
									<select name="subfraccionamiento" id="subfraccionamiento-modal" className="input input-full"
										value={this.state.idSubfrac}
										onChange={(event) => this.setState({idSubfrac: event.target.value})}>
											<option value="0">TODOS</option>
										{  
											this.state.subfraccionamientos.map((subfraccionamiento, key) => (
											<option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>)) 
										}
									</select>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Categoría:</b>
									</p>
								</div>
								<div className="column full">
									<select name="categoria" id="" className="input input-full">
										{this.state.categories.map((categoria, key) => (
											<option value={categoria.id_categoria} key={key}>{categoria.nombre}</option>
										))}
									</select>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Días previos para reserva:</b>
									</p>
								</div>
								<div className="column full">
									<input type="number" name="diasp" className="input input-modals" />
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Días máximos reserva:</b>
									</p>
								</div>
								<div className="column full">
									<input type="number" name="diasm" className="input input-modals" />
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Fianza:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="fianza" className="input input-modals" placeholder="" required
										maxLength="150"
									/>
								</div>
							</div>
						</div>
						<div className="column full column-img">

							<div className="responsive-img justify-end row">
								<div className="container-img">
								<img src={this.state.imageArea ? this.state.imageArea : "https://adime.es/static/img/group_placeholder.png"} className="img-duo-preview" id="previewArea" />
								
								<div className="middle-overlay">
									<label className="btn-upload-image column align-center justify-center">
										<i className="fas fa-file-upload"></i>
										<input type="file" id="fileImage" name="AreaImage" accept="image/*" onChange={this.showPreviewImageCommonArea.bind(this)} />
										<div className="white-space-8"></div>
										<p className="color-white">subir imagen</p>
									</label>
								</div>
								</div>
								
							</div>
						</div>
					</div>


					<div className="white-space-16"></div>
					<div className="column">
						<p>
							<b>Políticas de uso:</b>
						</p>
						<div className="white-space-8"></div>
						<div className="align-center row full">
							<div className="column full">
								<textarea name="descripcion" className='textarea full' cols="50" rows="5" placeholder='Descripción'></textarea>
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	}

	 // traer subfraccionamientos
	 async getSubFraccionamientos() {
		this.setState({loadingSub: true});
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
        if (response && !response.error) {
            if (response.subfraccionamiento && !response.empty) {
                this.setState({
                    subfraccionamientos: response.subfraccionamiento,
                    filterData: response.subfraccionamiento
				});
            } else {
                this.setState({
                    empty: true,
                    message: "",
                    subfraccionamientos: []
                });
            }
        } else {
            this.setState({
                error: true,
                message: ""
            });
		}
		this.setState({loadingSub: false});
	}
	
	async getCategories() {
		let data = {
			id: localStorage.getItem('frac')
		}

		const response = await request.post("/commonArea/get/categories", data)

		if (response && !response.error) {
			if (response.categories && !response.empty) {

				this.setState({ categories: response.categories })
			}

		} else {
			this.setState({
				error: true,
				message: ""
			});
		}
	}

	async handleSubmit(event) {
		this.setState({ loadingSave: true });
		event.preventDefault()
		let form = event.target
		let desc = form.descripcion.value
		if (desc.length < 1) {
			desc = 'sin descripcion'
		}

		let activo = form.activo.checked
		if (activo === true) {
			activo = 1

		}
		if (activo === false || activo === null) {
			activo = 0
		}

		if(this.state.idSubfrac === '') {
			alert('Debes seleccionar un subfraccionamiento antes de guardar');
			this.setState({ loadingSave: false });
			return;
		}

		let image = '';

		if(this.state.imageArea) {
			image = this.state.imageArea;
			
		}

		let data = {
			IdFraccionamiento: 	parseInt(localStorage.getItem('frac')),
			idSubfrac: 			this.state.idSubfrac,
			nombre: 			form.commonArea.value,
			descripcion: 		desc,
			Categoria: 			parseInt(form.categoria.value),
			diasReserva: 		parseInt(form.diasp.value),
			Activo: 			parseInt(activo),
			fianza: 			parseInt(form.fianza.value),
			diasMaximos: 		parseInt(form.diasm.value),
			imagen: 			image

		}

		const response = await request.post("/commonArea/create/new", data)

		
		if (response.created) {
			this.setState({ showModal: false, imageArea: '', message: ""});
			form.commonArea.value = '';
			form.descripcion.value = '';
			form.diasp.value = '';
			form.fianza.value = '';
			form.diasm.value = '';
			this.getCommonAreas();
			this.closeModal('newCommonArea');
		}else{
			this.setState({message: "NO SE PUDO CREAR ÁREA COMÚN"});
		}

		this.setState({ loadingSave: false });
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

	async getCommonAreas() {
		this.setState({ commonAreas: [], loading: true });
		const idSub = this.state.subfraccionamiento;
		let data = {
			IdFraccionamiento: parseInt(localStorage.getItem('frac')),
			idSub: idSub
		}
		const response = await request.post("/commonArea/get/areas", data)
		if (response.commonAreas) {
			this.setState({
				commonAreas: response.commonAreas
			});
			
		} else {
			this.setState({
				message: ""
			});
		}
		this.setState({ loading: false });
	}
}

export default CommonAreas;