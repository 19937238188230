import React, { Component } from 'react';
import ReactExport from 'react-export-excel';
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
const request = new Request();

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class SubFRaccPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
			subfraccionamientos: [],
			subfraccionamiento: localStorage.getItem('subfrac') || '0',
            showModal: false,
            hidden: false,
            page: 1,
            filterData: [],
			keys: [],
			dataSet: false,
			filter: '',
			newButton: true,
			selectSub: true
        }
    }



	componentDidUpdate(prevProps) {
		if(this.props.dataSet !== prevProps.dataSet) {
			if(Array.isArray(this.props.dataSet)) {
				if(this.props.dataSet.length > 0) {
					let columns = Object.keys(this.props.dataSet[0]);
					const columnsUpper = columns.map(column => column.toUpperCase().replace('_', ' '));
					const data = this.props.dataSet.map(row => {
						return columns.map(column => row[column])
					});
					if(this.props.dataSet) {
						const dataSet = [
							{
								columns: columnsUpper,
								data
							}
						]
						this.setState({dataSet});
					}
				}
			}
		}
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    reload() {
        this.getSubFraccionamientos()
	}

    save = data => {
        this.edditSub(data);
    }

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        //console.log(param1);

        if (param1 === "todo") {
            this.setState({ subfraccionamientos: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ subfraccionamientos: filterData });
        }

    }

	subChange(event) {
		this.setState({subfraccionamiento: event.target.value});
		localStorage.setItem('subfrac', event.target.value);
		if(this.props.subChange) {
			this.props.subChange(event.target.value);
		}
	}

	handleNuevo() {
		if(this.props.nuevo) {
			this.props.nuevo();
		}
	}

	setFilter() {
		if(this.props.setFilter) {
			this.props.setFilter(this.state.filter);
		}
	}

    render() {
        return (
			<div className="container column full">
				<div className="navigation align-center">
					<div className="justify-start">
						<h3 className="weight-semi">{this.props.title}</h3>
					</div>
				</div>
				<div className="row justify-between align-center">
					<div className="column">
						{this.state.selectSub &&
						<div className="row align-center">
							<select name="subfraccionamiento" id="subfraccionamiento" className="input input-select"
								value={this.state.subfraccionamiento}
								onChange={this.subChange.bind(this)}>
									<option value="0">TODOS</option>
								{  
									this.state.subfraccionamientos.map((subfraccionamiento, key) => (
									<option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>)) 
								}
							</select> &nbsp;
						</div>
						}
					</div>
					<div className="column">
						<div className="row align-center">
						<p className="font-regular weight-medium color-dark">Filtrar:</p>&nbsp;
							<div className="input-clean">
								<input
									className="input-filter"
									type="text"
									name="textFilter"
									id="filterData"
									value={this.state.filter}
									onChange={(event) => this.setState({filter: event.target.value})}>
								</input>&nbsp;
								<i className="fas fa-times" onClick={() => {
									this.setState({filter:''});
									if(this.props.setFilter) {
										this.props.setFilter('');
									}
								}}></i>
							</div>
							<button className="btn btn-filter btn-small" onClick={this.setFilter.bind(this)}>
								<i className="fas fa-search font-small">
								</i> &nbsp; Buscar
							</button>&nbsp;
							{this.state.newButton ?
								<button type="button" className="btn btn-primary btn-small color-white" onClick={this.handleNuevo.bind(this)}>
									<i className="fas fa-plus font-text" />
									&nbsp; Nuevo
								</button> 
								 :
								<button type="button" className="btn btn-disable btn-small color-white">
								<i className="fas fa-plus font-text" />
								&nbsp; Nuevo
							</button>

							}&nbsp;
							<ExcelFile element={
								<button type="button" className="btn btn-primary btn-small color-white">
									<i className="far fa-file-excel font-text" />
									&nbsp; Excel
								</button>
							} filename={this.props.title}>
								<ExcelSheet dataSet={this.state.dataSet || []} name={this.props.title}/> 
							</ExcelFile>
						</div>
					</div>
				</div>
			</div>
        );
    }

    componentDidMount() {
		this.getSubFraccionamientos();

		if(this.props.newButton == null) {
			this.setState({newButton: true});
		}
		else {
			this.setState({newButton: this.props.newButton});
		}

		if(this.props.selectSub == false) {
			this.setState({selectSub: this.props.selectSub})
		}
	}

    handleOpen() {
        this.setState({ showModal: true })
    }   
    handleClose() {
        this.setState({ showModal: false })
    }

    // traer subfraccionamientos
    async getSubFraccionamientos() {
		this.setState({loadingSub: true});
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
        if (response && !response.error) {
            if (response.subfraccionamiento && !response.empty) {
                this.setState({
                    subfraccionamientos: response.subfraccionamiento,
                    filterData: response.subfraccionamiento
				});
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    subfraccionamientos: []
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
		}
		this.setState({loadingSub: false});
    }

    // guardar subfraccionamiento
    async handleSubmit(event) {
		this.setState({loadingSave: true});
        event.preventDefault();
        const form = event.target;
        let data = {
            IdFraccionamiento: localStorage.getItem('frac'),
            Nombre: form.name.value,
            Direccion: form.address.value,
            Telefono: form.phone.value
        };

        const response = await request.post("/subfraccionamientos/create/subfraccionamiento", data);

        if (response && !response.error) {
            if (response.created && !response.empty) {
                this.setState({
                    reload: true,
                    loading: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }
        this.getSubFraccionamientos()
        this.setState({ showModal: false, loadingSave: false });
    }

    async edditSub(data) {
        const response = await request.post("/subfraccionamientos/eddit/subfraccionamiento", data);
        if (response.created) {
            this.getSubFraccionamientos()
        }
    }
}

export default Consumer(SubFRaccPage);