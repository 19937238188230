import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import FamiliaresHistoryTable from "../components/invitadosFrecuentes/familiaresTable";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Autocomplete from '../components/controls/autocomplete';
const request = new Request();


class familiaresHistory extends Component{
    state = {
        invitadosFrecuentes: [],
        keys: [],
		invitados: [],
		page: 1,
		filter: '',
		subfraccionamiento: localStorage.getItem('subfrac') || 0,
		subfraccionamientos: [],
		message: ''
	}
	
	reload() {
		this.getSubFraccionamiento()
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.invitadosfrecuentes();
		}
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }
    /* funcion para el filtro generico */

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        //console.log('filtros', this.state.filterData);

        if (param1 === "todo") {
            this.setState({ invitadosFrecuentes: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ invitadosFrecuentes: filterData });
        }

    }
    reload() {
        this.invitadosfrecuentes();
	}

    changeFrac() {
        this.invitadosfrecuentes()
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

    openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(invitadosFrecuentes) {
		invitadosFrecuentes = invitadosFrecuentes.filter(inv => {
			if((inv.acceso || '').toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if ((inv.invitado || '').toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return invitadosFrecuentes;
	}

    async completeForm(item) {
		this.setState({ INombre: item.label });
		if(item.value > 0) {
			const res = await request.get('/inquilinos/getbypersona', {id_persona: item.value, id_fraccionamiento: localStorage.getItem('frac')});
			if(res.inquilino) {
				const phone = parsePhoneNumber(`+${res.inquilino.telefono}`);
				// console.log(item);
				this.setState({
					IIdPersona: item.value,
                    iTelefono: phone.number
				});
			}
		}
	}

	closeModal() {
		this.setState({
			IidViv: 0,
			IIdPersona: 0,
			INombre: '',
			iTelefono: '',
			message: ''
		});
    }
    
    render() {
        let invitadosFrecuentes = [];
		let dataSet;

        if (Array.isArray(this.state.invitados)) {
			invitadosFrecuentes = this.state.invitados;
			dataSet = invitadosFrecuentes;
			invitadosFrecuentes = this.filter(invitadosFrecuentes);   
		}
        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Administración</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'historiala'} nav={'administracion'} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <Controls title="Familiares"  
                                    nuevo={this.openModal.bind(this, "newFamiliar")}
									dataSet={dataSet}
									newButton={true}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}
									selectSub={true}/>
                                <div className="white-space-16" />
                                <div className="card-table card-owners column" id="card-owners">
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
                                    <FamiliaresHistoryTable page={this.state.page} invitados={invitadosFrecuentes} reload={this.reload.bind(this)}/>
								}
                                </div>
                                <div className="white-space-16"></div>
                                <Paginador pages={Math.ceil(invitadosFrecuentes.length / 7)} setPage={this.setPage.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>

                <DinamicModal 
					idModal					=	{'newFamiliar'}
					sizeModalContainer		=	{'small'}
					title					=	{'AGREGAR NUEVO FAMILIAR'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					closeModal				=	{this.resetValue.bind(this)}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.message}
				>
				{this.renderNewFamiliar()}
				</DinamicModal>
                
            </div>
        );
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

    renderNewFamiliar() {
        let list_personas = [];
		let viviendas = [];
		if(Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map(persona => { return {label: persona.nombre, value: persona.id_persona}});
		}
		if(Array.isArray(this.state.viviendas)) {
			viviendas = this.state.viviendas;
		}
		return(
            <div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b> Unidad:	</b></p>
						</div>
						<div className="column full">
							<select className="input" name="vivienda" required value={this.state.IidViv} onChange={(event) => this.setState({IidViv: event.target.value})}>
								<option value="0">SELECCIONAR VIVIENDA</option>
								{viviendas.map(viv => 
									<option key={viv.id_vivienda} value={viv.id_vivienda}>
										{viv.numero_registro}
									</option>
								)}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<Autocomplete
								assignValue={this.completeForm.bind(this)}
								list={list_personas}>
								<input type="text" name="fullName" value={this.state.INombre}
									onChange={event => this.setState({ INombre: event.target.value })}
									className="input input-modals" placeholder="Nombre completo del Familiar"
									required
									maxLength="64" />
							</Autocomplete> 
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Telefono:	</b></p>
						</div>
						<div className="column full">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								country="MX"
								placeholder="Número de teléfono"
								value={this.state.iTelefono}
								onChange={ value => this.setState({ iTelefono: value, telefonoError: undefined }) }
								error={ this.state.telefonoError }
								className={'input-modals'} required /> 
						</div>
						<div className="column label-duo-normal space">
							<p><b>Acceso:</b></p>
						</div>
						<div className="column full">
                            <select name="acceso" id="acceso" className="input input-modals" value={this.state.acceso} enable="true" required
						        onChange={event => this.setState({ acceso: event.target.value })}>
                                    <option value="PENDIENTE">PENDIENTE</option>
                                    <option value="CONFIRMADO">CONFIRMADO</option>
					        </select>
						</div>
					</div>
                    
                    <div className="input-form-content row full align-center"> 
                    <div className="column label-duo-big">
                        <p><b>  Tipo:	</b></p>
                    </div>
                    <div className="column full">
                            <input type="text" name="tipo" value="Familiar"
                                onChange={event => this.setState({ tipo: event.target.value })} className="input input-modals" placeholder='Tipo' required maxLength="24" disabled/>
                    </div>

                    <div className="column label-duo-normal space">
    
                    </div>
                    <div className="column full">
                    
                    </div>
                    </div>

					<div className="white-space-8" />
					<div className="row justify-center">
						{this.state.saveMessage}
					</div>
				</div>
			</div>
		    )
        }
        
    handleOpen() {
        this.setState({ showModal: true })
	}

    handleClose() {
        this.setState({ showModal: true })
	}

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({loadingSave: true});
		let idSub = 		this.state.subfraccionamiento || 0;
        let data = {
            acceso: 		this.state.acceso || 'PENDIENTE',
            idfrac: 		localStorage.getItem('frac'),
            nombre: 		this.state.INombre,
            telefono: 		this.state.iTelefono,
            tipo: 1,
            persona: 		this.state.IIdPersona,
            vivienda: 		this.state.IidViv,
			id_frecuente: 	this.state.id_frecuente,
			IdSub: idSub
        }
		const response = await request.post("/invitadosFrecuentes/invitados/agregar", data);
		this.setState({message: response.message});
		if(response.created) {
			//this.setState({message: 'INVITADO AGREAGDO CORRECTAMENTE'})
			this.closeModal('newFamiliar');
			this.invitadosfrecuentes();
		} else {
			this.setState({message: "NO SE PUDO CREAR INVITADO"});
		}
		this.setState({acceso:'',INombre:'',iTelefono:'',IidViv:''});
        this.setState({loadingSave: false});
	}

	async resetValue() {
		this.setState({acceso:'',INombre:'',iTelefono:'',IidViv:''});
	}

	componentDidMount() {
        this.invitadosfrecuentes();
		this.getPersonas();
		this.getViviendas();
		this.getSubFraccionamientos();
	}

    async  invitadosfrecuentes() {
		this.setState({ invitados: [], loading: true });
		const idSub = this.state.subfraccionamiento || 0;
        let data = {
            idfrac: localStorage.getItem('frac'),
			tipo: 1,
			idSub
        };
        const response = await request.post("/invitadosFrecuentes/get/invitados", data)
        if (response && !response.error) {
            if (response.invitadosfrec && !response.empty) {
				this.setState({ invitados: response.invitadosfrec })
				console.log(response.invitadosfrec);
            }
        } else {
            this.setState({
                error: true,
				message: response.message
			});
		}
		this.setState({ loading: false });
    }
    async getPersonas() {
		const response = await request.post("/personas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.personas)) {
			this.setState({personas: response.personas});
		} else {
			this.setState({personas: []});
		}
	}

	async getViviendas() {
		const response = await request.post("/viviendas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.viviendas)) {
			this.setState({viviendas: response.viviendas});
		} else {
			this.setState({viviendas: []});
		}
	}

	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento});
		} else {
			this.setState({	subfraccionamientos: [] });
		}
    }
}

export default familiaresHistory;