/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente header
 */

import React, { Component, Fragment } from "react";
import Logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
import { PassThrough } from "stream";
import Rightbar from './rightbar';

const request = new Request();

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0, idAdministracion: 0 },
			denied: false,
			fraccionamientos: [],
			subfraccionamientos: [],
			fraccionamiento: 0,
			fraccionamientoNombre: localStorage.getItem('fracName'),
			subfraccionamiento: 0,
			hidden: false
		};
	}

	componentDidUpdate(prevProps) {
		if(this.props.context.fracActual !== prevProps.context.fracActual) {
			this.setState({fraccionamientoNombre: localStorage.getItem('fracName')});
		}
	}

	openNav() {
		let sidebar = document.getElementById('sidebar');
		if (sidebar.classList.contains('active')) {
			sidebar.classList.remove('active');
			sidebar.style.minWidth = "0%";
		}
		else {
			sidebar.classList.add('active');
			sidebar.style.minWidth = "20%";
		}
	}

	render() {
		return (
			<React.Fragment>
				<header className="justify-center header-slide">
					<div className="row full">
						{/*<div className="justify-between">*/}
						<div className="options row align-center justify-center">
							<div className="logo align-center">
								<Link to="/tablero" className="responsive-img auto">
									<img
										src={Logo}
										alt="Logo iGate"
										title="Logo iGate"
									/>
								</Link>
								<p className="font-large weight-medium color-white">
									&nbsp;iGate
									</p>
							</div>
							{
								this.props.sidebar ?
									<i className="fas fa-stream icon-main" onClick={this.openNav.bind()}></i>
									: null
							}
						</div>
						<div className="row full justify-end">
							<div className="row full justify-start align-center">
								<p className="font-regular color-white">
									<i className="fas fa-building"></i> &nbsp;
									{this.props.context.fraccionamiento}
								</p>
							</div>
							{/*this.state.denied ? (
								null
							) :
								(
									<div className="header-selects row align-center">
										<div className="select-options">
											<i className="fas fa-sort-down select-arrow "></i>

											<select name="fraccionamiento" id="fraccionamiento" value={this.state.fraccionamiento}
												className="select" onChange={this.cambiar.bind(this)} >

												{  
													this.state.fraccionamientos.map((fraccionamiento, key) => (
													<option value={fraccionamiento.id_fraccionamiento} key={key}>{fraccionamiento.nombre}</option>)) 
												}
											</select>
										</div>
										<div className="select-options" hidden={this.state.hidden}>
											<i className="fas fa-sort-down select-arrow "></i>
											<select name="subfraccionamiento" value={this.state.subfraccionamiento} id="subfrac" className="select" onChange={this.handleChange.bind(this)}>
												{this.state.subfraccionamientos.map((subfraccionamiento, key) => (
													<option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>

												))}
											</select>
										</div>
									</div>
												)*/}

							<nav className="align-center justify-end">
								{this.state.denied ? (
									<div className="row justify-end">
										<div className="nav-item auto">
											<button
												href="#one"
												className="font-regular color-white"
												onClick={this.handleLogout.bind(
													this
												)}>
												<i className="fas fa-sign-out-alt font-text" />Salir
											</button>
										</div>
									</div>
								) : (

										<div className="row justify-end">

											<div className="nav-item auto" id="catalogos">
												<Link
													to="/catalogos/subfraccionamientos"
													className="font-regular color-white">
													<i className="fas fa-address-card font-text" />{" "}
													Catálogos
												</Link>
											</div>
											<div className="nav-item auto" id="administracion">
												<Link
													to="/administracion"
													className="font-regular color-white">
													<i className="fas fa-home font-text" />{" "}
													Administración
												</Link>
											</div>
											<div className="nav-item auto" id="reportes">
												<Link
													to="/reportes"
													className="font-regular color-white">
													<i className="fas fa-warehouse font-text" />{" "}
													Reportes
												</Link>
											</div>

											<div className="nav-item auto">
												<a
													href="#one"
													className="font-regular color-white"
													onClick={this.handleLogout.bind(
														this
													)}>
													<i className="fas fa-sign-out-alt font-text" />{" "}
													Salir
												</a>
											</div>
											<div className="nav-item auto">
												<a	onClick={() => {
														const leftbar = document.getElementById('rightsidebar');
														if(leftbar) {
															leftbar.classList.toggle('open');
														}
													}}
													href="#settings"
													className="font-regular color-white">
													<i className="fas fa-cogs font-text" />
												</a>
											</div>
										</div>
									)}
							</nav>
						</div>
						{/*</div>*/}
					</div>
				</header>
				<Rightbar/>
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.load();

		let final = { val: window.location.pathname }

		if (final.val === "/catalogos/subfraccionamientos" || final.val === '/tablero' || final.val === '/catalogos/presupuestos/concepto'
			|| final.val === '/administracion/areas/instalaciones' || final.val === '/administracion/areas/reservacion' || final.val === '/administracion/areas/historial'
			|| final.val === '/entornos' || final.val === '/perfiles' || final.val === '/users') {

			this.setState({ hidden: true })

		} else {
			this.setState({ hidden: false })
		}

		if (document.getElementById('sidebar')) {
			document.getElementById('sidebar').classList.add('active');
			document.getElementById('sidebar').style.minWidth = "20%";
		}

		const allMenuItems = document.getElementsByClassName('nav-item');
		for (let index = 0; index < allMenuItems.length; index++) {
			if (allMenuItems[index].classList.contains('nav-active')) {
				allMenuItems[index].classList.remove('nav-active');
			}
			if (allMenuItems[index].classList.contains('active-item')) {
				allMenuItems[index].classList.remove('active-item');
			}
		}

		switch (this.props.nav) {
			case 'catalogos':
				document.getElementById(this.props.nav).classList.add('nav-active');
				break;
			case 'administracion':
				document.getElementById(this.props.nav).classList.add('nav-active');
				break;
			default:
				break;
		}

		{/*switch (this.props.active) {
			case 'subfraccionamientos':
				document.getElementById('admin').classList.add('active');
				document.getElementById('panel1').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');

				break;
			case 'viviendas':
				document.getElementById('admin').classList.add('active');
				document.getElementById('panel1').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;
			case 'propietarios':
				document.getElementById('admin').classList.add('active');
				document.getElementById('panel1').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'presupuestoConcepto':
				document.getElementById('presup').classList.add('active');
				document.getElementById('panel2').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'presupuestoTeorico':
				document.getElementById('presup').classList.add('active');
				document.getElementById('panel2').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'presupuestoGastos':
				document.getElementById('presup').classList.add('active');
				document.getElementById('panel2').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'EstadosCuenta':
				document.getElementById('EstCuenta').classList.add('active');
				document.getElementById('panel14').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'perfiles':
				document.getElementById('config').classList.add('active');
				document.getElementById('panel3').style.maxHeight = "160px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'usuarios':
				document.getElementById('config').classList.add('active');
				document.getElementById('panel3').style.maxHeight = "160px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'entorno':
				document.getElementById('config').classList.add('active');
				document.getElementById('panel3').style.maxHeight = "160px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'tipoN':
				document.getElementById('config').classList.add('active');
				document.getElementById('panel3').style.maxHeight = "160px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'tipoR':
				document.getElementById('config').classList.add('active');
				document.getElementById('panel3').style.maxHeight = "160px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'inquilinos':
				document.getElementById('admin').classList.add('active');
				document.getElementById('panel1').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'pendientes':
				document.getElementById('quejas').classList.add('active');
				document.getElementById('panel4').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'historial':
				document.getElementById('emergencias').classList.add('active');
				document.getElementById('panel1').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'definicion':
				document.getElementById('notificaciones').classList.add('active');
				document.getElementById('panel2').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'historiald':
				document.getElementById('notificaciones').classList.add('active');
				document.getElementById('panel2').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'historialp':
				document.getElementById('quejas').classList.add('active');
				document.getElementById('panel4').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'activas':
				document.getElementById('areas').classList.add('active');
				document.getElementById('panel3').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'reservas':
				document.getElementById('areas').classList.add('active');
				document.getElementById('panel3').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'historiala':
				document.getElementById('areas').classList.add('active');
				document.getElementById('panel3').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			case 'historialb':
				document.getElementById('bitacora').classList.add('active');
				document.getElementById('panel5').style.maxHeight = "136px";
				document.getElementById(this.props.active).classList.add('active-item');
				break;

			default:
				break;
		}*/}

		let acc = document.getElementsByClassName("accordion");
		let i;

		for (i = 0; i < acc.length; i++) {
			acc[i].addEventListener("click", function () {
				this.classList.toggle("active");
				var panel = this.nextElementSibling;
				if (panel.style.maxHeight) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + "px";
				}
			});
		}
	}

	async load() {
		const user = await this.props.context.loadUser();

		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					idAdministracion: user.id.id_administracion
				}
			});
		}
	}

	/*async getMenu() {
		this.setState({ loadingDiarios: true });
		let data = {
			idUsuario: this.state.user.idUsuario,
			idPerfil: this.state.user.idPerfil
		};
		const response = await request.post("/users/get/menu", data);

		if (response && !response.error) {
			if (response.menus && !response.denied) {
				this.setState({
					menus: response.menus,
					denied: false
				});
			} else {
				this.setState({
					denied: true
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				denied: true
			});
		}
	}*/

	handleLogout() {
		this.props.context.logout();
		window.location = "/login";
	}

	handleChange(event) {

		if (!event.target.value) {
			return

		}
		this.setState({ subfraccionamiento: event.target.value });

		let data = {

			if: parseInt(document.getElementById("fraccionamiento").value),
			isf: parseInt(document.getElementById("subfrac").value)
		}
		localStorage.setItem('objectKey', JSON.stringify(data));
		this.props.reload()
		this.props.change()



	}
	// traer subfraccionamiento

	async getFraccionamientosMenus() {
		const user = await this.props.context.loadUser();
		if (user) {
			const data = { idFraccionamiento: user.id.id_fraccionamiento };
			const response = await request.post("/fraccionamientos/get/menus", data);
			if (response) {
				console.log(response);
			}
		}
	}
}

export default Consumer(Header);
