import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import ReactModal from "react-modal";
import ReportsTypeTable from '../components/incidents/reportsTypeTable'
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal'; 
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import { getWeekYearWithOptions } from 'date-fns/esm/fp';

const request = new Request();

class ConfigurationTypeReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reporteTipo: 	'',
            showModal: 		false,
			tipos: 			[],
			page: 			1,
			filter: 		'',
			loadingSave: 	false
        }
    }

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    changeFrac() {
        this.getType()

	}

    getTipos() {
        this.getType()
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevoTipo() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(tipos) {
		tipos = tipos.filter(tip => {
			console.log(tip)
			if(tip.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return tipos;
	}

    render() {
		let tipos = [];
		let dataSet;

        if (Array.isArray(this.state.tipos)) {
			tipos = this.state.tipos;
			dataSet = tipos;
			tipos = this.filter(tipos);
        }
        return (
            <div className="owners column">
                <Helmet>
                    <title>iGate - Configuracion</title>
                </Helmet>
                <Header sidebar={true} active={'tipoR'} parent={'config'} panel={'panel3'} nav={"catalogos"} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
								<Controls 
									title		=	"Tipos de reportes"
									nuevo		=	{this.openModal.bind(this,"addNewTypeReport")}
									dataSet		=	{dataSet}
									setFilter	=	{this.setFilter.bind(this)}
									subChange	=	{this.subChange.bind(this)}
                                    selectSub	=	{false}/>
                                <div className="white-space-16" />
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<ReportsTypeTable 
										types	=	{tipos} 
										page	=	{this.state.page} 
										reload	=	{this.getTipos.bind(this)} 
									/>
								}
                                <div className="white-space-16" />
                                <Paginador pages={Math.ceil(tipos.length / 7)} setPage={this.setPage.bind(this)} />
							</div>
                        </div>
                    </div>
                </div>

				<DinamicModal 
					idModal				=	{'addNewTypeReport'}
					sizeModalContainer	=	{'small'}
					title				=	{'NUEVO TIPO DE REPORTE'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					closeModal			=	{this.resetValue.bind(this)}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
				>
				{this.renderNewTypeReport()}
				</DinamicModal>

            </div>
        );
	}

	renderNewTypeReport() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Tipo de reporte:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="conceptName" value={this.state.reporteTipo}
								onChange={event => this.setState({ reporteTipo: event.target.value })} className="input input-modals" placeholder="Nuevo tipo de reporte" required maxLength="64" />
						</div>
					</div>
				</div>
			</div>
		)
	}
	closeModal(idModal) {
	
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	componentDidMount() {
		this.getTipos();
	}

    async  handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
        let data = {
            id: parseInt(localStorage.getItem('frac')),
            texto: this.state.reporteTipo

        }
        const response = await request.post("/incidencias/create/type/report", data)

        
		if (response.created) {
			this.setState({
				showModal: false
			});
			this.getType();
			this.setState({reporteTipo: ''});
			this.closeModal('addNewTypeReport');
		} else {
			this.setState({
				empty: 		true,
				message: 	"NO SE PUDO CREAR NUEVO TIPO DE REPORTE"
			});
		}
        this.setState({loadingSave: false});
		

	}
	
	async resetValue() {
		this.setState({reporteTipo: ''});
	}

    async getType() {
        this.setState({ tipos: [], loading: true });
        let data = {
            id: parseInt(localStorage.getItem('frac'))
        }
        const response = await request.post("/incidencias/get/types/reports", data)


        if (response && !response.error) {
            if (response.tipos && !response.empty) {
                this.setState({
                    tipos: response.tipos
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
		}
		this.setState({ loading: false });


    }
}

export default ConfigurationTypeReports;