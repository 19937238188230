import React, { Component } from 'react';
import moment from "moment";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import MapEmergency from '../../components/maps/map';
import Request from "../../core/httpClient";
const request = new Request();

class DetailEmergency extends Component {
    constructor(props) {
        super(props);
        this.state = {
			emergency: 		this.props.emergency,
			comentario: 	this.props.emergency.comentario ? this.props.emergency.comentario : '',
			loadingSave: 	false
		}
		
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.emergency!== prevProps.emergency) {
			this.setState({
				emergency: 	this.props.emergency,
				comentario: this.props.emergency.comentario ? this.props.emergency.comentario : ''});
			console.log(this.props.emergency);
		}
	}

	reset() {
		this.setState({comentario: ''});
	}

    render() {
        return ( 
		<DinamicModal 
			idModal				=	{'detailEmergency'}
			sizeModalContainer	=	{'big'}
			title				=	{'DETALLE EMERGENCIA'} 
			success				=	{this.addComment.bind(this)} 
			showBtnSuccess		=	{true}
			btnTextSuccess		=	{"GUARDAR"}
			showBtnCancel		=	{true}
			btnTextCancel		=	{"CANCELAR"}
			closeModala			=	{this.reset.bind(this)}
			loadingSave			=	{this.state.loadingSave}
			message				=	{this.state.message}
			>
			<div className="full row align-center">
				<div className="column full">
					<div className="row full">
						<div className="column full">
						<div className="input-form-content row full align-center">
								<h3 className="color-danger">{this.state.emergency.subfraccionamiento}</h3>
						</div>
						<div className="white-space-16"></div>
						<div className="input-form-content row full align-center">
							<div className="column label-medium">
								<p><b> Nombre:	</b></p>
							</div>
							<div className="column full">
								<p className="label-gray">{this.state.emergency.nombre}</p>
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
							<div className="column label-medium">
								<p><b> Teléfono:	</b></p>
							</div>
							<div className="column full">
								<p className="label-gray">{this.state.emergency.telefono}</p>
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
							<div className="column label-medium">
								<p><b> Unidad:	</b></p>
							</div>
							<div className="column full">
								<p className="label-gray">{this.state.emergency.numero_vivienda}</p>
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
							<div className="column label-medium">
								<p><b> Recibido:	</b></p>
							</div>
							<div className="column full">
								<p className="label-gray">nombre guardia</p>
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
								<p><b> Dirección:	</b></p>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
								<textarea className="text-area-gray" value={this.state.emergency.direccion} rows="2" disabled></textarea>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
								<p><b> Comentarios:	</b></p>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
								<textarea className="text-area-gray" value={this.state.comentario} onChange={event => this.setState({ comentario: event.target.value })} rows="2"></textarea>
						</div>
						</div>
						<div className="column full"  style={{paddingLeft:"16px"}}>
						<MapEmergency data={this.state.emergency}></MapEmergency>
						</div>
					</div>
				</div>
			</div>
		</DinamicModal>
        );
	}
	
	async addComment(event) {
        event.preventDefault()
		this.setState({loadingSave: true});
        let data = {

            comentario: this.state.comentario,
            id: this.state.emergency.id_panico
        }
        const response = await request.post("/emergencys/update/emergency", data)

        
		if (response.updated) {
			this.setState({
				showModal: false
			});
			this.closeModal("detailEmergency");
			this.props.reload();
		} else {
			this.setState({
				empty: true,
				message: "NO SE PUDO GUARDAR DETALLE DE LA EMERGENCIA"
			});
		}
        
		this.setState({loadingSave: false});
		

	}
	
	  
	closeModal(idModal) {
		if(this.props.closeModal) {
			this.props.closeModal();
		}
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}


}

export default DetailEmergency;