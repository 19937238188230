import React, { Component } from 'react';
import ReactModal from 'react-modal';
import HousesSearch from "../propietarios/housesSearch";

class SearchHouse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }
    vivienda = data => {
        this.props.vivienda(data)
        this.setState({ showModal: false })
    }

    render() {
        return (
            <div>
                <button type="button" className="btn btn-mini btn-primary color-white" onClick={this.handleOpen.bind(this)}>
                    <i className="fas fa-search"></i>
                </button>
                <ReactModal isOpen={this.state.showModal} className="small-modal column" overlayClassName="overlay">
                    <form className="flex justify-center">
                        <div className="full column container">
                            <div className="close-modal justify-center header-modals">
                                <div>
                                    <h3 className="color-white">BUSCAR VIVIENDA</h3>
                                </div>
                                <button className="btn-modal-close color-dark" onClick={this.handleClose.bind(this)}>
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <div className="row-reverse search-content modal-content">
                                <HousesSearch vivienda={this.vivienda.bind(this)} ></HousesSearch>
                            </div>
                        </div>
                    </form>
                </ReactModal>
            </div>
        )
    }

    handleOpen() {
        this.setState({ showModal: true });
    }

    handleClose() { this.setState({ showModal: false, showAdministracion: false, showCatalogos: true }) }
}

export default SearchHouse;