import React, { Component } from 'react';
import ModalBorrar from '../modals/modalDelete'
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
const request = new Request();

class AreasSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: 			false,
            add: 				false,
            eddit: 				false,
            inicio: 			moment(`${moment().format('YYYY-MM-DD')} 00:00`),
            inicioPrefijo: 		'AM',
            cierre: 			moment(`${moment().format('YYYY-MM-DD')} 00:00`),
            cierrePrefijo: 		'AM',
            Einicio: 			'',
            EinicioPrefijo: 	'',
            Ecierre: 			0,
            EcierrePrefijo: 	'',
            IdHorario: 			0,
            id: 				this.props.id,
			horarios: 			[],
			idHorarioDelete: 	0,
			loadingSave: 		false,
        }
	}

    delete = (id, close) => {
        this.handleDelete(id, close)
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			this.setState({id: this.props.id, message: ''});
		}
	}
	
    render() {
        return (
			<div className="row full">
				<div className="column justify-center">
					<h3>Definición de Horarios</h3>
					<div className="row justify-end">
						<button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.openModal.bind(this, "AddNewSchedule")} >
							<i className=" fas fa-clock" /> &nbsp; Nuevo horario
						</button>
					</div>
					<div className="white-space-16"></div>
					<div>
						<div className="card-table card-owners column" id="card-owners">
							<div className="table-responsiv column">
								<table>
									<thead>
										<tr className="text-left">
											<th className="">Horarios</th>
											<th className="text-center th-options-small">Editar</th>
											<th className="text-center th-options-small">Eliminar</th>
										</tr>
									</thead>
									<tbody>
										{this.props.horarios.map((horario, key) => (
											<tr key={key}>
												<td className="text-left"><h5>{horario.descripcion}</h5></td>
												<td className="text-center">
													<button type="button" onClick={this.openModal.bind(this, 'EditModal',horario)} >
														<i className="font-small fas fa-pen" />
													</button>
												</td>
												<td className="text-center">
												<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, horario.id_horario)} style={{ padding: '5px' }} >
													<i className="font-text fas fa-trash-alt " />
												</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<DinamicModal
					idModal				=	{'AddNewSchedule'}
					sizeModalContainer	=	{'normal'}
					title				=	{'NUEVO HORARIO'}
					success				=	{this.handleSubmit.bind(this)}
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}>
					{this.renderAddNewSchedule()}
				</DinamicModal>
				<DinamicModal
					idModal				=	{'EditModal'}
					sizeModalContainer	=	{'normal'}
					title				=	{'EDITAR HORARIO'}
					success				=	{this.handleEddit.bind(this)}
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}>
					{this.renderAddNewSchedule()}
				</DinamicModal>
				<ModalBorrar id={this.state.idHorarioDelete} delete={this.delete.bind(this)} />
			</div>
        );
	}

    handleOpen() {
        this.setState({ showModal: true, })
        this.props.getSchedules()
	}

	openModal(idModal, horario) {
		this.setState({message: ''});
		if(horario.hora_inicio) {
			this.setState({
				id_horario: horario.id_horario,
				inicio: moment(`${moment().format('YYYY-MM-DD')} ${horario.hora_inicio} ${horario.prefijoinicio}`),
				cierre: moment(`${moment().format('YYYY-MM-DD')} ${horario.hora_fin} ${horario.prefijocierre}`)
			});
		} else {
			this.setState({
				inicio: moment(`${moment().format('YYYY-MM-DD')} 00:00`),
				cierre: moment(`${moment().format('YYYY-MM-DD')} 00:00`)
			});
		}
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal){
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}
	
	openModalDelete(id) {
		this.setState({idHorarioDelete: id});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	renderAddNewSchedule() {
		return(
			<div className="column">
				<div className="white-space-8" />
				<div className="row ">
					<div className="column" style={{ paddingLeft: '100px' }}>
						<div className="align-center row full">
							<p style={{ paddingRight: '15px' }}>
								<b>Inicio:</b>
							</p>
							<div className="column full">
								<TimePicker
									value={this.state.inicio}
									onChange={(value) => this.setState({inicio: value})}
									showSecond={false}
									use12Hours
									inputReadOnly
									className=""
									required
									/>
							</div>
						</div>

					</div>
					<div className="column" style={{ paddingLeft: '100px' }}>
						<div className="align-center row full">
							<p style={{ paddingRight: '15px' }}>
								<b>Cierre:</b>
							</p>
							<div className="column full">
								<TimePicker
									value		=	{this.state.cierre}
									onChange	=	{(value) => this.setState({cierre: value})}
									showSecond	=	{false}
									use12Hours
									inputReadOnly
									className	=	""
									required
									/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

    async handleEddit(event) {
		event.preventDefault();
		this.setState({loadingSave: true, message: ''});

        let inicio 		= moment(this.state.inicio);
		let cierre 		= moment(this.state.cierre);
        let duracion 	= moment(this.state.cierre).diff(this.state.inicio, 'hours');
        let descripcion = `${moment(this.state.inicio).format('hh:mm a')} a ${moment(this.state.cierre).format('hh:mm a')}`

		if(duracion <= 0) {
			cierre = moment(cierre).add(1, 'day');
			duracion = moment(cierre).diff(this.state.inicio, 'hours');
		}
		let inRange = false;
		if(Array.isArray(this.props.horarios)) {
			this.props.horarios.forEach(horario => {
				if(horario.id_horario === this.state.id_horario) {
					return;
				}
				const ini = moment(`${moment().format('YYYY-MM-DD')} ${horario.hora_inicio} ${horario.prefijoinicio}`);
				let fin = moment(`${moment().format('YYYY-MM-DD')} ${horario.hora_fin} ${horario.prefijocierre}`);
				if(moment(fin).diff(ini, 'hours') <= 0) {
					fin = fin.add(1, 'day');
				}
				if(ini < inicio && cierre < fin) {
					inRange = true;
				}
				if(ini < inicio && inicio < fin) {
					inRange = true;
				}
				if(ini < cierre && cierre < fin) {
					inRange = true;
				}
				if(ini.format() == inicio.format() && cierre.format() == fin.format()) {
					inRange = true;
				}
			});
		}

		if(inRange) {
			this.setState({loadingSave: false, message: 'EL NUEVO HORARIO NO PUEDE ESTAR EN EL RANGO DE HORARIOS ANTERIORES'})
			return;
		}
        let data = {
            IdHorario: 		this.state.id_horario,
            inicio: 		inicio.format('hh:mm'),
            cierre: 		cierre.format('hh:mm'),
            inicioP: 		moment(this.state.inicio).format('a'),
            cierreP: 		moment(this.state.cierre).format('a'),
            duracion: 		duracion,
            descripcion: 	descripcion,
        }

		const response = await request.post("/commonArea/update/schedule", data);
		if(response.updated){
			this.props.getSchedules(this.props.id);
			this.closeModal("EditModal");
		}else{
			
			this.setState({message: "NO SE PUDO EDITAR HORARIO"});
		}
		this.setState({loadingSave: false});
	}

    async handleSubmit(event) {
		event.preventDefault()
		this.setState({loadingSave: true, message: ''});
		let inicio 		= moment(this.state.inicio);
		let cierre 		= moment(this.state.cierre);
        let duracion 	= moment(this.state.cierre).diff(this.state.inicio, 'hours');
        let descripcion = `${moment(this.state.inicio).format('hh:mm a')} a ${moment(this.state.cierre).format('hh:mm a')}`

		if(duracion <= 0) {
			cierre 		= moment(cierre).add(1, 'day');
			duracion 	= moment(cierre).diff(this.state.inicio, 'hours');
		}
		let inRange = false;
		if(Array.isArray(this.props.horarios)) {
			this.props.horarios.forEach(horario => {
				const ini 	= moment(`${moment().format('YYYY-MM-DD')} ${horario.hora_inicio} ${horario.prefijoinicio}`);
				let fin 	= moment(`${moment().format('YYYY-MM-DD')} ${horario.hora_fin} ${horario.prefijocierre}`);
				if(moment(fin).diff(ini, 'hours') <= 0) {
					fin = fin.add(1, 'day');
				}
				if(ini < inicio && cierre < fin) {
					inRange = true;
				}
				if(ini < inicio && inicio < fin) {
					inRange = true;
				}
				if(ini.format < cierre && cierre < fin) {
					inRange = true;
				}
				if(ini.format() == inicio.format() && cierre.format() == fin.format()) {
					inRange = true;
				}
			});
		}

		if(inRange) {
			this.setState({loadingSave: false, message: 'EL NUEVO HORARIO NO PUEDE ESTAR EN EL RANGO DE HORARIOS ANTERIORES'})
			return;
		}

        let data = {
            id: 			this.props.id,
            inicio: 		inicio.format('hh:mm'),
            cierre: 		cierre.format('hh:mm'),
            inicioP: 		inicio.format('a'),
            cierreP: 		cierre.format('a'),
            duracion: 		duracion,
			descripcion: 	descripcion,
		}
		const response = await request.post("/commonArea/create/schedule", data);
		if(response.created){
			this.closeModal("AddNewSchedule");
			this.props.getSchedules(this.props.id);
			
		}else{
			this.setState({message: "NO SE PUDO AGREGAR HORARIO"});
		}
		this.setState({loadingSave: false});
	}

  
    async  handleDelete(id, close) {
        let data = {
            id: id
        }
        const response = await request.post("/commonArea/delete/schedule", data)
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.setState({message: response.message,});
            } else {
                this.setState({empty: true, message: response.message});
            }
		}

		this.props.getSchedules(this.props.id);
		close();
    }
}

export default AreasSchedule;
