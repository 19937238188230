import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
const request = new Request();

class FamiliaresTableEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nombre: 			'',
			viv: 				'',
			tipo: 				'',
			acceso: 			'',
			telefono: 			'',
			id_frecuente: 		0,
			idfrac: 			localStorage.getItem('frac') || 0,
			message: 			''
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.invitado !== prevProps.invitado) {
			this.setState({
				nombre: 		this.props.invitado.invitado,
				viv: 			this.props.invitado.numero_registro,
				tipo: 			this.props.invitado.tipo,
				acceso:			this.props.invitado.acceso,
				telefono: 		this.props.invitado.telefono,
				id_frecuente: 	this.props.invitado.id_frecuente,
				horarios:		this.props.invitado.horarios
			})
		}
	}

	render() {
		return (
			<div>
				<DinamicModal
					idModal					=	{'editFamiliar'}
					sizeModalContainer		=	{'small'}
					title					=	{'EDITAR FAMILIAR'}
					success					=	{this.handleSubmit.bind(this)}
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"ACTUALIZAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.message}
				>
					{this.rendereditFamiliar()}
				</DinamicModal>
			</div>
		);
	}

	rendereditFamiliar() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre :	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="nombre" value={this.state.nombre} enable="true"
								onChange={event => this.setState({ nombre: event.target.value })} className="input input-modals" placeholder="Nombre" required maxLength="64" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="phone" value={this.state.telefono}
								onChange={event => this.setState({ telefono: event.target.value })} className="input input-modals" placeholder='Número Telefono' required maxLength="24" disabled />
						</div>

						<div className="column label-duo-normal space">
							<p className="justify-start" style={{ paddingLeft: '20px' }}>
								<b>Acceso:</b>
							</p>
						</div>
						<div className="column full">
							<select name="acceso" id="acceso" className="input input-modals" value={this.state.acceso} enable="true"
								onChange={event => this.setState({ acceso: event.target.value })}>
								<option value="PENDIENTE">PENDIENTE</option>
								<option value="CONFIRMADO">CONFIRMADO</option>
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Tipo:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="tipo" value={this.state.tipo === 1 && 'Familiar'}
								onChange={event => this.setState({ tipo: event.target.value })} className="input input-modals" placeholder='Tipo' required maxLength="24" disabled />
						</div>

						<div className="column label-duo-normal space">
							<p className="justify-start" style={{ paddingLeft: '20px' }}>
								<b>Unidad:</b>
							</p>
						</div>
						<div className="column full">
							<div className="column full">
								<input type="text" name="vivienda" value={this.state.viv}
									onChange={event => this.setState({ viv: event.target.value })} className="input input-modals" placeholder='Número Vivienda' required maxLength="24" disabled />
							</div>
						</div>
					</div>

				</div>
			</div>
		)

	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({ loadingSave: true });
		console.log(this.state.invitado);
		let data = {
			acceso: 		this.state.acceso,
			id_frecuente: 	this.state.id_frecuente,
			idfrac: 		this.state.idfrac,
			nombre: 		this.state.nombre,
			horarios:		this.state.horarios	
		}

		const response = await request.post("/invitadosFrecuentes/invitados/update", data);
		console.log(response);
		if (response && !response.error) {
			if (response.updated && !response.empty) {
				this.props.reload();
			} else {
				this.setState({
					updated: 	response.updated,
					acceso: 	response.updated.acceso,
					idViv: 		response.updated.numero_registro,
					nombre: 	response.updated.nombre,
					tipo: 		response.updated.tipo.div,
					message: 	"NO SE PUDO ACTUALIZAR INVITADO"
				});
			}
		} else {
			this.setState({
				error: 		true,
				message: 	"NO SE PUDO ACTUALIZAR INVITADO"
			});
		}
		this.setState({ loadingSave: false });
	}
}
export default FamiliaresTableEdit;