import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import NotificationsTypeTable from '../components/notications/notificationsTypeTable';
import Paginador from "../components/paginador/paginador";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";

const request = new Request();

class ConfigurationTipeNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configuracionTipo: 	'',
            tipos: 				[],
			configuracionTipo: 	'',
			page: 				1,
			filter: 			'',
			subfraccionamiento: localStorage.getItem('sufrac'),
			loadingSave: false
        }
    }
    reload() {

	}
	
	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

    /*funcion del paginador*/
    setPage(page) {
         this.setState({ page });
    }

    changeFrac() {
        this.getType()

	}

    getTipos() {
        this.getType()
    }

	nuevoTipo() {
		this.setState({showModal: true});
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(tipos) {
		console.log(tipos);
		tipos = tipos.filter(tip => {
			if(tip.Descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return tipos;
	}

    render() {
		let tipos = [];
		let dataSet;

        if (Array.isArray(this.state.tipos)) {
			tipos = this.state.tipos;
			dataSet = tipos;
			tipos = this.filter(tipos);
        }
        return (
            <div className="owners column">
                <Helmet>
                    <title>iGate - Configuracion</title>
                </Helmet>
                <Header sidebar={true} active={'tipoN'} parent={'config'} panel={'panel3'} nav={"catalogos"} change={this.changeFrac.bind(this)} reload={this.reload.bind(this)} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <Controls title="Tipos de notificaciones"
									nuevo={this.openModal.bind(this,"addNotifications")}
									dataSet={dataSet}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}
									selectSub={false}/>
                                <div className="white-space-16" />
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<NotificationsTypeTable 
										types	=	{tipos} 
										page	=	{this.state.page} 
										reload	=	{this.getTipos.bind(this)}
									>
									</NotificationsTypeTable>
								}
								<div className="white-space-16" />
                                <Paginador pages={Math.ceil(tipos.length / 7)} setPage={this.setPage.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>

				<DinamicModal 
					idModal				=	{'addNotifications'}
					sizeModalContainer	=	{'small'}
					title				=	{'NUEVO TIPO DE NOTIFICACIÓN'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					closeModal			=	{this.resetValue.bind(this)}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
				>
				{this.renderAddNotification()}
				</DinamicModal>
            </div>


        );
	}

	componentDidMount() {
		this.getTipos();
	}

	renderAddNotification() {
		return(
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-big">
						<p><b>Tipo de notificación:	</b></p>
					</div>
					<div className="column full">
						<input type="text" name="conceptName" value={this.state.configuracionTipo}
							onChange={event => this.setState({ configuracionTipo: event.target.value })} className="input input-modals" placeholder="Nuevo tipo de notificación" required maxLength="64" />
					</div>
				</div>
			</div>
		</div>
   
		)
	}

    handleOpen() {
        this.setState({ showModal: true })
    }
    handleClose() {
        this.setState({ showModal: false })
	}
	
	async resetValue() {
		this.setState({configuracionTipo: ''})
	}

    async  handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true, message: ''});
        let data = {
            id: 	parseInt(localStorage.getItem('frac')),
            texto: 	this.state.configuracionTipo

        }
		const response = await request.post("/notificaciones/create/type/notification", data);
		console.log(response);
		if(response.created){
			this.getType();
			this.closeModal("addNotifications");
		}else{
			this.setState({message: "NO SE PUDO CREAR NUEVO TIPO DE NOTIFICACIÓN"});
		}
		this.setState({loadingSave: false});

	}
	
	closeModal(idModal) {
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

    async getType() {
		this.closeModal('addNotifications');
        this.setState({ tipos: [], loading: true });

        let data = {
            id: parseInt(localStorage.getItem('frac'))
        }
        const response = await request.post("/notificaciones/get/type/notification", data)

        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({
                    tipos: response.notifications
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }
		this.setState({ loading: false });
    }
}

export default ConfigurationTipeNotification;