import React, { Component } from 'react';
import moment from "moment";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import DinamicModal from '../dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
const request = new Request();

class ContactoEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPersona: 			 0,
            INombre: 			'',
            IDireccion: 		'',
            IMunicipio: 		'',
            IEstado: 			'',
            ICp: 				'',
            ITelefono: 			'',
            IPais: 				'',
            IGenero: 			 0,
            IId: 				 0,
            INumId: 			'',
            IVigencia: 			'',
            IFechaIngreso: 		moment().format("YYYY-MM-DD"),
            code: 				'',
            Iviv: 				'',
            IdViv: 				'',
			email: 				'',
			message: 			''
        }
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.contacto !== prevProps.contacto) {
			const phone = parsePhoneNumber(`+${this.props.contacto.telefono}`) || '';
			console.log(this.props.contacto);
			this.setState({
				idPersona: 				this.props.contacto.id_persona,
				INombre: 				this.props.contacto.nombre,
				IDireccion: 			this.props.contacto.direccion,
				IMunicipio: 			this.props.contacto.municipio,
				IEstado: 				this.props.contacto.estado,
				ICp: 					this.props.contacto.cp,
				ITelefono: 				phone.number || '',
				IPais: 					this.props.contacto.pais,
				IGenero: 				this.props.contacto.genero,
				IId: 					this.props.contacto.tipo_identificacion,
				INumId: 				this.props.contacto.num_identificacion,
				IVigencia: 				this.props.contacto.vigencia_identificacion,
				IFechaIngreso: 			moment(this.props.contacto.fecha_entrada).format("YYYY-MM-DD"),
				code: 					this.props.contacto.code,
				Iviv: 					this.props.contacto.numero_registro,
				IdViv: 					this.props.contacto.id_vivienda,
				email: 					this.props.contacto.email || '',
				IPrincipal: 			this.props.contacto.principal,
				message: 				''
			});
			const checkboxPrincipal = document.getElementById('contacto-principal');
			if(checkboxPrincipal) {
				checkboxPrincipal.checked = this.props.contacto.principal;
			}
		}
	}

	closeModal() {
		this.setState({ message: ''});
	}

    render() {
        return (
			<DinamicModal
				idModal					=	{'modal-contacto-editar'}
				sizeModalContainer		=	{'big'}
				title					=	{'EDITAR CONTACTO'} 
				success					=	{this.handleSubmit.bind(this)} 
				showBtnSuccess			=	{true}
				btnTextSuccess			=	{"GUARDAR"}
				showBtnCancel			=	{true}
				btnTextCancel			=	{"CANCELAR"}
				loadingSave				=	{this.state.loadingSave}
				message					=	{this.state.message}
				closeModal				=	{this.closeModal.bind(this)}
			>
					<div className="full row align-center">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p><b>Unidad:</b></p>
								</div>
								<div className="column full">
									<input type="text" name="vivienda" disabled value={this.state.Iviv}
										onChange={event => this.setState({ Iviv: event.target.value })}
										className="input input-modals" placeholder="N° de vivienda" required maxLength="64" />
								</div>
								<div className="column label-duo-normal space">
									<p><b>Principal:</b></p>
								</div>
								<div className="column full">
									<input type="checkbox" name="principal" id="contacto-principal"
										onChange={event => this.setState({ IPrincipal: event.target.checked })}
										className="input input-modals" placeholder="N° de vivienda" maxLength="64" />
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b>  Nombre completo:	</b></p>
								</div>
								<div className="column full">
									<input type="text" name="fullName" value={this.state.INombre}
										onChange={event => this.setState({ INombre: event.target.value })} className="input input-modals" placeholder="Nombre completo del inquilino" required maxLength="64" />
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-cente">
								<div className="column label-medium">
									<p><b>  Telefono:	</b></p>
								</div>
								<div className="column full">
									<PhoneInput
										displayInitialValueAsLocalNumber
										name="phoneNumber"
										country="MX"
										placeholder="Número de teléfono"
										value={this.state.ITelefono}
										onChange={ value => this.setState({ ITelefono: value, telefonoError: undefined }) }
										error={ this.state.telefonoError }
										className={'input-modals'}/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
										<b>Identificación:</b>
									</p>
								</div>
								<div className="column full">
									<select name="id" id="idEddit" className="input input-modals" value={this.state.IId} onChange={event => this.setState({ IId: event.target.value })}>
										<option value="0">INE</option>
										<option value="1">Pasaporte/Visa</option>
										<option value="2">Curp</option>
										<option value="3">Cartilla Militar</option>
									</select>
								</div>
								<div className="column label-duo-normal space">
									<p  className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>ID:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="numIdEddit" value={this.state.INumId}
											onChange={event => this.setState({ INumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" />
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
										<b>Correo :</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="fullName" value={this.state.email}
										onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder="Email" maxLength="64" />
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>Género:</b>
									</p>
								</div>
								<div className="column full">
									<select name="sex" id="" value={this.state.IGenero} required
										onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
										<option value="0">Hombre</option>
										<option value="1">Mujer</option>
										<option value="2">No especificado</option>
									</select>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Dirección:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="address" value={this.state.IDireccion}
										onChange={event => this.setState({ IDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" required maxLength="150" />
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
										<b>Municipio:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="municipality" value={this.state.IMunicipio}
										onChange={event => this.setState({ IMunicipio: event.target.value })} className="input input-modals" placeholder='Municipio' required maxLength="80" />
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>C.P:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" value={this.state.ICp}
										onChange={event => this.setState({ ICp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' required maxLength="10" />
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
										<b>Estado:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="state" value={this.state.IEstado}
										onChange={event => this.setState({ IEstado: event.target.value })} className="input input-modals" placeholder='Estado' required maxLength="24" />
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>País:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" value={this.state.IPais}
										onChange={event => this.setState({ IPais: event.target.value })} name="country" className="input input-modals" placeholder='País' required maxLength="10" />
								</div>
							</div>
							<div className="white-space-8" />
						</div>
					</div>
			</DinamicModal>
        );
    }

    handleOpen() {
        this.setState({ 
			showModal: true,
			idPersona: this.props.contacto.id_persona,
			INombre: this.props.contacto.nombre,
			IDireccion: this.props.contacto.direccion,
			IMunicipio: this.props.contacto.municipio,
			IEstado: this.props.contacto.estado,
			ICp: this.props.contacto.cp,
			ITelefono: this.props.contacto.telefono,
			IPais: this.props.contacto.pais,
			IGenero: this.props.contacto.genero,
			IId: this.props.contacto.tipo_identificacion,
			INumId: this.props.contacto.num_identificacion,
			IVigencia: this.props.contacto.vigencia_identificacion,
			IFechaIngreso: moment(this.props.contacto.fecha_entrada).format("YYYY-MM-DD"),
			code: this.props.contacto.code,
			Iviv: this.props.contacto.numero_registro,
			IdViv: this.props.contacto.id_vivienda 
		});
    }

    handleClose() {
        this.setState({showModal: false});
    }

    async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true, message: ''});
		let principal = false;
		const checkboxPrincipal = document.getElementById('contacto-principal');
		if(checkboxPrincipal) {
			principal = checkboxPrincipal.checked;
		}
        let data = {
            idVivienda: 			this.state.IdViv,
            Nombre: 				this.state.INombre,
            Direccion: 				this.state.IDireccion,
            Municipio: 				this.state.IMunicipio,
            Estado: 				this.state.IEstado,
            cp: 					this.state.ICp,
            Telefono: 				this.state.ITelefono,
            Pais: 					this.state.IPais,
            Genero: 				this.state.IGenero,
            TipoId: 				this.state.IId,
            NumId: 					this.state.INumId,
            Vigencia: 				this.state.IVigencia,
            Code: 					this.state.code,
            IdPersona: 				this.state.idPersona,
			email: 					this.state.email,
			principal: 				principal,
			idFraccionamiento: 		localStorage.getItem('frac') || 0
		}
        const response = await request.post("/contactos/update", data)
		this.setState({message: response.message});
        if(response.updated) {
			this.props.reload();
		}else{
			this.setState({message: "NO SE PUDO EDITAR CONTACTO"});
		}
		this.setState({loadingSave: false});
    }
}

export default ContactoEditarModal;