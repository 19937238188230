import React, { Component, Fragment } from 'react';
import moment from 'moment'
import ReactModal from "react-modal";
import ModalEddit from './modalEddit'
import ModalBorrar from '../modals/modalDelete';
import NumberFormat from "react-number-format";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import { Consumer } from "../../context";

import Request from "../../core/httpClient";
const request = new Request();


class BudgetExpensesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
            showModal: false,
			conceptos: [],
			years: [],
			id_factura: '',
            concepto: '',
            sub_fraccionamiento: 0
        }
    }

	async getYears() {
		const res = await request.get('/presupuestos/get/years');
		if(res.anios) {
			this.setState({years: res.anios});
		} else {
			this.setState({years: []});
		}
	}

	openModal(idModal,concep,id) {
		this.setState({message: null,concepto: concep,id_factura: id});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

    reload() {
        this.props.reload()
    }
    delete = id => {
        this.handleDelete(id)
    }
    render() {
        let facturas = []

        if (this.props.conceptos) {
            facturas = this.props.conceptos
        }

        return (
            <div className="card-table card-owners column" id="card-owners">
              	<div className="row align-center">
					<p className="font-regular weight-medium color-dark">
						Presupuesto:
					</p>
					<div className="column">
						<select className="input input-select" id="year" value={this.props.year} onChange={this.props.setYear.bind(this)}>
							{this.state.years.map(year => <option key={year.anio} value={year.anio}>{year.anio}</option>)}
						</select>
					</div>
                </div>
                <div className="white-space-16" />
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="">Folio</th>
                                <th className="description">Concepto</th>
                                <th className="">Importe</th>
                                <th className="">Fecha</th>
                                <th className="text-center th-options-small"> Editar </th>
                                <th className="text-center th-options-small"> Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {facturas.length > 0 ? (
                                facturas.slice(((this.props.page - 1) * 7), (this.props.page * 7)).map((concepto, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                {concepto.num_factura}
                                            </td>
                                            <td>
                                                {concepto.descripcion}
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    value={concepto.importe}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"$"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </td>

                                            <td>
                                                {moment(concepto.fecha).format("DD-MM-YYYY")}
                                            </td>
                                            <td className="text-center">
                                                <button className="btn-full justify-center btn-mini btn-secondary color-white" type="button" onClick={this.openModal.bind(this,"editExpense",concepto,concepto.id_factura)}>
                                                    <i className="font-small fas fa-pen" />
                                                </button>
											</td>
                                            <td>
                                                <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, concepto.id_factura)} style={{ padding: '5px' }} >
                                                    <i className="font-text fas fa-trash-alt " />
                                                </button>
											</td>
                                        </tr>
                                    )
                                })
                            ) : null
                            }
                        </tbody>
                    </table>

                </div>
               
                <ModalBorrar 
                    id      =   {this.state.id_factura} 
                    delete  =   {this.delete.bind(this)} 
                />
                <ModalEddit 
                    id                      =   {this.state.id_factura} 
                    concepto                =   {this.state.concepto} 
                    conceptos               =   {this.state.conceptos} 
                    reload                  =   {this.reload.bind(this)} 
                    subfraccionamientos     =   {this.props.subfraccionamientos}
                />
				<DinamicModal 
					idModal                 =   {'newExpense'}
					sizeModalContainer      =   {'small'}
					title                   =   {'NUEVA FACTURA'} 
					success                 =   {this.handleSubmit.bind(this)} 
					showBtnSuccess          =   {true}
					btnTextSuccess          =   {"GUARDAR"}
					showBtnCancel           =   {true}
					btnTextCancel           =   {"CANCELAR"}
					loadingSave             =   {this.state.loadingSave}
					message                 =   {this.state.message}
				>
				{this.renderNewExpense()}
				</DinamicModal>

            </div>
        );
	}

    componentDidMount() {
		this.load();
		this.getYears();
		this.getConcepts()
	}

	openModalDelete(id) {
		this.setState({id_factura: id});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
    }
    
    closeModal(idModal) {
		let modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-modal');
			modal.classList.remove('show-modal');
		}
	}
	
	renderNewExpense() {
        let subfraccionamientos = [];
        if(Array.isArray(this.props.subfraccionamientos)) {
			subfraccionamientos = this.props.subfraccionamientos;
		}
		return(
			<div className="full row align-center">
			<div className="column full">
                <div className="input-form-content row full align-center">
                    <div className="column label-medium">
                        <p>
                            <b>Subfraccionamiento:</b>
                        </p>
                    </div>
                    <div className="column full">
                        <select className="input input-modals" name="subfraccionamiento" value={this.state.sub_fraccionamiento} onChange={(event) => this.setState({sub_fraccionamiento: event.target.value})}>
                            {subfraccionamientos.map((sub, index) => <option key={index} value={sub.id_subfraccionamiento}>{sub.nombre}</option>)}
                        </select>
                    </div>
                </div>
                <div className="white-space-16" />
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p>
							<b>Concepto:</b>
						</p>
					</div>
					<div className="column full">
						<select name="concepto" id="" >
							{this.state.conceptos.map((concepto, key) => (
								<option key={key} value={concepto.id_concepto}>{concepto.descripcion}</option>
							))}
						</select>
					</div>
				</div>
				<div className="white-space-16" />
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p>
							<b>N° Factura:</b>
						</p>
					</div>
					<div className="column full">
						<input type="text" name="factura"
							className="input input-modals" placeholder="Número/Folio de factura" required minLength='2'
						/>
					</div>
				</div>
				<div className="white-space-16" />
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p>
							<b>Fecha:</b>
						</p>
					</div>
					<div className="column full">
						<input type="date" name="fecha"
							className="input input-modals" placeholder="Fecha" required minLength='2'
							maxLength='4'
						/>
					</div>
				</div>
				<div className="white-space-16" />
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p>
							<b>Importe:</b>
						</p>
					</div>
					<div className="column full">
						<input type="number" name="importe"
							className="input input-modals" placeholder="$ 00.00" required minLength='2'
							maxLength='4'
						/>
					</div>
				</div>
			
			</div>
		</div>
   
		)
	}


    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({
                user: {
                    idUsuario: user.id.id_usuario,
                    idPerfil: user.id.id_perfil,
                    idFraccionamiento: user.id.id_fraccionamiento
                }
            });
        }
	}

    handleOpen() {
        this.setState({ showModal: true })
        this.getConcepts()
	}

    handleClose() {
        this.setState({ showModal: false })
    }

    async handleSubmit(event) {
		this.setState({loadingSave: true, message: ''});
        event.preventDefault();
        const form = event.target
        let data = {
            IdSub:          form.subfraccionamiento.value,
            IdConcepto:     parseInt(form.concepto.value),
            importe:        parseInt(form.importe.value),
            NumFactura:     form.factura.value,
            Fecha:          form.fecha.value,
            IdPresupuesto:  this.props.IdPresupuesto,
            IdUsuario:      this.state.user.idUsuario
        }
        const response = await request.post("/presupuestos/presupuesto/create/factura", data);
        if(response.created){
            this.closeModal("newExpense");
            this.props.reload();
        }else{
            this.setState({message: "NO SE PUDO CREAR FACTURA"});
        }
        this.setState({loadingSave: false});
    }

    // traer todos los conceptos por fraccionamiento
    async getConcepts() {
        this.setState({ conceptos: [], idconcepto: 0 });
        let data = {
            IdFraccionamiento: localStorage.getItem('frac')
        }
        let response = await request.post("/presupuestos/get/all/real/concepts", data)

        if (response && !response.error) {
            if (response.concepts && !response.empty) {
                this.setState({
                    conceptos: response.concepts
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
    }

    async handleDelete(id) {
        let data = {
            idDetalle: id
        }
        const response = await request.post("/presupuestos/delete/factura", data)
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.reload()
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({ error: true, message: response.message })
        }
    }

}

export default Consumer(BudgetExpensesTable);