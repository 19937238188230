/**
 *  pages.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Login
 */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import LogoBlack from "../img/logoblack.png";
import { Consumer } from "../context";
import Request from "../core/httpClient";
const request = new Request();

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 }
		};
	}

	render() {
		return (
			<div className="login column">
				<Helmet>
					<title>iGate - Login</title>
				</Helmet>
				<div className="justify-center content">
					<div className="container align-center justify-center">
						<div className="card-login column">
							<div className="responsive-img justify-center">
								<img
									src={LogoBlack}
									alt="Logo iGate"
									title="Logo iGate"
								/>
							</div>
							<div className="white-space-8" />
							<h3 className="text-center">Iniciar sesión</h3>
							<div className="white-space-16" />
							<form
								className="column"
								onSubmit={this.handleSubmit.bind(this)}>
								<div className="input-container">
									<input
										name="user"
										type="text"
										maxLength="64"
										minLength="6"
										autoComplete="ÑÖcompletes"
										placeholder="Usuario"
										className="weight-semi text-center"
										required
									/>
								</div>
								<div className="white-space-8" />
								<div className="input-container">
									<input
										name="password"
										type="password"
										maxLength="64"
										minLength="8"
										autoComplete="ÑÖcompletes"
										placeholder="Contraseña"
										className="weight-semi text-center"
										required
									/>
								</div>
								<div className="white-space-16" />
								<div className="btn-container">
									<button
										type="submit"
										className="btn btn-primary color-white">
										{this.state.loading ? (
											<i className="fas fa-spinner fa-spin font-text" />
										) : (
												<span className="color-white">
													<i className="fas fa-sign-in-alt font-text" />
													&nbsp; ACCEDER
											</span>
											)}
									</button>
								</div>
								{this.state.message ? (
									<div className="column">
										<div className="white-space-8" />
										<p className="text-center">
											{this.state.message}
										</p>
									</div>
								) : null}
								<div className="white-space-16" />
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento
				}
			});
			this.props.history.push("/tablero");
		}
	}

	async getMenus() {
		/*const user = await this.props.context.loadUser();

		if (user) {
			console.log(user);
			const data = { idFraccionamiento: user.id.id_fraccionamiento };
			const response = await request.post("/fraccionamientos/get/menus/parents", data);
			if (response) {
				window.localStorage.removeItem('catalogos_menus');
				window.localStorage.removeItem('administracion_menus');
				this.props.context.menuSidebar(response.parents);
			}
		}
		
		let observable = JSON.parse(localStorage.getItem('object'));
		if (observable) {
			const data = { idFraccionamiento: observable.if };
			const response = await request.post("/fraccionamientos/get/menus/parents", data);
			if (response) {
				this.props.context.menuSidebar(response.parents);
			}
		}*/
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({ loading: true });
		const form = event.target;
		let data = { user: form.user.value, password: form.password.value };
		const response = await request.post("/users/login", data);
		if (response && !response.error) {
			if (response.user && !response.empty) {
				this.props.context.login({ id: response.user, auth: true });
				this.getMenus();
				this.props.history.push("/tablero");
			} else {
				this.setState({
					empty: response.empty,
					loading: false,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				loading: false,
				message: response.message
			});
		}
	}
}

export default withRouter(Consumer(Login));
