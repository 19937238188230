import React, { Component } from 'react';
import ProfilesModal from './profilesModalEddit'
import ModalBorrar from '../modals/modalDelete'
import ProfilesModalDetail from './profilesDetail';
import ProfileDetailTable from './profileDetailTable';
import Request from "../../core/httpClient";
const request = new Request();

class ProfilesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			id_perfil: ''
		}
    }

    reload() { this.props.reload() }
    delete = id => { this.handleDelete(id) }

    openRow(idPerfil) {
        if (this.state.perfilId === idPerfil) {
            this.setState({ perfilId: 0 });
            return;
        }
        this.setState({ perfilId: idPerfil });
    }

    render() {
        let perfiles = []

        if (this.props.perfiles) {
            perfiles = this.props.perfiles
        }

        return (
            <div className="card-table card-owners column" id="card-owners">
                {/*<h4>Usuarios del fraccionamiento</h4>*/}
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="nombre">Perfil</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                                <th className="text-center th-options-small">Permisos</th>
                            </tr>
                        </thead>
                        <tbody>

                            {perfiles.map((perfil, key) => (
                                <React.Fragment key={perfil.id_perfil}>
                                    <tr>
                                        <td className='text-left'>{perfil.nombre}</td>
                                        <td className="text-center">
                                            <ProfilesModal perfil={perfil} reload={this.reload.bind(this)} />
                                        </td>
                                        <td className="text-center">
                                            <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, perfil.id_perfil)} style={{ padding: '5px' }} >
                                                <i className="font-text fas fa-trash-alt " />
                                            </button>
                                        </td>
                                        <td className="text-center">
                                            <button type="button justify-center" onClick={this.openRow.bind(this, perfil.id_perfil)} /*onClick={this.handleOpen.bind(this)}*/> 
                                                <i className="font-small fas fa-user-lock"></i>
                                            </button>
                                            {/*<ProfilesModalDetail id={perfil.id_perfil} />*/}
                                        </td>
                                    </tr>
                                    {this.state.perfilId === perfil.id_perfil && 
                                        <tr>
                                            <td colSpan="6">
                                                <div className="row full">
                                                    <ProfileDetailTable id={this.state.perfilId} />
                                                    {/*<ProfilesModalDetail id={this.state.perfilId} />*/}
                                                </div>
                                            </td>
                                        </tr>}
                                </React.Fragment>
                            ))}

                        </tbody>
                    </table>
                </div>
				<ModalBorrar id={this.state.id_perfil} delete={this.delete.bind(this)} />
            </div>
        );
	}
	openModalDelete(id) {
		this.setState({id_perfil: id});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleDelete(id) {

        let data = { idperfil: id }
        const response = await request.post("/profiles/delete/profile", data)


        if (response && !response.error) {
            if (response.deleted && !response.empty) {

                this.setState({
                    showModal: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });


        }
        this.reload()
    }




}

export default ProfilesTable;