import React, { Component } from 'react';
import ReactModal from "react-modal";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
const request = new Request();

class ModalEddit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal:          false,
            idUsuario:          this.props.data.id_usuario,
            nombre:             this.props.data.nombre,
            usuario:            this.props.data.usuario,
            password:           '',
            rol:                this.props.data.id_perfil,

			valid:              false,
            valids:             true,
            edditPassHident:    true,
            user:               [],
            idFraccionamiento:  0,
            editUser:           false,
            eddit:              true,
            valid:              true,
            edditPass:          false,
			existe:             false,
			loadingSave:        false

        }
	}

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				idUsuario:  this.props.data.id_usuario,
				nombre:     this.props.data.nombre,
				usuario:    this.props.data.usuario,
				rol:        this.props.data.id_perfil,
				password:   ''
			});
			console.log("DATAAA ->",this.props.data);
			this.getUser();
		}
	}
	


    render() {
        let perfiles = []
        if (this.props.perfiles) {
            perfiles = this.props.perfiles
        }
        return (
            <div>
               
				<DinamicModal 
					idModal             =   {'editUsers'}
					sizeModalContainer  =   {'normal'}
					title               =   {'EDITAR USUARIO'} 
					success             =   {this.handleSubmit.bind(this)} 
					showBtnSuccess      =   {true}
					btnTextSuccess      =   {"GUARDAR"}
					showBtnCancel       =   {true}
                    btnTextCancel       =   {"CANCELAR"}
                    closeModal          =   {this.restart.bind(this)}
					loadingSave         =   {this.state.loadingSave}
					message             =   {this.state.message}
				>
				{this.renderEditUser(perfiles)}
				</DinamicModal>

            </div>
        );
	}
	
	renderEditUser(perfiles) {
		return (
			<div className="full row align-center">
				<div className="column full">
						<div className="input-form-content row full align-center ">
							<div className="column label-big align-start">
								<p><b>Nombre:</b></p>
							</div>
							<div className="column full">
								<input type="text" name="fullName" value={this.state.nombre} onChange={event => this.setState({ nombre: event.target.value })} placeholder="Nombre" className="input" required minLength="6" maxLength="100" />
							</div>
						</div>
						<div className="white-space-16"></div>
						<div className="input-form-content row full align-center ">
							<div className="column full label-big  align-start">
								<p><b>Usuario:</b></p>
							</div>
							<div className="column full">
								<div className="row full">
								<input type="text" name="user" value={this.state.usuario}  onChange={event => this.setState({ usuario: event.target.value })} className="input input-modals" placeholder="Usuario" required minLength="6" maxLength="150" />
							
								</div>
							</div>
						</div>
						<div>
							{this.state.existe ? (<p style={{ color: 'red' }}>Este usuario ya está registrado.</p>) : null}
						</div>
						<div className="white-space-16" />
						<div className="input-form-content row full align-center ">
							<div className="column label-big align-start">
								<p><b>Perfil:	</b></p>
							</div>
							<div className="column full">
							<select name="rol" id="" className="input input-modals"
								value={this.state.rol}
								onChange={event => this.setState({ rol: event.target.value })}>

								{perfiles.map((perfil, key) => (
									<option key={key} value={perfil.id_perfil}>{perfil.nombre}</option>
								))}
							</select>
							</div>
						</div>
					
						<div className="white-space-24"></div>
							<div className="row align-center justify-end">
								<button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.handleEdit.bind(this)} >
									<i className="font-text fas fa-lock" />&nbsp;
									Nueva contraseña
								</button>
							</div>
							{
								this.state.edditPass ? (
									<div>
										<div className="input-form-content row full align-center">
											<div className="column label-big align-start">
												<p>
													<b>Contraseña:</b>
												</p>	
											</div>
											<div className="column full">
												<input type="password" name="pass" id="pass" className="input"  onKeyUp={this.passValidate.bind(this)} required minLength="8" maxLength="150" placeholder="Contraseña" />
											</div>
										</div>
										<div className="white-space-8"></div>


										<div className="input-form-content row full align-center">
											<div className="column label-big align-start">
												<p>
													<b>Confirmar contraseña:</b>
												</p>
											</div>
											<div className="column full">
												<input type="password" name="pass2" id="pass2" value={this.state.password} onKeyUp={this.passValidate.bind(this)} onChange={event => this.setState({ password: event.target.value })} className="input" required minLength="8" maxLength="150" placeholder="Confirmar contraseña" />
											</div>
										</div>
										<div className="white-space-8"></div>
										{this.state.valid ? (null) : (<p style={{ color: 'red' }}>Las contraseñas deben ser iguales o mayor a 6 caracteres.</p>)}
										
									</div>
								) : null
							}
					</div>
			</div>
                    
		)
	}
   
    passValidate() {
        let pass1 = document.getElementById('pass').value,
			pass2 = document.getElementById('pass2').value;
			
			console.log(`${pass1} == ${pass2}`);
			if(pass1 === pass2 && pass1.length >= 8){
				this.setState({valid: true});
				return true;
			}
			this.setState({valid: false});
			return false;
    }
    restart(){
         document.getElementById('pass'). value = "";
         this.setState({password: ""});
       
    }
    async handleSubmit(event) {
        event.preventDefault();

		this.setState({loadingSave: true});
        let data = {
            nombre:         this.state.nombre,
            rol:            parseInt(this.state.rol),
			idUser:         this.state.idUsuario,
			UsuarioName:    this.state.usuario
        };


        if(this.passValidate()){
            const response = await request.post("/users/fraccionamiento/update/user", data);

            if (response.updated) {
                if(this.edditPassword)
                {
                    await this.edditPassword();
                } else{
                    this.props.reload();
                }
            } else {
                this.setState({
                    empty:      true,
                    message:    "NO SE PUDO ACTUALIZAR USUARIO"
                });
            }
        }
        
		this.setState({loadingSave: false});
	
       

	}
	


    handleCloseUser() {
        this.setState({ editUser: false, eddit: true, existe: false })
    }
    handleEditUser() {
        this.setState({
            editUser: true,
            edditPass: false,
            eddit: false
        })
    }

	handleEdit() {
        this.setState({
            edditPass:          !this.state.edditPass,
            edditPassHident:    !this.state.edditPassHident,
            eddit:              !this.state.eddit,
            editUser:           !this.state.editUser
        })
    }
   
    handleCancel() {
        this.setState({
            edditPass:  false,
            eddit:      true,
            editUser:   false
        })
    }

    async getUser() {
        let id = this.props.idUser

        let data = {
            IdUser: id
        }

        const response = await request.post("/users/get/fraccionamiento/user", data);
        //console.log('response: ', response);

        if (response && !response.error) {
            if (response.user && !response.empty) {
                this.setState({
                    nombre: response.user[0].nombre,
                    usuario: response.user[0].usuario,
                    rol: response.user[0].id_perfil,
                    idFraccionamiento: response.user[0].id_fraccionamiento
                });
            } else {
                this.setState({
                    empty: true,
                    // message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                // message: response.message
            });

        }
    }


    async edditUser() {
		this.setState({
            edditPass: true,
            edditPassHident: false,
            eddit: false,
            editUser: false
		})
		
        let validar = {
            Usuario: this.state.usuario,
            idFraccionamiento: this.state.idFraccionamiento,
        }
        const response = await request.post("/users/validate/user", validar);
        if (response && !response.error) {
            if (response.validate.exist && !response.empty) {
                this.setState({
                    existe: true,
                });
                return;
            } else {
                this.setState({
                    existe: false,
                });
                let data = {
                    Usuario: this.state.usuario,
                    idUser: this.props.idUser
                }
                const response = await request.post("/users/fraccionamiento/update/users/user", data)
                if (response && !response.error) {
                    if (response.updated && !response.empty) {
                        this.props.reload()
                        this.setState({

                            editUser: false
                        });

                    } else {
                        this.setState({
                            empty: true,
                            message: response.message
                        });
                    }
                } else {
                    this.setState({
                        error: true,
                        message: response.message
                    });

                }

            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }



    }

    async edditPassword() {
        let pass1 = document.getElementById('pass').value,
            pass2 = document.getElementById('pass2').value;

        if (pass1 === pass2) {
            this.setState({ valid: true, valids: false })
            let data = {
                IdUser: this.props.idUser,
                Password: pass2,

            }
            const response = await request.post("/users/fraccionamiento/update/user/password", data);
            if (response && !response.error) {
                if (response.updated && !response.empty) {
                    this.props.reload()
                    this.setState({

                        edditPass: false,
                        eddit: true
                    });

                } else {
                    this.setState({
                        empty: true,
                        message: response.message
                    });
                }
            } else {
                this.setState({
                    error: true,
                    message: response.message
                });

			}
			this.props.reload();

        } else {
			this.setState({ valid: false, valids: true })
			alert('Las contraseñas deben ser iguales');
            return;
        }
    }
}


export default ModalEddit;