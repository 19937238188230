/**
 *  Profiles.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de perfiles
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import ProfilesTable from '../components/profiles/profilesTable'
import ReactModal from "react-modal";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";

const request = new Request();

class Profiles extends Component {
	state = {
		perfiles: 					[],
		showModal: 					false,
		subfraccionamiento: 		localStorage.getItem('subfrac'),
		filter: 					'',
		message: 					'',
		loading:					''
	}

	reload() {
		this.loadPerfil()
	}

	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	changeFrac() {
		this.loadPerfil()
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevoPerfil() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(perfiles) {
		perfiles = perfiles.filter(per => {
			if(per.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return perfiles;
	}

	render() {
		let dataSet;
		let perfiles = [];
		if(Array.isArray(this.state.perfiles)) {
			perfiles = this.state.perfiles;
			dataSet = perfiles;
			perfiles = this.filter(perfiles);
		}
		return (
			<div className="owners column">
				<Helmet>
					<title>iGate - Perfiles</title>
				</Helmet>
				<Header sidebar={true} active={'perfiles'} parent={'config'} panel={'panel3'} nav={"catalogos"} reload={this.reload.bind(this)} />

				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								<Controls 
									title		=	"Perfiles"
									nuevo		=	{this.openModal.bind(this,"addProfile")}
									dataSet		=	{dataSet}
									setFilter	=	{this.setFilter.bind(this)}
									subChange	=	{this.subChange.bind(this)}
									selectSub	=	{false}
								/>
								<div className="white-space-16" />
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<ProfilesTable perfiles={perfiles} reload={this.reload.bind(this)} />
								}
							</div>
						</div>

					</div>
				</div>

				<DinamicModal 
					idModal					=	{'addProfile'}
					sizeModalContainer		=	{'small'}
					title					=	{'NUEVO PERFIL'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loading}
					closeModal				=	{this.handleClose.bind(this)}
					message					=	{this.state.message}
				>
				{this.renderNewProfile()}
				</DinamicModal>
			</div>
		);


	}

	renderNewProfile() {
		return(
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p><b> Perfil:	</b></p>
					</div>
					<div className="column full">
						<input type="text" name="nameProfile" className="input input-modals" id="profile" placeholder="Nuevo concepto" required maxLength="64" />
					</div>
				</div>
			</div>
		</div>

		)
	}

	handleClose() { document.getElementById('profile').value = '' }
	handleOpen() { this.setState({ showModal: true }) }

	componentDidMount() {
		this.loadPerfil()
	}

	async handleSubmit(event) {
		event.preventDefault();

		let form = event.target
		let data = { perfil: form.nameProfile.value, IdFrac: localStorage.getItem('frac') }

		const response = await request.post("/profiles/create/new/profile", data)

		
			if (response.created ) {
				this.setState({
					showModal: false,
					message: "PERFIL AGREGADO CORRECTAMENTE"
				});
				this.closeModal("addProfile");
				this.loadPerfil()
			} else {
				this.setState({
					empty: true,
					message: "NO SE PUGO AGREGAR PERFIL"
				});
			}
		
		form.nameProfile.value = '';
		this.setState({loading: false});
	}


	async loadPerfil() {
		this.setState({ perfiles: [], loading: true });
		let data = { IdFrac: parseInt(localStorage.getItem('frac')) };

		const response = await request.post("/users/user/perfiles", data)
		//console.log('response: ', response);

		if (response && !response.error) {
			if (response.perfiles && !response.empty) {
				this.setState({
					perfiles: response.perfiles
				});
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.setState({ loading: false });
	}
}


export default Consumer(Profiles);
