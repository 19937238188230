/**
 *  index.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejador principal del estado global de la aplicación
*/

import React, { Component, createContext } from "react";
import { loadUser, login, logout } from "./users";
import { menuSidebar, getMenus } from './menu';
import { setFraccionamiento, setNombreFraccionamiento } from './fraccionamiento';
// Instancia del Context, métodos: Provider y Consumer

const Context = createContext();

class GlobalContext extends Component {

    state = {
        init: null,
        loadUser: loadUser.bind(this),
        login: login.bind(this),
        logout: logout.bind(this),
        catalogos_menus: [],
        administracion_menus: [],
        menuSidebar: menuSidebar.bind(this),
        fracActual: 0,
		setFraccionamiento: setFraccionamiento.bind(this),
		setNombreFraccionamiento: setNombreFraccionamiento.bind(this),
		getMenus: getMenus.bind(this),
		menusCatalogos:[],
		menusAdministacion: []
    };

    componentDidMount() {
        this.state.loadUser();
    }

    render() {
        return (
            <Context.Provider value = { this.state }>
                { this.props.children }
            </Context.Provider>
        );
    }
}

/**
 * @function: Consumer
 * @description: HOC conector entre el estado global y un componente consumidor.
 * @param: Component => Componente Web
*/

const Consumer = Component => {
    return props => {
        return (
            <Context.Consumer>
                { context => <Component { ...props } context = { context } /> }
            </Context.Consumer>
        );
    };
};

export { Consumer, GlobalContext };