import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
const request = new Request();

class ProfileDetailTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            menus: [],
            user: {
                idUsuario: 0,
                idPerfil: 0,
                idFraccionamiento: 0
            },
            idPerfil: this.props.id,
            showCatalogos: true,
            showAdministracion: false,
            showReports: false,
            listaMenus: []
        }
	}
	
	selectAll(option,listName) {

		let checkboxes = document.getElementsByName(option);  
		let optionList = document.getElementById(listName);
		const perfil = this.state.menus;

		if(optionList.checked === true) {
			for(let i = 0; i < checkboxes.length; i++)  
			{  
				checkboxes[i].checked = true;
				if(listName === "select-all-r") {
					perfil[i].lectura = 1;
				}
				else {
					perfil[i].escritura = 1;
				}
			}  
		}
		else {
			for(let i = 0; i < checkboxes.length; i++)  
			{  
				checkboxes[i].checked = false;
				if(listName === "select-all-r") {
					perfil[i].lectura = 0;
				}
				else {
					perfil[i].escritura = 0;
				}
			}  
		}

		this.setState({ menus: perfil })
		console.log("CAMBIOS", perfil)
	
	}

    render() {
        return (
            <div className="profile-detail">
                <form className="flex justify-center" onSubmit={this.handleSubmit.bind(this)}>
                    <div className="full column container">
                        <div className="column tab content">
                            <div className="row menus">
                                <div className="column container align-center justify-center option" id="option1">
                                    <button type='button' className="butn" onClick={this.showCatalos.bind(this, 1)}>
                                        Catálogos
                                    </button>
                                </div>
                                <div className="column container align-center justify-center no-select" id="option2">
                                    <button type='button' className="butn" onClick={this.showAdministracion.bind(this, 2)}>
                                        Administración
                                    </button>
                                </div>
                                <div className="column container align-center justify-center no-select" id="option3">
                                    <button type='button' className="butn" onClick={this.showReports.bind(this, 3)}>
                                        Reportes
                                    </button>
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="detail-table">
                                <div className="row justify-center menu2">
                                    <div className="column container align-center" >
                                        <h4 className="">Menú</h4>
                                    </div>
                                    <div className="row align-center justify-center">
                                        <h4 className="">Lectura</h4> 
                                        &nbsp; 
										<label className="container-check">
											<input type="checkbox" name="select-all-r" id="select-all-r" onClick={this.selectAll.bind(this,"lectura","select-all-r")}/>
											<span className="checkmark"></span>
										</label>
                                    </div>
                                    <div className="row align-center justify-center">
                                        <h4 className="">Escritura</h4>
                                        &nbsp; 
										<label className="container-check" >
										<input type="checkbox" name="select-all-w" id="select-all-w" onClick={this.selectAll.bind(this,"escritura","select-all-w")}/>
										<span className="checkmark"></span>
										</label>
                                    </div>
                                </div>
                                {this.state.showCatalogos ? (
                                    <div className="container-complete">
                                        <div className="white-space-16"></div>
                                        {this.state.menus.map((menu, key) => (
                                            <div className="row justify-center align-center" key={key}>
                                                <div className="column align-start" style={{ paddingLeft: "32px" }}>
                                                    {menu.pantalla === -1 ? (
                                                        <div style={{marginBottom:"8px"}}>
                                                            {menu.parent === 0 ? (
                                                                <h4 className="weight-bold" name='menuName' >
                                                                    {menu.nombre}
                                                                </h4>
                                                            ) :
                                                                <p className="" name='menuName' >
                                                                    {menu.nombre}
                                                                </p>}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                {menu.pantalla === -1 ? (
                                                    <React.Fragment>
                                                        <div className="column align-center input-checks-red" >
                                                            {menu.parent === 0 ? (
																<label className="container-check">
																	<input type="checkbox" hidden name="lectura" id="lectura" checked={true} onChange={this.setOption.bind(this, 'lectura', menu, key)}
																	checked={menu.lectura ? (true) : (false)} />
																	<span className="checkmark"></span>
																</label>
																) : 
																( 
																<label className="container-check">
																	<input type="checkbox" name="lectura" id="lectura" onChange={this.setOption.bind(this, 'lectura', menu, key)}
																	checked={menu.lectura ? (true) : (false)} />
																	<span className="checkmark"></span>
																</label>
																)}
                                                        </div>
                                                        <div className="column align-center input-checks-red" >
                                                            {menu.parent === 0 ? (
																<label className="container-check">
																	<input type="checkbox" hidden name="escritura" id="escritura" checked={true} onChange={this.setOption.bind(this, 'escritura', menu, key)}
																	checked={menu.escritura ? (true) : (false)} />
																	<span className="checkmark"></span>
																</label>
																) : (
																<label className="container-check">
																	<input type="checkbox" name="escritura" id="escritura" onChange={this.setOption.bind(this, 'escritura', menu, key)}
                                                                    checked={menu.escritura ? (true) : (false)} />
																	<span className="checkmark"></span>
																</label>
                                                                )}
                                                        </div>
                                                    </React.Fragment>
                                                ) : null}
                                            </div>
                                        ))}
                                        <div className="white-space-16"></div>
                                    </div>

                                ) : null}
                                {this.state.showAdministracion ? (
                                    <div className="container-complete">
                                        <div className="white-space-16"></div>
                                        {this.state.menus.map((menu, key) => (
                                            <div className="row justify-center align-center" key={key} style={{ paddingLeft: '25px' }}>
                                                <div className="column align-start">
                                                    {menu.pantalla === -2 ? (
                                                        <div style={{marginBottom:"8px"}}>
                                                            {menu.parent === 0 ? (
                                                                <h4 className="weight-bold" name='menuName' >
                                                                    {menu.nombre}

                                                                </h4>
                                                            ) :
                                                                <p className="" name='menuName' >
                                                                    {menu.nombre}

                                                                </p>}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {menu.pantalla === -2 ? (
                                                    <React.Fragment>
                                                        <div className="column align-center input-checks-red" >
															{menu.parent === 0 ? null : (
															<label className="container-check">
																<input type="checkbox" name="lectura" id="lectura" onChange={this.setOption.bind(this, 'lectura', menu, key)}
																checked={menu.lectura ? (true) : (false)} />
																<span className="checkmark"></span>
															</label>
															)}
                                                        </div>

											
                                                        <div className="column align-center input-checks-red" >
                                                            {menu.parent === 0 ? null : (
																<label className="container-check">
                                                                	<input type="checkbox" name="escritura" id="escritura" onChange={this.setOption.bind(this, 'escritura', menu, key)}
                                                                    checked={menu.escritura ? (true) : (false)} />
																	<span className="checkmark"></span>
																</label>
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                ) : null}
                                            </div>
                                        ))}
                                        <div className="white-space-16"></div>
                                    </div>
                                ) : null}

                                {this.state.showReports ? (
                                    <div className="container-complete">
                                        <div className="white-space-16"></div>
                                        {this.state.menus.map((menu, key) => (
                                            <div className="row justify-center align-center" key={key} style={{ paddingLeft: '25px' }}>
                                                <div className="column align-start">
                                                    {menu.pantalla === -3 ? (
                                                        <div style={{marginBottom:"8px"}}>
                                                            {menu.parent === 0 ? (
                                                                <h4 className="weight-bold" name='menuName' >
                                                                    {menu.nombre}

                                                                </h4>
                                                            ) :
                                                                <p className="" name='menuName' >
                                                                    {menu.nombre}

                                                                </p>}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {menu.pantalla === -3 ? (
                                                    <React.Fragment >
                                                        <div className="column align-center input-checks-red" >
                                                            {menu.parent === 0 ? null : (
															<label className="container-check">
															<input type="checkbox" name="lectura" id="lectura" onChange={this.setOption.bind(this, 'lectura', menu, key)} checked={menu.lectura ? (true) : (false)} />)}
															<span className="checkmark"></span>
															</label>)}
                                                        </div>
                                                        <div className="column align-center input-checks-red" >
                                                            {menu.parent === 0 ? null : (
																<label className="container-check">
                                                                	<input type="checkbox" name="escritura" id="escritura" onChange={this.setOption.bind(this, 'escritura', menu, key)}
                                                                    checked={menu.escritura ? (true) : (false)} />
																	<span className="checkmark"></span>
																</label>
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                ) : null}
                                            </div>
                                        ))}
                                        <div className="white-space-16"></div>
                                    </div>
                                ) : null}

                                <div className="btn-container justify-end" style={{ paddingTop: "24px", paddingRight: "24px" }}>
                                    <button type="submit" className="btn btn-primary color-white" >
                                        <i className="fas fa-check" />
                                        &nbsp; GUARDAR
                                    </button>
                                    {/*<button type="button" className="btn btn-secondary color-white" onClick={this.handleClose.bind(this)}>
                                        <i className="fas fa-times" />
                                        &nbsp; CANCELAR
                                    </button>*/}
                                </div>
                                <div className="white-space-8"></div>
                            </div>
                        </div>
                    </div>
                </form>
                {/*</ReactModal>*/}
            </div>
        );
    }

    componentDidMount() {
        this.load();
        this.getMenus();
    }

    showAdministracion(option) {
        let step = document.getElementById("option" + option);

        for (let i = 1; i < 4; i++) {
            let item = document.getElementById("option" + i);
            if (item.classList.contains('option')) {
                item.classList.remove('option');
                item.classList.add('no-select');
            }
        }
        step.classList.remove('no-select');
        step.classList.add('option');

        this.setState({ showAdministracion: true, showCatalogos: false, showReports: false })
    }

    showCatalos(option) {
        let step = document.getElementById("option" + option);

        for (let i = 1; i < 4; i++) {
            let item = document.getElementById("option" + i);
            if (item.classList.contains('option')) {
                item.classList.remove('option');
                item.classList.add('no-select');
            }
        }
        step.classList.remove('no-select');
        step.classList.add('option');

        this.setState({ showAdministracion: false, showCatalogos: true, showReports: false });
    }

    showReports(option) {
        let step = document.getElementById("option" + option);

        for (let i = 1; i < 4; i++) {
            let item = document.getElementById("option" + i);
            if (item.classList.contains('option')) {
                item.classList.remove('option');
                item.classList.add('no-select');
            }
        }
        step.classList.remove('no-select');
        step.classList.add('option');

        this.setState({ showAdministracion: false, showCatalogos: false, showReports: true })
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({
                user: {
                    idUsuario: user.id.id_usuario,
                    idPerfil: user.id.id_perfil,
                    idFraccionamiento: user.id.id_fraccionamiento
                }
            });
        }
    }

    handleClose() { this.setState({ showModal: false, showAdministracion: false, showCatalogos: true }) }

    async getMenus() {
        let data = {
            IdFraccionamiento: parseInt(localStorage.getItem('frac')),
            IdPerfil: this.state.idPerfil
        }

        const response = await request.post("/profiles/get/menus/user", data);

        if (response && !response.error) {
            console.log(response);
            if (response.menus && !response.empty) {
                this.setState({
                    menus: response.menus
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
    }
    async handleSubmit(event) {
        event.preventDefault();

        //Datos al API
        const data = {

            id_perfil: this.state.idPerfil,
            menus: this.state.menus
        }

        //console.log(data);


        const response = await request.post('/profiles/permissions/user', data);
        //console.log('response: ', response);

        if (response && !response.error) {
            if (response.updated && !response.empty) {

                this.setState({
                    showModal: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });


        }

    }

    setOption(option, menu, key, event) {

        const perfil = this.state.menus


        if (option === 'lectura') {

            if (event.target.checked === true) {
                perfil[key].lectura = 1
            } else {
                perfil[key].lectura = 0
            }

        }
        if (option === 'escritura') {
            if (event.target.checked === true) {
                perfil[key].escritura = 1
            } else {
                perfil[key].escritura = 0
            }

        }
        this.setState({ menus: perfil })
		console.log("CAMBIOS", perfil)


        // menu[option] = event.target.checked;
        // const elemento = this.state.menus.find(men => men.id_menu.toString() === menu.id_menu.toString());
        // Object.assign(elemento, menu);
    }
}

export default Consumer(ProfileDetailTable);