import React, { Component } from 'react';

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
			open: false,
			text: this.textValue(),
			selected: false
		}
    }

	componentDidUpdate(prevProps) {
		if(this.props.children) {
			const {props} = this.props.children;
			if(props && prevProps.children) {
				if(props.value !== prevProps.children.props.value) {
					if(props.value.length >= 3) {
						this.setElements(props.value.toLowerCase())
					} else {
						this.setState({open: false, text: props.value.toLowerCase(), selected: false});
					}
				}
			}
		}
	}

	setElements(value) {
		this.setState({
			open: this.state.selected? false : true,
			text: value
		});
	}

	textValue() {
		if(this.props.children) {
			return this.props.children.props.value
		} else {
			return ''
		}
	}

	select(item) {
		const {assignValue} = this.props;
		if(assignValue) {
			assignValue(item);
			this.setState({open: false, selected: true});
		}
	}

	filter(list) {
		list = list.filter(item => {
			if(item.label.toLowerCase().indexOf(this.state.text) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return list;
	}

    render() {
		let list = [];
		if (Array.isArray(this.props.list)) {
			list = this.filter(this.props.list);
		}

        return (
			<div className="autocomplete">
				{this.props.children}
				<div className={`autocomplete-results column ${this.state.open && list.length > 0? 'show': 'hide'}`}>
					{list.map((item, index) => 
						<div key={index} className="autocomplete-items" onClick={this.select.bind(this, item)}>
							{item.label}
						</div>
					)}
				</div>
			</div>
        );
    }
}

export default AutoComplete;