/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de todos los propietarios
 */

import React, { Component } from "react";
import Request from "../../core/httpClient";
import Autocomplete from '../controls/autocomplete';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

const request = new Request();

class Owners extends Component {
  	constructor(props) {
		super(props);
		this.state = {
			showModalOwners: false,
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
			houses: 					[],
			error: 						false,
			reload: 					false,
			fraccionamientoNombre: 		'',
			propietarios: 				[],
			idSubfrac: 					0,
			searchHouse: 				false,
			nombre: 					'',
			pNombre: 					'',
			pDireccion: 				'',
			pMunicipio: 				'',
			pEstado: 					'',
			pCp: 						'',
			pTelefono: 					'',
			pPais: 						'',
			pGenero: 					0,
			pId: 						0,
			pNumId: 					'',
			pVigencia: 					'',
			pFechaCompra: 				'',
			pFechaVenta: 				'',
			pIdPersona: 				0,
			pviv: 						'',
			pidViv: 					0,
			page: 						1,
			filterData: 				[],
			filter: 					'',
			keys: 						[],
			email: 						'',
			subfraccionamiento: localStorage.getItem('subfrac') || 0,
			subfraccionamientos: []
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state !== prevState) {
			this.props.setInfo(this.state);
		}
		if((this.state.pIdPersona !== prevState.pIdPersona) || (this.state.pidViv !== prevState.pidViv)) {
			this.getFechas();
		}
	}

	reload() {
		this.getSubFraccionamiento()
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	reloadOwners() {
		this.getPropietarioFraccionamiento()

	}
	change() {
		this.getPropietarioFraccionamiento()
	}

	propietario = data => {
		this.setState({
			pNombre: 		data.nombre,
			pDireccion: 	data.direccion,
			pMunicipio: 	data.municipio,
			pEstado: 		data.estado,
			pCp: 			data.cp,
			pTelefono: 		data.telefono,
			pPais: 			data.pais,
			pGenero: 		data.genero,
			pId: 			parseInt(data.tipo_identificacion),
			pNumId: 		data.num_identificacion,
			pVigencia: 		'',
			pIdPersona: 	data.id_persona,
			search: 		false
		});
	}

	vivienda = data => {
		this.setState({
			pviv: 			data.numero_registro,
			pidViv: 		data.id_vivienda,
			searchHouse: 	false
		});
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}
	
	nuevoPropietario() {
		this.setState({showModalOwners: true})
	}

	setFilter(filter) {
		console.log(filter)
		this.setState({filter});
	}

	async completeForm(item) {
		this.setState({ INombre: item.label });
		if(item.value > 0) {
			const res = await request.get('/inquilinos/getbypersona', {id_persona: item.value, id_fraccionamiento: localStorage.getItem('frac')});
			if(res.inquilino) {
				const phone = parsePhoneNumber(`+${res.inquilino.telefono}`);
				this.setState({
					IIdPersona: 	item.value,
					iTelefono: 		phone.number,
					IDireccion: 	res.inquilino.direccion || '',
					email: 			res.inquilino.email || '',
					IId: 			res.inquilino.tipo_identificacion,
					iNumId: 		res.inquilino.num_identificacion,
					IGenero: 		res.inquilino.genero,
					IDireccion: 	res.inquilino.direccion,
					IMunicipio: 	res.inquilino.municipio,
					ICp: 			res.inquilino.cp,
					IEstado: 		res.inquilino.estado,
					IPais: 			res.inquilino.pais
				});
			}
		}
	}

	componentDidMount() {
		this.getPropietarioFraccionamiento();
		this.getPersonas();
		this.getViviendas();
	}

	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	render() {
		let list_personas = [];
		let viviendas = [];
		if(Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map(persona => { return {label: persona.nombre, value: persona.id_persona}});
		}
		if(Array.isArray(this.state.viviendas)) {
			viviendas = this.state.viviendas;
		}
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Unidad:</b></p>
						</div>
						<div className="column full">
							<select className="input" name="vivienda" disabled={this.props.editVivienda? false : true} value={this.props.editVivienda? this.state.IidViv : this.props.idVivienda} onChange={(event) => this.setState({IidViv: event.target.value})}>
								<option value="0">SELECCIONAR VIVIENDA</option>
								{viviendas.map(viv => 
									<option key={viv.id_vivienda} value={viv.id_vivienda}>
										{viv.numero_registro}
									</option>
								)}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<Autocomplete
								assignValue={this.completeForm.bind(this)}
								list={list_personas}>
								<input type="text" name="fullName" value={this.state.INombre}
									onChange={event => this.setState({ INombre: event.target.value })}
									className="input input-modals" placeholder="Nombre completo del contacto"
									required
									maxLength="64" />
							</Autocomplete>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="column full">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								country="MX"
								required
								placeholder="Número de teléfono"
								value={this.state.iTelefono}
								onChange={ value => this.setState({ iTelefono: value, telefonoError: undefined }) }
								error={ this.state.telefonoError }
								className={'input-modals'}/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>  Género:	</b>
							</p>
						</div>
						<div className="column full">
							<select name="sex" id="" value={this.state.IGenero} required
								onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>  Correo:	</b>
							</p>
						</div>
						<div className="column full">
							<input type="email" name="EmailEdit" value={this.state.email} required
								onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder="Email" maxLength="64" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Identificación:	</b></p>
						</div>
						<div className="column full">
							<select name="id" id="idEddit" className="input input-modals" value={this.state.iId} onChange={event => this.setState({ iId: event.target.value })}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b> ID :</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="numIdEddit" value={this.state.iNumId}
								onChange={event => this.setState({ iNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="address" value={this.state.IDireccion}
								onChange={event => this.setState({ IDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" required maxLength="150" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="municipality" value={this.state.IMunicipio}
								onChange={event => this.setState({ IMunicipio: event.target.value })} className="input input-modals" placeholder='Municipio' required maxLength="80" />
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.ICp}
								onChange={event => this.setState({ ICp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' required maxLength="10" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="state" value={this.state.IEstado}
								onChange={event => this.setState({ IEstado: event.target.value })} className="input input-modals" placeholder='Estado' required maxLength="24" />
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.IPais}
								onChange={event => this.setState({ IPais: event.target.value })} name="country" className="input input-modals" placeholder='País' required maxLength="10" />
						</div>
					</div>
					<div className="white-space-8" />
					{/*
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Fecha de ingreso:</b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.IFechaIngreso}
								onChange={event => this.setState({ IFechaIngreso: event.target.value })} required className='input input-modals' id='fcompra' />
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Fecha de salida:</b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.IFechaSalida}
								onChange={event => this.setState({ IFechaSalida: event.target.value })} required className='input input-modals' id='fcompra' />
						</div>
					</div>*/}
					
					<div className="white-space-8" />
					<div className="row justify-center">
						{this.state.saveMessage}
					</div>
				</div>
			</div>
		)
	}

  	//traer Propietarios del fraccionaiento
  	async getPropietarioFraccionamiento() {
		this.setState({ loadingPropietarios: true });
		let IdSub = this.state.subfraccionamiento;
		const idFrac = localStorage.getItem('frac');

		let data = { IdSub, idFrac };

    	const response = await request.post("/fraccionamientos/get/propietarios", data);

		if (response && !response.error) {
			if (response.propietario && !response.empty) {
				this.setState({
					propietarios: response.propietario,
					filterData: response.propietario
				});
			//console.log(response.propietario);
			} else {
				this.setState({
				propietarios: [],
				empty: true,
				message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.setState({ loadingPropietarios: false });
  	}

  	async handledSubmit(event) {
		event.preventDefault();
		this.setState({loading: true});
		/**Validar Vivienda */
		if (!(this.state.pidViv > 0)) {
			this.setState({loadingSave: false});
            alert("Para continuar debes seleccionar una vivienda")
            return;
		}
		/**Validar Telefono */
		if(!isValidPhoneNumber(this.state.pTelefono)) {
			this.setState({telefonoError: 'Número inválido', loading: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}

		let data = {
			IdViv: this.state.pidViv,
			Nombre: this.state.pNombre,
			Direccion: this.state.pDireccion,
			Municipio: this.state.pMunicipio,
			Estado: this.state.pEstado,
			Cp: this.state.pCp,
			Telefono: this.state.pTelefono,
			Pais: this.state.pPais,
			Genero: parseInt(this.state.pGenero),
			TipoId: parseInt(this.state.pId),
			NumId: this.state.pNumId,
			Vigencia: this.state.pVigencia,
			FechaCompra: this.state.pFechaCompra,
			FechaVenta: this.state.pFechaVenta,
			idPersona: this.state.pIdPersona,
			email: this.state.email,
			idFraccionamiento: localStorage.getItem('frac') || '0'
		};

    	const response = await request.post("/propietarios/propietario/create", data);

    	this.setState({ showModalOwners: false });
    	if (response && !response.error) {
      		if (response.created && !response.empty) {
				this.setState({
				reload: true,
				loading: false
				});
      		} else {
				this.setState({
				empty: true,
				message: response.message,
				loading: false
				});
				alert('El número de identificación ya está registrado.')
				return
      		}
    	} else {
			this.setState({
				error: true,
				message: response.message,
				loading: false
			});
    	}
    	this.getPropietarioFraccionamiento();
  	}

	handleOpen() {
		this.setState({ showModalOwners: true });
	}

	handleClose() {
		this.setState({
		showModalOwners: false,
		pNombre: '',
		pDireccion: '',
		pMunicipio: '',
		pEstado: '',
		pCp: '',
		pTelefono: '',
		pPais: '',
		pGenero: '',
		pId: 0,
		pNumId: '',
		pVigencia: '',
		pFechaCompra: '',
		pIdPersona: 0,
		edditProp: false,
		pviv: '',
		email : ''
		});
	}

	async getPersonas() {
		const response = await request.post("/personas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.personas)) {
			this.setState({personas: response.personas});
		} else {
			this.setState({personas: []});
		}
	}

	async getViviendas() {
		const response = await request.post("/viviendas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.viviendas)) {
			this.setState({viviendas: response.viviendas});
		} else {
			this.setState({viviendas: []});
		}
	}

	async getFechas() {
		const res = await request.post('/propietarios/get/fechas', {IdPersona: this.state.pIdPersona, IdViv: this.state.pidViv});
		if(res.dates) {
			console.log(res.dates)
			this.setState({
				pFechaCompra: res.dates.fecha_compra || '',
				pFechaVenta: res.dates.fecha_venta || ''
			})
		}
	}
}

export default Owners;
