/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de todos los propietarios
 */

import React, { Component } from "react";
import moment from 'moment-timezone';
import Request from "../../core/httpClient";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

const request = new Request();

class Owners extends Component {
  	constructor(props) {
		super(props);
		this.state = {
			showModalOwners: false,
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
			houses: [],
			error: false,
			reload: false,
			fraccionamientoNombre: '',
			propietarios: [],
			idSubfrac: 0,
			searchHouse: false,
			nombre: '',
			pNombre: '',
			pDireccion: '',
			pMunicipio: '',
			pEstado: '',
			pCp: '',
			pTelefono: '',
			pPais: '',
			pGenero: 0,
			pId: 0,
			pNumId: '',
			pVigencia: '',
			pFechaCompra: '',
			pFechaVenta: '',
			pIdPersona: 0,
			pviv: '',
			pidViv: 0,
			page: 1,
			filterData: [],
			filter: '',
			keys: [],
			email: '',
			subfraccionamiento: localStorage.getItem('subfrac') || 0,
			subfraccionamientos: []
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state !== prevState) {
			this.props.setInfo(this.state);
		}
		if(this.props.telefonoError !== prevProps.telefonoError) {
			this.setState({telefonoError: this.props.telefonoError});
		}
		if(this.props.contacto !== prevProps.contacto) {
			let phone = '';
			if(this.props.contacto.telefono) {
				phone = parsePhoneNumber(`+${this.props.contacto.telefono}`) || '';
				phone = phone.number;
			}
			this.setState({ 
				IIdPersona: this.props.contacto.id_persona,
				INombre: this.props.contacto.nombre || '',
				IDireccion: this.props.contacto.direccion || '',
				IMunicipio: this.props.contacto.municipio || '',
				IEstado: this.props.contacto.estado || '',
				ICp: this.props.contacto.cp || '',
				iTelefono: phone || '',
				IPais: this.props.contacto.pais || '',
				IGenero: this.props.contacto.genero || 0,
				IId: this.props.contacto.tipo_identificacion || 0,
				iNumId: this.props.contacto.num_identificacion || '',
				IVigencia: this.props.contacto.vigencia_identificacion || '',
				IFechaIngreso: moment(this.props.contacto.fecha_entrada).format("YYYY-MM-DD"),
				IFechaSalida: moment(this.props.contacto.fecha_salida).format("YYYY-MM-DD"),
				code: this.props.contacto.code || '',
				Iviv: this.props.contacto.numero_registro || '',
				IdViv: this.props.contacto.id_vivienda || 0,
				email: this.props.contacto.email || ''
			});

			const principal = document.getElementById('contacto-principal');
			if(principal) {
				principal.checked = this.props.contacto.principal;
			}
		}
	}

	render() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>Unidad:</b></p>
						</div>
						<div className="column full">
							<input type="text" name="vivienda" disabled value={this.state.Iviv}
								onChange={event => this.setState({ Iviv: event.target.value })}
								className="input input-modals" placeholder="N° de vivienda" required maxLength="64" />
						</div>
						<div className="column label-duo-normal space">
							<p><b>Principal:</b></p>
						</div>
						<div className="column full">
							<input type="checkbox" name="principal" id="contacto-principal" className="input input-modals" placeholder="N° de vivienda" maxLength="64" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.INombre}
								onChange={event => this.setState({ INombre: event.target.value })} className="input input-modals" placeholder="Nombre completo de contacto" required maxLength="64" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-cente">
						<div className="column label-medium">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="column full">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								country="MX"
								placeholder="Número de teléfono"
								value={this.state.iTelefono}
								onChange={ value => this.setState({ iTelefono: value, telefonoError: undefined }) }
								error={ this.state.telefonoError }
								className={'input-modals'}/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Identificación:</b>
							</p>
						</div>
						<div className="column full">
							<select name="id" id="idEddit" className="input input-modals" value={this.state.IId} onChange={event => this.setState({ IId: event.target.value })}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="numIdEddit" value={this.state.iNumId}
								onChange={event => this.setState({ iNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Correo :</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.email}
								onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder="Email" maxLength="64" />
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>Género:</b>
							</p>
						</div>
						<div className="column full">
							<select name="sex" id="" value={this.state.IGenero} required
								onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="address" value={this.state.IDireccion}
								onChange={event => this.setState({ IDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" required maxLength="150" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="municipality" value={this.state.IMunicipio}
								onChange={event => this.setState({ IMunicipio: event.target.value })} className="input input-modals" placeholder='Municipio' required maxLength="80" />
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.ICp}
								onChange={event => this.setState({ ICp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' required maxLength="10" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="state" value={this.state.IEstado}
								onChange={event => this.setState({ IEstado: event.target.value })} className="input input-modals" placeholder='Estado' required maxLength="24" />
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.IPais}
								onChange={event => this.setState({ IPais: event.target.value })} name="country" className="input input-modals" placeholder='País' required maxLength="10" />
						</div>
					</div>
					<div className="white-space-8" />
				</div>
			</div>
		)
	}
}

export default Owners;
