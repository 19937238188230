import React, { Component } from 'react';
import moment from "moment";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Request from "../../core/httpClient";
const request = new Request();

class InquilinoEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IIdPersona: 	this.props.inquilino.id_persona,
            INombre: 		this.props.inquilino.nombre,
            IDireccion: 	this.props.inquilino.direccion,
            IMunicipio: 	this.props.inquilino.municipio,
            IEstado: 		this.props.inquilino.estado,
            ICp: 			this.props.inquilino.cp,
            iTelefono:		this.props.inquilino.telefono,
            IPais: 			this.props.inquilino.pais,
            IGenero: 		this.props.inquilino.genero,
            IId: 			this.props.inquilino.tipo_identificacion,
            iNumId: 		this.props.inquilino.num_identificacion,
            IVigencia: 		this.props.inquilino.vigencia_identificacion,
            IFechaIngreso: 	moment(this.props.inquilino.fecha_entrada).format("YYYY-MM-DD"),
            code: 			this.props.inquilino.code,
            Iviv: 			this.props.inquilino.numero_registro,
            IIdViv: 		this.props.inquilino.id_vivienda,
            email: 			this.props.inquilino.email,
		}
		
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(this.state !== prevState) {
			this.props.setInfo(this.state);
		}
		if(this.props.telefonoError !== prevProps.telefonoError) {
			this.setState({telefonoError: this.props.telefonoError});
		}
		if(this.props.inquilino !== prevProps.inquilino) {
			let phone = '';
			if(this.props.inquilino.telefono) {
				phone = parsePhoneNumber(`+${this.props.inquilino.telefono}`) || '';
				phone = phone.number;
			}
			this.setState({ 
				IIdPersona: 		this.props.inquilino.id_persona,
				INombre: 			this.props.inquilino.nombre || '',
				IDireccion: 		this.props.inquilino.direccion || '',
				IMunicipio: 		this.props.inquilino.municipio || '',
				IEstado: 			this.props.inquilino.estado || '',
				ICp: 				this.props.inquilino.cp || '',
				iTelefono: 			phone || '',
				IPais: 				this.props.inquilino.pais || '',
				IGenero: 			this.props.inquilino.genero || 0,
				IId: 				this.props.inquilino.tipo_identificacion || 0,
				iNumId: 			this.props.inquilino.num_identificacion || '',
				IVigencia: 			this.props.inquilino.vigencia_identificacion || '',
				IFechaIngreso: 		moment(this.props.inquilino.fecha_entrada).format("YYYY-MM-DD"),
				IFechaSalida: 		moment(this.props.inquilino.fecha_salida).format("YYYY-MM-DD"),
				code: 				this.props.inquilino.code || '',
				Iviv: 				this.props.inquilino.numero_registro || '',
				IdViv: 				this.props.inquilino.id_vivienda || 0,
				email: 				this.props.inquilino.email || ''
			});
		}
	}

    render() {
        return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Unidad:</b></p>
						</div>
						<div className="column full">
							<input type="text" name="vivienda" disabled value={this.state.Iviv}
								onChange={event => this.setState({ Iviv: event.target.value })}
								className="input input-modals" placeholder="N° de vivienda" required maxLength="64" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.INombre}
								onChange={event => this.setState({ INombre: event.target.value })} className="input input-modals" placeholder="Nombre completo del inquilino" required maxLength="64" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-cente">
						<div className="column label-medium">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="column full">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								country="MX"
								required
								placeholder="Número de teléfono"
								value={this.state.iTelefono}
								onChange={ value => this.setState({ iTelefono: value, telefonoError: undefined }) }
								error={ this.state.telefonoError }
								className={'input-modals'} />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Identificación:</b>
							</p>
						</div>
						<div className="column full">
							<select name="id" id="idEddit" className="input input-modals" value={this.state.IId} onChange={event => this.setState({ IId: event.target.value })}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="numIdEddit" value={this.state.iNumId}
									onChange={event => this.setState({ iNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Correo :</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.email}
								onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder="Email" maxLength="64" />
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>Género:</b>
							</p>
						</div>
						<div className="column full">
							<select name="sex" id="" value={this.state.IGenero} required
								onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="address" value={this.state.IDireccion}
								onChange={event => this.setState({ IDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" required maxLength="150" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="municipality" value={this.state.IMunicipio}
								onChange={event => this.setState({ IMunicipio: event.target.value })} className="input input-modals" placeholder='Municipio' required maxLength="80" />
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.ICp}
								onChange={event => this.setState({ ICp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' required maxLength="10" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="state" value={this.state.IEstado}
								onChange={event => this.setState({ IEstado: event.target.value })} className="input input-modals" placeholder='Estado' required maxLength="24" />
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.IPais}
								onChange={event => this.setState({ IPais: event.target.value })} name="country" className="input input-modals" placeholder='País' required maxLength="10" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Fecha de ingreso:</b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.IFechaIngreso}
								onChange={event => this.setState({ IFechaIngreso: event.target.value })} required className='input input-modals' id='fcompra' />
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Fecha de salida:</b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.IFechaSalida}
								onChange={event => this.setState({ IFechaSalida: event.target.value })} className='input input-modals' id='fcompra' />
						</div>
					</div>
				</div>
			</div>
        );
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }
}

export default InquilinoEditarModal;