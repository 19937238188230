import React, { Component } from 'react';
import CommonAreasEddit from './commonAreasEddit'
import AreasSchedule from './AreasSchedule'
import Request from "../../core/httpClient";
const request = new Request();
class CommonAreaTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			dataArea: [],
			idCommon: '',
			id_espacio: 0,
			horarios: []
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.idCommon !== prevState.idCommon) {
			this.getSchedules(this.state.idCommon)
		}
	}
	componentDidMount() {
		console.log('DATA ->', this.props.commonAreas);
	}


    reload() {
        this.props.reload()
	}
	
	openModal(idModal, data) {
		this.setState({
			dataArea: data,
			id_espacio: data.id_espacio,
			horarios:[]
		});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	openRow(type, idCommon) {
		if(this.state.idCommon === idCommon && type === this.state.rowType) {
			this.setState({rowType: '', idCommon: 0});
			return;
		}
		this.setState({rowType: type, idCommon});
	}

	async getSchedules(idCommon) {
        let data = { id: idCommon }
        const response = await request.post("/commonArea/get/schedule", data)

        if (response.commonSchedules && !response.empty) {
			this.setState({
				horarios: response.commonSchedules
			});
        } else {
			this.setState({
				horarios: [],
				message: response.message
			});
        }
    }

    render() {
        let areas = []
        if (this.props.commonAreas) {
            areas = this.props.commonAreas
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead>
                            <tr className="text-left">
								<th className="text-left">Categoría</th>
                                <th className="">Nombre</th>
								<th className="text-center">Fianza</th>
                                <th>Estatus</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Horarios</th>
                            </tr>
                        </thead>
                        <tbody>
                            {areas.slice((this.props.page - 1) * 7, this.props.page * 7).map((area, key) => (
								<React.Fragment key={key}>
									<tr>
										<td className="text-left">{area.nombre_categoria}</td>
										<td className="text-left">{area.nombre}</td>
										<td className="text-center">{area.fianza ? `${area.fianza}.00`: '0.00'}</td>
										<td>{area.activo === 1 ? ('Activo') : ('Inactivo')}</td>
										<td className="text-center">
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type="button" onClick={this.openModal.bind(this,"editCommon",area)} >
											<i className="font-small fas fa-pen" />
										</button>
										</td>
										<td className="text-center">
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type="button" onClick={this.openRow.bind(this,"newSchedule", area.id_espacio)} >
											<i className="font-small fas fa-clock"></i>
										</button>
										</td>
									</tr>
									{this.state.idCommon === area.id_espacio &&
										<tr>
											<td colSpan="6">
												<AreasSchedule id={area.id_espacio} horarios={this.state.horarios} getSchedules = {this.getSchedules.bind(this)}/>
											</td>
										</tr>
									}
								</React.Fragment>
                            ))}

                        </tbody>
                    </table>
                </div>
				<CommonAreasEddit 
					subfraccionamientos	=	{this.props.subfraccionamientos} 
					area				=	{this.state.dataArea} 
					reload				=	{this.reload.bind(this)} 
				/>
            </div>
        );
	}
	
}

export default CommonAreaTable;