import React, { Component } from 'react';

class HouseForm extends Component {
	constructor(props) {
		super(props);
		this.state={
			subfraccionamientos: this.props.subfraccionamientos,
			sub_fraccionamiento: 0,
			fraccionamiento: '',
			direccion: '',
			Nregistro: '',
			mts: '',
			pIndiviso: '',
			vacacional: '',
			monto: '',
			comentarios: ''
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state !== prevState) {
			this.props.setInfo(this.state);
		}
		if(this.props.subfraccionamientos !== prevProps.subfraccionamientos) {
			this.setState({subfraccionamientos: this.props.subfraccionamientos});
		}
		if(this.props.vivienda !== prevProps.vivienda) {
			const {vivienda} = this.props;
			this.setState({
				fraccionamiento: vivienda.fraccionamiento,
				sub_fraccionamiento: vivienda.sub_fraccionamiento || vivienda.id_subfraccionamiento,
				direccion: vivienda.direccion,
				Nregistro: vivienda.numero_registro,
				mts: vivienda.metros_cuadrados,
				pIndiviso: vivienda.pro_indiviso,
				vacacional: vivienda.vacacional,
				monto: vivienda.monto,
				comentarios: vivienda.comentarios
			});
			if (this.props.nuevo) {
				this.setState({
					sub_fraccionamiento: 0,
					direccion: '',
					Nregistro: '',
					mts: '',
					pIndiviso: '',
					vacacional: '',
					monto: '',
					comentarios: ''
				});
			}
		}
	}

	handleChange(event) {
		let valor = event.target.checked

		if (valor === true) {
			valor = 1
		}
		else {
			valor = 0
		}
		this.setState({ vacacional: valor })
	}

	render() {
		let subfraccionamientos = [];
		if(Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		}
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Condominio:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="fraccionamiento" value={this.state.fraccionamiento} className="input input-modals" placeholder="Fraccionamiento" readOnly disabled />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Subfraccionamiento:</b>
							</p>
						</div>
						<select className="input input-modals" name="subfraccionamiento" value={this.state.sub_fraccionamiento} onChange={(event) => this.setState({sub_fraccionamiento: event.target.value})}>
							{subfraccionamientos.map((sub, index) => <option key={index} value={sub.id_subfraccionamiento}>{sub.nombre}</option>)}
						</select>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Unidad privativa:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="houseNumber" id="houseNum" className="input input-modals" placeholder="N°/Letra de vivienda" required maxLength="25"
								value={this.state.Nregistro} onChange={event => this.setState({ Nregistro: event.target.value })} />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="houseAddress" className="input input-modals" placeholder="Dirección completa" required
								maxLength="150" value={this.state.direccion}
								onChange={event => this.setState({ direccion: event.target.value })}
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-triple-huge">
							<p >
								<b>Metros Cuadrados:</b>
							</p>
						</div>
						<div className="column full">
							<input type="number" name="mts" value={this.state.mts} onChange={event => this.setState({ mts: event.target.value })} className="input input-modals" placeholder="m²" required/>
						</div>
						<div className="column label-duo-normal space">
							<p >
								<b>ProIndiviso:</b>
							</p>
						</div>
						<div className="column full space">
							<input type="text" name="proInd" value={this.state.pIndiviso} onChange={event => this.setState({ pIndiviso: event.target.value })} className="input input-modals" minLength="1" maxLength="3" placeholder="100%" required/>
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Vacacional:</b>
							</p>
						</div>
						<div className="column label-normal space">
							<input type="checkbox" name="vacation" onChange={this.handleChange.bind(this)} checked={this.state.vacacional === 1 ? (true) : (false)} className="input input-modals" placeholder="vacasional" />
						</div>
					</div>
					{this.state.valida ? (<p style="color-primary">El porcentaje de pro Indiviso no puede ser mayor a 100%</p>) : (null)}
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Mensualidad:</b>
							</p>
						</div>
						<div className="column full">
							<div className="row justify-center align-center">
								<h1>$</h1> 
								&nbsp;
								<input type="number" name="monto" className="input input-modals" placeholder="0.00"
									maxLength="150" value={this.state.monto}
									onChange={event => this.setState({ monto: event.target.value })}
								/>
							</div>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="row full justify-start">
						<p>
							<b>Comentarios:</b>
						</p>
					</div>
					<div className="white-space-8" />
					<div className="row full justify-start">
						<textarea name="comentarios" id="comentarios" value={this.state.comentarios} cols="30" rows="3" maxLength="500" className='textarea font-small full'
							onChange={(event) => this.setState({comentarios: event.target.value})}></textarea>			
					</div>
				</div>
			</div>
		)
	}
}

export default HouseForm;