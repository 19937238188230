import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import UsersTable from '../components/users/usersTable'
import moment from "moment-timezone";
import ReactModal from "react-modal";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Controls from '../components/controls/controls';

const request = new Request();

class UsersPage extends Component {
    constructor() {
        super()
        this.state = {
            users: 					[],
            showModal: 				false,
            name: 					'',
            newUser: 				'',
            rol: 					'',
            password: 				'',
            valid: 					false,
            valids: 				true,
            idFraccionamiento: 		0,
            nombreFrac: 			'',
            fraccionamientoNombre: 	true,
            existe: 				false,
            perfiles: 				[],
			page: 					1,
			filter: 				'',
			loadingSave:			false
        }
	}
	
	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

    reload() {
        this.getUsers();
    }

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }


    changeFrac() {
        this.getFraccionamiento()
        this.getUsers()
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevoUsuario() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(users) {
		users = users.filter(per => {
			if(per.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return users;
	}

    render() {
		let users = [];
		let dataSet;

        if (Array.isArray(this.state.users)) {
			users 	= this.state.users;
			dataSet = this.state.users;
			users 	= this.filter(users);
        }

        return (
            <div className="owners column">
                <Helmet>
                    <title>iGate - Tablero</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'usuarios'}  parent={'config'} panel={'panel3'} nav={"catalogos"} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
								<Controls 
									title		=	"Usuarios del sistema"
									nuevo		=	{this.openModal.bind(this,"newUser")}
									dataSet		=	{dataSet}
									setFilter	=	{this.setFilter.bind(this)}
									subChange	=	{this.subChange.bind(this)}
									selectSub	=	{false}
								/>
                                <div className="white-space-16" />
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<UsersTable 
										users		=	{users} 
										reload		=	{this.reload.bind(this)} 
										page		=	{this.state.page} 
										perfiles	=	{this.state.perfiles} 
									/>
								}
								<div className="white-space-16" />
								<Paginador 
									pages	=	{Math.ceil(users.length / 7)} 
									setPage	=	{this.setPage.bind(this)} 
								/>
                            </div>
                        </div>
                    </div>
                </div>

				<DinamicModal 
					idModal					=	{'newUser'}
					sizeModalContainer		=	{'normal'}
					title					=	{'NUEVO USUARIO'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}v 
					btnTextCancel			=	{"CANCELAR"}
					closeModal				=	{this.resetValue.bind(this)}
					
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.message}
				>
					{this.renderNewUser()}
				</DinamicModal>

            </div>

        );
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}
	restart(){
		document.getElementById('pass'). value = "";
		this.setState({password: ""});
	  
   }
	renderNewUser() {
		return (
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center ">
					<div className="column label-medium">
						<p><b>  Nombre:	</b></p>
					</div>
					<div className="column full">
						<input type="text" name="fullName" value={this.state.name} id="nameuser"
							onChange={event => this.setState({ name: event.target.value })} className="input input-modals" placeholder="Nombre del empleado" required maxLength="64" />
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center ">
					<div className="column label-medium">
						<p><b>  Usuario:	</b></p>
					</div>
					<div className="column full">
						<input type="text" name="user" value={this.state.newUser} id="usertex"
							onChange={event => this.setState({ newUser: event.target.value })} className="input input-modals" placeholder="Usuario" required maxLength="15" minLength='8' />
						{this.state.existe ? (<p style={{ color: 'red' }}>Este usuario ya está registrado.</p>) : null}
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center ">
					<div className="column label-medium" >
						<p><b>  Fraccionamiento:	</b></p>
					</div>
					<div className="column full">
						<input type="text" name="fraccionamiento" value={this.state.nombreFrac} disabled={true}
							onChange={event => this.setState({ nombreFrac: event.target.value })} className="input input-modals" placeholder="Selecciona un fraccionamiento" required maxLength="15" minLength='8' />
						{this.state.fraccionamientoNombre ? (null) : <p style={{ color: 'red' }}>Por favor selecciona un fraccionamiento para continuar.</p>}

					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center ">
					<div className="column label-medium">
						<p><b>  Rol:	</b></p>
					</div>
					<div className="column full">
						<select name="rol" id="" className="input input-modals"
							value={this.state.rol}
							onChange={event => this.setState({ rol: event.target.value })}>
							{this.state.perfiles.map((perfil, key) => (
								<option value={perfil.id_perfil} key={key}>{perfil.nombre}</option>
							))}
						</select>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center ">
					<div className="column label-medium">
						<p><b>  Contraseña:	</b></p>
					</div>
					<div className="column full">
						<input type="password" name="" id='pass' onKeyUp={this.passValidate.bind(this)} className="input input-modals" placeholder="Contraseña" required maxLength="64" />
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center ">
					<div className="column label-medium">
						<p><b>  Confirmar contraseña:	</b></p>
					</div>
					<div className="column full">
						<input type="password" name="password" id='pass2' onKeyUp={this.passValidate.bind(this)} className="input input-modals" placeholder="Confirmar contraseña" required maxLength="64" />
						{this.state.valid ? (null) : (<p style={{ color: 'red' }}>Las contraseñas debes coincidir y tener un tamaño mínimo de 6 caracteres.</p>)}

					</div>
				</div>
			</div>
		</div>
  
		)

	}

    componentDidMount() {
        this.load();
        this.loadPerfil();

    }
    load() {
        this.getFraccionamiento();
        this.getUsers();
    }

    async loadPerfil() {
        let data = { IdFrac: localStorage.getItem('frac') };

        const response = await request.post("/users/perfiles/nuevo", data)
		console.log(response);
        if (response && !response.error) {
            if (response.perfiles && !response.empty) {
                this.setState({
                    perfiles: response.perfiles
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
		}
    }

    handleOpen() {
        this.setState({
            showModal: 			!this.state.showModal,
            name: 				'',
            newUser: 			'',
            rol: 				'',
            password: 			'',
            valid: 				false,
            valids: 			true,
            idFraccionamiento: 	0,
            nombreFrac: 		'',
            existe: 			false
        })
        this.getFraccionamiento();
    }
    //comprobar que los campos de password sean iguales en editar usuario

    passValidate() {
        let pass1 = document.getElementById('pass').value,
			pass2 = document.getElementById('pass2').value;
			
			console.log(`${pass1} == ${pass2}`);
			if(pass1 === pass2 && pass1.length >= 8){
				this.setState({valid: true});
				return true;
			}
			this.setState({valid: false});
			return false;
    }

    async  handleSubmit(event) {
        event.preventDefault()

        const form = event.target;
        let data = {
            Nombre: 			form.fullName.value,
            Usuario: 			form.user.value,
            Fecha: 				moment(new Date()).format('YYYY-MM-DD'),
            Password: 			form.password.value,
            IdFraccionamiento: 	this.state.idFraccionamiento,
            Perfil: 			parseInt(form.rol.value)
        }

        let validar = {
            Usuario: 			form.user.value,
            idFraccionamiento: 	this.state.idFraccionamiento,
		}
		
        if(this.passValidate()){
			
			const response = await request.post("/users/validate/user", validar);

			if (response.validate.exist) {
				this.setState({ existe: true })
				return;

			} else {
				this.setState({ existe: false })
				const response = await request.post("/users/user/save/user", data);

			
				if (response.created && !response.empty) {
					this.setState({
						showModal: false
					});

					this.setState({newUser:'',nombreFrac:'',rol:'', name: ''});
					document.getElementById('pass2').value = '';
					this.closeModal('newUser');
					this.getUsers();
				} else {
					this.setState({
						empty: true,
						message: "NO SE PUDO CREAR USUARIO"
					});
				}
			}
		}
		this.setState({loadingSave: false});
		
	}
	
	async resetValue() {
		document.getElementById('pass2').value = '';
		document.getElementById('pass').value = '';
		document.getElementById('usertex').value = '';
		document.getElementById('nameuser').value = '';

		this.setState({newUser:'',rol:'',name:''});
	}

    async getFraccionamiento() {
        let data = { IdFraccionamiento: parseInt(localStorage.getItem('frac')) };

		const response = await request.post("/admin/administracion/get/fraccionamiento", data);

		if (response && !response.error) {
			if (response.fraccionamiento && !response.empty) {
				this.setState({
					nombreFrac: 		response.fraccionamiento[0].nombre,
					idFraccionamiento: 	response.fraccionamiento[0].id_fraccionamiento
				});
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: 		true,
				message: 	response.message
			});

		}
    }

    // traer usuarios

    async getUsers() {
		this.setState({loading: true});
		this.getFraccionamiento();

        let data = { IdFraccionamiento: localStorage.getItem('frac') };

		const response = await request.post("/users/get/fraccionamiento/users", data);

		if (response && !response.error) {
			if (response.users && !response.empty) {
				this.setState({

					users: response.users
				});
			} else {
				this.setState({
					empty: 		true,
					message: 	response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});

		}
		this.setState({loading: false});
    }
}

export default UsersPage;