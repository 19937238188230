/**
 *  Environment.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de entornos
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import EnvironmentTable from '../components/entorno/environmentTable'
import Request from "../core/httpClient";
const request = new Request();

class Environment extends Component {
	state = {
		user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0, idAdministracion: 0 },
		config: {
			tInvitaciones: 0,
			nRegistros: 0,
			comentario: true,
			foto: true,
			nInvitadosVip: 0,
			IdConfig: 0,
			numEmpleados: '',
			pin: true
		}
	}
	reload() {

	}
	changeFrac() {


	}
	change() {
		// this.getConfig()
		this.load()
	}
	updateVlues(valor) {

		const config = this.state.config
		Object.assign(config, valor)


		this.setState({ config })

	}
	enviar() {
		this.handleSubmit()
	}

	render() {


		return (
			<div className="owners column">
				<Helmet>
					<title>iGate - Entornos</title>
				</Helmet>
				<Header sidebar={true} active={'entorno'} parent={'config'} panel={'panel3'} nav={"catalogos"} change={this.change.bind(this)} reload={this.reload.bind(this)} />

				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								<div className="navigation align-center">
									<div className="justify-start">
										<h3 className="weight-semi">Configuración del sistema</h3>
									</div>
								</div>
								<div className="white-space-16" />
								<EnvironmentTable id={this.state.user.idUsuario} config={this.state.config} actualizar={this.updateVlues.bind(this)} enviar={this.enviar.bind(this)} IdConfig={this.state.config.IdConfig} />
								<div className="white-space-48"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		this.load()
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					idAdministracion: user.id.id_administracion
				}
			});

		}
		this.getConfig()
	}

	async getConfig() {
		this.limpiarvalores()
		// comentario: true,
		// foto: true,
		let data = { IdFraccionamiento: parseInt(localStorage.getItem('frac')) }

		const response = await request.post("/environments/get/configuration", data)
		// console.log('response: ', response);


		if (response && !response.error) {
			if (response.configuracion && !response.empty) {
				this.setState({
					config: {
						tInvitaciones: response.configuracion[0].tolerancia_hr,
						nRegistros: response.configuracion[0].numero_registros,
						nInvitadosVip: response.configuracion[0].num_invitados,
						comentario: response.configuracion[0].obligatorio_comentario,
						foto: response.configuracion[0].obligatorio_foto,
						IdConfig: response.configuracion[0].id_configuracion,
						numEmpleados: response.configuracion[0].empleados_domesticos,
						pin: response.configuracion[0].obligatorio_pin

					}

				});

			}

		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}



	}
	async  handleSubmit() {
		let comm
		if (this.state.config.comentario) {
			comm = 1

		} else {
			comm = 0

		}
		let fot
		if (this.state.config.foto) {
			fot = 1

		} else {
			fot = 0
		}
		let pin

		if (this.state.config.pin) {
			pin = 1

		} else { pin = 0 }

	/*	let observable = JSON.parse(localStorage.getItem('object'))
		if (!observable) {
			observable = JSON.parse(localStorage.getItem('objectKey'))
		}*/

		let observable = JSON.parse(localStorage.getItem('frac'))
	
		let data = {


			IdConfig: this.state.config.IdConfig,
			IdFraccionamiento: parseInt(observable),
			TolInvitaciones: this.state.config.tInvitaciones,
			Registros: this.state.config.nRegistros,
			Comentarios: comm,
			Foto: fot,
			InvVip: this.state.config.nInvitadosVip,
			IdUuario: this.state.user.idUsuario,
			numEmpleados: this.state.config.numEmpleados,
			pin: pin



		}




		const response = await request.post("/environments/save/configuration", data)
		console.log('response: ', response);


		if (response && !response.error) {
			if (response.created && !response.empty) {
				// alert("Configuracion actualizada correctamente")
			}

		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.getConfig()
	}
	limpiarvalores() {
		this.setState({
			config: {
				tInvitaciones: 0,
				nRegistros: 0,
				comentario: true,
				foto: true,
				nInvitadosVip: 0,
				IdConfig: 0,
				numEmpleados: ''
			}
		})
	}


}



export default Consumer(Environment);
