/**
 *  BudgetExpenses.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de presupuestos
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import Controls from '../components/controls/controls';
import BudgetExpensesTable from '../components/budgetExpences/BudgetExpensesTable';
import Paginador from "../components/paginador/paginador";

import Request from "../core/httpClient";
const request = new Request();

class BudgetExpenses extends Component {
	state = {
		showModal: 					false,
		subfraccionamientos: 		[],
		subfraccionamientoNombre: 	'',
		subfraccionamiento: 		localStorage.getItem('subfrac'),
		idSub: 						'',
		gastos: 					[],
		presupuestosTeorico:	  	[],
		idYearTeorico: 				0,
		anio: 						0,
		page: 						1,
		filterData: 				[],
		keys: 						[],
		year: 						2019,
		filter: 					''
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getFacturas();
		}

		if(this.state.year !== prevState.year) {
			this.getFacturas();
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	reload() {
		this.setState({ showModal: false });
		this.getFacturas();
	}

	changeFrac() {
		this.getFacturas()
	}

	change() {
		this.getFacturas()
	}

	subChange(event) {
		this.setState({subfraccionamiento: event.target.value});
	}

	filterGeneric(e) {
		e.preventDefault();

		const form = e.target;

		const param1 = form.filterIncidents.value;
		const param2 = form.textFilter.value;

		let filterData = this.state.filterData;

		//

		if (param1 === "todo") {
			this.setState({ conceptos: this.state.filterData });
			form.textFilter.value = "";
		}
		else {
			const key = param1;
			filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
			this.setState({ conceptos: filterData });
		}

	}

	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	closeModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}
	
	nuevoGasto() {
		this.setState({showModal: true})
	}

	setFilter(filter) {
		console.log(filter)
		this.setState({filter});
	}

	setYear(event) {
		this.setState({year: event.target.value});
	}

	filter(gastos) {
		gastos = gastos.filter(gas => {
			if(gas.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if(gas.num_factura.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			}
			else {
				return false;
			}
		})
		return gastos;
	}

	render() {
		let gastos = [];
		let dataSet;

		if (this.state.gastos) {
			gastos = this.state.gastos;
			dataSet = gastos;
			gastos = this.filter(gastos);
		}

		return (

			<div className="owners column">
				<Helmet>
					<title>iGate - Presupuestos</title>
				</Helmet>
				<Header sidebar={true} active={'presupuestoGastos'} parent={'presup'} panel={'panel2'} nav={"catalogos"} change={this.change.bind(this)} reload={this.reload.bind(this)} />
				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								<Controls 
									title			=	"Gastos"
									nuevo			=	{this.openModal.bind(this,"newExpense")}
									dataSet			=	{dataSet}
									setFilter		=	{this.setFilter.bind(this)}
									subChange		=	{this.subChange.bind(this)}
								/>
								<div className="white-space-16" />
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<BudgetExpensesTable
										conceptos				=	{gastos}
										id						=	{this.state.idYearTeorico}
										reload					=	{this.reload.bind(this)}
										page					=	{this.state.page}
										subfrac					=	{this.state.subfraccionamiento}
										showModal				=	{this.state.showModal}
										handleClose				=	{this.handleClose.bind(this)}
										setYear					=	{this.setYear.bind(this)}
										year					=	{this.state.year}
										subfraccionamientos		=	{this.state.subfraccionamientos}
										subfrac					=	{this.state.subfraccionamiento}
									/>
								}
								<div className="white-space-16" />
								<Paginador pages={Math.ceil(gastos.length / 7)} setPage={this.setPage.bind(this)} />
							</div>
						</div>
					</div>
				</div>

			</div>

		);
	}

	componentDidMount() {
		this.getFacturas();
		this.getSubFraccionamientos();
	}

	handleClose() {
		this.setState({ showModal: false })
	}

	handleOpen() {
		this.setState({ showModal: true })
		this.getSubFraccionamiento()
	}

	// traer facturas
	async getFacturas() {
		this.setState({ conceptos: [], loading: true });
		let data = {
			idFrac: localStorage.getItem('frac'),
			IdSub: this.state.subfraccionamiento,
			anio: this.state.year
		};

		const response = await request.post("/presupuestos/presupuesto/get/real/facturas", data);
		if (response && !response.error) {
			if (response.conceptos && !response.empty) {
				this.setState({
					gastos: response.conceptos,
					filterData: response.conceptos,
					keys: Object.keys(response.conceptos[0]).filter(key => key.indexOf('id') === -1),
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					gastos: []
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.setState({loading: false});
		this.getIdPresupuesto();
	}


	async handleSubmit(event) {
		event.preventDefault()
		const form = event.target;
		let data = {
			IdSub: this.state.idSub,
			Year: parseInt(form.year.value),
			Description: form.description.value,
			idTeorico: parseInt(this.state.idYearTeorico)
		}

		const response = await request.post("/presupuestos/presupuesto/create/real", data)

		if (response && !response.error) {
			if (response.created && !response.empty) {
				this.setState({ showModal: false });
			} else {
				this.setState({ empty: true, message: response.message });
			}
		} else {
			this.setState({error: true,message: response.message});
		}
		this.getPresupuestos()
		this.getFacturas()
	}

	cambiar() {
		let id = parseInt(document.getElementById("year").value);
		this.setState({ conceptos: [], idYearTeorico: id })
		this.getFacturas()
	}

	async getIdPresupuesto() {
		let data = {
			anio: this.state.year,
			subfrac: localStorage.getItem('subfrac')
		};
		const response = await request.post("/presupuestos/presupuesto/get/id", data);
		if(response.id_presupuesto) {
			this.setState({IdPresupuesto: response.id_presupuesto});
		}
	}

	// traer subfraccionamientos
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
		let data = { Idsub: fraccionamiento }
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento});
		} else {
			this.setState({	subfraccionamientos: [] });
		}
	}
}


export default Consumer(BudgetExpenses);
