/**
 *  header-simple.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente header simple para no-usuarios
 */

import React, { Component } from "react";
import Logo from "../../img/logo.png";
import { Link } from "react-router-dom";

class HeaderSimple extends Component {	

	render() {
		return (
			<header className="justify-center">
				<div className="container">
					<div className="justify-between">
						<div className="logo align-center">
							<Link to="/tablero" className="responsive-img auto">
								<img
									src={Logo}
									alt="Logo iGate"
									title="Logo iGate"
								/>
							</Link>
							<p className="font-large weight-medium color-white">
								&nbsp;iGate
							</p>
						</div>
						<nav className="align-center justify-end">
                            <div className="row">
                                <div className="nav-item auto">
                                    <Link
                                        to="/"
                                        className="font-regular color-white" >
                                            <i className="fas fa-sign-out-alt font-text" />
                                            Cerrar
                                    </Link>
                                </div>
							</div>
						</nav>
					</div>
				</div>
			</header>
		);
	}	
}

export default HeaderSimple;
