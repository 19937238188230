/**
 *  budgetTeoric.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Alexis
 *  @description: Página de presupuestos
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import Paginador from "../components/paginador/paginador";
import BudgetTableTeoric from '../components/budgetTeoric/budgetTeoric';
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";
const request = new Request();


class BudgetTeoric extends Component {
	state = {
		showModal: 					false,
		subfraccionamientos: 		[],
		subfraccionamientoNombre: 	'',
		subfraccionamiento: 		localStorage.getItem('subfrac'),
		idSub: 						 0,
		presupuestos: 				[],
		id: 						 0,
		total: 						 0,
		totalReal: 					 0,
		presupuestos: 				[],
		conceptos: 					[],
		idYear: 					 0,
		totales: 					[],
		disabled: 					false,
		page: 						1,
		year: 						2019,
		IdPresupuesto:			 	0,
		filter: 					''
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getPresupuestos();
		}

		if(this.state.year !== prevState.year) {
			this.getPresupuestos();
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	changeFrac() {
		this.setState({
			subfraccionamientos: [],
			idSub: 0,
			presupuestos: [],
			total: 0,
			conceptos: [],
			idYear: 0,
		})
		this.getPresupuestos();
		this.getConceptos()
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	openModal(idModal) {
		const {fraccionamiento} = this.props.context;
		this.setState({formMessage: null, vivienda: {fraccionamiento}});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	setYear(event) {
		this.setState({year: event.target.value});
	}

	filter(presupuestos) {
		presupuestos = presupuestos.filter(con => {
			if (con.concepto.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return presupuestos;
	}

	render() {
		let conceptos = [];
		let presupuestos = [];
		let subfraccionamientos = [];
		let dataSet;

		if (Array.isArray(this.state.conceptos)) {
			conceptos = this.state.conceptos;
		}

		if(Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		}

		if(Array.isArray(this.state.presupuestos)) {
			presupuestos = this.state.presupuestos;
			dataSet = presupuestos;
			presupuestos = this.filter(presupuestos);
		}

		return (
			<div className="owners column">
				<Helmet>
					<title>iGate - Presupuestos</title>
				</Helmet>
				<Header sidebar={true} active={'presupuestoTeorico'} parent={'presup'} panel={'panel2'} nav={"catalogos"} />
				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								<Controls title="Presupuestos"
									nuevo={this.openModal.bind(this, 'newBudget')}
									dataSet={dataSet}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}/>
								<div className="white-space-16" />
								{this.state.loadingPresupuestos?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<BudgetTableTeoric
										disabled={this.state.disabled}
										totales={this.state.totales}
										conceptos={conceptos}
										presupuestos={presupuestos}
										page={this.state.page}
										totalReal={this.state.totalReal}
										total={this.state.total}
										id={this.state.idYear}
										showModal={this.state.showModal}
										handleClose={this.handleClose.bind(this)}
										setYear={this.setYear.bind(this)}
										year={this.state.year}
										getPresupuestos={this.getPresupuestos.bind(this)}
										IdPresupuesto={this.state.IdPresupuesto}
										subFrac={this.state.subfraccionamiento}
										>
									</BudgetTableTeoric>
								}
								<div className="white-space-16" />
								<Paginador pages={Math.ceil(presupuestos.length / 6)} setPage={this.setPage.bind(this)} />
							</div>
						</div>
					</div>
				</div>
				<DinamicModal 
					idModal					=	{'newBudget'}
					sizeModalContainer		=	{'normal'}
					title					=	{'NUEVO PRESUPUESTO'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.formMessage}
					>
					<div className="full row align-center">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Subfraccionamiento:</b>
									</p>
								</div>
								<div className="column full">
									<select className="input input-modals" name="subfraccionamiento" value={this.state.sub_fraccionamiento} onChange={(event) => this.setState({sub_fraccionamiento: event.target.value})}>
										{subfraccionamientos.map((sub, index) => <option key={index} value={sub.id_subfraccionamiento}>{sub.nombre}</option>)}
									</select>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Concepto:</b>
									</p>
								</div>
								<div className="column full">
									<select className="input input-modals"  name="concepto">
										{conceptos.map(concepto => <option key={concepto.id_concepto} value={concepto.id_concepto}>{concepto.descripcion}</option>)}
									</select>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Año:</b>
									</p>
								</div>
								<div className="column full">
									<input type="number" name="year"
										className="input input-modals" placeholder="AAAA" required minLength='2'
										maxLength='4'
									/>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Importe:</b>
									</p>
								</div>
								<div className="column full">
									<input type="number" name="importe" className="input input-modals" placeholder='$0.00' required min="0"/>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Comentarios:</b>
									</p>
								</div>
								<div className="white-space-8" />
								<div className="align-center row full">
									<div className="column full">
										<textarea className='textarea font-small full' name="comentarios" id="" cols="5" rows="5"></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</DinamicModal>
				}
			</div>
		);
	}

	componentDidMount() {
		this.getConceptos();
		this.getPresupuestos();
		this.getSubFraccionamientos();
	}

	handleClose() {
		this.setState({ showModal: false })
	}

	// traer presupuestos
	async getPresupuestos() {
		this.setState({ presupuesto: [], loadingPresupuestos: true });

		let data = { 
			IdSub: this.state.subfraccionamiento,
			idFrac: localStorage.getItem('frac'),
			year: this.state.year
		};

		const response = await request.post("/presupuestos/presupuesto/get/teoric", data);

		if (response && !response.error) {
			if (response.presupuestos && !response.empty) {
				this.setState({
					presupuestos: response.presupuestos,
					idYear: response.presupuestos[0].id_presupuesto,
					disabled: false
				});

			} else {
				this.setState({
					empty: true,
					message: response.message,
					presupuestos: [],
					disabled: true
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.getConceptos();
		this.setState({ presupuesto: [], loadingPresupuestos: false });
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		const form = event.target;
		let data = {
			idSub: form.subfraccionamiento.value,
			concepto: form.concepto.value,
			year: parseInt(form.year.value),
			comentarios: form.comentarios.value,
			importe: form.importe.value
		}

		const response = await request.post("/presupuestos/presupuesto/create/teoric", data)

		
		if (response.created) {
			this.setState({ showModal: false });
			this.closeModal("newBudget");
			this.getPresupuestos();
		} else { 
			this.setState({ empty: true, formMessage: "NO SE PUDO CREAR NUEVO PRESUPUESTO" });
		}
		
		this.setState({loadingSave: false});
	}

	async getIdPresupuesto() {
		let data = {
			anio: this.state.year,
			subfrac: localStorage.getItem('subfrac')
		};

		const response = await request.post("/presupuestos/presupuesto/get/id", data);
		if(response.id_presupuesto) {
			this.setState({IdPresupuesto: response.id_presupuesto});
		}
	}

	// traer suma de los conceptos
	async getTotalConceptos() {
		let data = {
			anio: this.state.year,
			subfrac: this.state.subfraccionamiento
		};

		const response = await request.post("/presupuestos/presupuesto/get/total", data);

		if (response && !response.error) {
			if (response.total && !response.empty) {
				this.setState({
					total: response.total[0].total
				});
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.getTotalReal()
	}

	async getTotalReal() {
		let data = { 
			anio: this.state.year,
			subfrac: this.state.subfraccionamiento
		};

		const response = await request.post("/presupuestos/presupuesto/get/sumas", data);

		if (response && !response.error) {
			if (response.total && !response.empty) {
				this.setState({
					totalReal: response.total[0].total
				});
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
	}
	//Traer conceptos
	async getConceptos() {
		if (!localStorage.getItem('frac')) {
			return
		} else {
			let data = { idFraccionamiento: localStorage.getItem('frac') };
			const response = await request.post("/presupuestos/get/all", data);
			if (response && !response.error) {
				if (response.conceptos && !response.empty) {
					this.setState({
						conceptos: response.conceptos
					});
				} else {
					this.setState({
						empty: true,
						message: response.message
					});
				}
			} else {
				this.setState({ error: true, message: response.message });

			}
		}
	}
	// traer subfraccionamientos
    async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento});
		} else {
			this.setState({	subfraccionamientos: [] });
		}
    }
}

export default Consumer(BudgetTeoric);
