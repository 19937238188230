import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";

class AdministracionPage extends Component {
    state = {}
    reload() {


        // this.getHouses();
        // this.getNombreSubFraccionamiento()


    }
    changeFrac() {

        // this.getNombreSubFraccionamiento()
        // this.getHouses();

    }
    render() {
        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Administración</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} nav={"administracion"}  sidebar={true} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default AdministracionPage;