/**
 *  guests.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Invitados
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import HousesTable from "../components/houses/houses-table";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import HouseForm from "../components/houses/HouseForm";

const request = new Request();

class Houses extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
			showModal: false,
			ownerState: "0",
			fraccionamientoNombre: "",
			propietarios: [],
			viviendas: [],
			vivienda: {},
			search: false,
			idSub: 0,
			idViv: 0,
			page: 1,
			filterData: [],
			filter: '',
			keys: [],
			valida: false,
			subfraccionamientoNombre: '',
			subfraccionamiento: localStorage.getItem('subfrac') || 0,
			subfraccionamientos: [],
			mesagge: null
		};
	}

	openModal(idModal) {
		const {fraccionamiento} = this.props.context;
		this.setState({formMessage: null, vivienda: {fraccionamiento}});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getHouses();
		}
	}

	reload() {
		this.getHouses();
		//this.getNombreSubFraccionamiento()
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	changeFrac() {
		this.getHouses();
		// this.setState({ viviendas: [] })
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(viviendas) {
		viviendas = viviendas.filter(viv => {
			if(viv.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if(viv.direccion.toLowerCase().indexOf(this.state.filter) !== -1){
				return true;
			} else {
				return false;
			}
		})
		return viviendas;
	}

	setInfo(state) {
		console.log(state);
	}

	render() {
		let viviendas = [];
		let dataSet;

		if (this.state.viviendas) {
			viviendas = this.state.viviendas;
			dataSet = viviendas;
			//Filters
			viviendas = this.filter(viviendas);
		}

		return (
			<div className="houses column">
				<Helmet>
					<title>iGate - Viviendas</title>
				</Helmet>
				<Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'viviendas'} parent={'admin'} panel={'panel1'} nav={"catalogos"} />
				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								<Controls title="Viviendas"
									nuevo={this.openModal.bind(this,"newHouse")}
									dataSet={dataSet}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}/>
								<div className="white-space-16" />
								{this.state.loadingViviendas?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<HousesTable dataHouses={viviendas} nombre={this.state.subfraccionamiento} page={this.state.page} reload={this.reload.bind(this)} subfraccionamientos={this.state.subfraccionamientos} />
								}
								<div className="white-space-16" />
								<Paginador pages={Math.ceil(viviendas.length / 7)} setPage={this.setPage.bind(this)} />

								<DinamicModal 
									idModal={'newHouse'}
									sizeModalContainer={'big'}
									title={'AÑADIR NUEVA VIVIENDA'} 
									success={this.handleSubmit.bind(this)} 
									showBtnSuccess={true}
									btnTextSuccess={"GUARDAR"}
									showBtnCancel={true}
									btnTextCancel={"CANCELAR"}
									loadingSave={this.state.loadingSave}
									message={this.state.formMessage}
								>
									<HouseForm nuevo={true} setInfo={this.setInfo.bind(this)} vivienda={this.state.vivienda} subfraccionamientos={this.state.subfraccionamientos}/>
								</DinamicModal>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		this.load();
		this.getHouses();
		this.getSubFraccionamientos();
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento
				}
			});
		}
	}

	// TRAER LAS VIVIENDAS DEL SUBFRACCIONAMIENTO
	async getHouses() {
		this.setState({ viviendas: [] })

		let idSub = this.state.subfraccionamiento || 0;
		const idFrac = localStorage.getItem('frac') || 0;
		this.setState({ loadingViviendas: true });
		let data = { idSub, idFrac };
		const response = await request.post("/subfraccionamientos/get/viviendas", data);

		if (response.subTotalViviendas) {
			this.setState({
				viviendas: response.subTotalViviendas,
				filterData: response.subTotalViviendas
			});
			console.log(response.subTotalVivienda);
		} else {
			this.setState({
				empty: true,
				message: response.message,
				loadingViviendas: false,
				viviendas: []
			});
		}

		this.setState({
			error: true,
			message: response.message,
			loadingViviendas: false
		});
	}

	// traer subfraccionamientos
    async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento});
		} else {
			this.setState({	subfraccionamientos: [] });
		}
    }

	handleOwner(event) {
		this.setState({ ownerState: event.target.value });

	}

	handleSearch() {
		this.setState({ search: !this.state.search })
	}

	async handleSubmit(event) {
		this.setState({loadingSave: true});
		event.preventDefault();
		const form = event.target;

		let vacacional = form.vacation.checked
		let pro = form.proInd.value

		if (pro > 100) {
			this.setState({ valida: true,loadingSave: false});
			alert("ProIndiviso debe de ser menor o igual a 100");
			return;
		} 

		if (vacacional === true) {
			vacacional = 1

		} else {
			vacacional = 0
		}

			let data = {
				direccion: form.houseAddress.value,
				numeroRegistro: form.houseNumber.value,
				idSub: form.subfraccionamiento.value,
				UPrivativa: form.houseNumber.value,
				mts: form.mts.value,
				proIn: pro,
				vacation: vacacional,
				monto: form.monto.value || 0,
				comentarios: form.comentarios.value
			};
	
			const response = await request.post("/viviendas/crear", data);
	
			if (response.created) {
					this.setState({
						reload: true,
						loading: false,
						formMessage: "NUEVA VIVIENDA AGREGADA",
						showModal: false,
						valida: false
					});

			} else {
				this.setState({
					error: true,
					formMessage: "HUBO UN ERROR AL AÑADIR NUEVA VIVIENDA",
					loading: false,
					showModal: true
			});
				

			}
			const modal = document.getElementById("newHouse");
			if(!this.state.showModal &&  modal){
				modal.classList.add('hide-dinamic-modal');
				modal.classList.remove('show-dinamic-modal');
			}
			this.setState({loadingSave: false});
			this.getHouses();
		
	}
}

export default Consumer(Houses);
