/**
 *  dashboard.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Inicio del Administrador
 */


import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import CardQuick from "../components/dashboard/CardQuick";
import DailyInvitations from "../components/dashboard/daily-invitations";
import Incidents from "../components/dashboard/incidents";
import { Consumer } from "../context";
import moment from "moment";
import Request from "../core/httpClient";
import Map from '../components/maps/map';
const request = new Request();

class DashBoard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
			noInvitaciones: 0,
			noActivas: 0,
			noIncidentes: 0,
			invitacionesDiarias: [],
			invitacionesActivas: [],
			incidenciasDIa: 0,
			totalViviendas: 0,
			direccion: 0,
			fraccionamiento: [{ id_fraccionamiento: 0, p_latitude: "", p_longitude: "" }],
			preMap: [{ id_fraccionamiento: 0, p_latitude: "19.4284700", p_longitude: "-99.1276600" }],
			loadViewMap: false
		};
	}

	componentDidMount() {
		//this.getDashboard();
		this.changeFrac();
	}

	async getFraccionamiento() {
		const user = await this.props.context.loadUser();
		const frac = await localStorage.getItem('frac');
		if (user) {
			const idFraccionamiento = frac;
			const data = { IdFraccionamiento: idFraccionamiento };

			const response = await request.post("/admin/administracion/get/fraccionamiento", data);
			console.log(response);
			if (response && !response.error) {
				if (response.fraccionamiento && !response.empty) {
					if (response.fraccionamiento[0].p_latitude !== '' || response.fraccionamiento[0].p_longitude !== '') {
						this.setState({ fraccionamiento: response.fraccionamiento});
					}
				}
			}
		}
	}

	async changeFrac() {
		await this.load();
		await this.reload();
	}

	async reload() {
		await this.getFraccionamiento();
		setTimeout(
			function() {
				this.setState({loadViewMap: true});
			}
			.bind(this),
			1000
		);
	}

	render() {
		return (
			<div className="dashboard column">
				<Helmet>
					<title>iGate - Tablero</title>
				</Helmet>
				<Header change={this.changeFrac.bind(this)} reload={this.reload.bind(this)} />
				<div className="row">

					<div className="justify-center">
						<div className="container column">
							<div className="white-space-16" />
							<div className="navigation align-center">
								<div className="justify-start">
									<h3 className="weight-semi">

									</h3>
								</div>
								<div className="justify-end">
									<p>
										<span className="weight-semi">iGate</span> >
										Tablero
								</p>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="quick row">
								<CardQuick
									icon="id-card"
									value={this.state.noInvitaciones}
									loading={
										this.state.loadingDiarios ? true : false
									}
									description="N° de invitaciones del día"
								/>
								<CardQuick
									icon="user-friends"
									value={this.state.noActivas}
									loading={
										this.state.loadingActivos ? true : false
									}
									description="Invitados en el fraccionamiento"
								/>
								<CardQuick
									icon="warehouse"
									value={this.state.totalViviendas}
									loading={
										this.state.loadingViviendas ? true : false
									}
									description="N° de viviendas"
								/>
								<CardQuick
									icon="exclamation-triangle"
									value={this.state.noIncidentes}
									loading={
										this.state.loadingIncidencias ? true : false
									}
									description="N° de incidencias del día"
								/>
							</div>
							<div className="white-space-24" />
							<DailyInvitations
								dataDiaria={this.state.invitacionesDiarias}
								dataActiva={this.state.invitacionesActivas}
							/>
							<div className="white-space-24" />
							<h3 className="weight-semi">Incidencias</h3>
							<div className="white-space-24" />

							<Incidents incidentesHoy={this.state.incidenciasDIa} />

							<div className="white-space-24" />
							<h3 className="weight-semi">Información General</h3>
							<div className="white-space-8" />

							<p className="font-small">
								{this.state.direccion.complete}
							</p>

							<div className="white-space-16" />
							{
								this.state.loadViewMap && this.state.fraccionamiento[0].p_latitude ?
									<Map data={this.state.fraccionamiento[0]}></Map>
									:
									<Map data={this.state.preMap[0]}></Map>
									
							}


							<div className="white-space-24" />
						</div>
					</div>

				</div>

			</div>
		);
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento
				}
			});
		
		}
		setTimeout(
			function() {
				this.getDashboard();
			}
			.bind(this),
			1000
		);
	}

	getDashboard() {
		this.getInvitacionesDiarias();
		this.getActivos();
		this.getIncidencias();
		this.getCards();
		this.getDireccion();
	}

	//   traer invitaciones del día
	async getInvitacionesDiarias() {
		this.setState({ loadingDiarios: true, invitacionesDiarias: [] });
		let data = {
			IdFraccionamiento: localStorage.getItem('frac'),
			Fecha: moment(Date.now()).format("YYYY-MM-DD")
		};


		const response = await request.post("/invitaciones/get/panelentradas", data);

		if (response && !response.error) {

			this.setState({
				invitacionesDiarias: response.invitaciones,
				loadingDiarios: false
			});

		} else {

			this.setState({
				error: true,
				message: response.message,
				loadingDiarios: false
			});
		}

	}

	//   traer invitaciones pendientes de salida
	async getActivos() {
		this.setState({ loadingActivos: true, invitacionesActivas: [] });

		let data = {
			IdFraccionamiento: localStorage.getItem('frac'),
			Fecha: moment(Date.now()).format("YYYY-MM-DD")
		};
		const response = await request.post("/invitaciones/get/panelsalidas", data);

		if (response && !response.error) {
			this.setState({
				invitacionesActivas: response.activas,
				loadingDiarios: false
			});

		} else {

			this.setState({
				error: true,
				message: response.message,
				loadingDiarios: false
			});
		}
	}

	//   traer incidencias del dia
	async getIncidencias() {
		this.setState({ loadingIncidencias: true, incidenciasDIa: [] });

		let data = {
			Fecha: moment(Date.now()).format("MM-DD-YYYY"),
			IdFraccionamiento: localStorage.getItem('frac')
		};
		const response = await request.post("/incidencias/get/panelincidentes", data);

		//console.log(response);

		if (response && !response.error) {

			this.setState({
				incidenciasDIa: response.incidentes,
				loadingIncidencias: false
			});

		} else {
			this.setState({
				error: true,
				message: response.message,
				loadingIncidencias: false
			});
		}
	}

	// get info de cards
	async getCards() {
		let data = {
			Fecha: moment(Date.now()).format("YYYY-MM-DD"),
			IdFraccionamiento: localStorage.getItem('frac')
		};
		//console.log(data);
		this.setState({ loadingViviendas: true });
		const response = await request.post("/invitaciones/get/panelcards", data);

		if (response && !response.error) {
			//console.log(response);
			this.setState({
				noInvitaciones: response[0].cantidad,
				noActivas: response[1].cantidad,
				totalViviendas: response[2].cantidad,
				noIncidentes: response[3].cantidad,
				loadingDiarios: false,
				loadingIncidencias: false,
				loadingViviendas: false,
				loadingActivos: false
			});

		} else {
			this.setState({
				error: true,
				message: response.message,
				loadingIncidencias: false
			});
		}

	}

	//   traer direccion fraccionaiento
	async getDireccion() {
		this.setState({ loadingDireccion: true });
		let data = parseInt(this.state.user.idFraccionamiento);

		const response = await request.get(
			`/fraccionamientos/direccion/${data}`
		);

		if (response && !response.error) {
			if (response.fraccionamientoDireccion && !response.empty) {
				this.setState({
					direccion: response.fraccionamientoDireccion,
					loadingDireccion: false
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loadingDireccion: false
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loadingDireccion: false
			});
		}
	}
}

export default Consumer(DashBoard);
