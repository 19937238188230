import React, { Component } from 'react';
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
import moment from 'moment-timezone';
const request = new Request();

class AddAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
			viviendas: 		[],
			propietarios: 	[],
			contactos: 		[],
			folio: 			'',
			concepto:		'',
			id_vivienda: 	0,
			mes: 			'',
			anio: 			'',
			monto: 			0,
			estado: 		0,
			comentarios: 	''
        }
    }

	componentDidMount() {
		this.getViviendas();
		this.getPropietarios();
		this.getContactos();
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.account !== prevProps.account) {
			const {account} = this.props;
			if(account) {
				console.log(account.fecha_mes);
				this.setState({
					folio: 			account.folio,
					concepto: 		account.concepto,
					id_vivienda: 	account.id_vivienda,
					mes: 			moment(account.fecha_mes).tz('GMT').format('MM'),
					anio: 			moment(account.fecha_mes).tz('GMT').format('YYYY'),
					monto: 			account.monto,
					estado: 		account.estado,
					id_propietario: account.id_propietario,
					id_contacto: 	account.id_contacto,
					comentarios: 	account.comentarios || ''
				});
			}
			if(this.props.nuevo) {
				this.setState({
					folio: 			'',
					concepto: 		'',
					id_vivienda: 	0,
					mes: 			'',
					anio: 			'',
					monto: 			0,
					estado: 		0,
					comentarios: 	''
				});
				let id_vivienda = 0;
				const vivSelect = document.getElementById('vivienda');
				if(vivSelect) {
					id_vivienda = vivSelect.value;
				}
				const vivienda = this.state.viviendas.find(viv => viv.id_vivienda == id_vivienda);
				if(vivienda) {
					this.setState({
						monto: vivienda.monto
					});
				}
			}
		}
		if(this.state.id_vivienda !== prevState.id_vivienda) {
			if(this.props.nuevo) {
				const vivienda = this.state.viviendas.find(viv => viv.id_vivienda == this.state.id_vivienda);
				if(vivienda) {
					this.setState({
						monto: vivienda.monto || 0
					})
				}
			}
			this.getPropietarios();
			this.getContactos();
		}
	}

	async getViviendas() {
		const response = await request.post("/viviendas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.viviendas)) {
			this.setState({viviendas: response.viviendas});
		} else {
			this.setState({viviendas: []});
		}
	}

	async getPropietarios() {
		const response = await request.post("/viviendas/vivienda/propietarios", {idVivienda: this.state.id_vivienda});
		if(Array.isArray(response.propietariosVivienda)) {
			this.setState({propietarios: response.propietariosVivienda});
		} else {
			this.setState({propietarios: []});
		}
	}

	async getContactos() {
		const response = await request.post("/viviendas/vivienda/contactos", {idVivienda: this.state.id_vivienda});
		if(Array.isArray(response.contactosVivienda)) {
			this.setState({contactos: response.contactosVivienda});
		} else {
			this.setState({contactos: []});
		}
	}

	render() {
		let viviendas 		= [];
		let propietarios 	= [];
		let contactos 		= [];
		if(Array.isArray(this.state.viviendas)) {
			viviendas = this.state.viviendas;
		}
		if(Array.isArray(this.state.propietarios)) {
			propietarios = this.state.propietarios;
		}
		if(Array.isArray(this.state.contactos)) {
			contactos = this.state.contactos;
		}
		return(
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Folio:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="folio" value={this.state.folio} onChange={event => this.setState({ folio: event.target.value })} className="input input-modals" placeholder="" required/>
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Concepto:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="concepto" value={this.state.concepto} onChange={event => this.setState({ concepto: event.target.value })} className="input input-modals" placeholder="" required/>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Unidad:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" id="vivienda" name="vivienda" required value={this.state.id_vivienda} onChange={(event) => this.setState({id_vivienda: event.target.value})}>
								{viviendas.map(vivienda => <option key={vivienda.id_vivienda} value={vivienda.id_vivienda}>{vivienda.numero_registro}</option>)}
							</select>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Propietario:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="propietario" required value={this.state.id_propietario} onChange={(event) => this.setState({id_propietario: event.target.value})}>
								{propietarios.map(propietario => <option key={propietario.id_propietario} value={propietario.id_propietario}>{propietario.nombre}</option>)}
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Contacto:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="contacto" required value={this.state.id_contacto} onChange={(event) => this.setState({id_contacto: event.target.value})}>
								{contactos.map(contacto => <option key={contacto.id_contacto} value={contacto.id_contacto}>{contacto.nombre}</option>)}
							</select>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Mes:</b>
							</p>
						</div>
						<div className="column full">
							<input type="number" name="mes" value={this.state.mes} onChange={event => this.setState({ mes: event.target.value })} className="input input-modals" placeholder="MM" required min="1" max="12" maxLength="2"/>
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Año:</b>
							</p>
						</div>
						<div className="column full">
							<input type="number" name="anio" value={this.state.anio} onChange={event => this.setState({ anio: event.target.value })} className="input input-modals" placeholder="AAAA" required min="1900" max="4500" maxLength="4"/>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Monto:</b>
							</p>
						</div>
						<div className="column full">
							<input type="number" name="monto" value={this.state.monto} onChange={event => this.setState({ monto: event.target.value })} className="input input-modals" placeholder="0.00" required/>
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="estado" value={this.state.estado} onChange={(event) => this.setState({estado: event.target.value})}>
								<option value="0">pendiente</option>
								<option value="1">pagado</option>
							</select>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Comentarios:</b>
							</p>
						</div>
						<div className="white-space-8" />
						<div className="row full justify-start">
							<textarea name="comentarios" id="comentarios" value={this.state.comentarios} cols="30" rows="3" maxLength="500" className='textarea font-small full'
								onChange={(event) => this.setState({comentarios: event.target.value})}></textarea>			
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Consumer(AddAccount);