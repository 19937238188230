import React, { Component } from 'react';
import InquilinoEditarModal from './inquilinoEditar';
import ModalBorrar from '../modals/modalDelete';
import Request from "../../core/httpClient";
const request = new Request();


class InquilinosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			dataInquilino: ''
		}
    }

	reload() { this.props.reload() }

    delete = id => {
        this.handleDelete(id)
	}

    render() {

        let inquilinos = []
        if (this.props.inquilinos) {
            inquilinos = this.props.inquilinos
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead>
                            <tr className="text-left">
                                <th className="th-options-huge">Nombre</th>
                                <th className="th-options-huge">Teléfono</th>
                                <th className="th-options-huge">Unidad</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inquilinos.slice(((this.props.page - 1) * 8), (this.props.page * 8)).map((inquilino, key) => (
                                <tr key={key}>
                                    <td>
                                        {inquilino.nombre}
                                    </td>
                                    <td>
                                        {inquilino.telefono}
                                    </td>
                                    <td className='text-left'>
                                        {inquilino.numero_registro}
                                    </td>
									<td className="text-center">
										<button type="button justify-center" onClick={this.openModal.bind(this, 'editInquilinos', inquilino)} >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
								  	<td>
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, inquilino)} style={{ padding: '5px' }} >
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
				</div>
                <InquilinoEditarModal 
                    reload      =   {this.reload.bind(this)} 
                    inquilino   =   {this.state.dataInquilino} 
                
                />
                                  
				<ModalBorrar
					id          =   {this.state.id_inquilino}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>
        );
	}

	openModal(idModal, data) {
		this.setState({dataInquilino: data});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	openModalDelete(inq) {
		this.setState({id_inquilino: inq.id_inquilino});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleDelete(id) {
        let data = {
            id_inquilino: id,
        }
        const response = await request.post("/viviendas/inquilino/delete", data);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.setState({
                    message: response.message,

                });
                this.reload()

            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }

        }
    }
}

export default InquilinosTable;