import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import moment from "moment";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import Request from "../core/httpClient";
import BitacoraModal from '../components/bitacora/bitacoraModal';
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";
const request = new Request();


class SecurityBitacora extends Component {
    state = {
        bitacora: [],
        /* Estados para el filtro generico : filterData y keys */
        filterData: [],
        keys: [],
		page: 1,
		filter: '',
		subfraccionamiento: localStorage.getItem('subfrac')
    }

    /*funcion del paginador*/ 
    setPage(page) {
        this.setState({page});
    }
    
    /* funcion para el filtro generico */

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target; 
        
        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        //console.log('filtros', this.state.filterData);
    
        if(param1 === "todo") {
            this.setState({bitacora: this.state.filterData});
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({bitacora : filterData});
        }
    }



    reload() {
        this.getRondas()
        // this.getHouses();
        // this.getNombreSubFraccionamiento()
	}

    changeFrac() {
        this.getRondas()
        // this.getNombreSubFraccionamiento()
        // this.getHouses();
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevaArea() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(bitacora) {
		bitacora = bitacora.filter(bit => {
			if(bit.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (bit.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return bitacora;
	}

    render() {
		let bitacora = [];
		let dataSet;

        if (Array.isArray(this.state.bitacora)) {
			bitacora = this.state.bitacora;
			dataSet = bitacora;
			bitacora = this.filter(bitacora);
        }
        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Administracion</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'historialb'} nav={'administracion'} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">                               
                                <div className="white-space-32"></div>
                                <Controls title="Bitácora de seguridad"
									nuevo={this.nuevaArea.bind(this)}
									dataSet={dataSet}
									newButton={false}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}/>
                                <div className="white-space-16"></div>
                                <div className="card-table card-owners column" id="card-owners">
                                    <div className="table-responsive">
                                        <table>
                                            <thead>
                                                <tr className="text-left">
                                                    <th className="vivienda text-center">Fraccionamiento</th>
                                                    <th className="text-center">Descripción</th>
                                                    <th className="text-left">Fecha</th>
                                                    <th className="text-center">Usuario</th>
                                                    <th className="text-center th-options-small">Ver más</th>
                                                </tr>
                                            </thead>
                                            <tbody>
											{this.state.loading?
												<tr>
													<td colSpan="5">
														<div className="row justify-center">
															<i className="fas fa-spinner fa-spin"></i>
														</div>
													</td>
												</tr>
												:
												bitacora.slice(((this.state.page - 1) * 7),(this.state.page * 7)).map((bitacora, key) => { 
                                                return (
                                                    <tr key={key}>
                                                        <td className="text-center">{bitacora.fracNombre}</td>
                                                        <td className="">{bitacora.comentarios}</td>
                                                        <td>{moment(bitacora.fecha).format("YYYY-MM-DD")}</td>
                                                        <td>{bitacora.usuario}</td>
                                                        <td><BitacoraModal data={bitacora.comentarios} data2={bitacora.usuario} /></td>
                                                    </tr>
                                                )})}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <Paginador pages={Math.ceil(bitacora.length / 7)} setPage={this.setPage.bind(this)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.getRondas()
    }
    async getRondas() {
		this.setState({loading: true});
        let data = {
            IdFraccionamiento: localStorage.getItem('frac'),
        };
        
        const response = await request.post("/incidencias/get/bitacora", data);
        //console.log('response: ', response);

        if (response && !response.error) {
            if (response.bitacora && !response.empty) {
                this.setState({
                    bitacora: response.bitacora,
                    filterData: response.bitacora, 
                    keys: Object.keys(response.bitacora[0]).filter(key => key.indexOf('id') === -1),
                });

            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false,
                    bitacora: []
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
		}
		this.setState({loading: false});
    }
}

export default SecurityBitacora;