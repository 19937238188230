/**
 *  daily-invitations.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de Visitas del día
 */

import React, { Component } from 'react';
import moment from 'moment';

class DailyInvitations extends Component {
	render() {
		let dataDiaria = [],
			dataActiva = [];

		if (this.props.dataDiaria) {
			dataDiaria = this.props.dataDiaria;

		}
		if (this.props.dataActiva) {
			dataActiva = this.props.dataActiva;
		}

		return (
			<div className="daily-invitations row-responsive">
				<div className="card-table card-table-1 column">
					<div className="title">
						<h4>Invitados del día</h4>
					</div>
					<div className="white-space-8" />
					{this.props.dataDiaria.length !== 0 ? (
						<div className="table-responsive">
							<table>
								<thead>
									<tr>
										<th className="text-left">Nombre</th>
										<th className="text-left">Unidad</th>
										<th className="text-center">Hora entrada</th>
									</tr>
								</thead>
								<tbody>
									{dataDiaria.map((invitacion, key) => (
										<tr key={key}>
											<td>{invitacion.nombre_invitado}</td>
											<td>{invitacion.numero_registro}</td>
											<td className="text-center">
												{invitacion.Hora ? (
													moment(invitacion.Hora).format('HH:mm')
												) : (
														'N/A'
													)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
							<b>Sin invitados</b>
						)}
				</div>
				<div className="card-table card-table-2 column">
					<div className="title">
						<h4>Invitados en fraccionamiento</h4>
					</div>
					<div className="white-space-8" />
					{this.props.dataActiva.length !== 0 ? (
						<div className="table-responsive">
							<table>
								<thead>
									<tr className="text-left">
										<th>Nombre</th>
										<th>Unidad</th>
										<th className="text-center">Hora Entrada</th>
									</tr>
								</thead>
								<tbody>
									{dataActiva.map((invitacion, key) => (
										<tr key={key}>
											<td>
												<i className="fas fa-circle color-success" />
												&nbsp;
												{invitacion.nombre_invitado}
											</td>
											<td>{invitacion.numero_registro}</td>
											<td className="text-center">
												{moment( invitacion.Hora).format('HH:mm')}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
							<b>Sin invitados en el fraccionamiento</b>
						)}
				</div>
			</div>
		);
	}
}

export default DailyInvitations;
