import React, { Component } from 'react';
import Header from '../components/header/header';
import Sidebar from '../components/header/sidebar';
import { Helmet } from "react-helmet";
import { Consumer } from '../context';
import AcountStatementTable from '../components/acountStatement/acountStatement'
import AccountForm from '../components/acountStatement/accountForm';
import Paginador from "../components/paginador/paginador";
import Controls from '../components/controls/controls';
import Request from "../core/httpClient";
import XLSX from 'xlsx';
import DinamicModal from '../components/dinamicModal/dinamicModal';
const request = new Request();

class accountStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
			subfraccionamientos: 		[],
			subfraccionamiento: 		localStorage.getItem('subfrac'),
			subfraccionamientoNombre: 	'',
            idSub: 						0,
			accounts: 					[],
			filter: 					'',
			excelData:					[],
			validado: 					false,
			page: 						1
        }
	}

	uploadFile(event) {
		this.setState({error: false});
		if(event.target.files[0]) {
			const reader 	= new FileReader();
			const rABS 		= !!reader.readAsBinaryString;
			if (rABS) {
			  reader.readAsBinaryString(event.target.files[0]);
			} else {
			  reader.readAsArrayBuffer(event.target.files[0]);
			};
			reader.onload = (e) => {
				this.setState({loadExcel: true, validado: false});
				this.openModal('importRecibos');
				const bstr 		= e.target.result;
				const wb 		= XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
				const wsname 	= wb.SheetNames[0];
				const ws 		= wb.Sheets[wsname];
				let data 		= XLSX.utils.sheet_to_json(ws);
				this.setState({excelData: data, loadExcel: false});
			};
		}
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getAccountStatement()
		}
	}

	openModal(idModal) {
		this.setState({formMessage: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal) {
		this.setState({formMessage: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

    change() {
        this.getAccountStatement()
	}

    reload() {
        this.getAccountStatement()
	}

    filtro = data => {
        this.filtrar(data)
    }
    /*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevoEstado() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		console.log(filter.toLowerCase());
		this.setState({ filter: filter.toLowerCase()});
	}

	filter(report) {
		report = report.filter(bit => {
			if (bit.folio.indexOf(this.state.filter) !== -1) {
				return true;
			} else if (bit.concepto.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (bit.numero_registro.indexOf(this.state.filter) !== -1) {
				return true;
			} else if (bit.propietario) {
				if (bit.propietario.toLowerCase().indexOf(this.state.filter) !== -1) {
					return true;
				}
			} else if (bit.monto) {
				if ((bit.monto).toString().indexOf((this.state.filter).toString()) !== -1) {
					return true;
				}
			} else {
				return false;
			}
		});
		return report;
	}

	handleClose() {
		this.setState({showModal: false});
	}

    render() {
		let accounts = [];
		let dataSet;

		if (Array.isArray(this.state.accounts)) {
			accounts = this.state.accounts;
			dataSet = accounts;
			accounts = this.filter(accounts);
		}
        return (
            <div className="owners column">
                <Helmet>
                    <title>iGate - Estados de cuenta</title>
                </Helmet>
                <Header sidebar={true} active={'EstadosCuenta'} parent={'EstCuenta'} panel={'panel14'} nav={"catalogos"} change={this.change.bind(this)} reload={this.reload.bind(this)} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
								<Controls
									title		=	"Estados de cuenta"
									nuevo		=	{this.openModal.bind(this,"addAccount")}
									dataSet		=	{dataSet}
									setFilter	=	{this.setFilter.bind(this)}
									subChange	=	{this.subChange.bind(this)}
								/>
								<div className	="white-space-8"></div>
								<div className	="hidden">
									<input id="upload_file" className="input-file" type="file" accept=".xls,.xlsx" onChange={this.uploadFile.bind(this)}></input>
								</div>
								<div className="row full">
									<button className="btn btn-primary btn-small color-white" onClick={this.openModal.bind(this, 'generarRecibos')}>
									<i className="fas fa-file"></i> &nbsp;Crear Recibos
									</button>
									&nbsp;
									<button className="btn btn-primary btn-small color-white" onClick={() => {
										const upInput = document.getElementById('upload_file');
										if(upInput) {
											upInput.click();
										}
									}}>
										<i className="fas fa-file-excel"></i> &nbsp; Importar
									</button>
								</div>
                                <div className="white-space-16" />
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<AcountStatementTable 
										page		=	{this.state.page} 
										accounts	=	{accounts} 
										reload		=	{this.reload.bind(this)} 
										idSub		=	{this.state.idSub} 
										filtro		=	{this.filtro.bind(this)} 
									/>
								}
								<div className="white-space-16"></div>
                                {/* <AcountStatementTable reportes={this.state.reportes} reload={this.reload.bind(this)} idSub={this.state.idSub} filtro={this.filtro.bind(this)} /> */}
								<Paginador pages={Math.ceil(accounts.length / 7)} setPage={this.setPage.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
				<DinamicModal
					idModal					=	{'importRecibos'}
					sizeModalContainer		=	{'big'}
					title					=	{'IMPORTAR RECIBOS'} 
					success					=	{this.handleImport.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					closeModal				=	{this.closeExcelModal.bind(this)}
					message					=	{this.state.formMessage}>
					{this.renderExcel()}
				</DinamicModal>
				<DinamicModal
					idModal					=	{'generarRecibos'}
					sizeModalContainer		=	{'big'}
					title					=	{'GENERAR RECIBOS'} 
					success					=	{this.handleGenerar.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					closeModal				=	{this.closeExcelModal.bind(this)}
					message					=	{this.state.formMessage}>
					{this.renderGenerar()}
				</DinamicModal>
				<DinamicModal 
					idModal					=	{'addAccount'}
					sizeModalContainer		=	{'big'}
					title					=	{'NUEVO RECIBO'} 
					success					=	{this.handleNew.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"AGREGAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.formMessage}
				>
					<AccountForm 
						nuevo	=	{true} 
						account	=	{{}}
					/>
				</DinamicModal>
            </div>
        );
    }

	renderExcel() {
		let excelData = [];
		if (Array.isArray(this.state.excelData)) {
			excelData = this.state.excelData;
		}
		return (
			<div className="column full">
				<div className="row justify-end">
					<button type="button" className="btn btn-primary btn-small color-white" onClick={this.validateExcel.bind(this)}>
						Validar
					</button>
				</div>
				<div className="table-responsiv column full">
					<table>
						<thead>
							<tr className="text-left">
								<th>Folio</th>
								<th>Unidad</th>
								<th>Mes</th>
								<th>Año</th>
								<th>Monto</th>
								<th>Concepto</th>
								<th className="text-center">Válido</th>
							</tr>
						</thead>
						{this.state.loadExcel? 
							<tr className="text-left">
								<td colSpan="6">
									<div className="row full justify-center align-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
								</td>
							</tr> 
							:
							excelData.map((data, index) => {
							return (
								<tr key={index} className="text-left">
									<td>{data.FOLIO}</td>
									<td>{data.UNIDAD}</td>
									<td>{data.MES}</td>
									<td>{data.AÑO}</td>
									<td>{data.MONTO}</td>
									<td>{data.CONCEPTO}</td>
									<td className="text-center"><i className={data.id_vivienda > 0? 'fas fa-check color-green' : 'fas fa-times-circle color-red'}></i></td>
								</tr>
							)
						})}
					</table>
				</div>
			</div>
		)
	}

	renderGenerar() {
		let subfraccionamientos = [];
		if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		}
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Subfraccionamiento:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" id="subfraccionamiento" name="subfraccionamiento" value={this.state.id_subfraccionamiento} onChange={(event) => this.setState({id_subfraccionamiento: event.target.value})}>
								{subfraccionamientos.map(subfraccionamiento => <option key={subfraccionamiento.id_subfraccionamiento} value={subfraccionamiento.id_subfraccionamiento}>{subfraccionamiento.nombre}</option>)}
							</select>
						</div>
					</div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Folio:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="folio" value={this.state.folio} onChange={event => this.setState({ folio: event.target.value })} className="input input-modals" placeholder="" required/>
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Concepto:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="concepto" value={this.state.concepto} onChange={event => this.setState({ concepto: event.target.value })} className="input input-modals" placeholder="" required/>
						</div>
					</div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Mes:</b>
							</p>
						</div>
						<div className="column full">
							<input type="number" name="mes" value={this.state.mes} onChange={event => this.setState({ mes: event.target.value })} className="input input-modals" placeholder="MM" required min="1" max="12" maxLength="2"/>
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Año:</b>
							</p>
						</div>
						<div className="column full">
							<input type="number" name="anio" value={this.state.anio} onChange={event => this.setState({ anio: event.target.value })} className="input input-modals" placeholder="AAAA" required min="1900" max="4500" maxLength="4"/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Comentarios:</b>
							</p>
						</div>
						<div className="white-space-8" />
						<div className="row full justify-start">
							<textarea name="comentarios" id="comentarios" value={this.state.comentarios} cols="30" rows="3" maxLength="500" className='textarea font-small full'
								onChange={(event) => this.setState({comentarios: event.target.value})}></textarea>			
						</div>
					</div>
				</div>
			</div>
		)
	}

    componentDidMount() {
		this.getAccountStatement();
		this.getSubFraccionamientos();
	}

	async validateExcel(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		const data = {
			fraccionamiento: localStorage.getItem('frac'),
			recibos: this.state.excelData
		}
		const response = await request.post("/cuentas/validate/excel", data);
		if(response.accounts) {
			this.setState({validado: true, excelData: response.accounts});
		}
		this.setState({formMessage: response.message, loadingSave: false});
	}

	async handleNew(event) {
		event.preventDefault();
		const form 			= event.target;
		this.setState({loadingSave: true});
		const data = {
			folio: 			form.folio.value,
			concepto: 		form.concepto.value,
			vivienda: 		form.vivienda.value,
			propietario: 	form.propietario.value,
			contacto: 		form.contacto.value,
			mes: 			form.mes.value,
			anio: 			form.anio.value,
			monto: 			form.monto.value,
			estado: 		form.estado.value,
			comentarios: 	form.comentarios.value,
			usuario: 		this.props.context.user
		}
		const response = await request.post('/cuentas/create/account', data);
		if(response.created){
			this.setState({formMessage: "RECIBO CREADO CORRECTAMENTE"});
			// this.clearForm(form);
			this.getAccountStatement();
			this.closeModal("addAccount");
		}else{
			console.log("NO SE PUDO");
			this.setState({formMessage: "NO SE PUDO CREAR RECIBO"});
		}
		this.setState({loadingSave: false});
	}
	clearForm(form){
		form.folio.value 		= "";
		form.concepto.value 	= "";
		form.vivienda.value 	= "";
		form.propietario.value 	= "";
		form.contacto.value 	= "";
		form.mes.value 			= "";
		form.anio.value 		= "";
		form.monto.value	 	= "";
		form.estado.value 		= "";
		form.comentarios.value  = "";
	}

	async handleImport(event) {
		event.preventDefault();
		if(!this.state.validado) {
			this.setState({formMessage: 'SE DEBE VALIDAR PRIMERO LA INFORMACIÓN'})
			return;
		}
		this.setState({loadingSave: true, formMessage: ''});
		const data = {
			fraccionamiento: localStorage.getItem('frac'),
			usuario: this.props.context.user,
			recibos: this.state.excelData
		}
		const response = await request.post("/cuentas/import/excel", data);
		this.setState({formMessage: response.message});
		if(response.created) {
			this.setState({formMessage: `${response.created} REGISTROS INSERTADOS`})
		}
		this.getAccountStatement();
		this.setState({loadingSave: false});
	}

	async handleGenerar(event) {
		event.preventDefault();
		const form = event.target;
		this.setState({loadingSave: true});

		const data = {
			folio: 				form.folio.value,
			concepto: 			form.concepto.value,
			mes: 				form.mes.value,
			anio: 				form.anio.value,
			comentarios: 		form.comentarios.value,
			usuario: 			this.props.context.user.id,
			fraccionamiento: 	localStorage.getItem('frac'),
			subfraccionamiento: form.subfraccionamiento.value
		}

		const response = await request.post('/cuentas/generate/account', data);
		// this.setState({formMessage: response.message});
		if(response.created) {
			this.setState({loadingSave: false,
				folio: '',
				concepto: '',
				mes: '',
				anio: '',
				comentarios: '',
				formMessage: "RECIBOS CREADOS CORRECTAMENTE"
			});
			this.getAccountStatement();
			this.closeModal("generarRecibos");
		}else{
			this.setState({formMessage: "NO SE PUDIERON CREAR LOS RECIBOS"});
		}
		this.setState({loadingSave: false});
	}

	closeExcelModal() {
		const input = document.getElementById('upload_file');
		if(input) {
			input.files = null;
			input.value= null;
		}
	}

    async getAccountStatement() {
        this.setState({loading: true, accounts: [] })
        let data = {
			idFrac: localStorage.getItem('frac'),
            idSub: this.state.subfraccionamiento || 0
        }
        const response = await request.post("/cuentas/get/all", data);
		console.log("Response");
		console.log(response);
        if (response && !response.error) {
            if (response.accounts && !response.empty) {
                this.setState({
                    accounts: response.accounts
				});
				// console.log(response.accounts);
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
		this.setState({ loading: false });
	}

	// traer subfraccionamientos
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
		let data = { Idsub: fraccionamiento }
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento});
		} else {
			this.setState({	subfraccionamientos: [] });
		}
	}

    async filtrar(data) {
        this.setState({ reportes: [] });
        if (data.filtro === '1') {
            data.filtro = 0

            const response = await request.post("/cuentas/get/filter", data)
            if (response && !response.error) {
                if (response.reportes && !response.empty) {
                    this.setState({
                        reportes: response.reportes
                    });
                } else {
                    this.setState({
                        empty: true,
                        message: response.message
                    });
                }
            } else {
                this.setState({
                    error: true,
                    message: response.message
                });
            }

        } else if (data.filtro === '2') {
            data.filtro = 1


            const response = await request.post("/cuentas/get/filter", data)
            if (response && !response.error) {
                if (response.reportes && !response.empty) {
                    this.setState({
                        reportes: response.reportes

                    });

                } else {
                    this.setState({
                        empty: true,
                        message: response.message
                    });
                }
            } else {
                this.setState({
                    error: true,
                    message: response.message
                });
            }

        }
        else {
            this.getSubFraccionamiento()
        }
    }
}

export default Consumer(accountStatement);