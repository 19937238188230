import React, { Component } from 'react';
import ReactModal from "react-modal";
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

const request = new Request();

class SubFraccTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalSubEddit: false,
            id: '',
            nombre: '',
            direccion: '',
			telefono: '',
			message: null
        }
	}
	
	openModal(idModal,sub) {
		let phone = '';
		if(sub.telefono) {
			phone = parsePhoneNumber(`+${sub.telefono}`);
			phone = phone.number;
		}

		this.setState({
			message: null,
			nombre: sub.nombre,
			direccion: sub.direccion,
			telefono: phone,
			id: sub.id_subfraccionamiento
		});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

    delete = (id, close) => {
        this.handleDelete(id, close)
    }

    render() {

        let subfraccionamiento = [];

        if (this.props.sub) {
            subfraccionamiento = this.props.sub;
        }

        return (

            <div className="card-table card-owners column" id="card-owners">
                <div className="table-responsiv column">
					{this.props.children}
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="th-options-huge">Nombre</th>
                                <th>Dirección</th>
                                <th>Teléfono</th>
								<th className="text-center">Unidades</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>

                            {subfraccionamiento.slice(((this.props.page - 1) * 8), (this.props.page * 8)).map((sub, key) => {
                                return (
                                    <tr className="text-left" key={sub.id_subfraccionamiento}>
                                        <td >{sub.nombre}</td>
                                        <td >{sub.direccion}</td>
                                        <td >{sub.telefono}</td>
										<td className="text-center">
											<h4 className="font-small weight-bold">{sub.Num_Viviendas}</h4>
										</td>
                                        <td className="text-center">
                                            <button type="button" onClick={this.openModal.bind(this,'editSubfrac',sub)} className='btn-full justify-center btn-mini btn-secondary color-white'>
                                                <i className="font-small fas fa-pen" /> 
                                            </button>
                                        </td>
                                        <td className="text-center">
											<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, sub)} style={{ padding: '5px' }} >
												<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
											</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

				<DinamicModal 
					idModal={'editSubfrac'}
					sizeModalContainer={'small'}
					title={'EDITAR SUBFRACCIONAMIENTO'} 
					success={this.handleEddit.bind(this)} 
					showBtnSuccess={true}
					btnTextSuccess={"ACTUALIZAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
				>
				{this.renderEditSubfrac()}
				</DinamicModal>
				<ModalBorrar
					id={this.state.id_subfraccionamiento}
					delete={this.delete.bind(this)}/>
            </div>
        );
	}

	renderEditSubfrac() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p><b>Nombre:</b></p>
					</div>
					<div className="column full">
						<input type="text" name="fullName" value={this.state.nombre} onChange={event => this.setState({ nombre: event.target.value })} className="input input-modals" placeholder="Nombre" required minLength="6" maxLength="100" />
					</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p>
							<b>Dirección:</b>
						</p>
					</div>
					<div className="column full">
						<input type="text" name="address" value={this.state.direccion} onChange={event => this.setState({ direccion: event.target.value })} className="input input-modals" placeholder="Dirección" required minLength="6" maxLength="150" />
					</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p>
							<b>Teléfono:</b>
						</p>
					</div>
					<div className="column full">
						<PhoneInput
							displayInitialValueAsLocalNumber
							name="phoneNumber"
							country="MX"
							placeholder="Número de teléfono"
							value={this.state.telefono}
							onChange={ value => this.setState({ telefono: value, telefonoError: undefined }) }
							error={ this.state.telefonoError }
							className={'input-modals'}/>
					</div>
					</div>
				</div>
		</div>
  
		)
	}

	openModalDelete(sub, close) {
		this.setState({id_subfraccionamiento: sub.id_subfraccionamiento});
		let modal = document.getElementById("deleteModal");
		if(close) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}
   
    handleOpen(sub) {
        this.setState({
			nombre: sub.nombre,
			direccion: sub.direccion,
			telefono: sub.telefono,
			id: sub.id_subfraccionamiento,
			showModalSubEddit: !this.state.showModalSubEddit 
		});
    }

    async handleEddit(event) {
		this.setState({loadingSave: true});
		event.preventDefault();
		/**Validar Telefono */
		if(!isValidPhoneNumber(this.state.telefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}
        let data = {
            IdSub: this.state.id,
            Nombre: this.state.nombre,
            Direccion: this.state.direccion,
            Telefono: this.state.telefono
        };
		const response = await request.post("/subfraccionamientos/eddit/subfraccionamiento", data);
        if (response && !response.error && response.created) {
			this.props.getSubFraccionamientos();
		}else{
			this.setState({message:'NO SE PUDO ACTUALIZAR SUBFRACCIONAMIENTO'});
		}
		this.setState({loadingSave: false});

    }


    async handleDelete(id, handleClose) {

        let data = { IdSub: id }

        const response = await request.post("/subfraccionamientos/delete/subfraccionamiento", data);

        if (response && !response.error) {
            if (response.eddited && !response.empty) {
				this.props.reload();
				handleClose();
				if(id == localStorage.getItem('subfrac')) {
					localStorage.setItem('subfrac', 0);
				}
            } else {
                this.setState({ empty: true, message: response.message });
            }
        } else {
            this.setState({ error: true, message: response.message });
        }
    }
}

export default SubFraccTable;