import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import NotificationHistoryTable from "../components/notications/notificationHistoryTable"
import Paginador from "../components/paginador/paginador";
import Controls from '../components/controls/controls';
import Request from "../core/httpClient";
const request = new Request();


class NotificacionHistory extends Component {
    state = {
        notificaciones: [],
        /* Estados para el filtro generico : filterData y keys */
        filterData: [],
        keys: [],
		page: 1,
		filter: ''
    }

    /*funcion del paginador*/ 
    setPage(page) {
        this.setState({page});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
            this.getNotificaciones();
        }
    }

    /* funcion para el filtro generico */

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target; 
        
        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        //console.log('filtros', this.state.filterData);
    
        if(param1 === "todo") {
            this.setState({notificaciones: this.state.filterData});
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({notificaciones : filterData});
        }

    }

    reload() {
        this.getNotificaciones()
    }

    changeFrac() {
        this.getNotificaciones()
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevaDefinicion() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(notificaciones) {
		notificaciones = notificaciones.filter(not => {
			if(not.notificacion_texto.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (not.tipo.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (not.usuario.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			}else {
				return false;
			}
		});
		return notificaciones;
	}

    render() {
		let notificaciones = [];
		let dataSet;

        if(this.state.notificaciones) {
			notificaciones = this.state.notificaciones;
			dataSet = notificaciones;
			notificaciones = this.filter(notificaciones);
        }

        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Administración</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'historiald'} parent={'notificaciones'} panel={'panel2'} nav={'administracion'} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32"></div>
                                <div className="justify-center">
                                    <div className="column full">
										<Controls title="Historial de notificaciones"
											nuevo={this.nuevaDefinicion.bind(this)}
											dataSet={dataSet}
											newButton={false}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}/>
                                        <div className="white-space-16"></div>
										{this.state.loading?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
                                        	<NotificationHistoryTable notificaciones={notificaciones} page={this.state.page} />
										}
                                        <div className="white-space-16"></div>
                                        <Paginador pages={Math.ceil(notificaciones.length / 7)} setPage={this.setPage.bind(this)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.getNotificaciones()
    }
    async getNotificaciones() {
        this.setState({loading: true});
        
        let idSub = this.state.subfraccionamiento || 0;
        let data = {
			idFraccionamiento: localStorage.getItem('frac'),
			idSub
        }

        const response = await request.post("/notificaciones/get/all/notifications/sent", data)
        //console.log('response: ', response);
        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({
                    notificaciones: response.notifications,
                    filterData: response.notifications, 
                    keys: Object.keys(response.notifications[0]).filter(key => key.indexOf('id') === -1),
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false,
                    showModal: false,
                    notificaciones: []

                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false,
                showModal: false
            });
        }
		this.setState({loading: false});
    }
}

export default NotificacionHistory;