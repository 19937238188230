import React, { Component } from 'react';
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import SubFRaccTable from "../components/subfraccionamiento/subfractable";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import ReactModal from "react-modal";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

const request = new Request();

class SubFRaccPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0, idAdministracion: 0 },
            subfraccionamientos: [],
            showModal: false,
			hidden: false,
			loadingSave: false,
            page: 1,
			filterData: [],
			filter: '',
			keys: [],
			mesagge: null,
			telefono: ''
        }
    }

	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    reload() {
        this.getSubFraccionamientos()
	}

    save = data => {
        this.edditSub(data);
	}
	
	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        //console.log(param1);

        if (param1 === "todo") {
            this.setState({ subfraccionamientos: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ subfraccionamientos: filterData });
        }

    }

	nuevoSubfraccionamiento() {
		this.setState({showModal: true})
	}

	setFilter(filter) {
		this.setState({filter});
	}

	filter(subfraccionamientos) {
		subfraccionamientos = subfraccionamientos.filter(sub => {
			if(sub.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if(sub.direccion.toLowerCase().indexOf(this.state.filter) !== -1){
				return true;
			} else if(sub.telefono.toLowerCase().indexOf(this.state.filter) !== -1){
				return true;
			} else {
				return false;
			}
		})
		return subfraccionamientos;
	}

    render() {
        let subfraccionamientos = [];
		let dataSet;
        if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
			dataSet = subfraccionamientos;
			subfraccionamientos = this.filter(subfraccionamientos);
        }
        return (
            <div className="owners column">
				<Helmet>
                    <title>iGate - Subfraccionamientos</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} sidebar={true} active={'subfraccionamientos'} parent={'admin'} panel={'panel1'} nav={"catalogos"}></Header>
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar ></Sidebar>
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
								<Controls title="Subfraccionamientos"
									nuevo={this.openModal.bind(this,"newSubfrac")}
									dataSet={dataSet}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}
									selectSub={false}/>
                                <div className="white-space-16" />
								{this.state.loadingSub?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
                                	<SubFRaccTable sub={subfraccionamientos} page={this.state.page} getSubFraccionamientos={this.getSubFraccionamientos.bind(this)} reload={this.reload.bind(this)}>
									</SubFRaccTable>
								}
								<div className="white-space-16"></div>
                                <Paginador pages={Math.ceil(subfraccionamientos.length / 8)} setPage={this.setPage.bind(this)} />

                            </div>
                        </div>
                    </div>
                </div>

				<DinamicModal 
					idModal={'newSubfrac'}
					sizeModalContainer={'small'}
					title={'NUEVO SUBFRACCIONAMIENTO'} 
					success={this.handleSubmit.bind(this)} 
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.mesagge}
				>
				{this.renderNewSubfrac()}
				</DinamicModal>
                
            </div>
        );
    }



    componentDidMount() {
        this.load()
		this.getSubFraccionamientos();
	}

	/*MODAL AGREGAR NUEVO SUBFRACCIONAMIENTO*/

	renderNewSubfrac() {
		return(
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center ">
					<div className="column label-normal">
						<p><b>Nombre:</b></p>
					</div>
					<div className="column full">
						<input type="text" name="name" className="input input-modals" placeholder="Nombre del subfracccionamiento" required minLength="6" maxLength="100" />
					</div>
				</div>
				<div className="white-space-16" />
				<div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p>
							<b>Dirección:</b>
						</p>
					</div>
					<div className="column full">
						<input type="text" name="address" className="input input-modals" placeholder="Dirección de contacto"
							required minLength="6" maxLength="150" />
					</div>
				</div>
				<div className="white-space-16" />
				<div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p>
							<b>Teléfono:</b>
						</p>
					</div>
					<div className="column full">
						
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phone"
								country="MX"
								placeholder="Número de teléfono"
								value={this.state.telefono}
								onChange={ value => this.setState({ telefono: value, telefonoError: undefined }) }
								error={ this.state.telefonoError }
								className={'input-modals'}/>
					</div>
				</div>
			</div>
		</div>
  
		)
	}

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({
                user: {
                    idUsuario: user.id.id_usuario,
                    idPerfil: user.id.id_perfil,
                    idFraccionamiento: user.id.id_fraccionamiento,
                    idAdministracion: user.id.id_administracion
                }
            });
        }
    }

    handleOpen() {
        this.setState({ showModal: true })
    }   
    handleClose() {
        this.setState({ showModal: false })
    }

    // traer subfraccionamientos
    async getSubFraccionamientos() {
		
		this.setState({loadingSub: true});
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento }
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		console.log(response);
		if (response.subfraccionamiento) {
			this.setState({
				subfraccionamientos: response.subfraccionamiento,
				filterData: response.subfraccionamiento
			});
			//console.log("suuub", response.subfraccionamiento);
		} else {
			this.setState({
				empty: true,
				mesagge: response.message,
				subfraccionamientos: []
			});
		}
		this.setState({loadingSub: false});
    }

    // guardar subfraccionamiento
    async handleSubmit(event) {
		this.setState({loadingSave: true});
        event.preventDefault();
		const form = event.target;

		if(!isValidPhoneNumber(this.state.telefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} 

        let data = {
            IdFraccionamiento: localStorage.getItem('frac'),
            Nombre: form.name.value,
            Direccion: form.address.value,
            Telefono: this.state.telefono.replace('+','')
        };

        const response = await request.post("/subfraccionamientos/create/subfraccionamiento", data);

		

        if (response && !response.error) {
            if (response.created && !response.empty) {
                this.setState({
					reload: true,
					mesagge: "NUEVO FRACCIONAMIENTO AGREGADO",
					loading: false,
					showModal: false
				});
				form.name.value = "";
				form.address.value = "";
				form.phone.value = "";
				console.log('si');
				this.getSubFraccionamientos();
            } else {
                this.setState({
                    empty: true,
                    mesagge: "NO SE PUDO CREAR NUEVO FRACCIONAMIENTO",
					loading: false,
					showModal: true
				});
				console.log('no');
            }
        } else {
            this.setState({
                error: true,
                mesagge: "NO SE PUDO CREAR NUEVO FRACCIONAMIENTO",
				loading: false,
				showModal: true
			});
			console.log('no2');
		}

		const modal = document.getElementById("newSubfrac");
		// console.log(this.state.showModal);
		if(this.state.showModal && modal){
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}else{
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
		
        
        this.setState({ loadingSave: false });
    }


    async edditSub(data) {
        const response = await request.post("/subfraccionamientos/eddit/subfraccionamiento", data);
        if (response && !response.error) {
			this.getSubFraccionamientos();
		}
		this.setState({loadingSave: false});
    }
}

export default Consumer(SubFRaccPage);