import React, { Component } from 'react';
import ReactModal from "react-modal";

class BitacoraModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            Rdescription: this.props.bitacora
        }
    }
    render() {

        let bitacora = ''

        if (this.props.data) {
            bitacora = this.props.data


        }
        let usuario = ''

        if (this.props.data2) {
            usuario = this.props.data2


        }
        return (
            <div >
                <button className="btn-full btn-mini btn-secondary color-white" type='button' onClick={this.handleOpen.bind(this)} style={{ padding: '5px' }} >
                    <i className="font-text fas fa-file-alt" />
                </button>
                <ReactModal isOpen={this.state.showModal} className="modal modal-full modal-alert justify-center column" overlayClassName="overlay" >
                    <div className="full column align-center">
                        <div className="close-modal justify-end header-close">
                            <button className="btn-modal-close" onClick={this.handleOpen.bind(this)}>
                                <i className="fas fa-times" />
                            </button>
                        </div>
                        <div className="column modal-content">
                            <div className="full">
                                <p>
                                    <b>Reportado por:</b>
                                </p>

                                <p>{usuario}</p>
                                <div className="white-space-16" />
                                <p>
                                    <b>Descripción de la resolución:</b>
                                </p>
                                <div className="white-space-4" />
                                <textarea className="textarea full" value={bitacora}
                                    onChange={event => this.setState({ bitacora: event.target.value })} disabled={true} name="descriptionResolution" placeholder="Descripción de la resolución (600 caracteres máximo)" cols="50" rows="8" maxLength="600" minLength="20"></textarea>
                                {/*<button type="button" className="btn btn-medium btn-secondary color-white" onClick={this.handleOpen.bind(this)} >
                                    <i className="fas fa-times" /> &nbsp; CERRAR
                                </button>*/}
                            </div>
                        </div>
                    </div>
                </ReactModal>
            </div>

        );
    }

    handleOpen() {
        this.setState({ showModal: !this.state.showModal })

    }
}


export default BitacoraModal;