/**
 *  budgerConcepts.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de presupuestos
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import Paginador from "../components/paginador/paginador";
import BudgetConceptTable from "../components/budgetConcept/budgetConcept"
import ReactModal from "react-modal"
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';

const request = new Request();


class BudgerConcepts extends Component {
	state = {
		showModal: 				false,
		conceptName: 			'',
		idFraccionamiento:		0,
		conceptos: 				[],
		page: 					1,
		filter: 				'',
		subfraccionamiento: 	localStorage.getItem('subfrac'),
		loadingSave: 			false,
	}

	openModal(idModal) {
		this.setState({message: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal){
		this.setState({message: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	reload() {
		this.getConceptos()
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	changeFrac() {
		this.getConceptos()
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevoConcepto() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter});
	}

	filter(conceptos) {
		conceptos = conceptos.filter(con => {
			if(con.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return conceptos;
	}

	render() {

		let conceptos = [];
		let dataSet;

		if (Array.isArray(this.state.conceptos)) {
			conceptos 	= this.state.conceptos;
			dataSet 	= conceptos;
			conceptos 	= this.filter(conceptos);
		}

		return (
			<div className="owners column">
				<Helmet>
					<title>iGate - Propietarios</title>
				</Helmet>
				<Header sidebar={true} active={'presupuestoConcepto'} parent={'presup'} panel={'panel2'} nav={"catalogos"} change={this.changeFrac.bind(this)} reload={this.reload.bind(this)} />
				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					{/*<div className="row justify-center">
						<div className="column" id="sidebar">
							<Sidebar />
						</div>*/}
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								<Controls title="Conceptos Presupuestos"
									nuevo={this.openModal.bind(this,"newBudgetConcept")}
									dataSet={dataSet}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}
									selectSub={false}
									/>
								<div className="white-space-16" />
								{this.state.loadingConceptos?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<BudgetConceptTable conceptos={conceptos} page={this.state.page} reload={this.reload.bind(this)}></BudgetConceptTable>
								}
								<div className="white-space-16" />
								<Paginador pages={Math.ceil(conceptos.length / 7)} setPage={this.setPage.bind(this)} />
							</div>
						</div>

					</div>
					{/*</div>*/}
				</div>
			
			
				<DinamicModal 
					idModal				=	{'newBudgetConcept'}
					sizeModalContainer	=	{'small'}
					title				=	{'NUEVO CONCEPTO'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					closeModal			=	{this.resetValue.bind(this)}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
				>
				{this.renderNewBudgetConcept() }
				</DinamicModal>
			</div>
		);
	}

	componentDidMount() {
		this.getConceptos();
	}

	resetValue() {
		this.setState({conceptName: ''})
	}

	renderNewBudgetConcept() {
		return(
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center ">
					<div className="column label-normal">
						<p><b>Concepto:	</b></p>
					</div>
					<div className="column full">
						<input type="text" name="conceptName" value={this.state.conceptName}
							onChange={event => this.setState({ conceptName: event.target.value })} className="input input-modals" placeholder="Nuevo concepto" required maxLength="64" required/>
					</div>
				</div>
			</div>
		</div>
	
		)
	}

	handleOpen() {
		this.setState({ showModal: !this.state.showModal, conceptName: '' });

		this.getFraccionamiento()

	}
	async handleSubmit(event) {
		this.setState({loadingSave: true, message: ''});
		event.preventDefault();
		let data = {
			concepto: this.state.conceptName,
			idFrac: localStorage.getItem('frac')
		}
		const response = await request.post("/presupuestos/presupuesto/create/concept", data);
		if (response.created) {
			this.setState({showModal: false, message: 'CONCEPTO AGREGADO CORRECTAMENTE', conceptName: ''});
			this.closeModal("newBudgetConcept");
			this.reload();
		}else{
			this.setState({message: "NO SE PUDO AGREGAR CONCEPTO"});
		}
		this.setState({loadingSave: false, conceptName: ''});
	}

	async getFraccionamiento() {
		let data = { IdFraccionamiento: localStorage.getItem('frac') };

		const response = await request.post("/admin/administracion/get/fraccionamiento", data);

		if (response && !response.error) {
			if (response.fraccionamiento && !response.empty) {
				this.setState({
					nombreFrac: response.fraccionamiento[0].nombre,
					idFraccionamiento: response.fraccionamiento[0].id_fraccionamiento
				});
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});

		}
	}

	async getConceptos() {
		this.setState({ conceptos: [], loadingConceptos: true });
		if (!localStorage.getItem('frac')) {
			return
		} else {
			let data = { idFraccionamiento: localStorage.getItem('frac') };
			const response = await request.post("/presupuestos/get/all", data);
			if (response && !response.error) {
				if (response.conceptos && !response.empty) {
					this.setState({
						conceptos: response.conceptos
					});
				} else {
					this.setState({
						empty: true,
						message: response.message
					});
				}
			} else {
				this.setState({ error: true, message: response.message });

			}
		}
		this.setState({ loadingConceptos: false });
	}
}


export default Consumer(BudgerConcepts);
