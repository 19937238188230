import React, { Component } from 'react';
import moment from 'moment-timezone'
import ReservationEddit from '../commonAreasReservation/reservationEddit'
import ModalBorrar from '../modals/modalDelete'
import NumberFormat from "react-number-format";
import Request from "../../core/httpClient";
const request = new Request();

class ReservationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			dataReservation: []
		}
    }
    reservas() {
        this.props.reserva()
    }
    delete = id => {
        this.handleDelete(id)
	}

	openModal(idModal,data) {
		this.setState({
			message: null,
			dataReservation: data
		});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

    render() {

        let reservas = []

        if (this.props.reservas) {
            reservas = this.props.reservas

        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="table-responsiv column">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="">Área reservada</th>
                                <th className="">Fecha</th>
                                <th className="text-left">Unidad</th>
                                <th className="text-left">Fianza</th>
                                <th className="text-left">Horario</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>

                            </tr>
                        </thead>
                        <tbody>
                            {reservas.slice((this.props.page - 1) * 7, this.props.page * 7).map((reserva, key) => (
                                <tr key={key}>
                                    <td>{reserva.nombre}</td>
                                    <td>{moment(reserva.fecha_reserva).tz('GMT').format("DD-MM-YYYY")}</td>
                                    <td>{reserva.numero_registro}</td>
                                    <td>
                                        <NumberFormat
                                            value={reserva.fianza_cobrada}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td>{reserva.descripcion}</td>
									<td className="text-center">
										<button className='btn-full justify-center btn-mini btn-secondary color-white' type="button" onClick={this.openModal.bind(this,'editReservation',reserva)} >
											<i className="font-small fas fa-pen" /> 
										</button>
									</td>
                                    <td> 
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this,reserva)} style={{ padding: '5px' }} >
											<i className="font-text fas fa-trash-alt " /> 
										</button>
									</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <ReservationEddit 
                    reserva     =   {this.state.dataReservation}
                    reservas    =   {this.reservas.bind(this)} 
                />
                <ModalBorrar 
                    id      =   {this.state.id_reservacion} 
                    delete  =   {this.delete.bind(this)} 
                />
            </div>
        );
	}
	
	openModalDelete(reserv) {
		this.setState({id_reservacion: reserv.id_reserva});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleDelete(id) {

        let data = { Idreserva: id }
        const response = await request.post("/commonArea/delet/reservation", data);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.setState({

                });
            } else {
                this.setState({ empty: true, message: response.message });
            }
        } else {
            this.setState({ error: true, message: response.message });
        }
        this.props.reload()
    }
}

export default ReservationTable;