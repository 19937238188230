import React, { Component } from 'react';
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import moment from 'moment-timezone';
import ContactosTable from "../components/contactos/contactosTable"
import Header from "../components/header/header";
import Paginador from "../components/paginador/paginador";
import Controls from '../components/controls/controls';
import Sidebar from "../components/header/sidebar";
import Request from "../core/httpClient";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Autocomplete from '../components/controls/autocomplete';
import DinamicModal from '../components/dinamicModal/dinamicModal';

const request = new Request();


class Contactos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactos: 				[],
            INombre: 				'',
            IDireccion: 			'',
            IMunicipio: 			'',
            IEstado: 				'',
            ICp: 					'',
            iTelefono: 				'',
            IPais: 					'',
            IGenero: 				 0,
            IId: 					 0,
            iNumId: 				'',
            iVigencia: 				'',
			IFechaIngreso: 			moment().format('YYYY-MM-DD'),
			IFechaSalida:  			moment().format('YYYY-MM-DD'),
            IIdPersona: 			 0,
            IidViv: 				 0,
            page: 					 1,
            code: 					'',
			email:					'',
			fraccionamiento: 		localStorage.getItem('frac') || 0,
			subfraccionamiento: 	localStorage.getItem('subfrac') || '0',
			subfraccionamientos: 	[],
			filter: 				'',
			personas: 				[],
			saveMessage: 			''
        }
    }

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getContactos();
		}
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    reload() {
        this.getContactos();
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	openModal(idModal) {
		this.setState({saveMessage: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(inquilinos) {
		inquilinos = inquilinos.filter(inq => {
			if(inq.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if(inq.telefono.toLowerCase().indexOf(this.state.filter) !== -1){
				return true;
			} else if(inq.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return inquilinos;
	}

	async completeForm(item) {
		this.setState({ INombre: item.label });
		if(item.value > 0) {
			const res = await request.get('/inquilinos/getbypersona', {id_persona: item.value, id_fraccionamiento: localStorage.getItem('frac')});
			if(res.inquilino) {
				const phone = parsePhoneNumber(`+${res.inquilino.telefono}`);
				console.log(item);
				this.setState({
					IIdPersona: item.value,
					iTelefono: phone.number,
					IDireccion: res.inquilino.direccion || '',
					email: res.inquilino.email || '',
					IId: res.inquilino.tipo_identificacion,
					iNumId: res.inquilino.num_identificacion,
					IGenero: res.inquilino.genero,
					IDireccion: res.inquilino.direccion,
					IMunicipio: res.inquilino.municipio,
					ICp: res.inquilino.cp,
					IEstado: res.inquilino.estado,
					IPais: res.inquilino.pais
				});
			}
		}
	}

	closeModal() {
		this.setState({
			IidViv: 0,
			IIdPersona: 0,
			INombre: '',
			iTelefono: '',
			IDireccion: '',
			email: '',
			IId: 0,
			iNumId: '',
			IGenero: 0,
			IDireccion: '',
			IMunicipio: '',
			ICp: '',
			IEstado: '',
			IPais: '',
			saveMessage: ''
		});
	}

    render() {
		let contactos = [];
		let dataSet;
		if(Array.isArray(this.state.contactos)) {
			contactos = this.state.contactos;
			dataSet = contactos;
			contactos = this.filter(contactos)
		}
        return (
            <div className="owners column">
                <Helmet>
                    <title>iGate - Inquilinos</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} sidebar={true} active={'inquilinos'} parent={'admin'} panel={'panel1'} nav={"catalogos"} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
								<Controls title="Contactos"
									nuevo={this.openModal.bind(this, 'modal-contacto')}
									dataSet={dataSet}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}/>
                                <div className="white-space-16"></div>
								{this.state.loadingContactos?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
                                	<ContactosTable contactos={contactos} page={this.state.page} reload={this.reload.bind(this)} />
                  	              }
							<div className="white-space-16"></div>
                                <Paginador pages={Math.ceil(this.state.contactos.length / 8)} setPage={this.setPage.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
				<DinamicModal 
					idModal					=	{'modal-contacto'}
					sizeModalContainer		=	{'big'}
					title					=	{'CONTACTOS ASOCIADOS A LA VIVIENDA'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.saveMessage}
					closeModal				=	{this.closeModal.bind(this)}
				>
					{this.nuevoForm()}
				</DinamicModal>
            </div>
        );
	}

	nuevoForm() {
		let list_personas = [];
		let viviendas = [];
		if(Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map(persona => { return {label: persona.nombre, value: persona.id_persona}});
		}
		if(Array.isArray(this.state.viviendas)) {
			viviendas = this.state.viviendas;
		}
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Unidad:</b></p>
						</div>
						<div className="column full">
							<select className="input" name="vivienda" value={this.state.IidViv} onChange={(event) => this.setState({IidViv: event.target.value})}>
								<option value="0">SELECCIONAR VIVIENDA</option>
								{viviendas.map(viv => 
									<option key={viv.id_vivienda} value={viv.id_vivienda}>
										{viv.numero_registro}
									</option>
								)}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<Autocomplete
								assignValue={this.completeForm.bind(this)}
								list={list_personas}>
								<input type="text" name="fullName" value={this.state.INombre}
									onChange={event => this.setState({ INombre: event.target.value })}
									className="input input-modals" placeholder="Nombre completo del inquilino"
									required
									maxLength="64" />
							</Autocomplete>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Telefono:	</b></p>
						</div>
						<div className="column full">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								country="MX"
								placeholder="Número de teléfono"
								value={this.state.iTelefono}
								onChange={ value => this.setState({ iTelefono: value, telefonoError: undefined }) }
								error={ this.state.telefonoError }
								className={'input-modals'}/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>  Género:	</b>
							</p>
						</div>
						<div className="column full">
							<select name="sex" id="" value={this.state.IGenero} required
								onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>  Correo:	</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="EmailEdit" value={this.state.email}
								onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder="Email" maxLength="64" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Identificación:	</b></p>
						</div>
						<div className="column full">
							<select name="id" id="idEddit" className="input input-modals" value={this.state.iId} onChange={event => this.setState({ iId: event.target.value })}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b> ID :</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="numIdEddit" value={this.state.iNumId}
								onChange={event => this.setState({ iNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="address" value={this.state.IDireccion}
								onChange={event => this.setState({ IDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" required maxLength="150" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="municipality" value={this.state.IMunicipio}
								onChange={event => this.setState({ IMunicipio: event.target.value })} className="input input-modals" placeholder='Municipio' required maxLength="80" />
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.ICp}
								onChange={event => this.setState({ ICp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' required maxLength="10" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="state" value={this.state.IEstado}
								onChange={event => this.setState({ IEstado: event.target.value })} className="input input-modals" placeholder='Estado' required maxLength="24" />
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.IPais}
								onChange={event => this.setState({ IPais: event.target.value })} name="country" className="input input-modals" placeholder='País' required maxLength="10" />
						</div>
					</div>
				</div>
			</div>
		)
	}

    componentDidMount() {
		this.getContactos();
		this.getPersonas();
		this.getViviendas();
	}

    async handleSubmit(event) {
        event.preventDefault();
		this.setState({loadingSave: true, saveMessage: ''});
		/**Validar Vivienda */
		if (!(this.state.IidViv > 0)) {
			this.setState({loadingSave: false});
            alert("Para continuar debes seleccionar una vivienda")
            return;
		}
		/**Validar Telefono */
		if(!isValidPhoneNumber(this.state.iTelefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}

		let data = {
			idVivienda: 		this.state.IidViv,
			Nombre: 			this.state.INombre,
			Direccion: 			this.state.IDireccion,
			Municipio: 			this.state.IMunicipio,
			Estado: 			this.state.IEstado,
			Cp: 				this.state.ICp,
			Telefono: 			this.state.iTelefono,
			Pais: 				this.state.IPais,
			Genero: 			this.state.IGenero,
			TipoId: 			this.state.IId,
			NumId: 				this.state.iNumId,
			Vigencia: 			this.state.iVigencia,
			Fechaingreso: 		this.state.IFechaIngreso,
			FechaSalida: 		this.state.IFechaSalida,
			IdPersona: 			this.state.IIdPersona,
			Code: 				this.state.code,
			email: 				this.state.email,
			idFraccionamiento: 	this.state.fraccionamiento
		}

		const response = await request.post("/viviendas/contacto/crear", data);
		const modal = document.getElementById("modal-contacto");
		if(response.created){
			if( modal){
				modal.classList.add('hide-dinamic-modal');
				modal.classList.remove('show-dinamic-modal');
			}
			this.getContactos();

		}else{
			this.setState({saveMessage: "NO SE PUDO CREAR CONTACTO"});
			if( modal){
				modal.classList.remove('hide-dinamic-modal');
				modal.classList.add('show-dinamic-modal');
			}
		}
		
		this.setState({loadingSave: false});
    }

    async getContactos() {
        this.setState({ contactos: [], loadingContactos: true });
        let IdSub = this.state.subfraccionamiento;
		const idFrac = localStorage.getItem('frac');

		let data = { IdSub, idFrac };

        const response = await request.post("/contactos/get", data)
        if (response && !response.error) {
            if (response.contactos && !response.empty) {
                this.setState({
                    contactos: response.contactos,
                    filterData: response.contactos
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false,
                    contactos: []
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
		}
		this.setState({ loadingContactos: false });
	}

	async getPersonas() {
		const response = await request.post("/personas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.personas)) {
			this.setState({personas: response.personas});
		} else {
			this.setState({personas: []});
		}
	}

	async getViviendas() {
		const response = await request.post("/viviendas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.viviendas)) {
			this.setState({viviendas: response.viviendas});
		} else {
			this.setState({viviendas: []});
		}
	}
}

export default Consumer(Contactos);