import React, { Component } from 'react';
import moment from "moment";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Request from "../../core/httpClient";
const request = new Request();

class PropietarioEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPersona: 		this.props.propietario.id_persona,
            INombre: 		this.props.propietario.nombre,
            IDireccion: 	this.props.propietario.direccion,
            IMunicipio: 	this.props.propietario.municipio,
            IEstado: 		this.props.propietario.estado,
            ICp: 			this.props.propietario.cp,
            ITelefono: 		this.props.propietario.telefono,
            IPais: 			this.props.propietario.pais,
            IGenero: 		this.props.propietario.genero,
            IId: 			this.props.propietario.tipo_identificacion,
            INumId: 		this.props.propietario.num_identificacion,
            IVigencia: 		this.props.propietario.vigencia_identificacion,
            IFechaIngreso: 	moment(this.props.propietario.fecha_entrada).format("YYYY-MM-DD"),
            code: 			this.props.propietario.code,
            Iviv: 			this.props.propietario.numero_registro,
            IIdViv: 		this.props.propietario.id_vivienda,
            email: 			this.props.propietario.email,
		}
		
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.propietario !== prevProps.propietario) {
			console.log(this.props.propietario);
			this.setState({ 
				idPersona: 		this.props.propietario.id_persona,
				INombre: 		this.props.propietario.nombre,
				IDireccion: 	this.props.propietario.direccion,
				IMunicipio: 	this.props.propietario.municipio,
				IEstado: 		this.props.propietario.estado,
				ICp: 			this.props.propietario.cp,
				ITelefono: 		this.props.propietario.telefono,
				IPais: 			this.props.propietario.pais,
				IGenero: 		this.props.propietario.genero,
				IId: 			this.props.propietario.tipo_identificacion,
				INumId: 		this.props.propietario.num_identificacion,
				IVigencia: 		this.props.propietario.vigencia_identificacion,
				pFechaCompra: 	this.props.propietario.fecha_compra,
				pFechaVenta: 	this.props.propietario.fecha_venta,
				code: 			this.props.propietario.code,
				Iviv: 			this.props.propietario.numero_registro,
				IIdViv: 		this.props.propietario.id_vivienda,
				email: 			this.props.propietario.email
			});
		}
	}

    render() {
        return (
            <div>
				<DinamicModal 
					idModal				=	{'editPropietario'}
					sizeModalContainer	=	{'big'}
					title				=	{'EDITAR PROPIETARIO'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
				>
					<div className="full row align-center">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b> Unidad:	</b></p>
								</div>
								<div className="column full">
									<input type="text" name="vivienda" disabled value={this.state.Iviv}
										onChange={event => this.setState({ Iviv: event.target.value })}
										className="input input-modals" placeholder="N° de vivienda" required maxLength="64" />
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b>  Nombre completo:	</b></p>
								</div>
								<div className="column full">
									<input type="text" name="fullName" value={this.state.INombre}
										onChange={event => this.setState({ INombre: event.target.value })} className="input input-modals" placeholder="Nombre completo del propietario" required maxLength="64" />
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-cente">
								<div className="column label-medium">
									<p><b>  Telefono:	</b></p>
								</div>
								<div className="column full">
								<PhoneInput
									displayInitialValueAsLocalNumber
									name="phoneNumber"
									country="MX"
									placeholder="Número de teléfono"
									value={this.state.ITelefono}
									onChange={ value => this.setState({ ITelefono: value, telefonoError: undefined }) }
									error={ this.state.telefonoError }
									className={'input-modals'}/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
										<b>Identificación:</b>
									</p>
								</div>
								<div className="column full">
									<select name="id" id="idEddit" className="input input-modals" value={this.state.IId} onChange={event => this.setState({ IId: event.target.value })}>
										<option value="0">INE</option>
										<option value="1">Pasaporte/Visa</option>
										<option value="2">Curp</option>
										<option value="3">Cartilla Militar</option>
									</select>
								</div>
								<div className="column label-duo-normal space">
									<p  className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>ID:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="numIdEddit" value={this.state.INumId}
											onChange={event => this.setState({ INumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" />
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
										<b>Correo :</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="fullName" value={this.state.email}
										onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder="Email" maxLength="64" />
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>Género:</b>
									</p>
								</div>
								<div className="column full">
									<select name="sex" id="" value={this.state.IGenero} required
										onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
										<option value="0">Hombre</option>
										<option value="1">Mujer</option>
										<option value="2">No especificado</option>
									</select>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Dirección:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="address" value={this.state.IDireccion}
										onChange={event => this.setState({ IDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" required maxLength="150" />
								</div>
							</div>

							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
										<b>Municipio:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="municipality" value={this.state.IMunicipio}
										onChange={event => this.setState({ IMunicipio: event.target.value })} className="input input-modals" placeholder='Municipio' required maxLength="80" />
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>C.P:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" value={this.state.ICp}
										onChange={event => this.setState({ ICp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' required maxLength="10" />
								</div>
							</div>

							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
										<b>Estado:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="state" value={this.state.IEstado}
										onChange={event => this.setState({ IEstado: event.target.value })} className="input input-modals" placeholder='Estado' required maxLength="24" />
								</div>
								<div className="column label-duo-normal space">
									<p className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>País:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" value={this.state.IPais}
										onChange={event => this.setState({ IPais: event.target.value })} name="country" className="input input-modals" placeholder='País' required maxLength="10" />
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Fecha de compra:</b>
									</p>
								</div>
								<div className="column full">
									<input type="date" value={this.state.pFechaCompra}
										onChange={event => this.setState({ pFechaCompra: event.target.value })} required className='input input-modals' id='fcompra' />
								</div>
								<div className="column label-duo-normal space">
									<p>
										<b>Fecha de venta:</b>
									</p>
								</div>
								<div className="column full">
									<input type="date" value={this.state.pFechaVenta}
										onChange={event => this.setState({ pFechaVenta: event.target.value })} className='input input-modals' id='fventa' />
								</div>
							</div>
						</div>
					</div>
				</DinamicModal>
            </div>
        );
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }

    async handleSubmit(event) {
		event.preventDefault();

		this.setState({
			loadingSave: true
		});

		const form 	= event.target;
		/**Validar Telefono */
		if(!isValidPhoneNumber(this.state.ITelefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}
        let data = {
            idVivienda: 		this.state.IIdViv,
            Nombre: 			this.state.INombre,
            Direccion: 			this.state.IDireccion,
            Municipio: 			this.state.IMunicipio,
            Estado: 			this.state.IEstado,
            cp: 				this.state.ICp,
            Telefono: 			this.state.ITelefono,
            Pais: 				this.state.IPais,
            Genero: 			this.state.IGenero,
            TipoId: 			this.state.IId,
            NumId: 				this.state.INumId,
            Vigencia: 			this.state.IVigencia,
			FechaCompra: 		this.state.pFechaCompra,
			FechaVenta: 		this.state.pFechaVenta,
            Code: 				this.state.code,
            IdPersona: 			this.state.idPersona,
			email: 				this.state.email,
			idFraccionamiento: 	localStorage.getItem('frac') || 0
        }

        const response = await request.post("/propietarios/update", data)
        //console.log('response: ', response);
       
		if (response.updated && !response.empty) {
			this.props.reload();
		} else {
			this.setState({
				empty: true,
				message: "NO SE PUDO ACTUALIZAR PROPIETARIO",
				loading: false
			});
		}
		this.setState({loadingSave: false});
    }
}

export default PropietarioEditarModal;