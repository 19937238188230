import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import ReactModal from "react-modal";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import NotificationTable from "../components/notications/notificationTable";
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";

const request = new Request();


class NotificacionDefinition extends Component {
    state = {
        showModal: false,
        notificaciones: [],
        /* Estados para el filtro generico : filterData y keys */
        filterData: [],
        keys: [],
        page: 1,
        notificacionestipe: [],
		tipe: "",
		filter: '',
        subfraccionamientoNombre: '',
		subfraccionamiento: localStorage.getItem('subfrac') || 0,
		subfraccionamientos: []
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getNotificaciones();
		}
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
	}

    /* funcion para el filtro generico */
    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        //

        if (param1 === "todo") {
            this.setState({ notificaciones: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ notificaciones: filterData });
        }

    }

    reload() {
        this.getNotificaciones()

        // this.getHouses();
        // this.getNombreSubFraccionamiento()
	}

    changeFrac() {
		this.setState({
			subfraccionamientos: [],
			idSub: 0,
        })
		this.getNotificaciones();
	}

    updated() {
        this.getNotificaciones();
	}

    subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevaDefinicion() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(notificaciones) {
		notificaciones = notificaciones.filter(not => {
			if(not.texto.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (not.tipo.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return notificaciones;
	}

    render() {
		let notificaciones = [];
		let dataSet;

        if (this.state.notificaciones) {
			notificaciones = this.state.notificaciones;
			dataSet= notificaciones;
			notificaciones = this.filter(notificaciones);
        }

        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Administración</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'definicion'}  parent={'notificaciones'} panel={'panel2'} nav={'administracion'} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="justify-center">
                                    <div className="column full">
                                        <div className="white-space-32"></div>
										<Controls title="Notificaciones"
											nuevo={this.openModal.bind(this,"addNotificationsDef")}
											dataSet={dataSet}
											newButton={true}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
                                            selectSub={false}
                                            />
                                        <div className="white-space-16" />
										{this.state.loading?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
                                        	<NotificationTable notificaciones={notificaciones} page={this.state.page} updated={this.updated.bind(this)} subfraccionamientos={this.state.subfraccionamientos}/>
										}
										<div className="white-space-16"></div>
                                        <Paginador pages={Math.ceil(notificaciones.length / 7)} setPage={this.setPage.bind(this)} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				<DinamicModal 
					idModal             =   {'addNotificationsDef'}
					sizeModalContainer  =   {'small'}
					title               =   {'NUEVA NOTIFICACIÓN'} 
					success             =   {this.handleSubmit.bind(this)} 
					showBtnSuccess      =   {true}
					btnTextSuccess      =   {"GUARDAR"}
					showBtnCancel       =   {true}
					btnTextCancel       =   {"CANCELAR"}
					closeModal          =   {this.resetValue.bind(this)}
					loadingSave         =   {this.state.loadingSave}
					message             =   {this.state.message}
				>
				{this.renderNotification()}
				</DinamicModal>
            </div>
        );
	}

	async resetValue() {
		document.getElementById('message').value = '';
		this.setState({tipe: ''});
	}
	
	renderNotification() {
		return (
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-big">
						<p><b>  Tipo de notificación:	</b></p>
					</div>
					<select name="tipo" id="notification_type" className="input input-select-type" value={this.state.tipe} onChange={event => this.setState({ tipe: event.target.value })}>
						{this.state.notificacionestipe.map((value, key) => (
							<option key={key} value={value.Descripcion}>{value.Descripcion}</option>
						))}
					</select>
				</div>
            
				<div className="white-space-16"></div>
				<p>
					<b>Mensaje:</b>
				</p>
				<div className="white-space-4" />
				<textarea className="textarea" name="descriptionMessage" placeholder="Mensaje a enviar (600 caracteres máximo)" cols="50" rows="8" maxLength="600" id="message" required></textarea>
		
			</div>
		</div>
		)
	}

	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}


    handleOpen() {
        this.setState({ showModal: !this.state.showModal })
        this.getTipeNotification()
	}

    async getTipeNotification() {
        this.setState({ notificacionestipe: [] })
        let data = {
            id: localStorage.getItem('frac'),
            subfrac: localStorage.getItem('subfrac')
        }
        const response = await request.post("/notificaciones/get/tipe", data);

        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({
                    notificacionestipe: response.notifications
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false,
                    showModal: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false,
                showModal: false
            });
        }
    }

    async handleSubmit(event) {
		this.setState({loadingSave: true});
        event.preventDefault();
        const form = event.target;

        let data = {
            texto:              form.descriptionMessage.value,
            tipo:               form.notification_type.value,
			idFraccionamiento:  localStorage.getItem('frac'),
			idSub:              this.state.subfraccionamiento
        }

        const response = await request.post("/notificaciones/create/new/notification", data)
        
        if (response.created) {
            this.setState({
                reload: true,
                showModal: false
            });
            this.setState({loadingSave: false});
            form.descriptionMessage.value = '';
            form.notification_type.value = '';
            this.getNotificaciones();
            this.closeModal('addNotificationsDef');
        } else {
            this.setState({
                empty:      true,
                message:    "NO SE PUDO CREAR NOTIFICACIÓN",
                showModal:  false
            });
        }
        this.setState({loadingSave: false});
		
	}
	
	closeModal(idModal) {

		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

    componentDidMount() {
		this.getTipeNotification();
		this.getNotificaciones();
		this.getSubFraccionamientos();
    }

    async getNotificaciones() {
        this.setState({loading: true});
        let data = {
			idFraccionamiento: localStorage.getItem('frac'),
			idSub: this.state.subfraccionamiento
        }

        const response = await request.post("/notificaciones/get/all/notifications", data)
        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({
                    notificaciones: response.notifications,
                    filterData: response.notifications,
                    keys: Object.keys(response.notifications[0]).filter(key => key.indexOf('id') === -1),
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false,
                    showModal: false,
                    notificaciones: [],
                    filterData: []
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false,
                showModal: false
            });
        }
		this.setState({loading: false});
	}
	
	// traer subfraccionamientos
    async getSubFraccionamientos() {
		this.setState({loadingSub: true});
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
        if (response && !response.error) {
            if (response.subfraccionamiento && !response.empty) {
                this.setState({
                    subfraccionamientos: response.subfraccionamiento,
                    filterData: response.subfraccionamiento
				});
				console.log("SUUUB",response.subfraccionamiento );
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    subfraccionamientos: []
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
		}
		this.setState({loadingSub: false});
    }
}

export default NotificacionDefinition;