import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
const request = new Request();

class PersonalDomesticoTableEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            viv: '',
            tipo: '',
			acceso: '',
			id_frecuente: 0,
            horarios: {dia: [], mañana:[], tarde: []},
			idfrac: localStorage.getItem('frac') || 0
		}
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}
	onlyOneMonday(checkbox) {
		var checkboxes = document.getElementsByName("lunes")
		checkboxes.forEach((item) => {
			if (item !== checkbox) item.checked = true;
		})
	}

	uncheck(id) {
		let check = document.getElementById(id);
		if(check.value === "0") {
			check.checked = true;
			check.value = "1";
			alert("0");
		}
		if(check.value === "1") {
			check.checked = false;
			check.value = "0";
			alert("1");
		}
		else {
			check.checked = true;
			check.value = "0";
			alert("2");
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.invitado !== prevProps.invitado) {
			if(this.props.invitado.horarios) {
				this.getHorarios(JSON.parse(this.props.invitado.horarios));
			} else {
				this.getHorarios({dia: [], mañana:[], tarde: []});
			}
			this.setState({
				nombre: 		this.props.invitado.invitado,
				telefono: 		this.props.invitado.telefono,
				viv: 			this.props.invitado.numero_registro,
				tipo: 			this.props.invitado.tipo,
				acceso: 		this.props.invitado.acceso,
				id_frecuente: 	this.props.invitado.id_frecuente,
				horarios: 		JSON.parse(this.props.invitado.horarios)
			});
		}
	}

	getHorarios(horarios) {
		console.log('PERSONAL DOMESTICO', horarios);
		const {dia, mañana, tarde} 	= horarios;
		const lunes 				= document.getElementById('horario_lunes');
		const martes 				= document.getElementById('horario_martes');
		const miercoles 			= document.getElementById('horario_miercoles');
		const jueves 				= document.getElementById('horario_jueves');
		const viernes 				= document.getElementById('horario_viernes');
		const sabado 				= document.getElementById('horario_sabado');
		const domingo 				= document.getElementById('horario_domingo');
		if(lunes) {
			const value = dia[0]? 'dia': mañana[0]? 'mañana': tarde[0]? 'tarde': '';
			lunes.value = value;
		}
		if(martes) {
			const value = dia[1]? 'dia': mañana[1]? 'mañana': tarde[1]? 'tarde': '';
			martes.value = value;
		}
		if(miercoles) {
			const value = dia[2]? 'dia': mañana[2]? 'mañana': tarde[2]? 'tarde': '';
			miercoles.value = value;
		}
		if(jueves) {
			const value = dia[3]? 'dia': mañana[3]? 'mañana': tarde[3]? 'tarde': '';
			jueves.value = value;
		}
		if(viernes) {
			const value = dia[4]? 'dia': mañana[4]? 'mañana': tarde[4]? 'tarde': '';
			viernes.value = value;
		}
		if(sabado) {
			const value = dia[5]? 'dia': mañana[5]? 'mañana': tarde[5]? 'tarde': '';
			sabado.value = value;
		}
		if(domingo) {
			const value = dia[6]? 'dia': mañana[6]? 'mañana': tarde[6]? 'tarde': '';
			domingo.value = value;
		}

	}

    render() {
        return (
            <div>
				<DinamicModal 
					idModal					=	{'editDomestico'}
					sizeModalContainer		=	{'small'}
					title					=	{'EDITAR PERSONAL DOMESTICO'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"ACTUALIZAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.message}
					>
					{this.rendereditDomestico()}
					</DinamicModal>
            </div>
        );
	}
	
	rendereditDomestico() {
		return(
            <div className="full row align-center">
				<div className="column full">
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
						<p><b>  Nombre :	</b></p>
						</div>
						<div className="column full">
						<input type="text" name="nombre" value={this.state.nombre} enable="true"
							onChange={event => this.setState({ nombre: event.target.value })} className="input input-modals" placeholder="Nombre" required maxLength="64"/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center"> 
						<div className="column label-duo-big">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="column full">
								<input type="text" name="phone" value={this.state.telefono}
									onChange={event => this.setState({ telefono: event.target.value })} className="input input-modals" placeholder='Número Telefono' required maxLength="24" disabled/>
						</div>

						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
							<b>Acceso:</b>
							</p>
						</div>
						<div className="column full">
						    <select name="acceso" id="acceso" className="input input-modals" value={this.state.acceso} enable="true"
								onChange={event => this.setState({ acceso: event.target.value })}>
                                    <option value="PENDIENTE">PENDIENTE</option>
                                    <option value="CONFIRMADO">CONFIRMADO</option>
							</select>
						</div>
					</div>
                	<div className="white-space-8"/>
                	<div className="input-form-content row full align-center"> 
						<div className="column label-duo-big">
							<p><b>  Tipo:	</b></p>
						</div>
						<div className="column full">
                        <input type="text" name="tipo" value={this.state.tipo === 2 && 'Personal Domestico'}
							onChange={event => this.setState({ tipo: event.target.value })} className="input input-modals" placeholder='Tipo' required maxLength="24" disabled/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>Unidad:</b>
							</p>
						</div>
				  		<div className="column full">
                  		<div className="column full">
							<input type="text" name="vivienda" value={this.state.viv}
								onChange={event => this.setState({ viv: event.target.value })} className="input input-modals" placeholder='Número Vivienda' required maxLength="24" disabled/>
				  		</div>
				  	</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center"> 
						<div className="column label-duo-big">
							<p><b> Lunes: </b></p>
						</div>
						<div className="column full">
							<select className="input" id="horario_lunes">
								<option value=""></option>
								<option value="dia">DIA</option>
								<option value="mañana">MAÑANA</option>
								<option value="tarde">TARDE</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>Martes:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input" id="horario_martes">
								<option value=""></option>
								<option value="dia">DIA</option>
								<option value="mañana">MAÑANA</option>
								<option value="tarde">TARDE</option>
							</select>
						</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center"> 
						<div className="column label-duo-big">
							<p><b> Miercoles:  </b></p>
						</div>
						<div className="column full">
							<select className="input" id="horario_miercoles">
								<option value=""></option>
								<option value="dia">DIA</option>
								<option value="mañana">MAÑANA</option>
								<option value="tarde">TARDE</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>Jueves: </b>
							</p>
						</div>
						<div className="column full">
							<select className="input" id="horario_jueves">
								<option value=""></option>
								<option value="dia">DIA</option>
								<option value="mañana">MAÑANA</option>
								<option value="tarde">TARDE</option>
							</select>
						</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center"> 
						<div className="column label-duo-big">
							<p><b> Viernes:   </b></p>
						</div>
						<div className="column full">
							<select className="input" id="horario_viernes">
								<option value=""></option>
								<option value="dia">DIA</option>
								<option value="mañana">MAÑANA</option>
								<option value="tarde">TARDE</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{  paddingLeft: '20px' }}>
								<b> Sábado:  </b>
							</p>
						</div>
						<div className="column full">
							<select className="input" id="horario_sabado">
								<option value=""></option>
								<option value="dia">DIA</option>
								<option value="mañana">MAÑANA</option>
								<option value="tarde">TARDE</option>
							</select>
						</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center"> 
						<div className="column label-duo-big">
							<p><b> Domingo:  </b></p>
						</div>
						<div className="column full">
							<select className="input" id="horario_domingo">
								<option value=""></option>
								<option value="dia">DIA</option>
								<option value="mañana">MAÑANA</option>
								<option value="tarde">TARDE</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>  </b>
							</p>
						</div>
						<div className="column full">
							
						</div>
				</div>
			
                {/*<div className="table-responsiv column">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="justify-center">Todo el Día</th>
                                <th className=""></th>
                                <th className=""></th>
                            </tr>
                        </thead>
                        <tbody>
							<tr className="row justify-center" id="edit_dia">
								<td>L <input type="checkbox" name="lunes" defaultChecked={diaArray[0]}></input></td>
								<td>M <input type="checkbox" name="martes" defaultChecked={diaArray[1]}></input></td>
								<td>M <input type="checkbox" name="miercoles" defaultChecked={diaArray[2]}></input></td>
								<td>J <input type="checkbox" name="jueves" defaultChecked={diaArray[3]}></input></td>
								<td>V <input type="checkbox" name="viernes" defaultChecked={diaArray[4]}></input></td>
								<td>S <input type="checkbox" name="dia" defaultChecked={diaArray[5]}></input></td>
								<td>D <input type="checkbox" name="dia" defaultChecked={diaArray[6]}></input></td>
							</tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsiv column">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="justify-center">Mañana</th>
                                <th className=""></th>
                                <th className=""></th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr className="row justify-center" id="edit_mañana">
									<td>L <input type="checkbox" name="lunes" defaultChecked={mañanaArray[0]}></input></td>
									<td>M <input type="checkbox" name="martes"  defaultChecked={mañanaArray[1]}></input></td>
									<td>M <input type="checkbox" name="miercoles" defaultChecked={mañanaArray[2]}></input></td>
									<td>J <input type="checkbox" name="jueves"  defaultChecked={mañanaArray[3]}></input></td>
									<td>V <input type="checkbox" name="viernes"  defaultChecked={mañanaArray[4]}></input></td>
									<td>S <input type="checkbox" name="mañana" defaultChecked={mañanaArray[5]}></input></td>
									<td>D <input type="checkbox" name="mañana" defaultChecked={mañanaArray[6]}></input></td>
                                </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsiv column">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="justify-center">Tarde</th>
                                <th className=""></th>
                                <th className=""></th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr className="row justify-center" id="edit_tarde">
									<td>L <input type="checkbox" name="lunes" defaultChecked={tardeArray[0]}></input></td>
									<td>M <input type="checkbox" name="martes" defaultChecked={tardeArray[1]}></input></td>
									<td>M <input type="checkbox" name="miercoles" defaultChecked={tardeArray[2]}></input></td>
									<td>J <input type="checkbox" name="jueves" defaultChecked={tardeArray[3]}></input></td>
									<td>V <input type="checkbox" name="viernes"  defaultChecked={tardeArray[4]}></input></td>
									<td>S <input type="checkbox" name="tarde" defaultChecked={tardeArray[5]}></input></td>
									<td>D <input type="checkbox" name="tarde" defaultChecked={tardeArray[6]}></input></td>
                                </tr>
                        </tbody>
                    </table>
                </div>*/}
			</div>
		</div>
		)
	}

	setHorarios(option, date, dia, mañana, tarde) {
		switch(option) {
			case 'dia':
				dia[date] = true;
				break;
			case 'mañana':
				mañana[date] = true;
				break;
			case 'tarde':
				tarde[date] = true;
				break;
			default: break;
		}
	}

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({loadingSave: true});

		/*const divHorariosDia = document.getElementById('edit_dia');
		const diaCheckBox = divHorariosDia.querySelectorAll("input[type='radio']");
		const dia = [];
		diaCheckBox.forEach((input, index) => {
			dia[index] = input.checked;
		});
		const divHorariosMañana = document.getElementById('edit_mañana');
		const mañanaCheckBox = divHorariosMañana.querySelectorAll("input[type='radio']");
		const mañana = [];
		mañanaCheckBox.forEach((input, index) => {
			mañana[index] = input.checked;
		})
		const divHorariosTarde = document.getElementById('edit_tarde');
		const tardeCheckBox = divHorariosTarde.querySelectorAll("input[type='radio']");
		const tarde = [];*/
		const dia 			= [false, false, false, false, false, false, false];
		const mañana 		= [false, false, false, false, false, false, false];
		const tarde 		= [false, false, false, false, false, false, false];
		const lunes 		= document.getElementById('horario_lunes');
		const martes 		= document.getElementById('horario_martes');
		const miercoles 	= document.getElementById('horario_miercoles');
		const jueves 		= document.getElementById('horario_jueves');
		const viernes 		= document.getElementById('horario_viernes');
		const sabado 		= document.getElementById('horario_sabado');
		const domingo 		= document.getElementById('horario_domingo');
		if(lunes) {
			const value = lunes.value;
			this.setHorarios(value, 0, dia, mañana, tarde);
		}
		if(martes) {
			const value = martes.value;
			this.setHorarios(value, 1, dia, mañana, tarde);
		}
		if(miercoles) {
			const value = miercoles.value;
			this.setHorarios(value, 2, dia, mañana, tarde);
		}
		if(jueves) {
			const value = jueves.value;
			this.setHorarios(value, 3, dia, mañana, tarde);
		}
		if(viernes) {
			const value = viernes.value;
			this.setHorarios(value, 4, dia, mañana, tarde);
		}
		if(sabado) {
			const value = sabado.value;
			this.setHorarios(value, 5, dia, mañana, tarde);
		}
		if(domingo) {
			const value = domingo.value;
			this.setHorarios(value, 6, dia, mañana, tarde);
		}

        let data = {
            acceso: 		this.state.acceso,
			idfrac: 		this.state.idfrac,
			id_frecuente: 	this.state.id_frecuente,
			horario: 		JSON.stringify({dia, mañana, tarde}),
			nombre: 		this.state.nombre
        }
		const response = await request.post("/invitadosFrecuentes/invitados/update", data);

       
		if (response.updated && !response.empty) {
			
			this.closeModal("editDomestico");
			this.props.reload();
		} else {
			// this.setState({
			// 	updated: 	response.updated,
			// 	acceso: 	response.updated.acceso,
			// 	idViv: 		response.updated.numero_registro,
			// 	nombre: 	response.updated.nombre,
			// 	tipo: 		response.updated.tipo
			// });
			this.setState({message: "NO SE PUDO EDITAR PERSONAL DOMÉSTICO"});
		}
        
        this.setState({loadingSave: false});
	}
}
export default PersonalDomesticoTableEdit;