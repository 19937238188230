/**
 *  houses-table.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de Viviendas
 */

import React, { Component } from "react";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
import moment from "moment";
import PropietarioModal from './PropietarioModal';
import InquilinoModal from './inquilinoModal';
import ContactoModal from './contactoModal';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import HouseForm from './HouseForm';

const request = new Request();

class HousesTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fraccionamiento: '',
			direccion: '',
			Nregistro: '',
			mts: '',
			pIndiviso: '',
			vacacional: '',
			monto: '',
			comentarios: '',
			subfraccionamientos: this.props.subfraccionamientos,
			message: null,
		};
		this.handleDateChange = this.handleDateChange.bind(this);
	}

	componentDidMount() {
		let data = window.localStorage.getItem('openRow');
		if (data) {
			data = JSON.parse(data);
			this.setState({ viviendaId: data.idVivienda, rowType: data.type });
		}
	}

	openModal(idModal, idVivienda, vivienda) {
		console.log(vivienda);
		this.setState({formMessage: null, viviendaId: idVivienda, vivienda: vivienda});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
			this.getDetalleVivienda(idVivienda);
		}
	}

	openRow(type, idVivienda) {
		const data = { type, idVivienda };
		window.localStorage.setItem('openRow', JSON.stringify(data));
		if(this.state.viviendaId === idVivienda && type === this.state.rowType) {
			window.localStorage.removeItem('openRow');
			this.setState({rowType: '', viviendaId: 0});
			return;
		}
		this.setState({rowType:type, viviendaId: idVivienda});
	}

	setInfo(state) {
		this.setState({
			fraccionamiento: state.fraccionamiento,
			direccion: state.direccion,
			Nregistro: state.Nregistro,
			mts: state.mts,
			pIndiviso: state.pIndiviso,
			vacacional: state.vacacional,
			monto: state.monto,
			comentarios: state.monto
		});
	}

	render() {
		let houses = [];

		if (this.props.dataHouses) {
			houses = this.props.dataHouses;
		}

		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="table-responsiv column">
					<table>
						<thead>
							<tr className="text-left">
								<th className="">Unidad</th>
								<th className="description">Dirección</th>
								<th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Propietarios</th>
								<th className="text-center th-options-small">Inquilinos</th>
								<th className="text-center th-options-small">Contactos</th>
							</tr>
						</thead>
						<tbody>
							{houses.slice(((this.props.page - 1) * 7), (this.props.page * 7)).map((house, key) => {
								return (
									<React.Fragment key={house.id_vivienda}>
										<tr className={this.state.viviendaId === house.id_vivienda? 'row-active' : ''}>
											<td className="text-left">{house.numero_registro}</td>
											<td>{house.direccion}</td>
											<td className="text-center">
												<button type="button" className="btn-full justify-center btn-mini btn-secondary color-white" onClick={this.openModal.bind(this,"editHouse", house.id_vivienda, house)}>
													<i className="font-small fas fa-pen" />
												</button>
											</td>
											<td className="text-center">
												<button className="btn-full btn-mini btn-secondary color-white tooltip" onClick={this.openRow.bind(this, "propietarios", house.id_vivienda)}>
													&nbsp; <i className="font-small fas fa-user-shield" />
													<span className="tooltiptext">{house.total_propietarios}</span>
												</button>	
											</td>
											<td className="text-center">
												<button className="btn-full btn-mini btn-secondary color-white tooltip" onClick={this.openRow.bind(this, "inquilinos", house.id_vivienda)} >
													<i className="font-small fas fa-users" />
													<span className="tooltiptext">{house.total_inquilinos}</span>
												</button>
											</td>
											<td className="text-center">
												<button className="btn-full btn-mini btn-secondary color-white tooltip" onClick={this.openRow.bind(this, "contactos", house.id_vivienda)} >
													<i className="font-small fas fa-address-book" />
													<span className="tooltiptext">{house.total_contactos}</span>
												</button>
											</td>
										</tr>
										{this.state.viviendaId === house.id_vivienda && this.state.rowType === 'propietarios' &&
											<tr>
												<td colSpan="6">
													<div className="row full">
														<PropietarioModal reload={this.props.reload} id={this.state.viviendaId} editVivienda={false}></PropietarioModal>
													</div>
												</td>
											</tr>
										}
										{this.state.viviendaId === house.id_vivienda && this.state.rowType === 'inquilinos' &&
											<tr>
												<td colSpan="6">
													<div className="row full">
														<InquilinoModal reload={this.props.reload} id={this.state.viviendaId} editVivienda={false}></InquilinoModal>
													</div>
												</td>
											</tr>
										}
										{this.state.viviendaId === house.id_vivienda && this.state.rowType === 'contactos' &&
											<tr>
												<td colSpan="6">
													<div className="row full">
														<ContactoModal reload={this.props.reload} id={this.state.viviendaId} editVivienda={false}></ContactoModal>
													</div>
												</td>
											</tr>
										}
									</React.Fragment>
								)
							})}
						</tbody>
					</table>
				</div>
				<DinamicModal 
					idModal={'editHouse'}
					sizeModalContainer={'big'}
					title={'DATOS DE LA VIVIENDA'} 
					success={this.handledSubmit.bind(this)} 
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.formMessage}
				>
					<HouseForm setInfo={this.setInfo.bind(this)} vivienda={this.state.vivienda} subfraccionamientos={this.props.subfraccionamientos}/>
				</DinamicModal>

			</div>
		);
	}

	// ENVIA LOS DATOS DEL FORMULARIO
	async handledSubmit(event) {
		this.setState({ loadingSave: true });
		event.preventDefault();
		const form = event.target;
	
		let vacacional = this.state.vacacional;
		let pro = this.state.pIndiviso;

		if (pro > 100) {
			this.setState({ valida: true,loadingSave: false});
			alert("ProIndiviso debe de ser menor o igual a 100");
			return;
		} 

		if (vacacional === true) {
			vacacional = 1

		} else {
			vacacional = 0
		}
		let data = {
			IdVivienda: this.state.IdViv,
			Direccion: form.houseAddress.value,
			Numero_Registro: form.houseNumber.value,
			UPrivativa: this.state.uPrivativa,
			mts: parseInt(this.state.mts),
			proIn: parseInt(this.state.pIndiviso),
			vacation: this.state.vacacional,
			monto: form.monto.value,
			comentarios: form.comentarios.value
		};
		const response = await request.post("/viviendas/vivienda/update", data);
		if(response.updated){
			this.setState({formMessage: 'DATOS DE VIVIENDA ACTUALIZADOS CORRECTAMENTE'});
			this.props.reload();
		}else{
			this.setState({formMessage: "LOS DATOS DE VIVIENDA NO SE PUDIERON ACTUALIZAR CORRECTAMENTE"});
		}
		this.setState({loadingSave: false});
	}

	//  EDITAR LOS DATOS DEL PROPIETARIO
	async editarPropietarios(idPropietario, id_vivienda) {

		let nombreProp = document.getElementById("fullNameEdit").value,
			direccionProp = document.getElementById("addressEdit").value,
			municipioProp = document.getElementById("municipalityEdit").value,
			estadoProp = document.getElementById("stateEdit").value


		if (nombreProp.length === 0 || direccionProp.length === 0 || municipioProp.length === 0 || estadoProp.length === 0) {
			return;

		}
		else {
			let data = {
				IdPropietario: idPropietario,
				Nombre: document.getElementById("fullNameEdit").value,
				Direccion: document.getElementById("addressEdit").value,
				Municipio: document.getElementById("municipalityEdit").value,
				Cp: document.getElementById("zipEDit").value,
				Estado: document.getElementById("stateEdit").value,
				Telefono: document.getElementById("phoneEdit").value
			};
			const response = await request.post("/propietarios/update", data);

			if (response && !response.error) {
				if (response.updated && !response.empty) {
					this.setState({
						reload: true,
						loading: false,
						edditProp: false
					});

					this.getPropietariosVivienda(id_vivienda);
				} else {
					this.setState({
						empty: true,
						message: response.message,
						loading: false
					});
				}
			} else {
				this.setState({
					error: true,
					message: response.message,
					loading: false
				});
			}
		}
	}
	handleOpen(idVivienda) {
		this.setState({ showModal: true });

		this.getDetalleVivienda(idVivienda);
		// this.getInquilinoVivienda(idVivienda);
		// this.getPropietariosVivienda(idVivienda);
	}
	// MUESTRA LOS DATOS DE UN UNICO INQUILINO EN FORMULARIO
	async handleInqDetalle(id_inquilino) {
		this.setState({ iNombre: "" });
		let data = { idInquilino: id_inquilino };
		const response = await request.post("/viviendas/vivienda/get/inquilino", data);

		if (response && !response.error) {
			if (response.inquilino && !response.empty) {
				this.setState({
					iNombre: response.inquilino[0].nombre,
					iTelefono: response.inquilino[0].telefono,
					ifechaNac: response.inquilino[0].fecha_nacimiento,
					isexo: response.inquilino[0].sexo,
					ipassword: response.inquilino[0].password,
					inquilinoId: response.inquilino[0].id_inquilino
				});
			} else {
				this.setState({ empty: true, message: response.message });
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
	}


	handleClose() {
		this.setState({ showModal: false, edit: false, inquilinos: [] });
	}

	handleEdit() {
		this.setState({ edit: !this.state.edit });
	}

	handleAdd() {
		this.setState({ add: !this.state.add, edditInq: false });
	}

	// MUESTRA EL FORMULARIO DE EDITAR INQUILINO Y CIERRA EL DE GUARDAR INQUILINO
	handleEditInq(id_inquilino) {
		if (id_inquilino) {
			this.setState({ edditInq: !this.state.edditInq, add: false });
			this.handleInqDetalle(id_inquilino);
		} else {
			this.setState({ edditInq: !this.state.edditInq, add: false });
		}
	}
	// MUESTRA EL FORMULARIO DE EDITAR PROPIETARIO Y CIERRA EL DE GUARDAR INQUILINO
	handleEditProp(idPropietario) {
		if (idPropietario) {
			this.setState({ edditProp: !this.state.edditProp, add: false });
			this.handlePropietarioDetalle(idPropietario);
		} else {
			this.setState({ edditProp: !this.state.edditProp, add: false });
		}

	}

	handleDateChange(date, event) {
		this.setState({ startDate: date, ifechaNac: event.target.value });
	}

	// TRAE LOS DATOS DE LA VIVIENDA
	async getDetalleVivienda(idVivienda) {
		this.setState({
			Nregistro: "",
			direccion: "",
			IdViv: 0,
			mts: 0,
			uPrivativa: '',
			pIndiviso: 0,
			vacacional: 0,
			loadingDetalle: true
		});
		let data = { idVivienda: idVivienda };

		const response = await request.post("/propietarios/get/propietario", data);
		//console.log(response);
		if (response && !response.error) {
			if (response.propietario && !response.empty) {
				this.setState({
					Nregistro: response.propietario[0].numero_registro,
					direccion: response.propietario[0].direccion,
					IdViv: response.propietario[0].id_vivienda,
					subfrac: response.propietario[0].nombre,
					mts: response.propietario[0].metros_cuadrados,
					uPrivativa: response.propietario[0].unidad_privativa,
					pIndiviso: response.propietario[0].pro_indiviso,
					vacacional: response.propietario[0].vacacional
				});
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.setState({ loadingDetalle: false });
	}
}

export default Consumer(HousesTable);
