import React, { Component } from 'react';
import ReactModal from 'react-modal'
import moment from 'moment-timezone'
import { Consumer } from "../../context";
import SearchHouse from "../propietarios/search-house";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
const request = new Request();
class ReservationEddit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {
				idUsuario: 				0,
				idPerfil: 				0,
				idFraccionamiento:		0
			},
			showModal: 					false,
			inicial: 					this.props.reserva.id_espacio,
			horarios: 					[],
			horario: 					this.props.reserva.IdHorario,
			pidViv: 					this.props.reserva.id_vivienda,
			viv: 						this.props.reserva.numero_registro,
			fecha: 						moment(this.props.reserva.fecha_reserva).tz('GMT').format("YYYY-MM-DD"),
			info: 						[],
			fianza: 					this.props.reserva.fianza_cobrada,
			comentarios: 				this.props.reserva.comentarios,
			nombreRecibido: 			this.props.reserva.nombre_recibido,
			nombreDevolucion: 			this.props.reserva.nombre_devolucion,
			id: 						this.props.reserva.id_reserva,
			fianzaDev: 					this.props.reserva.fianza_devolucion,
			fechaDev: 					this.props.reserva.fecha_devolucion
		}
	}

	vivienda = data => {
		this.setState({
			pviv: 						data.numero_registro,
			pidViv: 					data.id_vivienda,
			searchHouse: 				false

		});
	}
	componentDidMount() {
		this.getCommonArea();
		this.load();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.reserva !== prevProps.reserva) {
			this.setState({
				inicial: 			this.props.reserva.id_espacio,
				horario: 			this.props.reserva.IdHorario,
				pidViv: 			this.props.reserva.id_vivienda,
				viv: 				this.props.reserva.numero_registro,
				fecha: 				moment(this.props.reserva.fecha_reserva).tz('GMT').format("YYYY-MM-DD"),
				fianza: 			this.props.reserva.fianza_cobrada,
				comentarios: 		this.props.reserva.comentarios,
				nombreRecibido: 	this.props.reserva.nombre_recibido,
				nombreDevolucion: 	this.props.reserva.nombre_devolucion,
				id: 				this.props.reserva.id_reserva,
				fianzaDev: 			this.props.reserva.fianza_devolucion
			});
			this.getSchedule();
		}
		if(this.state.fecha !== prevState.fecha) {
			this.getSchedule();
		}
	}
	closeModal(idModal) {
		let modal = document.getElementById("editReservation");
		if(modal) {
			modal.classList.add('hide-modal');
			modal.classList.remove('show-modal');
		}
	}

	render() {
		return (
			<div>
				<DinamicModal
					idModal				=	{'editReservation'}
					sizeModalContainer	=	{'big'}
					title				=	{'EDITAR RESERVA'}
					success				=	{this.handleSubmit.bind(this)}
					closeModal			=	{this.resetData.bind(this)}
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"ACTUALIZAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}>

					<div className="full row align-center ">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Área a reservar:</b>
									</p>
								</div>
								<div className="column full">
									{/* event => this.setState({ inicial: event.target.value }) */}
									<select name="categoria" id="area" disabled className="input" value={this.state.inicial} onChange={this.handleChange.bind(this)} >
										{this.state.info.map((info, key) => (
											<option key={key} value={info.id_espacio}>{info.nombre}</option>
										))}

									</select>
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Fecha:</b>
									</p>
								</div>
								<div className="column full">
									<input type="date" name="" id="" className="input" value={this.state.fecha} onChange={this.cambioFecha.bind(this)} />
								</div>
								<div className="column label-duo-normal space">
									<p>
										<b>Horario:</b>
									</p>
								</div>
								<div className="column full">
									<select name="categoria" id="horario" className="input" value={this.state.horario} onChange={event => this.setState({ horario: event.target.value })} >
										{this.state.horarios.map((horario, key) => (
											<option key={key} value={horario.id_horario}>{horario.descripcion}</option>
										))}
									</select>
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b> Unidad:	</b></p>
								</div>
								<div className="column full">
									<input type="text" name="vivienda" disabled value={this.state.viv}
										onChange={event => this.setState({ pviv: event.target.value })}
										className="input input-modals" placeholder="N° de vivienda" required maxLength="64" />
								</div>
							</div>
							<div className="white-space-16"></div>

							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Depósito Fianza:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="fianza" value={this.state.fianza} disabled onChange={event => this.setState({ fianza: event.target.value })} className="input input-modals" placeholder="" required
										maxLength="150"
									/>
								</div>
								<div className="column label-duo-normal space">
									<p>
										<b>Recibido por:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" disabled className="input input-modals"
										value={this.state.nombreRecibido}
									/>
								</div>

							</div>
							<div className="white-space-16"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Devolución Fianza:</b>
									</p>
								</div>
								<div className="column full">
								{
									this.props.reserva.fianza_devolucion ?
										<input type="text" name="fianzaDev" disabled value={this.state.fianzaDev} onChange={event => this.setState({ fianzaDev: event.target.value })} className="input input-modals" placeholder="" required
											maxLength="150"
										/>
										
									 :
									<input type="text" name="fianzaDev" value={this.state.fianzaDev} onChange={event => this.setState({ fianzaDev: event.target.value })} className="input input-modals" placeholder="" 
										maxLength="150"
									/>


								}
								</div>
								<div className="column label-duo-normal space">
									<p>
										<b>Devuelto por:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" disabled className="input input-modals"
										value={this.state.nombreDevolucion ? this.state.nombreDevolucion :  this.state.user.nombre}
									/>
								</div>

							</div>
							<div className="white-space-16"></div>

							<div className="column label">
								<p><b> Comentarios:	</b></p>
							</div>
							<div className="align-center row full justify-between">

								<div className="column full">
									<textarea name="comentarios" id="" cols="30" value={this.state.comentarios} onChange={event => this.setState({ comentarios: event.target.value })} rows="5" className='textarea full'></textarea>
								</div>

							</div>
						</div>
					</div>
				</DinamicModal>
			</div>
		);
	}
	handleOpen() {
		this.setState({ showModal: true })
		this.getCommonArea()
		this.load()

	}

	handleClose() {
		this.setState({ showModal: false })
	}

	handleChange(event) {
		this.setState({ inicial: event.target.value })
		this.getSchedule()
	}

	cambioFecha(event) {
        this.setState({ fecha: event.target.value })
    }

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					nombre: user.id.nombre
				}
			});
		}
	}

	async  getCommonArea() {
		let data = {
			id: localStorage.getItem('frac')
		}
		const response = await request.post("/commonArea/get/info", data)

		if (response && !response.error) {
			if (response.info && !response.empty) {

				this.setState({ info: response.info })
			}
		} else {
			this.setState({
				error: true,
				message: ""
			});
		}
		this.getSchedule()

	}

	async getSchedule() {
		this.setState({ horarios: [] });
		let data = {
			id: this.props.reserva.id_espacio,
			fecha: this.state.fecha
		}
		// console.log(data);
		const response = await request.post("/commonArea/get/available/schedule", data);

		if (response && !response.error) {
			if (response.commonSchedules && !response.empty) {
				this.setState({
					horarios: response.commonSchedules
				});
			} else {
				this.setState({
					message: ""
				});
			}
		} else {
			this.setState({
				error: true,
				message: ""
			});
		}
	}

	resetData = () => {
		this.setState({fianzaDev: ''});
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		let form = event.target

		let valida = this.state.pidViv
		
		if(this.state.fianzaDev !== null) {
			let fianzaCobrada = parseInt(this.state.fianza);
			let fianzaDevuelta = parseInt(this.state.fianzaDev);
			if(fianzaDevuelta > fianzaCobrada) {
				this.setState({loadingSave: false});
				alert('La cantidad devuelta debe ser igual o menor al depósito de la fianza');
				return;
			} 
		}
	
		const idHorario = parseInt(document.getElementById('horario').value);
		/*if(idHorario <= 0 || isNaN(idHorario)) {
			this.setState({loadingSave: false});
			alert('Debe seleccionar un horario válido');
            return
		}*/

		if(moment(this.state.fecha).diff(moment(), 'days') < 0) {
			this.setState({loadingSave: false});
			alert('La fecha no puede ser anterior a la actual');
            return
		}

		if (valida === 0) {
			this.setState({loadingSave: false});
			alert('Debes seleccionar una vivienda')
			return;
		} else {
			let data = {
				idHorario,
				fecha: 				this.state.fecha,
				fechaActualizacion: moment().format('YYYY-MM-DD'),
				comentarios: 		form.comentarios.value,
				idReserva: 			this.state.id,
				idUserDev: 			parseInt(this.state.user.idUsuario),
				fianzaDev: 			parseInt(this.state.fianzaDev),
				fechaDev: 			this.state.fechaDev ? this.state.fechaDev : this.state.fianzaDev ?  moment().format('YYYY-MM-DD') : '',

			}

			const response = await request.post("/commonArea/update/reservation", data);

			if (response.created) {
				this.setState({
					showModal: 	false,
					info: 		[],
					inicial: 	0,
					horarios: 	[],
					horario: 	0,
					pidViv: 	0,
					fianzaDev: 	'',
					pviv: 		'',
					fecha: 		''
				});
				this.closeModal("editReservation");
				this.props.reservas();
			} else {
				this.setState({
					message: "NO SE PUDO ACTUALIZAR RESERVACIÓN"
				});
			}
			

		}
		this.setState({loadingSave: false});
	}
}

export default Consumer(ReservationEddit);