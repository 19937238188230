import React, { Component } from 'react';
import ReactModal from "react-modal";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import moment from "moment";
import Request from "../../core/httpClient";
const request = new Request();
ReactModal.setAppElement("#root");

class IncidentsHistoryTable extends Component {
	constructor() {
		super();
		this.state = {
			showModal: 			false,
			incidentes: 		[],
			incidenteDetalle: 	[],
			resolucion: 		0,
			idQueja: 			0,
			Rdescription: 		"",
			filtroValor: 		''
		};
	}

	openModal(idModal, idHistory) {
		this.setState({ Rdescription: '' });
		this.getIncidenciaDetalle(idHistory)
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}
	render() {
		let incidentes = [];

		if (this.props.incidentes) {
			incidentes = this.props.incidentes;
		}

		return (
			<div className="column">
				<div className="card-table column" id="card-owners">

					<div className="table-responsiv">

						<table className="card-incidents full">
							<thead>
								<tr className="text-left">
									<th className="type">Tipo</th>
									<th className="name">Unidad</th>
									<th className="date">Fecha</th>
									<th className="description">Descripción</th>
									<th className="resolution ">Resolución</th>
									<th className="text-center th-options-small">Ver detalles</th>
								</tr>
							</thead>
							<tbody>
								{incidentes.slice(((this.props.page - 1) * 7), (this.props.page * 7)).map((incidente, key) => {
									return (

										<tr className="tr" key={key}>
											<td className="weight-semi">{incidente.quejaDesripcion}</td>
											<td className="description-detail">{incidente.numero_registro}</td>
											<td className="">
												{moment(incidente.fecha).format("DD-MM-YYYY")}
											</td>
											<td className="description text-justify">
												<p className="truncate-ellipsis">{incidente.descripcion}</p>
											</td>
											<td className="weight-semi">
											{incidente.resolucion === 0 ? ('Pendiente') : null || incidente.resolucion === 1 ? ('En revision') : null  || incidente.resolucion === 2 ? ('Resuelto') : null }
											</td>

											<td className="text-center weight-semi">
												<button
													className="btn-full justify-center align-center font-small btn-secondary color-white"
													onClick={this.openModal.bind(this, "detailIncidentsHistory", incidente.id_queja)}
												>
													<i class="fas fa-info-circle"></i>
												</button>
											</td>
										</tr>
									)
								}
								)
								}
							</tbody>
						</table>
					</div>

					<DinamicModal
						idModal				=	{'detailIncidentsHistory'}
						sizeModalContainer	=	{'big'}
						title				=	{'DETALLE COMPLETO DE LA INCIDENCIA'}
						showBtnSuccess		=	{false}
						showBtnCancel		=	{false}
						loadingSave			=	{this.state.loadingSave}
						message				=	{this.state.message}
					>
						{this.renderHistoryTable()}
					</DinamicModal>

				</div>
			</div>
		);
	}

	renderHistoryTable() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-extra">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.incidenteDetalle.nombre}
								onChange={event => this.setState({ pNombre: event.target.value })} className="input input-modals" placeholder="Nombre completo" required maxLength="64" disabled />
						</div>
					</div>
					<div className="white-space-16" />
					<div className="row full">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p><b>  Tipo de incidencia:	</b></p>
								</div>
								<div className="column full">
									<input type="text" name="numIdEddit" value={this.state.incidenteDetalle.quejaDesripcion}
										 className="input input-modals" placeholder='Tipo' required maxLength="24" disabled />
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p className="justify-start">
										<b>Fecha:</b>
									</p>
								</div>
								<div className="column full">
									<input type="date" name="numIdEddit" value={moment(this.state.incidenteDetalle.fecha).format(
										"YYYY-MM-DD"
									)}
										onChange={event => this.setState({ pNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" disabled />
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p><b>  Teléfono:	</b></p>
								</div>
								<div className="column full">
									<input type="text" name="numIdEddit" value={this.state.incidenteDetalle.telefono}
										onChange={event => this.setState({ pNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" disabled />
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p className="justify-start" >
										<b>Resolución:</b>
									</p>
								</div>
								<div className="column full">
									<select
										name="incident-resolution"
										id="incident-resolution"
										className="input input-modals"
										value={this.state.resolucion}
										onChange={event => this.setState({ resolucion: event.target.value })}
										disabled
									>
										<option value="0">Sin resolver</option>
										<option value="1">En revisión</option>
										<option value="2">Resuelto</option>
									</select>
								</div>
							</div>
						</div>
						<div className="column full">
							<div class="responsive-img justify-end row">
								<div className="container-img">
									<img src={this.state.incidenteDetalle.imagen ? this.state.incidenteDetalle.imagen : "https://adime.es/static/img/group_placeholder.png"} className="img-duo-preview" id="previewAreaEdit" />
								</div>

							</div>
						</div>
					</div>
				
					<div className="white-space-16" />
					<p>
						<b>Descripción de la resolución:</b>
					</p>
					<div className="white-space-4" />
					<textarea className="textarea full" value={this.state.Rdescription} disabled={true}
						onChange={event => this.setState({ Rdescription: event.target.value })} name="descriptionResolution" placeholder="Descripción de la resolución (600 caracteres máximo)" cols="50" rows="5" maxLength="600" minLength="20"></textarea>

				</div>
			</div>
		)
	}

	handleOpen(id) {
		//this.setState({ showModal: true })
		this.getIncidenciaDetalle(id);

	}
	handleClose() {
		this.setState({
			showModal: false,
			incidentes: [],
			incidenteDetalle: [],
			resolucion: 0,
			idQueja: 0,
			Rdescription: "",
			filtroValor: ''
		})

	}
	//   traer una unica incidencia
	async getIncidenciaDetalle(idQueja) {
		let data = {
			idQueja: idQueja
		};

		const response = await request.post("/incidencias/get/detalle", data);


		if (response && !response.error) {
			if (response.incidenciaDetalle && !response.empty) {
				this.setState({
					showModal: 			true,
					incidenteDetalle: 	response.incidenciaDetalle,
					resolucion:		  	response.incidenciaDetalle.resolucion,
					idQueja: 		  	response.incidenciaDetalle.id_queja,
					Rdescription: 		response.incidenciaDetalle.resolucion_descripcion
				});
				
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
	}

	openImage() {
		this.setState({ showImage: !this.state.showImage })
	}
}

export default IncidentsHistoryTable;