import React, { Component } from 'react';
import { Consumer } from "../../context";
import { Link } from "react-router-dom";
import Request from "../../core/httpClient";
const request = new Request();

class SidebarAdministracion extends Component {
    state = {
        menus: [],
        active: 0,
        activeSub: 0
    }

    render() {
        let menus = [];
        if (Array.isArray(this.state.menus)) {
            menus = this.state.menus;
        }

        return (
            <div className="sidebar row" >
                <div className="column content-side">
                    {menus.map((item, index) =>
                        item.recordset ?
                            <React.Fragment key={index}>
                                <button className={`accordion color-white ${this.state.active.toString() === item.id_menu.toString() ? 'active' : ''}`}
                                    onClick={this.parentClick.bind(this, item)}>
                                    {item.parent_name}
                                    <i className="fas fa-angle-down font-regular color-white"></i>
                                </button>
                                {item.recordset.map((list, ind) =>
                                    <div className={`panel ${item.open ? 'maxHeight' : ''}`} key={ind}>
                                        <div className={`row nav-item auto ${this.state.activeSub.toString() === list.id_menu.toString() ? 'active-item' : ''}`} onClick={this.childrenClick.bind(this, list)}>
                                            <Link
                                                to={list.ruta}
                                                className="font-regular color-white">
                                                <span className="color-white">{list.nombre}</span>
                                            </Link>
                                        </div>
                                    </div>)}
                            </React.Fragment> : null)}
                    {/*<button className="accordion color-white" id="emergencias">
                        Emergencias
                        <i className="fas fa-angle-down font-regular color-white"></i>
                    </button>
                    <div className="panel" id="panel1">
                        <div className="nav-item auto" id="historial">
                            <Link
                                to="/administracion/emergencias"
                                className="font-regular color-white">

                                <span className="color-white">Historial</span>
                            </Link>
                        </div>
                    </div>
                    <button className="accordion color-white" id="notificaciones">
                        Notificaciones
                    <i className="fas fa-angle-down font-regular color-white"></i>
                    </button>
                    <div className="panel" id="panel2">
                        <div className="nav-item auto" id="definicion">
                            <Link
                                to="/administracion/notificaciones/definicion"
                                className="font-regular color-white">
                                <span className="color-white">Definición</span>
                            </Link>
                        </div>
                        <div className="nav-item auto" id="historiald">
                            <Link
                                to="/administracion/notificaciones/historial"
                                className="font-regular color-white">
                                <span className="color-white">Historial</span>
                            </Link>
                        </div>
                    </div>
                    <button className="accordion color-white" id="areas">
                        Áreas comunes
                        <i className="fas fa-angle-down font-regular color-white"></i>
                    </button>
                    <div className="panel" id="panel3">
                        <div className="row nav-item auto" id="activas">
                            <Link
                                to="/administracion/areas/instalaciones"
                                className="font-regular color-white">
                                <span className="color-white" >  Instalaciones</span>
                            </Link>
                        </div>
                        <div className="row nav-item auto" id="reservas">
                            <Link
                                to="/administracion/areas/reservacion"
                                className="font-regular color-white">
                                <span className="color-white" >  Reservas</span>
                            </Link>
                        </div>
                        <div className="row nav-item auto" id="historiala">
                            <Link
                                to="/administracion/areas/historial"
                                className="font-regular color-white">
                                <span className="color-white">  Historial</span>
                            </Link>
                        </div>
                    </div>
                    <button className="accordion color-white" id="quejas">
                        Reportes
                        <i className="fas fa-angle-down font-regular color-white"></i>
                    </button>
                    <div className="panel" id="panel4">
                        <div className="nav-item auto" id="pendientes">
                            <Link
                                to="/administracion/quejas/pendientes"
                                className="font-regular color-white">
                                <span className="color-white">Pendientes</span>
                            </Link>
                        </div>
                        <div className="nav-item auto" id="historialp">
                            <Link
                                to="/administracion/quejas/historial"
                                className="font-regular color-white">
                                <span className="color-white">Historial</span>
                            </Link>
                        </div>
                    </div>
                    <button className="accordion color-white" id="bitacora">
                        Bitácora seguridad
                        <i className="fas fa-angle-down font-regular color-white"></i>
                    </button>
                    <div className="panel" id="panel5" >
                        <div className="nav-item auto" id="historialb">
                            <Link
                                to="/administracion/bitacora/historial"
                                className="font-regular color-white">
                                <span className="color-white">Historial</span>
                            </Link>
                        </div>
                    </div>*/}
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.menusLocal();

        let submenu = window.localStorage.getItem('submenu');
        if (submenu) {
            submenu = JSON.parse(submenu);
            this.setState({ activeSub: submenu });
        }
    }

    menusLocal() {
        const menusLocal = window.localStorage.getItem('administracion_menus');
        let parentsOpenLocal = window.localStorage.getItem('parents_open');
        parentsOpenLocal = JSON.parse(parentsOpenLocal);
        if (menusLocal) {
            let menusObject = JSON.parse(menusLocal);
            menusObject = menusObject.map(menuList => {
                if(parentsOpenLocal) {
                    if(parentsOpenLocal.find(p => p.menu.toString() === menuList.id_menu.toString())) {
                        menuList.open = true;
                    }
                    return menuList;
                }
                return menuList;
            })
            this.setState({ menus: menusObject });
        }
    }

    parentClick(item) {
        this.setState({ active: item.id_menu });
        Object.assign(item, { open: !item.open });
        window.localStorage.setItem('administracion_menus', JSON.stringify(this.state.menus));
        let parentsOpen = [];
        const parentsOpenLocal = window.localStorage.getItem('parents_open');
        if(parentsOpenLocal) {
            parentsOpen = JSON.parse(parentsOpenLocal);
            if(parentsOpen.find(parent => parent.menu.toString() === item.id_menu.toString())) {
                parentsOpen = parentsOpen.filter(parent => parent.menu.toString() !== item.id_menu.toString());
            } else {
                parentsOpen.push({menu: item.id_menu});
            }
            window.localStorage.setItem('parents_open', JSON.stringify(parentsOpen));
        } else {
            window.localStorage.setItem('parents_open', JSON.stringify([{menu: item.id_menu}]));
        }
    }

    childrenClick(list) {
        this.setState({ activeSub: list.id_menu });
        window.localStorage.setItem('submenu', list.id_menu);
    }
}

export default Consumer(SidebarAdministracion);