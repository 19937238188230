/**
 *  incidents.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Inicio de Incidencias
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import IncidentsTable from '../components/incidents/incidents-table';
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import moment from "moment";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";

const request = new Request();

class Incidents extends Component {
    state = {
		user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
        incidentes: [],
        incidentsHoy: false,
        /* Estados para el filtro generico : filterData y keys */
        filterData: [],
        keys: [],
		page: 1,
		filter: '',
		subfraccionamiento: localStorage.getItem('subfrac')
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
            this.getIncidencias();
        }
	}
	
    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    /* funcion para el filtro generico */

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        if (param1 === "todo") {
            this.setState({ incidentes: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ incidentes: filterData });
        }

    }

    reload() { this.getIncidencias() }

    changeFrac() { this.getIncidencias() }

    filtrar = idR => { this.handleChange(idR) }

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevaReporte() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(incidentes) {
		incidentes = incidentes.filter(inc => {
		
			if(inc.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (inc.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (inc.quejaDesripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return incidentes;
	}

    render() {
        let incidentes = [];
        let dataSet;

        if (Array.isArray(this.state.incidentes)) {
			incidentes = this.state.incidentes;
			dataSet = incidentes;
			incidentes = this.filter(incidentes);
        }

        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Incidentes</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'pendientes'} nav="administracion" />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
								<div className="white-space-32" />
								<Controls title="Pendientes"
									nuevo={this.nuevaReporte.bind(this)}
									dataSet={dataSet}
									newButton={false}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}/>
                                <div className="white-space-16"></div>
								{this.state.loadingIncidencias?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
                                	<IncidentsTable incidentes={incidentes} page={this.state.page} estado={this.state.incidentsHoy} reload={this.getIncidencias.bind(this)} filtrar={this.filtrar.bind(this)} />
								}
								<div className="white-space-16"></div>
                                <Paginador pages={Math.ceil(incidentes.length / 7)} setPage={this.setPage.bind(this)} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
		this.getIncidencias();
	}
	
	
    // TRAER INCIDENCIAS HOY
    async getIncidencias() {
        this.setState({ incidentes: [], loadingIncidencias: true });

        let idSub = this.state.subfraccionamiento || 0;
        let data = {
            Fecha: moment(new Date()).format("YYYY-MM-DD"),
			IdFraccionamiento: localStorage.getItem('frac'),
			idSub
        };

        const response = await request.post("/incidencias/get/today", data);

        if (response && !response.error) {
            if (response.incidenciasDIa && !response.empty) {
				
                this.setState({
                    incidentes: response.incidenciasDIa,
                    filterData: response.incidenciasDIa,
                    keys: Object.keys(response.incidenciasDIa[0]).filter(key => key.indexOf('id') === -1),
                    incidentsHoy: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    incidentsHoy: true
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loadingIncidencias: false
            });
        }
		this.setState({ loadingIncidencias: false })
	}

    // filtro por resolucion
    async  handleChange() {

        let idR = parseInt(document.getElementById('resolucion').value);
        this.setState({ incidentes: [] })
        let observable = JSON.parse(localStorage.getItem('object'))
        if (!observable) {
            return
        }

        if (idR === 4) {
            this.getIncidencias()
            return

        }

        let data = {
            resolution: idR,
            idFraccionamiento: observable.if
        }
        const response = await request.post("/incidencias/filtro/resolucion", data)
  
        if (response && !response.error) {
            if (response.incidencias && !response.empty) {
                this.setState({
                    incidentes: response.incidencias,
                    filterData: response.incidencias,
                    keys: Object.keys(response.incidencias[0]).filter(key => key.indexOf('id') === -1)
                })

            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }


    }

}

export default Consumer(Incidents);