import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import Paginador from "../components/paginador/paginador";
import moment from "moment";
import ReactExport from "react-export-excel";
import Request from "../core/httpClient";
const req = new Request();

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Reports extends Component {
    state = {
        startDate:  moment().add(-7, 'day').format('YYYY-MM-DD'),
        endDate:    moment().format('YYYY-MM-DD'),
        tipos:      [],
        data:       [], 
        page:       1,
        empty:      true
    }

    componentDidMount() {
        this.load();
    }

    async load() {
         const res = await req.get('/reportes/tipos');
         if (res.tipos) {
            this.setState({ tipos: res.tipos });
         }
    }

    reload() {
        // this.getHouses();
        // this.getNombreSubFraccionamiento()
    }

    changeFrac() {
        // this.getNombreSubFraccionamiento()
        // this.getHouses();
    }

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    startChange(event) {
        this.setState({ startDate: event.target.value })
    }

    endChange(event) {
        this.setState({ endDate: event.target.value })
    }

    async getReport(event) {
        event.preventDefault();

        this.setState({ loading: true });

        const startdate = event.target.startdate.value;
        const enddate   = event.target.enddate.value;
        const sp        = event.target.reportSelect.value;
        const frac      = window.localStorage.getItem('frac');

        if (frac) {
            const data = { start: startdate, end: enddate, sp: sp, id_frac: parseInt(frac) };
            const res = await req.post('/reportes/get', data);
            if (res.reports) {
                this.setState({ data: res.reports, empty: false, loading: false });
                console.log(res.reports);
            }
            if (res.empty) {
                this.setState({ empty: res.empty, loading: false });
            }
        }        
    }

    render() {
        let dataSet = [], columns = [], dataSetExcel = [];
        if (this.state.data.length !== 0) {
            columns = Object.keys(this.state.data[0]);
            dataSet = this.state.data.map((row, i) => {
                return (<tr className="table-row" key={i}>
                    {columns.map((column, index) => <td key={index}>{column === 'Tour' ? JSON.parse(row[column]).en : row[column]}</td>)}
                </tr>);
            });

            dataSetExcel = [
                {
                    columns,
                    data: this.state.data.map(row => {
                        return columns.map(column => column === 'Tour' ? JSON.parse(row[column]).en : row[column])
                    })
                }
            ]
        }

        let tiposReportes = [];
        if (this.state.tipos) {
            tiposReportes = this.state.tipos;
        }
        
        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Administracion</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} />
                <div className="row justify-center">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column text-justify">
                                <div className="white-space-32"></div>
                                <div className="navigation align-center">
                                    <div className="justify-start">
                                        <h3 className="weight-semi">Reportes</h3>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="filter-date row-responsive">
                                    <form className="get-reports row-responsive" onSubmit={this.getReport.bind(this)}>
                                        <div className="select-report full column">
                                            <h5 className="color-black">TIPO:</h5>
                                            <div className="white-space-8" />
                                            <select name="report-select" name="reportSelect" className="input input-select">
                                                {tiposReportes.map((tipo, index) =>
                                                    <option key={index} value={tipo.consulta}>{tipo.nombre}</option>)}
                                            </select>
                                        </div>
                                        <div className="data-from column">
                                            <h5 className="color-black">DESDE:</h5>
                                            <div className="white-space-8" />
                                            <input
                                                type="date"
                                                id="startdate"
                                                name="startdate"
                                                className="input input-text"
                                                placeholder="Desde"
                                                value={this.state.startDate}
                                                onChange={this.startChange.bind(this)}
                                            />
                                        </div>
                                        <div className="data-to column">
                                            <h5 className="color-black">HASTA:</h5>
                                            <div className="white-space-8" />
                                            <input
                                                type="date"
                                                id="enddate"
                                                name="enddate"
                                                className="input input-text"
                                                placeholder="Hasta"
                                                value={this.state.endDate}
                                                onChange={this.endChange.bind(this)}
                                            />
                                        </div>
                                        <div className="get-result justify-start align-end">
                                            <button className="btn btn-filter btn-small">
                                                {!this.props.loading ?
                                                    <i className="fas fa-download" />
                                                    : <i className="fas fa-spinner fa-spin" />
                                                }
                                                &nbsp; OBTENER
                                            </button>
                                        </div>
                                    </form>
                                    <div className="get-results justify-end align-end">
                                        <ExcelFile element={
                                            <button type="button" className="btn btn-primary btn-small color-white">
                                                <i className="far fa-file-excel" />
                                                &nbsp; EXCEL
							                </button>
                                        } filename={'Reporte'}>
                                            <ExcelSheet dataSet={dataSetExcel} name="Reporte" />
                                        </ExcelFile>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                {this.state.loading ? 
                                <React.Fragment>
                                    <div className="white-space-32"></div>
                                    <div className="row justify-center">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                </React.Fragment> : !this.state.empty ? 
                                <React.Fragment>
                                    <div className="card-table card-owners column" id="card-owners">
                                        <div className="table-responsiv column">
                                            <table>
                                                <thead>
                                                    <tr className="text-left">
                                                        {columns.map((column, index) => <th key={index}>{column}</th>)}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        dataSet.slice(((this.state.page - 1) * 10), (this.state.page * 10)).map((row, index) => { return (row) })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {dataSet.length > 10 ? 
                                    <React.Fragment>
                                        <div className="white-space-16"></div>
                                        <Paginador pages={Math.ceil(dataSet.length / 10)} setPage={this.setPage.bind(this)} />
                                    </React.Fragment> : null}
                                </React.Fragment> : 
                                <React.Fragment>
                                    <div className="white-space-48"></div>
                                    <div className="justify-center">
                                        <h3>No se encontraron resultados.</h3>
                                    </div>
                                </React.Fragment>}
                                <div className="white-space-32"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reports;