import React, { Component } from 'react';
import ReactModal from "react-modal";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
const request = new Request();


class ProfilesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal:      false,
            nombre:         this.props.perfil.nombre,
            idperfil:       this.props.perfil.id_perfil,
            loadingSave:    false
        }
	}
	
	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }
    closeModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

    render() {


        return (
            <div>
                <button className="btn-full justify-center btn-mini btn-secondary color-white" type="button" onClick={this.openModal.bind(this,"editProfiles")}>
                    <i className="font-small fas fa-pen" />
                </button>

				<DinamicModal 
					idModal             =   {'editProfiles'}
					sizeModalContainer  =   {'small'}
					title               =   {'EDITAR PERFIL'} 
					success             =   {this.handleSubmit.bind(this)} 
					showBtnSuccess      =   {true}
					btnTextSuccess      =   {"ACTUALIZAR"}
					showBtnCancel       =   {true}
					btnTextCancel       =   {"CANCELAR"}
					loadingSave         =   {this.state.loadingSave}
					message             =   {this.state.message}
				>
				{this.renderEditProfiles()}
				</DinamicModal>

                <ReactModal className="modal modal-full modal-owners  column" overlayClassName="overlay">
                    <form className="flex justify-center" onSubmit={this.handleSubmit.bind(this)}>
                        <div className="full column align-center">
                            <div className="close-modal justify-center header-modals">
                                <div>
                                    <h3 className="color-white">EDITAR PERFIL</h3>
                                </div>
                                <button className="btn-modal-close color-dark" onClick={this.handleClose.bind(this)}>
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <div className="column modal-content">
                                <div className="align-center row ">
                                    <div className="column label">
                                        <p><b>  Perfil:	</b></p>
                                    </div>
                                    <div className="column full">
                                        <input type="text" name="conceptName" value={this.state.nombre} onChange={event => this.setState({ nombre: event.target.value })} className="input input-modals" placeholder="Nuevo concepto" required maxLength="64" />
                                    </div>
                                </div>
                                <div className="white-space-32" />
                                <div className="btn-container justify-end">
                                    <button type="submit" className="btn btn-primary color-white" >
                                        <i className="fas fa-check" />
                                        &nbsp; ACTUALIZAR
                                    </button>
                                    <button type="button" className="btn btn-secondary color-white" onClick={this.handleClose.bind(this)}>
                                        <i className="fas fa-times" />
                                        &nbsp; CANCELAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ReactModal>
            </div>



        );
	}
	
	renderEditProfiles() {
		return (
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center ">
					<div className="column label-normal align-start">
						<p><b>  Perfil:	</b></p>
					</div>
					<div className="column full">
						<input type="text" name="conceptName" value={this.state.nombre} onChange={event => this.setState({ nombre: event.target.value })} className="input input-modals" placeholder="Nuevo concepto" required maxLength="64" />
					</div>
				</div>
			</div>
		</div>
  
		)
	}

    handleOpen() { this.setState({ showModal: true }) }
    handleClose() { this.setState({ showModal: false }) }

    async handleSubmit(event) {
        event.preventDefault();



        let data = { perfil: this.state.nombre, idperfil: this.state.idperfil }

        const response = await request.post("/profiles/eddit/profile", data);


        
        if (response.eddited) {
            this.setState({
                showModal: false
            });
            this.closeModal("editProfiles");
            this.props.reload();
        } else {
            this.setState({
                empty: true,
                message: "NO SE PUDO EDITAR PERFIL"
            });
        }
        this.setState({loadingSave: false});
        



    }
}

export default ProfilesModal;