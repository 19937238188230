import React, { Component } from 'react';
import ContactoEditarModal from './contactosEditar';
import ModalBorrar from '../modals/modalDelete'
import Request from "../../core/httpClient";
const request = new Request();


class ContactosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    reload() { this.props.reload() }
    delete = id => {

        this.handleDelete(id)

    }
    render() {

        let contactos = []
        if (this.props.contactos) {
            contactos = this.props.contactos
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead>
                            <tr className="text-left">
                                <th className="th-options-huge">Nombre</th>
                                <th className="th-options-huge">Teléfono</th>
                                <th className="th-options-huge">Unidad</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contactos.slice(((this.props.page - 1) * 8), (this.props.page * 8)).map((contacto, key) => (
                                <tr key={key}>
                                    <td>
                                        {contacto.nombre}
                                    </td>
                                    <td>
                                        {contacto.telefono}
                                    </td>
                                    <td className='text-left'>
                                        {contacto.numero_registro}
                                    </td>
									<td className="text-center">
										<button type="button justify-center" onClick={this.openModalEdit.bind(this, contacto)} >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
                                    <td>
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, contacto)} style={{ padding: '5px' }} >
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
				<ContactoEditarModal reload={this.reload.bind(this)} contacto={this.state.contacto} />
				<ModalBorrar
					id={this.state.id_contacto}
					delete={this.handleDelete.bind(this)}/>
            </div>
        );
	}

	openModalEdit(contacto) {
		this.setState({contacto});
		let modal = document.getElementById("modal-contacto-editar");
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	openModalDelete(inq) {
		this.setState({id_contacto: inq.id_contacto});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleDelete(id) {
        let data = {
            id_contacto: id,
        }
        const response = await request.post("/viviendas/contacto/delete", data);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.setState({
                    message: response.message,

                });
                this.reload()

            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }

        }
    }
}

export default ContactosTable;