import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import Paginador from "../components/paginador/paginador";
import ReservationHistoryTable from '../components/commonAreasReservation/reservationHistoryTable'
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import moment from 'moment';
const request = new Request();


class CommonAreasHistorry extends Component {
    state = {
        commonAreas: [],
        keys: [],
		reservas: [],
		page: 1,
		filter: '',
		subfraccionamiento: localStorage.getItem('subfrac')
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
            this.getReservas();
            console.log('Obteniendo reservas pasadas');
		}
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }
    /* funcion para el filtro generico */

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        //console.log('filtros', this.state.filterData);

        if (param1 === "todo") {
            this.setState({ CommonAreas: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ CommonAreas: filterData });
        }

    }
    reload() {
        // this.getHouses();
        // this.getNombreSubFraccionamiento()
	}

    changeFrac() {
        this.getReservas()
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevaArea() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(CommonAreas) {
		CommonAreas = CommonAreas.filter(com => {
			if(com.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (com.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return CommonAreas;
	}

    render() {
        let CommonAreas = [];
		let dataSet;

        if (Array.isArray(this.state.reservas)) {
			CommonAreas = this.state.reservas;
			dataSet = CommonAreas;
			CommonAreas = this.filter(CommonAreas);
        }
        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Administración</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'historiala'} nav={'administracion'} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <Controls title="Historial"
									nuevo={this.nuevaArea.bind(this)}
									dataSet={dataSet}
									newButton={false}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}/>
                                <div className="white-space-16" />
                                <div className="card-table card-owners column" id="card-owners">
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
                                    <ReservationHistoryTable reservas={CommonAreas} />
								}
                                </div>
                                <div className="white-space-16"></div>
                                <Paginador pages={Math.ceil(CommonAreas.length / 7)} setPage={this.setPage.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
	}

	componentDidMount() {
		this.getReservas();
	}

    async  getReservas() {
		this.setState({ reservas: [], loading: true });
		const idSub = this.state.subfraccionamiento;
        let data = {
            Id: localStorage.getItem('frac'),
			fecha: moment().format('YYYY-MM-DD'),
			idSub: idSub
        };
        // console.log(data);

        const response = await request.post("/commonArea/get/reservas/history", data)
        console.log('Respondiendo');
        console.log( response);

        if (response && !response.error) {
            if (response.info && !response.empty) {
                this.setState({ reservas: response.info })
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
		}
		this.setState({ loading: false });
    }
}

export default CommonAreasHistorry;