import React, { Component } from 'react';
import { Consumer } from '../../context';
import ModalBorrar from '../modals/modalDelete'
import Request from "../../core/httpClient";
import NumberFormat from "react-number-format";
import DinamicModal from '../dinamicModal/dinamicModal';
import AccountForm from './accountForm';
const request = new Request();


class AcountStatementTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			filtro: 0,
			id_recibo: '',
			account: {},
			viviendas: []
        }
    }
    delete = id => {
        this.handleDelete(id)
	}

    render() {

        let accounts = []

        if (this.props.accounts) {
            accounts = this.props.accounts

        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="text-left">Folio</th>
                                <th className="text-left">Unidad</th>
                                <th className="text-left">Propietario</th>
								<th className="text-left">Contacto</th>
                                <th className="text-left">Monto</th>
								<th className="text-left">Concepto</th>
                                <th className="text-left">Estado</th>
                                <th className='text-center th-options-small'>Editar</th>
                                <th className='text-center th-options-small'>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>

                            {accounts.slice((this.props.page - 1) * 7, this.props.page * 7).map((account, key) => (
                                <tr key={key}>
                                    <td className="text-left">{account.folio}</td>
                                    <td className="text-left">{account.numero_registro}</td>
                                    <td className="text-left">{account.propietario}</td>
									<td className="text-left">{account.contacto}</td>
                                    <td className="text-left">
                                        <NumberFormat
                                            value={account.monto}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
									<td className="text-left">{account.concepto}</td>
                                    <td className="text-left">{account.estado === 0 ? ('pendiente') : ('pagado')}</td>
                                    <td className="text-center">
										<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this, "editAccount", account)}>
											<i className="font-small fas fa-pen" />
										</button>
									</td>
                                    <td className="text-center">
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, account.id_recibo)} style={{ padding: '5px' }} >
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                </div>
				<ModalBorrar id={this.state.id_recibo} delete={this.delete.bind(this)} />
				<DinamicModal
					idModal                 =   {'editAccount'}
					sizeModalContainer      =   {'big'}
					title                   =   {'EDITAR RECIBO'} 
					success                 =   {this.handleSubmit.bind(this)} 
					showBtnSuccess          =   {true}
					btnTextSuccess          =   {"GUARDAR"}
					showBtnCancel           =   {true}
					btnTextCancel           =   {"CANCELAR"}
					loadingSave             =   {this.state.loadingSave}
					message                 =   {this.state.message}>
					<AccountForm account={this.state.account}/>
				</DinamicModal>
            </div>
        );
	}

	openModal(id, account) {
		this.setState({account});
		let modal = document.getElementById(id);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }
    closeModal(id, account) {
		this.setState({account});
		let modal = document.getElementById(id);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	openModalDelete(id) {
		this.setState({id_recibo: id});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleSubmit(event) {
		event.preventDefault();
		const form = event.target;
		this.setState({loadingSave: true});
		const data = {
			id_recibo:      this.state.account.id_recibo,
			folio:          form.folio.value,
			concepto:       form.concepto.value,
			vivienda:       form.vivienda.value,
			propietario:    form.propietario.value,
			contacto:       form.contacto.value,
			mes:            form.mes.value,
			anio:           form.anio.value,
			monto:          form.monto.value,
			estado:         form.estado.value,
			comentarios:    form.comentarios.value,
			usuario:        this.props.context.user
		}
        const response = await request.post('/cuentas/update/account', data);
        if(response.updated){
            this.props.reload();
            this.closeModal("editAccount");
        }else{
            this.setState({message: "NO SE PUDO EDITAR RECIBO"});
        }
		this.setState({loadingSave: false});
	}

    async handleDelete(id) {
        let data = {
            id: id
        }

        const response = await request.post("/cuentas/delete/account", data)
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.setState({
                    showModal: false

                });

            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
        this.props.reload()

    }
}

export default Consumer(AcountStatementTable);