import React, { Component } from 'react';
import ReportTypeEddit from './reportTypeEddit'
import ModalBorrar from '../modals/modalDelete'

import Request from "../../core/httpClient";
const request = new Request();

class ReportsTypeTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id_tipoqueja: 	'',
			tipo: 			''
		}
	}
	reload() {
		this.props.reload()
	}
	delete = id => {

		this.handleDelete(id)

	}

	openModal(idModal,type) {
		this.setState({ mesagge: null,tipo: type });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	render() {
		let types = [];
		if (this.props.types) {
			types = this.props.types;
		}

		return (
			<div className="card-table card-table-budget card-owners column" id="card-owners">
				<div className="table-responsiv column">
					<table>
						<thead>
							<tr className="text-left">
								<th className="">Tipo</th>
								<th className=" text-center th-options-small">Editar</th>
								<th className=" text-center th-options-small">Eliminar</th>
							</tr>
						</thead>
						<tbody>
							{types.slice((this.props.page - 1) * 7, this.props.page * 7).map((tipo, key) => (
								<tr key={key}>
									<td>{tipo.descripcion}</td>
									<td className="text-center">
										<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this, "editNewTypeReport",tipo)} >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td>
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, tipo.id_tipoqueja)} style={{ padding: '5px' }} >
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
								</tr>
							))

							}

						</tbody>
					</table>
				</div>
				<ReportTypeEddit 
					tipo	=	{this.state.tipo} 
					reload	=	{this.reload.bind(this)}>

				</ReportTypeEddit>
				<ModalBorrar 
					id		=	{this.state.id_tipoqueja} 
					delete	=	{this.delete.bind(this)} 
				/>
			</div>
		);
	}

	openModalDelete(id) {
		this.setState({ id_tipoqueja: id});
		let modal = document.getElementById("deleteModal");
		if (modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleDelete(id) {
		let data = {

			IdReporte: id
		}
		const response = await request.post("/incidencias/delete/type", data);


		if (response && !response.error) {
			if (response.deleted && !response.empty) {
				this.setState({
					message: response.message,

				});
				this.reload()

			} else {
				this.setState({
					empty: true,
					message: response.message,
					loading: false
				});
			}

		}
		this.reload()
	}
}

export default ReportsTypeTable;