import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import ReactModal from 'react-modal'


import Request from "../../core/httpClient";
const request = new Request();
class CommonAreasEddit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: 				false,
			nombre: 				this.props.area.nombre,
			descripcion: 			this.props.area.descripcion,
			activo: 				this.props.area.activo,
			id: 					this.props.area.id_espacio,
			categoria: 				this.props.area.id_categoria,
			diasR: 					this.props.area.dias_reserva,
			fianza: 				this.props.area.fianza,
			diasMax: 				this.props.area.dias_maximos_reserva,
			imagenArea: 			this.props.area.area_imagen,
			idSubfrac: 				this.props.area.id_subfraccionamiento ? this.props.area.id_subfraccionamiento : ' ',
			subfraccionamientos: 	this.props.subfraccionamientos,
			categories: 			[]
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.area !== prevProps.area) {
			this.setState({
				nombre: 			this.props.area.nombre,
				descripcion: 		this.props.area.descripcion,
				activo: 			this.props.area.activo,
				id: 				this.props.area.id_espacio,
				categoria: 			this.props.area.id_categoria,
				diasR: 				this.props.area.dias_reserva,
				fianza: 			this.props.area.fianza,
				diasMax: 			this.props.area.dias_maximos_reserva,
				imagenArea: 		this.props.area.area_imagen,
				idSubfrac: 			this.props.area.id_subfraccionamiento ? this.props.area.id_subfraccionamiento : ' '
			});
		
			this.getCategories();
		}
	}
	closeModal(idModal) {
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}
	showPreviewImageCommon() {
		let file = document.getElementById("fileImageEdit").files[0];
		let reader = new FileReader();
		if (file) {
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				//document.getElementById("previewArea").src = reader.result;
				this.setState({imagenArea: reader.result});
				
			}
			
		}
	}

	render() {
		return (
			<div>
				<DinamicModal
					idModal				=	{'editCommon'}
					sizeModalContainer	=	{'big'}
					title				=	{'EDITAR ÁREA COMÚN'}
					success				=	{this.handleSubmit.bind(this)}
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"ACTUALIZAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loading}
					message				=	{this.state.message}
				>
					{this.renderEditCommonArea()}
				</DinamicModal>
			</div>
		);
	}

	renderEditCommonArea() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-big">
							<p>
								<b>Nombre:</b>
							</p>
						</div>
						<div className="column full space">
							<input type="text" name="commonArea" value={this.state.nombre} onChange={event => this.setState({ nombre: event.target.value })} className="input input-modals" placeholder="Nombre de  área común" required
								maxLength="150"
							/>
						</div>
						<div className="column label-normal space">
							<h5>
								<b>Activo</b>
							</h5>
						</div>
						<div className="column space">
							<input type="checkbox" name="activo" id="activar" checked={this.state.activo ? (true) : (false)} onChange={event => this.setState({ activo: event.target.checked })} />
						</div>
					</div>
					<div className="white-space-24"></div>

					<div className="row full">
						<div className="column full">
						<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Subfraccionamiento:</b>
									</p>
								</div>
								<div className="column full">
									<select name="subfraccionamiento" id="subfraccionamiento-modal" className="input input-full"
										value={this.state.idSubfrac}
										onChange={(event) => this.setState({idSubfrac: event.target.value})}>
											<option value="0">TODOS</option>
										{  
											this.state.subfraccionamientos.map((subfraccionamiento, key) => (
											<option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>)) 
										}
									</select>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Categoría: </b>
									</p>
								</div>
								<div className="column full">
									<select name="categoria" id="" value={this.state.categoria} onChange={event => this.setState({ categoria: event.target.value })} className="input input-full">
										{this.state.categories.map((categoria, key) => (
											<option value={categoria.id_categoria} key={key}>{categoria.nombre}</option>
										))}
									</select>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Días previos para reserva: </b>
									</p>
								</div>
								<div className="column full">
									<input type="number" value={this.state.diasR} onChange={event => this.setState({ diasR: event.target.value })} name="diasp" className="input input-modals" />
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Días máximos reserva: </b>
									</p>
								</div>
								<div className="column full">
									<input type="number" value={this.state.diasMax} onChange={event => this.setState({ diasMax: event.target.value })} name="diasm" className="input input-modals" />
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Fianza $:</b>
									</p>
								</div>
								<div className="column full">
									<input type="number" name='fianza' value={this.state.fianza} onChange={event => this.setState({ fianza: event.target.value })} className="input input-modals" />
								</div>
							</div>

						</div>
						<div className="column full column-img">

							<div class="responsive-img justify-end row">
								<div className="container-img">
									<img src={this.state.imagenArea ? this.state.imagenArea : "https://adime.es/static/img/group_placeholder.png"} className="img-duo-preview" id="previewAreaEdit" />

									<div class="middle-overlay">
										<label className="btn-upload-image column align-center justify-center">
											<i className="fas fa-file-upload"></i>
											<input type="file" id="fileImageEdit" name="AreaImage" accept="image/*" onChange={this.showPreviewImageCommon.bind(this)} />
											<div className="white-space-8"></div>
											<p className="color-white">subir imagen</p>
										</label>
									</div>
								</div>

							</div>
						</div>
					</div>

					<div className="white-space-16"></div>
					<div className="column">
						<p>
							<b>Políticas de uso:</b>
						</p>
						<div className="white-space-8"></div>
						<div className="align-center row full">
							<div className="column full">
								<textarea name="descripcion" className='textarea full' cols="50" rows="5" placeholder='Descripción' value={this.state.descripcion} onChange={event => this.setState({ descripcion: event.target.value })}></textarea>
							</div>
						</div>
					</div>

				</div>

			</div>
		)
	}

	handleOpen() {
		this.setState({ showModal: true,message: "" })
		this.getCategories()
	}

	async getCategories() {
		this.setState({ loading: true });
		let data = {
			id: localStorage.getItem('frac')
		}

		const response = await request.post("/commonArea/get/categories", data)
		if (response && !response.error) {
			if (response.categories && !response.empty) {

				this.setState({ categories: response.categories })
			}

		} else {
			this.setState({
				error: true,
				message: ""
			});
		}
		this.setState({ loading: false });
	}

	async handleSubmit(event) {
		this.setState({ loading: true });
		event.preventDefault()

		let activar = document.getElementById('activar').checked

		if(this.state.idSubfrac === '') {
			alert('Debes seleccionar un subfraccionamiento antes de guardar');
			this.setState({ loading: false });
			return;
		}
		if (activar === true || activar === 1) {
			activar = 1
		} else {
			activar = 0
		}
		console.log("Testing");
		console.log(this.state.imagenArea);
		let data = {
			nombre:			this.state.nombre,
			descripcion:	this.state.descripcion,
			activo:			activar,
			id:				this.state.id,
			categoria:		parseInt(this.state.categoria),
			diasR:			parseInt(this.state.diasR),
			fianza:			parseInt(this.state.fianza),
			diasMax:		parseInt(this.state.diasMax),
			imagen:			this.state.imagenArea,
			idSubfrac:		this.state.idSubfrac
		}
		console.log(data);
		const response = await request.post("/commonArea/update/common", data);
		console.log(response);
		if (response.updated) {
			this.setState({
				showModal: 	false,
				message: 	""
			});
			this.closeModal("editCommon");
			this.props.reload();
		} else {
			this.setState({
				message: "NO SE PUDO EDITAR ÁREA COMÚN"
			});
		}
		this.setState({ loading: false });
	}

	handleClose() {
		this.setState({ showModal: false })
	}
}

export default CommonAreasEddit;