import React, { Component } from 'react';
import ReactModal from "react-modal";
import DinamicModal from '../dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
const request = new Request();

class ConceptModalEddit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            concepto: this.props.datos.descripcion,
            id: this.props.datos.id_concepto,
            showModal: false
        }
	}

	componentDidUpdate(prevProps) {
		if (this.props.datos !== prevProps.datos) {
			this.setState({
				concepto: this.props.datos.descripcion,
            id: this.props.datos.id_concepto,
			});
		}
	}
	

    render() {

        return (
            <div>
				<DinamicModal 
					idModal				=	{'editBudget'}
					sizeModalContainer	=	{'small'}
					title				=	{'EDITAR CONCEPTO'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"ACTUALIZAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
					>
					{this.renderEditBudget()}
				</DinamicModal>

            </div>
        );
	}

	renderEditBudget() {
		return(
		<div className="full row align-center">
		<div className="column full">
			<div className="input-form-content row full align-center">
				<div className="column label-normal">
					<p><b>  Concepto:	</b></p>
				</div>
				<div className="column full">
					<input type="text" name="conceptName" value={this.state.concepto}
						onChange={event => this.setState({ concepto: event.target.value })} className="input input-modals" placeholder="Nuevo concepto" required maxLength="64" />
				</div>
			</div>
		</div>
	</div>
	)

	}
   
    async handleSubmit(event) {
        event.preventDefault();

        let data = {
            idConcepto: this.state.id,
            concepto: this.state.concepto

        }
        const response = await request.post("/presupuestos/presupuesto/update/concept", data);
		if (response.updated && !response.empty) {
			this.props.reload();
		} else {
			this.setState({
				empty: 		true,
				message: "NO SE PUDO ACTUALIZAR CONCEPTO"
			});
		}

    }
}

export default ConceptModalEddit;