import React, { Component } from 'react';
import NumberFormat from "react-number-format";
import moment from 'moment-timezone'
class ReservationHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let reservas = []

        if (this.props.reservas) {
            reservas = this.props.reservas
        }

        return (
            <div>
                <div className="table-responsiv column">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="">Área reservada</th>
                                <th className="">Fecha</th>
                                <th className="text-left">Unidad</th>
                                <th className="text-left">Fianza</th>
                                <th className="text-left">Horario</th>
                                <th></th>
                                <th></th>

                            </tr>
                        </thead>
                        <tbody>
                            {reservas.map((reserva, key) => (
                                <tr key={key}>
                                    <td>{reserva.nombre}</td>
                                    <td>{moment(reserva.fecha_reserva).tz('GMT').format("DD-MM-YYYY")}</td>
                                    <td>{reserva.numero_registro}</td>
                                    <td>
                                        <NumberFormat
                                            value={reserva.fianza_cobrada}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td>{reserva.descripcion}</td>
                                    {/* <td><ReservationEddit reserva={reserva} reservas={this.reservas.bind(this)} /></td>
                                    <td> <ModalBorrar id={reserva.id_reserva} delete={this.delete.bind(this)} /></td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ReservationHistoryTable;