import React, { Component } from 'react';
import moment from "moment";
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import Request from "../../core/httpClient";
const request = new Request();

class PropietarioEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPersona: 0,
            pNombre: '',
            IDireccion: '',
            IMunicipio: '',
            IEstado: '',
            ICp: '',
            pTelefono: '',
            IPais: '',
            IGenero: 0,
            IId: '',
            INumId: '',
            IVigencia: '',
            IFechaIngreso: '',
            code: '',
            Iviv: '',
            IIdViv: 0,
			email: '',
			pFechaVenta: ''
		}
		
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(this.state !== prevState) {
			this.props.setInfo(this.state);
		}
		if(this.props.telefonoError !== prevProps.telefonoError) {
			this.setState({telefonoError: this.props.telefonoError});
		}
		if(this.props.propietario !== prevProps.propietario) {
			let phone = '';
			if(this.props.propietario.telefono) {
				phone = parsePhoneNumber(`+${this.props.propietario.telefono}`);
				if(phone) {
					phone = phone.number;
				}
			}
			this.setState({ 
				pIdPersona: 		this.props.propietario.id_persona,
				pNombre: 			this.props.propietario.nombre,
				pDireccion: 		this.props.propietario.direccion,
				pMunicipio: 		this.props.propietario.municipio,
				pEstado: 			this.props.propietario.estado,
				pCp: 				this.props.propietario.cp,
				pTelefono: 			phone,
				pPais: 				this.props.propietario.pais,
				pGenero: 			this.props.propietario.genero,
				pId: 				this.props.propietario.tipo_identificacion,
				pNumId: 			this.props.propietario.num_identificacion,
				pVigencia: 			this.props.propietario.vigencia_identificacion,
				pFechaCompra: 		moment(this.props.propietario.fecha_compra).format("YYYY-MM-DD"),
				pFechaVenta: 		moment(this.props.propietario.fecha_venta).format("YYYY-MM-DD"),
				code: 				this.props.propietario.code,
				Iviv: 				this.props.propietario.numero_registro,
				pIdViv: 			this.props.propietario.id_vivienda,
				email: 				this.props.propietario.email
			});
		}
	}

    render() {
        return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b> Unidad:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="vivienda" disabled value={this.state.Iviv}
								onChange={event => this.setState({ Iviv: event.target.value })}
								className="input input-modals" placeholder="N° de vivienda" required maxLength="64" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.pNombre}
								onChange={event => this.setState({ pNombre: event.target.value })} className="input input-modals" placeholder="Nombre completo del propietario" required maxLength="64" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-cente">
						<div className="column label-medium">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="column full">
						<PhoneInput
							displayInitialValueAsLocalNumber
							name="phoneNumber"
							country="MX"
							placeholder="Número de teléfono"
							value={this.state.pTelefono}
							onChange={ value => this.setState({ pTelefono: value, telefonoError: undefined }) }
							error={ this.state.telefonoError }
							className={'input-modals'}/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Identificación:</b>
							</p>
						</div>
						<div className="column full">
							<select name="id" id="idEddit" className="input input-modals" value={this.state.pId} onChange={event => this.setState({ pId: event.target.value })}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="numIdEddit" value={this.state.pNumId}
									onChange={event => this.setState({ pNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Correo :</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.email}
								onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder="Email" maxLength="64" />
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>Género:</b>
							</p>
						</div>
						<div className="column full">
							<select name="sex" id="" value={this.state.pGenero} required
								onChange={event => this.setState({ pGenero: event.target.value })} className="input input-modals">
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="address" value={this.state.pDireccion}
								onChange={event => this.setState({ pDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" required maxLength="150" />
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="municipality" value={this.state.pMunicipio}
								onChange={event => this.setState({ IMunicipio: event.target.value })} className="input input-modals" placeholder='Municipio' required maxLength="80" />
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.pCp}
								onChange={event => this.setState({ pCp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' required maxLength="10" />
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="state" value={this.state.pEstado}
								onChange={event => this.setState({ pEstado: event.target.value })} className="input input-modals" placeholder='Estado' required maxLength="24" />
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.pPais}
								onChange={event => this.setState({ pPais: event.target.value })} name="country" className="input input-modals" placeholder='País' required maxLength="10" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Fecha de compra:</b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.pFechaCompra}
								onChange={event => this.setState({ pFechaCompra: event.target.value })} required className='input input-modals' id='fcompra' />
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Fecha de venta:</b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.pFechaVenta}
								onChange={event => this.setState({ pFechaVenta: event.target.value })} className='input input-modals' id='fventa' />
						</div>
					</div>
				</div>
			</div>
        );
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }
}

export default PropietarioEditarModal;