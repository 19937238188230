import React, { Component } from 'react';


import Request from "../../core/httpClient";
const request = new Request();

class EnvironmentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            edditOpenpay: false,
            disabledOpenpay: true,
            tInvitaciones: 0,
            nRegistros: 0,
            comentario: true,
            foto: true,
            nInvitadosVip: 0,
            openpayId: '',
            openpayLlavePublica: '',
            openpayLlavePrivada: '',
            IdConfig: 0

        }
    }
    render() {
        let tInvitaciones = '';
        let nRegistros = '';
        let comentario = 0;
        let foto = 0;
        let nInvitadosVip = '';
        let numEmpleados = ''
        let pin = 0

        let IdConfig = 0

        if (this.props.config) {
            console.log('this.props.config: ', this.props.config);
            tInvitaciones = this.props.config.tInvitaciones;
            nRegistros = this.props.config.nRegistros;
            comentario = this.props.config.comentario;
            foto = this.props.config.foto;
            nInvitadosVip = this.props.config.nInvitadosVip;
            IdConfig = this.props.config.IdConfig
            numEmpleados = this.props.config.numEmpleados
            pin = this.props.config.pin
        }

        return (
            <div>
                <div className="card-table card-owners column" id="card-owners">
                    <form className="column align-center" onSubmit={this.handleSubmit.bind(this)}>
                        <div className="white-space-24"></div>
                        <div className="container align-center column justify-center">
                            <div className="info-content column">

                                <div className="edit-button justify-start">
                                    <h3>Invitaciones</h3>
                                    <div className="justify-end">
                                        <button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.handleEddit.bind(this)} >
                                            <i className="fas fa-pen"></i> Editar
                                    </button>


                                    </div>
                                </div>

                                <div className="">
                                    <div className="configuration-rows invitations row">
                                        <div className="justify-start">
                                            <h4>Primera tolerancia</h4>
                                        </div>
                                        <div className="justify-start">
                                            <select name="" id="" className="description input-select-configuration" disabled={this.state.disabled} value={tInvitaciones} onChange={event => this.props.actualizar({ tInvitaciones: event.target.value })}>
                                                <option value="1">1 hr</option>
                                                <option value="2">2 hr</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="configuration-rows subsecuentes row">
                                        <div className="justify-start">
                                            <h4>Segunda tolerancia</h4>
                                        </div>
                                        <div className="justify-start">
                                            <select name="" id="" className='description input-select-configuration' disabled={this.state.disabled} value={nRegistros} onChange={event => this.props.actualizar({ nRegistros: event.target.value })}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="configuration-rows subsecuentes row">
                                        <div className="justify-start">
                                            <h4>Pin de seguridad</h4>
                                        </div>
                                        <div className="justify-start align-center">
                                            <label className="container-check">
                                                <input type="checkbox" name="" id="" checked={pin}
                                                    onChange={event => this.props.actualizar({ pin: event.target.checked })} disabled={this.state.disabled} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="white-space-32"></div>
                                <div className="edit-button justify-start">
                                    <h3>Invitados Frecuentes</h3>
                                </div>
                                <div className=''>

                                    <div className="configuration-rows personal row">
                                        <div className="justify-start">
                                            <h4>Número de personal doméstico</h4>
                                        </div>
                                        <div className="justify-start">
                                            <select name="" id="" className='description input-select-configuration' disabled={this.state.disabled} value={numEmpleados} onChange={event => this.props.actualizar({ numEmpleados: event.target.value })}>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="configuration-rows vip row">
                                        <div className="justify-start">
                                            <h4>Número de familiares</h4>
                                        </div>
                                        <div className="justify-start">
                                            <select name="" id="" className='description input-select-configuration' disabled={this.state.disabled} value={nInvitadosVip} onChange={event => this.props.actualizar({ nInvitadosVip: event.target.value })}>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="white-space-32"></div>
                                <div className="edit-button justify-start">
                                    <h3>Control Seguridad</h3>
                                </div>
                                <div className="configuration-rows commments row">
                                    <div className="justify-start">
                                        <h4>Obligatorio comentarios</h4>
                                    </div>
                                    <div className="justify-start align-center">
                                        <label className="container-check">
                                            <input type="checkbox" name="" id="" disabled={this.state.disabled} checked={comentario}
                                                onChange={event => this.props.actualizar({ comentario: event.target.checked })} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="configuration-rows photo row">
                                    <div className="justify-start">
                                        <h4>Obligatorio fotografía al entrar</h4>
                                    </div>
                                    <div className="justify-start align-center">
                                        <label className="container-check">
                                            <input type="checkbox" name="" id="" disabled={this.state.disabled} checked={foto}
                                                onChange={event => this.props.actualizar({ foto: event.target.checked })} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="white-space-24"></div>

                                {this.state.disabled ? (null) : (
                                    <div className="btn-container justify-center">
                                        <button type="button" className="btn btn-primary color-white" onClick={this.handleSubmit.bind(this)}>
                                            <i className="fas fa-check" />
                                            &nbsp; ACTUALIZAR
                                        </button>
                                        <button type="button" className="btn btn-secondary color-white" onClick={this.handleEddit.bind(this)} >
                                            <i className="fas fa-times" />
                                            &nbsp; CANCELAR
                                        </button>
                                    </div>
                                )}
                            </div>

                            <div className="white-space-8"></div>

                            <div className="info-openpay column">
                                <div className="edit-button justify-end">
                                    <div className="justify-start">
                                        <h4>Datos OPENPAY</h4>
                                    </div>
                                    <div className="justify-end">
                                        <button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.handleEdditOpen.bind(this)} >
                                            <i className="fas fa-money-check"></i> Editar Openpay
                                        </button>
                                    </div>

                                </div>
                                <div className="white-space-16"></div>
                                <div className="row">
                                    <div className="justify-center">
                                        <input type="text" className="input" value={this.state.openpayId} onChange={event => this.setState({ openpayId: event.target.value })} placeholder='Id' disabled={this.state.disabledOpenpay} />
                                    </div>
                                    <div className="justify-center">
                                        <input type="text" className="input" value={this.state.openpayLlavePrivada} onChange={event => this.setState({ openpayLlavePrivada: event.target.value })} placeholder='Llave Privada' disabled={this.state.disabledOpenpay} />
                                    </div>
                                    <div className="justify-center">
                                        <input type="text" placeholder='Llave Pública' value={this.state.openpayLlavePublica} onChange={event => this.setState({ openpayLlavePublica: event.target.value })} className="input " disabled={this.state.disabledOpenpay} />
                                    </div>

                                </div>
                                <div className="white-space-16"></div>



                                <div className="white-space-24"></div>

                                {this.state.edditOpenpay ? (
                                    <div className="btn-container justify-center">
                                        <button type="button" className="btn btn-primary color-white" onClick={this.validar.bind(this)} >
                                            <i className="fas fa-check" />
                                            &nbsp; ACTUALIZAR
                                        </button>
                                        <button type="button" className="btn btn-secondary color-white" onClick={this.handleEdditOpen.bind(this)} >
                                            <i className="fas fa-times" />
                                            &nbsp; CANCELAR
                                        </button>
                                    </div>
                                ) : (null)}
                            </div>
                        </div>
                        <div className="white-space-24"></div>
                    </form>
                </div>
            </div>
        );
    }




    handleEddit() {
        this.setState({ disabled: !this.state.disabled, edditOpenpay: false });
    }
    handleSubmit() {
        this.props.enviar()
        this.setState({

            disabled: true

        });
    }
    handleEdditOpen() {
        this.setState({ edditOpenpay: !this.state.edditOpenpay, disabledOpenpay: !this.state.disabledOpenpay, disabled: true })
    }
    setOptionComentary(event) {
        this.setState({ comentario: event.target.checked })
    }
    setOptionPhoto(event) {
        this.setState({ foto: event.target.checked })

    }

    validar() {
        if (this.props.IdConfig === 0) {
            alert("Este fraccionamiento no cuenta una configuración inicial, favor de actualizarla antes de cambiar los datos de OpenPay")
            this.setState({
                openpayId: '',
                openpayLlavePublica: '',
                openpayLlavePrivada: '',
                edditOpenpay: false,
                disabledOpenpay: true
            })
        }
        this.actualizarOpenpay();

    }
    async actualizarOpenpay() {
        let data = {
            id: this.props.IdConfig,
            openpayId: this.state.openpayId,
            openpayLlavePublica: this.state.openpayLlavePublica,
            openpayLlavePrivada: this.state.openpayLlavePrivada,
            idUsuario: this.props.id
        }
        const response = await request.post("/environments/update/openpay", data)
        if (response && !response.error) {
            if (response.updated && !response.empty) {
                this.setState({
                    openpayId: '',
                    openpayLlavePublica: '',
                    openpayLlavePrivada: '',
                    edditOpenpay: false,
                    disabledOpenpay: true

                });
                alert("OpenPay actualizado correctamente")
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
            alert('error al actualizar, intentalo nuevamente')
        }
    }
}

export default EnvironmentTable;