/**
 *  incidents-table.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de incidencias
 */

import React, { Component } from "react";
import ReactModal from "react-modal";
import { Consumer } from "../../context";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import moment from "moment";
import Request from "../../core/httpClient";

const request = new Request();
ReactModal.setAppElement("#root");

class IncidentsTable extends Component {
	constructor() {
		super();
		this.state = {
			showModal: false,
			incidentes: [],
			incidenteDetalle: [],
			resolucion: 0,
			idQueja: 0,
			Rdescription: "",
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0, nombre: '' },
			message: ''
		};
	}

	openModal(idModal, idQueja) {
		this.setState({ Rdescription: '' });
		this.getIncidenciaDetalle(idQueja);
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	render() {
		let estado = false
		if (this.props.estado) {
			estado = this.props.estado;
		}

		return (
			<div className="column">
				<div className="card-table column" id="card-owners">
					{estado ? (
						<div className="container column justify-center">
							<div className="white-space-32"></div>
							<div className="justify-center">
								<i className="fas fa-check color-primary fa-5x"></i>
							</div>
							<div className="white-space-8"></div>
							<h3 className="text-center">
								Sin incidencias hoy
              				</h3>
							<div className="white-space-32"></div>
						</div>
					) :
						<div className="table-responsiv">
							<table className="card-incidents full">
								<thead>
									<tr className="text-left">
										<th className="type">Tipo</th>
										<th className="name">Unidad</th>
										<th className="date">Fecha</th>
										<th className="description">Descripción</th>
										<th className="resolution ">Resolución</th>
										<th className="text-center th-options-small">Ver detalles</th>
									</tr>
								</thead>
								<tbody>
									{this.props.incidentes.slice(((this.props.page - 1) * 7), (this.props.page * 7)).map((incidente, key) => {
										return (
											<tr className="tr" key={key}>
												<td className="weight-semi">{incidente.quejaDesripcion}</td>
												<td className="description-detail">{incidente.numero_registro}</td>
												<td className="">
													{moment(incidente.fecha).format("DD-MM-YYYY")}
												</td>
												<td className="description text-justify">
													<p className="truncate-ellipsis">{incidente.descripcion}</p>
												</td>
												<td className="weight-semi">
													{incidente.resolucion === 0 ? ('Pendiente') : null || incidente.resolucion === 1 ? ('En revision') : null}
												</td>
												<td className="text-center weight-semi">
													<button
														className="btn-full justify-center align-center font-small btn-secondary color-white"
														onClick={this.openModal.bind(this, "detailIncidents", incidente.id_queja)}
													>
														<i className="fas fa-info-circle"></i>
													</button>
												</td>
											</tr>
										)
									}
									)
									}
								</tbody>
							</table>
						</div>}

					<DinamicModal
						idModal				=	{'detailIncidents'}
						sizeModalContainer	=	{'big'}
						title				=	{'DETALLE COMPLETO DE LA INCIDENCIA'}
						success				=	{this.handleSubmit.bind(this)}
						showBtnSuccess		=	{true}
						btnTextSuccess		=	{"ACTUALIZAR"}
						showBtnCancel		=	{true}
						btnTextCancel		=	{"CANCELAR"}
						loadingSave			=	{this.state.loadingSave}
						message				=	{this.state.message}
					>
						{this.renderDetailsIncident()}
					</DinamicModal>


				</div>
			</div>
		);
	}
	componentDidMount() {
		this.load();
	}

	renderDetailsIncident() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-extra">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.incidenteDetalle.nombre}
								onChange={event => this.setState({ pNombre: event.target.value })} className="input input-modals" placeholder="Nombre completo" required maxLength="64" disabled />
						</div>
					</div>
					<div className="white-space-16" />
					<div className="row full">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p><b>  Tipo de incidencia:	</b></p>
								</div>
								<div className="column full">
									<input type="text" name="numIdEddit" value={this.state.incidenteDetalle.quejaDesripcion}
										onChange={event => this.setState({ pNumId: event.target.value })} className="input input-modals" placeholder='tipo de incidencia' required maxLength="24" disabled />
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p className="justify-start">
										<b>Fecha:</b>
									</p>
								</div>
								<div className="column full">
									<input type="date" name="numIdEddit" value={moment(this.state.incidenteDetalle.fecha).format("YYYY-MM-DD")}
										onChange={event => this.setState({ pNumId: event.target.value })} className="input input-modals" placeholder='Fecha' required maxLength="24" disabled />
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p><b>  Teléfono:	</b></p>
								</div>
								<div className="column full">
									<input type="text" name="numIdEddit" value={this.state.incidenteDetalle.telefono}
										onChange={event => this.setState({ pNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" disabled />
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p className="justify-start" >
										<b>Resolución:</b>
									</p>
								</div>
								<div className="column full">
									<select
										name="incident-resolution"
										id="incident-resolution"
										className="input input-modals"
										value={this.state.resolucion}
										onChange={event => this.setState({ resolucion: event.target.value })}>
										<option value="0">Sin resolver</option>
										<option value="1">En revisión</option>
										<option value="2">Resuelto</option>
									</select>
								</div>
							</div>
						</div>
						<div className="column full">
							<div class="responsive-img justify-end row">
								<div className="container-img">
									<img src={this.state.incidenteDetalle.imagen ? this.state.incidenteDetalle.imagen : "https://adime.es/static/img/group_placeholder.png"} className="img-duo-preview" id="previewAreaEdit" />
								</div>

							</div>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-extra">
							<p><b>  Usuario que atiende:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.incidenteDetalle.nombreResolucion ? this.state.incidenteDetalle.nombreResolucion : this.state.user.nombre}
								className="input input-modals" placeholder="Nombre completo" required maxLength="64" disabled />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="row full">
						<div className="column full">
							<div className="row full">
								<p><b> Descripción:	</b></p>
							</div>
							<div className="white-space-8"></div>
							<textarea type="text" name="fullName" value={this.state.incidenteDetalle.descripcion ? this.state.incidenteDetalle.descripcion : "N/A"}
								onChange={event => this.setState({ pNombre: event.target.value })} className="textarea full" placeholder="Descripcion de la incidencia" cols="50" rows="3" maxLength="64" disabled />

						</div>
						<div className="column full" style={{ paddingLeft: "20px" }}>
							<p>
								<b>Descripción de la resolución:</b>
							</p>
							<div className="white-space-8" />
							{
								this.state.incidenteDetalle.resolucion_descripcion ?
									<textarea className="textarea full" value={this.state.incidenteDetalle.resolucion_descripcion}
										onChange={event => this.setState({ Rdescription: event.target.value })} name="descriptionResolution" placeholder="Descripción de la resolución (600 caracteres máximo)" cols="50" rows="3" maxLength="600" minLength="20" disabled></textarea>
									:
									<textarea className="textarea full" value={this.state.Rdescription === null ? '' : this.state.Rdescription}
										onChange={event => this.setState({ Rdescription: event.target.value })} name="descriptionResolution" placeholder="Descripción de la resolución (600 caracteres máximo)" cols="50" rows="3" maxLength="600" minLength="20"></textarea>

							}
						</div>
					</div>
				</div>
			</div>
		)
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					nombre: user.id.nombre
				}
			});
		}
	}
	// actualizar estatus de quejas
	async handleSubmit(event, id) {
		this.setState({ loadingSave: true });
		event.preventDefault();

		let data = {

			idQueja: 			this.state.idQueja,
			resolucion: 		parseInt(this.state.resolucion),
			resolucionDetalle:	this.state.incidenteDetalle.resolucion_descripcion ? this.state.incidenteDetalle.resolucion_descripcion : this.state.Rdescription,
			fechaResolucion: 	moment(new Date()).format("YYYY-MM-DD"),
			idUsuario:	 		this.state.incidenteDetalle.id_usuario_resolucion ? this.state.incidenteDetalle.id_usuario_resolucion : this.state.user.idUsuario

		}
		const response = await request.post("/incidencias/incidents/update", data);
		
		if (response && !response.error) {
			if (response.updated && !response.empty) {
				this.setState({

					resolucion:		'',
					idQueja: 		'',
					Rdescription:	'',

				});
				this.handleClose();
				this.props.reload();
			} else {
				this.setState({
					empty:		true,
					message:	"NO SE PUDO ACTUALIZAR DETALLE INCIDENCIA"
				});
			}
		} else {
			this.setState({
				error:		true,
				message:	"NO SE PUDO ACTUALIZAR DETALLE INCIDENCIA"
			});
		}
		this.setState({ loadingSave: false });
	}


	handleClose() {
		this.setState({ showModal: true, incidenteDetalle: [] });
	}

	//   traer una unica incidencia
	async getIncidenciaDetalle(idQueja) {
		let data = {
			idQueja: idQueja
		};

		const response = await request.post("/incidencias/get/detalle", data);


		if (response && !response.error) {
			if (response.incidenciaDetalle && !response.empty) {
				this.setState({
					incidenteDetalle:	response.incidenciaDetalle,
					resolucion: 		response.incidenciaDetalle.resolucion,
					idQueja:			response.incidenciaDetalle.id_queja,
					Rdescription:		response.incidenciaDetalle.resolucion_descripcion
				});
			} else {
				this.setState({
					empty:		true,
					message:	response.message
				});
			}
		} else {
			this.setState({
				error:		true,
				message:	response.message
			});
		}
	}

	handleChange() {
		let idR = parseInt(document.getElementById('resolucion').value)

		this.props.filtrar(idR)
	}

}

export default Consumer(IncidentsTable);
