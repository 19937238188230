import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import DetailEmergency from '../components/ModalEmergency/modalDetailEmegency';
import ContactoModal from '../components/contactos/contactosEmergencia';
import moment from "moment";
import Socket from '../core/socket';
moment.locale();
const request = new Request();


class Emergency extends Component {
    state = {
        emergencias: [],
        /* Estados para el filtro generico : filterData y keys */
        filterData:         [],
        keys:               [],
		page:               1,
		filter:             '',
		subfraccionamiento: localStorage.getItem('subfrac'),
		dataEmergency:      [],
		viviendaId:         '',
		rowType:            '',
		idPanic:            ''
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
            this.getEmergencys();
        }
	}
	
	componentDidMount() {
		let data = window.localStorage.getItem('openRow');
		if (data) {
			data = JSON.parse(data);
			this.setState({ viviendaId: data.idVivienda, rowType: data.type, idPanico: data.idPanic });
		}
	}
	
	openModal(idModal,data) {
		this.setState({dataEmergency: data});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }
    closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}
    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    reload() {
        this.getEmergencys()
    }

    changeFrac() {
        this.getEmergencys()
    }

    /* funcion para el filtro generico */

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;
        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;
        let filterData = this.state.filterData;

        if (param1 === "todo") {
            this.setState({ emergencias: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ emergencias: filterData });
        }
    }

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevaEmergencia() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(emergencias) {
		emergencias = emergencias.filter(eme => {
			if(eme.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return emergencias;
	}

	openRow(type, idVivienda, idPanic) {
		const data = { type, idVivienda, idPanic };
		window.localStorage.setItem('openRow', JSON.stringify(data));
		if(this.state.idPanic === idPanic && type === this.state.rowType) {
			window.localStorage.removeItem('openRow');
			this.setState({rowType: '', viviendaId: 0, idPanico: idPanic});
			return;
		}
		this.setState({rowType:type, viviendaId: idVivienda,idPanico: idPanic});
	}

    render() {
		let emergencias = [];
		let dataSet;

        if (this.state.emergencias) {
			emergencias = this.state.emergencias;
			dataSet = emergencias;
			emergencias = this.filter(emergencias);
		}

        return (
            <div className="admin column">
                <Helmet>
                    <title>iGate - Administración</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'historial'} parent={'emergencias'} panel={'panel1'} nav={'administracion'} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32"></div>
                                <Controls title="Historial de emergencias"
									nuevo={this.nuevaEmergencia.bind(this)}
									dataSet={dataSet}
									newButton ={false}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}/>
                                <div className="white-space-16" />
                                <div className="card-table card-owners column" id="card-owners">
                                    <div className="table-responsiv column">
                                        <table>
                                            <thead>
                                                <tr className="text-left">
                                                    <th className="">Nombre</th>
                                                    <th className="">Teléfono</th>
                                                    <th className="">Fecha</th>
                                                    <th className="vivienda">Unidad</th>
													<th className="text-center th-options-small">Detalle</th>
													<th className="text-center th-options-small">Contactos</th>
                                                </tr>
                                            </thead>
                                            <tbody>
												{this.state.loading?
													<tr>
														<td colSpan="6">
															<div className="row justify-center">
																<i className="fas fa-spinner fa-spin"></i>
															</div>
														</td>
													</tr>
													:
													emergencias.slice(((this.state.page - 1) * 7), (this.state.page * 7)).map((emergencia, key) => {
                                                    return (
														<React.Fragment key={key}>
                                                        <tr>
                                                            <td>{emergencia.nombre}</td>
                                                            <td>{emergencia.telefono}</td>
                                                            <td>{moment(emergencia.fecha).format("DD-MM-YYYY HH:ss")}</td>
                                                            <td>{emergencia.numero_vivienda}</td>
															<td className="align-center justify-center">
																<button className="font-small weight-semi btn-secondary"
																onClick={this.openModal.bind(this,"detailEmergency",emergencia)}
																>
																	<i className="fas fa-info-circle"></i>
																</button>
															</td>
															<td>
																<button className="font-small weight-semi btn-secondary full" onClick={this.openRow.bind(this, "contactos", emergencia.id_vivienda, emergencia.id_panico)}>
																	<i className="fas fa-address-book"></i>
																</button>
															</td>
                                                        </tr>
														{this.state.idPanico === emergencia.id_panico && this.state.rowType === 'contactos' &&
															<tr>
																<td colSpan="6">
																	<div className="row full">
																		<ContactoModal reload={this.props.reload} id={this.state.viviendaId} editVivienda={false}></ContactoModal>
																	</div>
																</td> 
															</tr>
														}
													
														</React.Fragment>
														
                                                    )})
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <Paginador pages={Math.ceil(emergencias.length / 7)} setPage={this.setPage.bind(this)} />
                            </div>
                        </div>
                    </div>
					<DetailEmergency emergency={this.state.dataEmergency} reload={this.getEmergencys.bind(this)}></DetailEmergency>
                </div>
            </div>
        );
    }

    componentDidMount() {
		this.getEmergencys();
		Socket.on('newEmergency', (data) => {
			this.getEmergencys();
		})
	}

    // traer emergencias
    async getEmergencys() {
        this.setState({emergencias: [], loading: true});

        let idSub = this.state.subfraccionamiento || 0;
        let data = { IdFraccionamiento: localStorage.getItem('frac'), IdSubfraccionamiento: idSub };
        let response = await request.post("/incidencias/get/emergencys", data);

        if (response && !response.error) {
            if (response.emergencias && !response.empty) {
                this.setState({
                    emergencias: response.emergencias,
                    filterData: response.emergencias,
                    keys: Object.keys(response.emergencias[0]).filter(key => key.indexOf('id') === -1),
                    loadingIncidencias: false
				});
				console.log("EMERGENCIAAAS ->" , response.emergencias)
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loadingIncidencias: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loadingIncidencias: false
            });
		}
		this.setState({loading: false});
    }
}

export default Emergency;