import React, { Component } from 'react';

import EdditModal from "./notificationsModal";
import ModalBorrar from "../modals/modalDelete";
import NotificationsSend from "./notificationsSend"
import Request from "../../core/httpClient";
const request = new Request();

class NotificationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			showModal: false,
			idNotification: null 
        }
    }

    reload() { this.props.updated() }

    delete = id => { this.handleDelete(id) }

    render() {

        // let notificaciones = []
        // if (this.props.notificaciones) {
        //     notificaciones = this.props.notificaciones
        // }
        return (
            <div className="card-table card-owners column" id="card-owners">

                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="">Tipo</th>
                                <th className="description">Mensaje</th>

                                <th className="text-center th-options-small">Enviar</th>
                                <th className="date text-center th-options-small">Editar</th>
                                <th className="date text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>

                            {this.props.notificaciones.slice(((this.props.page - 1) * 7), (this.props.page * 7)).map((notificacion, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{notificacion.tipo}</td>
                                        <td>{notificacion.texto}</td>
                                        <td className="text-center">
											<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" onClick={this.openModal.bind(this,'sendNotificationsDef',notificacion.id_notificacion)} >
												<i className="fas fa-paper-plane font-text" />
											</button>
                                        </td>
                                        <td className="text-center"> 
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModal.bind(this,'editNotificationsDef',  notificacion.id_notificacion) }>
											<i className="font-small fas fa-pen" />
										</button>
                                        </td>
                                        <td className="text-center">
											<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this,notificacion.id_notificacion)} style={{ padding: '5px' }} >
												<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
											</button>
                                           </td>
                                    </tr>
                                )
                            }
                            )
                            }

                        </tbody>
                    </table>
                </div>
				<ModalBorrar id={this.state.idNotification} delete={this.delete.bind(this)} />
                <EdditModal 
                    id      =   {this.state.idNotification} 
                    reload  =   {this.reload.bind(this)}
                />
				<NotificationsSend id={this.state.idNotification} subfracsList={this.props.subfraccionamientos}/>
            </div>
        );
	}
	
	openModal(idModal,id) {
		this.setState({ idNotification: id });
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	openModalDelete(id) {
		this.setState({idNotification: id});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleDelete(id) {

        let data = { IdNotificacion: id }

        const response = await request.post("/notificaciones/delete/notification", data);

        if (response && !response.error) {
            if (response.deleted && !response.empty) {

            } else {
                this.setState({ empty: true, message: response.message });
            }
        } else {
            this.setState({ error: true, message: response.message });
        }
        this.props.updated();
    }
}

export default NotificationTable;