import React, { Component } from 'react';
import moment from "moment-timezone";
import ModalEddit from './userEdditModal'
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete'
const request = new Request();

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state          = {
			id_usuario:     '',
			dataUser:       []
		}
    }
    recargar() {
        this.props.reload()
    }
    delete = id => {
        this.handleDelete(id)
    }
    render() {
        let perfiles = []
        if (this.props.perfiles) {
            perfiles = this.props.perfiles
        }

        let users = [];
        if (this.props.users) {
            users = this.props.users

        }
        //console.log('users: ', users);
        return (
            <div className="card-table card-owners column" id="card-owners">
                {/*<h4>Usuarios del fraccionamiento</h4>*/}
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="name">Nombre</th>
                                <th className="">Usuario</th>
                                <th className="date">Perfil</th>
                                <th className="date">Fecha de creación</th>
                                <th className="date text-center th-options-small">Editar</th>
                                <th className="date text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>

                            {users.slice(((this.props.page - 1) * 7), (this.props.page * 7)).map((usuario, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{usuario.nombre} </td>
                                        <td>{usuario.usuario}</td>
                                        <td>{usuario.perfil}</td>
                                        <td>{moment(usuario.fecha_creacion).tz('GMT').format('DD-MM-YYYY')}</td>
                                        <td className="text-center">
										<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this,"editUsers", usuario)}>
											<i className="font-small fas fa-pen" />
										</button >
										</td>
                                        <td className="text-center">
											<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' onClick={this.openModalDelete.bind(this, usuario.id_usuario)} style={{ padding: '5px' }} >
												<i className="font-text fas fa-trash-alt " />
											</button>
                                        </td>

                                    </tr>



                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <ModalEddit 
                    data        =   {this.state.dataUser} 
                    reload      =   {this.recargar.bind(this)} 
                    perfiles    =   {perfiles}
                >
                </ModalEddit>
                <ModalBorrar 
                    id      =   {this.state.id_usuario} 
                    delete  =   {this.delete.bind(this)}
                >
                </ModalBorrar>
            </div>
        );
	}
	
	openModalDelete(id) {
		this.setState({id_usuario: id});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	openModal(idModal,data) {
		this.setState({mesagge: null, dataUser: data});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		this.getUser();
    }
    closeModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}
	

    async handleDelete(id) {
        let data = {
            idUsuario: id
        }

        const response = await request.post("/users/fraccionamiento/delete/user", data)

        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.setState({
                    showModal: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }
        this.props.reload()

    }


    async getUser() {
        let id = this.props.idUser

        let data = {
            IdUser: id
        }

        const response = await request.post("/users/get/fraccionamiento/user", data);
        //console.log('response: ', response);

        if (response && !response.error) {
            if (response.user && !response.empty) {
                this.setState({
                    nombre: response.user[0].nombre,
                    usuario: response.user[0].usuario,
                    rol: response.user[0].id_perfil,
                    idFraccionamiento: response.user[0].id_fraccionamiento
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }
    }



}

export default UsersTable;