import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from '../components/header/header'
import ReactModal from 'react-modal'
import SidebarAdministracion from '../components/header/sidebarAdministracion'
import SearchHouse from "../components/propietarios/search-house";
import { Consumer } from "../context";
import ReservationTable from '../components/commonAreasReservation/reservationTable'
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";
import moment from 'moment-timezone';
const request = new Request();
class CommonAreasReservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            info: 					[],
            inicial: 				0,
            horarios: 				[],
            horario: 				0,
            pidViv: 				0,
			fecha: 					'',
			fecha2:					'',
            user: {
                idUsuario: 			0,
                idPerfil: 			0,
				idFraccionamiento: 	0,
				nombre: 			''
			},
			page: 					1,
			filter: 				'',
			subfraccionamiento: 	localStorage.getItem('subfrac'),
			subIdSelect: 			'',
			subfracsList: 			[],
			reservas: 				[],
			readOnly:				true
        }
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getReservas();
		}
		if(this.state.inicial !== prevState.inicial) {
			this.getSchedule()
		}
	}

    /*funcion del paginador*/ 
	setPage(page) {
		this.setState({page});
	}

    changeFrac() {
        this.getReservas()
        // this.getEmergencys()
	}

    reservas() {
        this.getReservas()
    }

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevaArea() {
		this.getCommonArea();
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(CommonAreas) {
		CommonAreas = CommonAreas.filter(com => {
			if(com.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (com.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return CommonAreas;
	}

    render() {
		let CommonAreas = [];
		let dataSet;

        if (Array.isArray(this.state.reservas)) {
			CommonAreas = this.state.reservas;
			dataSet = CommonAreas;
			CommonAreas = this.filter(CommonAreas);
			// console.log(CommonAreas);
		}
	
        return (
            <div className="houses column">
                <Helmet>
                    <title>iGate - Administración</title>
                </Helmet>
                <Header sidebar={true} active={'reservas'} parent={'areas'} panel={'panel3'} nav={"administracion"} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <Controls title="Reservaciones"
									nuevo		=	{this.openModal.bind(this,"newReservation")}
									dataSet		=	{dataSet}
									setFilter	=	{this.setFilter.bind(this)}
									subChange	=	{this.subChange.bind(this)}/>
                                <div className="white-space-16"></div>
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
                                	<ReservationTable reservas={CommonAreas} page={this.state.page} reserva={this.reservas.bind(this)} reload={this.getReservas.bind(this)} />
								}
								<div className="white-space-16"></div>
								<Paginador pages={Math.ceil(CommonAreas.length / 7)} setPage={this.setPage.bind(this)}/>
							</div>
                        </div>
                    </div>
                </div>

				<DinamicModal 
					idModal					=	{'newReservation'}
					sizeModalContainer		=	{'big'}
					title					=	{'NUEVA RESERVA'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.messageSave}
				>
				{this.renderAreasReservation()}
				</DinamicModal>

            </div>
        );
	}
	
    componentDidMount() {
		
		this.load();
		// console.log("Pedir reservas");
		this.getReservas();
		// console.log("Termine de pedirlas");
		this.getSubFraccionamientos();
		this.getViviendas(this.state.subIdSelect);
	}

	openModal(idModal) {
		this.getCommonArea();
		this.setState({messageSave: null, message: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
	closeModal(idModal){
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	renderAreasReservation() {
		let viviendas = [];
		let nameUser = '';

		if(this.state.user.nombre) {
			nameUser = this.state.user.nombre;
		}
		if(Array.isArray(this.state.viviendas)) {
			viviendas = this.state.viviendas;
		}

		return (
		<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p>
							<b>Área a reservar:</b>
						</p>
					</div>
					<div className="column full">
						{/* event => this.setState({ inicial: event.target.value }) */}
						<select name="categoria" id="area" className="input" value={this.state.inicial} onChange={this.handleChange.bind(this)} >
							{this.state.info.map((info, key) => (
								<option key={key} value={info.id_espacio}>{info.nombre}</option>

							))}
						</select>
					</div>
				</div>
				<div className="white-space-16"></div>
				
				<div className="input-form-content row full align-center">
					<div className="column label-duo-big">
						<p><b>Desdes el día:</b></p>
					</div>
					<div className="column full">
						<input 
								type		=	"date"  
								name		=	"fecha" 
								id			=	"fecha" 
								className	=	"input" 
								value		=	{this.state.fecha} 
								onChange	=	{this.cambioFecha.bind(this)}
								min			=	{this.state.min}
						/>
					</div>
					<div className="column label-duo-normal space">
						<p><b>Hasta el día:</b></p>
					</div>
					<div className="column full">
					<input 
								type		=	"date" 
								name		=	"fecha2" 
								id			=	"fecha2" 
								className	=	"input" 
								value		=	{this.state.fecha2} 
								readOnly			 
								onChange	=	{this.cambioFecha.bind(this)} 
								min			=	{this.state.fecha}
								max 		= 	{this.state.max}
					/>
					</div>
				</div>

				{/*  */}
				<div className="input-form-content row full align-center">
					
					<div className="column label-medium ">
						<p>
							<b>Horario:</b>
						</p>
					</div>
					<div className="column full">
						<select name="categoria" id="horario" className="input" value={this.state.horario} onChange={event => this.setState({ horario: event.target.value })} >
							<option value="0">SELECCIONAR HORARIO</option>
							{this.state.horarios.map((horario, key) => (
								<option key={key} value={horario.id_horario}>{horario.descripcion}</option>
							))}
						</select>
					</div>
				</div>

				{/*  */}
				<div className="white-space-16"></div>


			
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b> Subfraccionamiento:	</b></p>
					</div>

					<div className="column full">
						<select name="subfraccionamientoId" id="subfraccionamientoId" className="input input-select"
							value={this.state.subIdSelect}
							onChange={this.subfracChange.bind(this)}>
							<option value="0">SELECCIONAR SUBFRACCIONAMIENTO</option>
							{  
								this.state.subfracsList.map((subfraccionamiento, key) => (
								<option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>)) 
							}
						</select>
					</div>
				</div>

				<div className="white-space-16"></div>
				
				
				
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b> Unidad:	</b></p>
					</div>

					<div className="column full">
						<select className="input" name="vivienda" value={this.state.pidViv} onChange={(event) => this.setState({pidViv: event.target.value})}>
							<option value="0">SELECCIONAR VIVIENDA</option>
							{viviendas.map(viv => 
								<option key={viv.id_vivienda} value={viv.id_vivienda}>
									{viv.numero_registro}
								</option>
							)}
						</select>
					</div>
					
				</div>
				<div className="white-space-16"></div>
			
				<div className="input-form-content row full align-center">
					<div className="column label-duo-big">
						<p>
							<b>Deposito Fianza:</b>
						</p>
					</div>
					<div className="column full">
						<input type="number" name="fianza" className="input input-modals" placeholder="" required
							maxLength="150"
						/>
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>Recibido por:</b>
						</p>
					</div>
					<div className="column full">
						<input type="text" disabled className="input input-modals" 
							value={this.state.user.nombre}
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="column label">
					<p><b> Comentarios:	</b></p>
				</div>
				<div className="white-space-8"></div>
				<div className="align-center row full justify-between">

					<div className="column full">
						<textarea name="comentarios" id="" cols="30" rows="3" className='textarea full'></textarea>
					</div>

				</div>
			</div>
		</div>
		)
	}


	subfracChange(event) {
		let idSub = event.target.value;
		this.setState({subIdSelect: idSub});
		this.getViviendas(idSub);
	}


	async getViviendas(idSubfrac) {
		const frac = localStorage.getItem('frac');
		const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", 
		{	id_fraccionamiento: frac,
			id_subfraccionamiento: idSubfrac}
		);
		if(Array.isArray(response.viviendas)) {
			this.setState({viviendas: response.viviendas});
		} else {
			this.setState({viviendas: []});
		}
	}
	
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
        if (response && !response.error) {
            if (response.subfraccionamiento && !response.empty) {
                this.setState({
					subfracsList: response.subfraccionamiento,
					subIdSelect: this.state.subfraccionamiento ?  this.state.subfraccionamiento[0].id_subfraccionamiento : ''
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    subfracsList: []
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
		}
		this.setState({loadingSub: false});
    }
	

    async load() {
		const user = await this.props.context.loadUser(); 
        if (user) {
            this.setState({
                user: {
                    idUsuario: 			user.id.id_usuario,
                    idPerfil: 			user.id.id_perfil,
					idFraccionamiento: 	user.id.id_fraccionamiento,
					nombre: 			user.id.nombre
                }
            });

        }
	}

    async cambioFecha(event) {
		let inputDate = event.target;
		if(inputDate.id === "fecha"){
			let format 		= "YYYY-MM-DD";
			let date1 		=  moment(inputDate.value).tz("GMT").format(format);
			document.getElementById("fecha2").removeAttribute("readOnly");
			
			let area 		= this.state.info.find(element => element.id_espacio == this.state.inicial);
			let maxd 		=  moment(inputDate.value).add(parseInt(area.diasmax) -1 ,'days').tz("GMT").format(format);
			console.log(area);
			await this.setState({ fecha: inputDate.value, fecha2: inputDate.value, max: maxd });
		}else{
			await this.setState({ fecha2: inputDate.value });
		}
		
		await this.getSchedule();
		
		// let date2 =  moment(inputDate.value).add(1,'days').tz("GMT").format(format);
			// let date3 =  moment(date2,'DD-MM-YYYY').add(1,'days').tz("GMT").format(format);
			// console.log(date2 + " === " +date1 + " === "+ date3);
			// console.log(moment(date3,format).from(moment(date1,format)));

			
	}

    async getReservas() {
		this.setState({ reservas: [], loading: true });
		
		let idSub = this.state.subfraccionamiento || 0;
        let data = {
            Id: 	localStorage.getItem('frac'),
			fecha: 	moment().format('YYYY-MM-DD'),
			IdSub: 	idSub
        };

		
        const response = await request.post("/commonArea/get/reservas", data)
        if (response && !response.error) {
			
            if (response.info && !response.empty) {
				
				this.setState({ reservas: response.info });
			
            }

        } else {
		
            this.setState({
                error: true,
                message: response.message
            });
        }
		this.setState({loading: false});
	}
	
	

    vivienda = data => {
        this.setState({
            pviv: 			data.numero_registro,
            pidViv: 		data.id_vivienda,
            searchHouse: 	false

        });
	}

    async handleChange(event) {
		await this.setState({ inicial: event.target.value });

		let area 		= this.state.info.find(element => element.id_espacio == this.state.inicial);
		
		let minday 		= moment().format('YYYY-MM-DD');
		minday 			= moment(minday,'YYYY-MM-DD').add(parseInt(area.dias_anticipacion -1) ,'days').tz("GMT").format('YYYY-MM-DD');
		document.getElementById("fecha2").removeAttribute("readOnly");
		let maxd 		=  moment(minday,'YYYY-MM-DD').add(parseInt(area.diasmax)-1  ,'days').tz("GMT").format('YYYY-MM-DD');


		await this.setState({min: minday, fecha: minday, fecha2: minday,max: maxd});

		
		await this.getSchedule();
	}
	

    async getCommonArea() {
        let data = {
            id: localStorage.getItem('frac')
        }
        const response = await request.post("/commonArea/get/info", data)

        if (response && !response.error) {
            if (response.info && !response.empty) {
				let minday = moment().format('YYYY-MM-DD');
				let add 	=  response.info[0].dias_anticipacion;
				minday 		= moment(minday,'YYYY-MM-DD').add(add,'days').tz("GMT").format('YYYY-MM-DD');
				document.getElementById("fecha2").removeAttribute("readOnly");

				this.setState({ info: response.info, inicial: response.info[0].id_espacio, min: minday, fecha:minday, fecha2: minday });
				// moment().format('YYYY-MM-DD')
				// et mind 		=  moment(inputDate.value).add(parseInt(area.dias_anticipacion),'days').tz("GMT").format(format);

            }

        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
    }

    async getSchedule() {
		
        this.setState({ horarios: [] })
        let data = {
			id: 	this.state.inicial,
			fecha: 	this.state.fecha,
			fecha2:	this.state.fecha2,
		}
		const response = await request.post("/commonArea/get/available/schedule", data);

        if (response && !response.error) {
            if (response.commonSchedules && !response.empty) {
                await this.setState({
                    horarios: response.commonSchedules
				});
				
            } else {
                this.setState({
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }

    }
    async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true, messageSave: ''});
        let form = event.target
		let valida = this.state.pidViv;
		const idHorario = parseInt(this.state.horario);
		if(idHorario <= 0 || isNaN(idHorario)) {
			this.setState({loadingSave: false});
			alert('Debe seleccionar un horario válido');
            return
		}
		if(moment(this.state.fecha).diff(moment(), 'days') < 0) {
			this.setState({loadingSave: false});
			alert('La fecha no puede ser anterior a la actual');
            return
		}
        if (valida <= 0) {
			this.setState({loadingSave: false});
			alert('Debes seleccionar una vivienda');
            return
        } else {

			let format = "YYYY-MM-DD";
			let f1 = moment(this.state.fecha).tz("GMT").format(format);
			let f2 = moment(this.state.fecha2).tz("GMT").format(format);
			
		

			let dif = moment(f1,format).diff(moment(f2,format),'days');
			dif		= Math.abs(dif);
			
			

            let data = {
				idFracc:  		localStorage.getItem('frac'),
                idHorario,
                area: 			this.state.inicial,
                fecha: 			this.state.fecha,
                idviv:  		parseInt(valida),
                idUsuario:  	parseInt(this.state.user.idUsuario),
                fianza: 		parseInt(form.fianza.value),
				comentarios: 	form.comentarios.value,
				diferencia:		dif
			}
			

			const response = await request.post("/commonArea/save/reservation", data);

            
                if (response.created && !response.empty) {
                    this.setState({
                        showModal: false,
                        info: 		[],
                        inicial: 	0,
                        horarios: 	[],
                        horario: 	0,
                        pidViv: 	0,
                        pviv: 		'',
						fecha: 		'',
						messageSave: 'AREA RESERVADA CORRECTAMENTE'
					});
					this.getReservas();
					this.closeModal("newReservation");
                } else {
                    this.setState({
                        messageSave: "NO SE PUDO AGREGAR NUEVA RESERVA"
                    });
                }
            
        }
		
		this.setState({loadingSave: false});
    }

}

export default Consumer(CommonAreasReservation);