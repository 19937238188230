/**
 *  invitation.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de visualización del QR
*/

import React, { Component } from "react";
import HeaderSimple from "../components/header/header-simple";
import Request from "../core/httpClient";
import moment from "moment";
import { Helmet } from "react-helmet";
const request = new Request();

class Invitation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: '', 
            data: '',
            nombre_invitado: '',
            expiracion: moment()
        }
    }

    render() {
        return(
            <div className="invitation column">
                {/* <Helmet>
                    <meta property="og:title" content="iGate - Invitación" />
                    <meta property="og:description" content="Tienes una nueva invitación desde iGate - Click para ver" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="/" />
                </Helmet>
                <HeaderSimple /> */}
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <div className="justify-center">
                            {
                                this.state.loading ?
                                    (<i className="fas fa-spinner fa-2x fa-spin"></i>):
                                    (<img src={this.state.qr} alt="qr" width="250px" height="250px"/>)
                            }
                        </div>
                        <div className="column">
                            <div className="white-space-32"></div>
                            <p className="text-center">
                                Invitado: <b>{ this.state.nombre_invitado }</b>
                            </p>
                            <div className="white-space-16"></div>
                            <p className="text-center">
                                Fecha de expiración: <b>{ moment(this.state.expiracion).format("DD-MM-YYYY") }</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        if(this.props.match.params) {
            this.load(this.props.match.params);
        }
    }

    async load(params) {
        this.setState({loading: true});
        const res = await request.post('/invitaciones/get/databytoken', {token: params.id});
        if(res.error) {
            this.setState({error:res.error, message: res.message, loading: false});
            return;
        }
        if(res.number) {
            this.setState({telephone: res.number, id: res.id, vivienda: res.vivienda, inquilino: res.inquilino});
            const data = {
                id: res.id,
                number: res.number,
                vivienda: this.state.vivienda,
                inquilino: this.state.inquilino
            }
            const res2 = await request.post('/invitaciones/set/invitation', data);
           // console.log(res2);
            if(res2.qr) {
                this.setState({qr: `data:image/jpeg;base64,${res2.qr}`, nombre_invitado: res2.nombre_invitado, expiracion: res2.expiracion, showQR: true});
            }
            this.setState({error:res2.error, message: res2.message, loading: false});
        }
    }

}

export default Invitation;
